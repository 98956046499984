import { IModalAction, IModalState } from './modal.resources';
import { types } from './modal.types';

const initialState: IModalState = {
  data: {},
  register: [],
};

export default function modalReducer(state: IModalState = initialState, action: IModalAction): IModalState {
  const actionType = {
    [types.DATA]: {
      ...state,
      data: action.data,
    },
    [types.REGISTER]: {
      ...state,
      register: action.register,
    },
  };

  return actionType[action.type] ?? state;
}
