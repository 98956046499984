import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';
import DivTarget from '../DivTarget';
import Hint from '../Hint';

interface IProps {
  children: any;
  position?: 'right' | 'left';
  label: string;
}

const Die: FC<IProps> = ({ position, children, label }) => {
  const classesPrompt = classNames('die', {
    [`die--${position}`]: position,
  });

  return (
    <div className={classesPrompt}>
      {children}
      <Hint label={label} move={['right', 'bottom']}>
        {(ref) => <DivTarget innerRef={ref} className="die__polygon" />}
      </Hint>
    </div>
  );
};

export default Die;
