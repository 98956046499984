export default {
  EN: {
    'storage.tlt': 'File manager',
    'storage.free': 'free',
    'storage.file.type.all': 'All file types (*.*)',
    'storage.file.s': 'Files',
    'storage.image.s': 'Images (jpg / jpeg, png, tiff)',
    'storage.book.s': 'Books',
    'storage.video.s': 'Video files (mp4, avi, WMA, WebM, MKV, RM, RMVB, MOV, MPG, FLV, WMV, ASF, TS, 3G2, 3GP, OGV)',
    'storage.audio.s': 'Audio files` (mp3, WMA)',
    'storage.crt.folder': 'Create folder`',
    'storage.crt.folder.pls': 'Input folder name',
    'storage.upload.tlt': 'Uploading',
    'storage.upload.not.tlt': 'Stop uploading file?',
    'storage.upload.err.copy': 'File with this name exists already.',
    'storage.upload.end': 'Upload finished!',
    'storage.upload.repeat': 'Repeat upload',
    'storage.delete.cnf': 'Do you really want to delete parmanently?',
  },
};
