import React from 'react';

interface IProps {
  className?: string;
}

export default function Mail({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16" {...params}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M10 7C5.63 7 0 2.604 0 2.604v-1.27C0 .957.124.641.371.384A1.18 1.18 0 011.25 0h17.5c.338 0 .631.128.879.385.247.257.371.573.371.948v1.271S14.37 7 10 7zm-.625 1.333c.488.026.762.026 1.25 0C14.738 8.116 20 4.146 20 4.146v10.52c0 .362-.124.674-.371.938-.248.264-.54.396-.879.396H1.25c-.339 0-.632-.132-.879-.396A1.325 1.325 0 010 14.667V4.146s5.262 3.97 9.375 4.187z"
      />
    </svg>
  );
}
