import { CONFIG } from '../env';
import Provider from '../providers';

interface IRouters {
  [key: string]: string;
}

interface IUri {
  [key: string]: Function;
}

type IQueryParams = IRouters | null;

const types: IRouters = {
  EVENTS: `/events`,
  PARTICIPANTS: `/participants/events`,
  PARTICIPANTS_HOMEWORK: `/participants/homeworks`,
  EVENT_STRUCTURES: `/event-structures`,
  EDUCATION: `/tests`,
  GENERAL: `/storage`,
  GROUPS: '/groups',
};

const generateStorageUri: Function = (endpoint: string, type: string): Function => {
  return (id: number | string, params: IQueryParams = null, endPath = CONFIG.REACT_APP_API_URL) => {
    return `${endPath}${endpoint}/${id}/${type}${params ? Provider.createQueryParams(params) : ''}`;
  };
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI

const generateStorageUriComment: Function = (endpoint: string): Function => {
  return (data, params: IQueryParams = null, endPath = CONFIG.REACT_APP_API_URL) => {
    const { eventId, elementId, commentId } = data;

    return `${endPath}${endpoint}/${eventId}/${elementId}/comment/${commentId}/file${
      params ? Provider.createQueryParams(params) : ''
    }`;
  };
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI

export const URI_EVENTS: IUri = {
  cover: generateStorageUri(types.EVENTS, 'cover'),
  miniCover: generateStorageUri(types.EVENTS, 'mini-cover'),
  file: generateStorageUri(types.EVENT_STRUCTURES, 'file'),
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI

export const URI_PARTICIPANTS: IUri = {
  cover: generateStorageUri(types.PARTICIPANTS, 'cover'),
  miniCover: generateStorageUri(types.PARTICIPANTS, 'mini-cover'),
  file: generateStorageUri(types.PARTICIPANTS_HOMEWORK, 'file'),
  fileComment: generateStorageUriComment(types.PARTICIPANTS_HOMEWORK),
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI

export const URI_EDUCATION: IUri = {
  cover: generateStorageUri(types.EDUCATION, 'cover'),
  miniCover: generateStorageUri(types.EDUCATION, 'mini-cover'),
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI

export const URI_GROUPS: IUri = {
  cover: generateStorageUri(types.GROUPS, 'cover'),
  miniCover: generateStorageUri(types.GROUPS, 'mini-cover'),
};

export const URI_GENERAL: IUri = {
  download: generateStorageUri(types.GENERAL, 'download'),
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n and work new generation on URI
//

const generation = (pathPoint, params = {}) => {
  const END_POINT = CONFIG.REACT_APP_URI_URL;

  const PATH_POINT = pathPoint;

  const PARAMS = Provider.createQueryParams(params);

  return `${END_POINT}${PATH_POINT}${PARAMS}`;
};

export const URI = {
  STORAGE: {
    FILE: (fileId) => generation(`/storage/${fileId}/download`),
  },
  EVENTS: {
    STRUCTURE_USER: {
      COVER: (eventId, elementId, params = {}) =>
        generation(`/participants/event-structures/${eventId}/${elementId}/file`, params),
      DOWNLOAD: (eventId, elementId, params = {}) =>
        generation(`/participants/event-structures/${eventId}/${elementId}/file`, params),
      COVERING: (eventId, elementId, params = {}) =>
        generation(`/participants/event-structures/${eventId}/${elementId}/cover`, params),
      MINI_COVER: (eventId, elementId, params = {}) =>
        generation(`/participants/event-structures/${eventId}/${elementId}/mini-cover`, params),
    },
    ADMIN_LIST_COVER: (eventId) => generation(`/events/${eventId}/cover`),
    ADMIN_LIST_MINI_COVER: (eventId) => generation(`/events/${eventId}/mini-cover`),
    USER_LIST_MINI_COVER: (eventId) => generation(`/participants/events/${eventId}/mini-cover`),
    USER_COVER: (eventId) => generation(`/participants/events/${eventId}/cover`),
    USER_LIST_MINI_COVER_INVITATIONS: (eventId) => generation(`/participants/invitations/${eventId}/mini-cover`),
    EXPORT_EXEL: (eventId) => generation(`/events/${eventId}/statistic/download-file`),
    REQUEST_PARTICIPANTS: (eventId) => generation(`/participants/event-requests/${eventId}/cover`),
  },
  TESTS: {
    ADMIN_LIST_MINI_COVER: (testId) => generation(`/tests/${testId}/mini-cover`),
  },
  INVITE_EVENTS: {
    COVER: (token) => generation(`/participants/invitations/${token}/cover`),
    MEMBERS_COVER: (token) => generation(`/event-members/${token}/cover`),
    MINI_COVER: (token) => generation(`/participants/invitations/${token}/mini-cover`),
    MEMBERS_MINI_COVER: (token) => generation(`/event-members/${token}/mini-cover`),
  },
  GROUPS: {
    COVER: (id) => generation(`/groups/${id}/cover`),
    MINI_COVER: (id) => generation(`/groups/${id}/mini-cover`),
  },
  HOMEWORK: {
    ADMIN: (eventId, elementId, params) => generation(`/homeworks/${eventId}/${elementId}/file`, params),
    ADMIN_COMMENT: (eventId, elementId, commentId, params) =>
      generation(`/homeworks/${eventId}/${elementId}/comment/${commentId}/file`, params),
  },
  QUESTIONS: {
    AUDIO: (questionId) => generation(`/questions/${questionId}/audio`),
    IMAGE: (questionId) => generation(`/questions/${questionId}/image`),
    VIDEO: (questionId) => generation(`/questions/${questionId}/video`),
    VIDEO_ATTEMPT: (eventId, attemptId, questionId) =>
      generation(`/attempt-questions/${eventId}/${attemptId}/${questionId}/video`),
    VIDEO_COVER_ATTEMPT: (eventId, attemptId, questionId) =>
      generation(`/attempt-questions/${eventId}/${attemptId}/${questionId}/video-cover`),
    AUDIO_ATTEMPT: (eventId, attemptId, questionId) =>
      generation(`/attempt-questions/${eventId}/${attemptId}/${questionId}/audio`),
    VIDEO_COVER: (questionId) => generation(`/questions/${questionId}/video-cover`),
  },
  ADMIN: {
    POST_COVER: (fileId) => generation(`/public/file/${fileId}`),
  },
};
