import React from 'react';

interface IProps {
  className?: string;
}

export default function ELUpload(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="29" fill="none" viewBox="0 0 35 29" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M24.596 24h2.7C31 24 34 20.898 34 17.084c0-3.454-2.472-6.3-5.689-6.809C27.305 4.988 22.781 1 17.351 1 11.232 1 6.262 6.06 6.18 12.34 3.278 12.594 1 15.1 1 18.155 1 21.382 3.543 24 6.676 24h3.728"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21.872 19.436L17.436 15 13 19.436m4.43 7.566V17.2"
      />
    </svg>
  );
}
