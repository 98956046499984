import React from 'react';

function ScoreMobile() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="none" viewBox="0 0 36 36">
      <circle cx="18" cy="18" r="16.2" fill="var(--el-fonts--icon-color)" stroke="#494949" strokeWidth="3.6" />
      <path
        stroke="var(--el-fonts--title-color)"
        strokeWidth="3.06"
        d="M21.363 16.577l-3.196-3.835a.154.154 0 00-.237 0l-3.197 3.836a.154.154 0 01-.151.052l-4.04-.888a.154.154 0 00-.181.195l2.206 7.353c.02.065.08.11.148.11h10.618a.154.154 0 00.148-.111l2.16-7.352a.154.154 0 00-.182-.194l-3.944.886a.154.154 0 01-.152-.052z"
      />
    </svg>
  );
}

export default ScoreMobile;
