import './index.scss';
import React, { FC, ReactNode, useMemo } from 'react';
import { EditorText } from '../../app/app.modules';
import { useToState } from '../../hooks';
import Text from '../Text';
import Textarea from '../Textarea';

interface IProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  defaultValue?: string;
  onBlur?(data: string): void;
  onChange?(data: string): void;
  placeholder?: string | ReactNode;
}

const LabelDesc: FC<IProps> = ({ title, description, defaultValue, onBlur, placeholder }) => {
  const [state, setState] = useToState({
    value: defaultValue ?? '',
    isFocus: defaultValue !== '' ?? false,
  });

  const onValid = state.value !== defaultValue;

  const renderTitle = () => {
    return <Text className="label-desc__title">{title}</Text>;
  };

  const renderDesc = () => {
    return (
      description && (
        <Text variant="md" className="label-desc__desc">
          {description}
        </Text>
      )
    );
  };

  const onChangeDescription = (value) => {
    setState({
      value,
    });
  };

  const onBlurChange = (value) => {
    onValid && onBlur && onBlur(value);
  };

  const onFocusChange = () => {
    setState({
      isFocus: !state.isFocus,
    });
  };

  const renderDescription = () => {
    const { isFocus } = state;

    return isFocus ? (
      <EditorText
        data={state.value}
        onChange={onChangeDescription}
        minHeight={100}
        className="label-desc__description"
        onBlur={onBlurChange}
      />
    ) : (
      <Textarea
        className="label-desc__description"
        variant="answer"
        value={state.value}
        onChange={onChangeDescription}
        placeholderNode={placeholder}
        onBlur={onBlurChange}
        onFocus={onFocusChange}
      />
    );
  };

  return useMemo(
    () => (
      <div className="label-desc">
        {renderTitle()}
        {renderDesc()}
        {renderDescription()}
      </div>
    ),
    [state.value, state.isFocus, defaultValue]
  );
};

export default LabelDesc;
