import './index.scss';
import React, { FC, ReactElement } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useErrorFields, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { parserName, uuid } from '../../../utils';
import { Button, Icon, Input, Text } from '../../app.modules';
import MiddlewareIntraSystemSearch from '../../Middleware/MiddlewareIntraSystemSearch';

interface IProps {
  data: {
    params: any;
    setAlert?(data: any): void;
  };
  onDestroy: any;
  ButtonClose?: any;
}

const ModalInvitations: FC<IProps> = function ({ data, onDestroy, ButtonClose }): ReactElement {
  const [state, setState] = useToState({
    change: false,
    isSendConfirm: false,
    fields: {
      email: '',
      firstName: '',
      lastName: '',
      patronymic: '',
      ...(data?.params?.role && {
        role: data?.params?.role,
      }),
    },
    attachedFields: [],
    errorUsers: false,
  });

  const onValidUsers = (email) => {
    const usersData = data?.params?.usersData?.data;

    return !usersData?.some((el) => el.email === email);
  };

  const [error, setError] = useErrorFields(state.fields, (value, key) => {
    switch (key) {
      case 'firstName':
      case 'lastName':
        return value.length;
      case 'email':
        const isUniqueEmail = onValidUsers(value);

        return isUniqueEmail && isEmail(value);
      default:
        return true;
    }
  });

  const { params } = data;

  const loading = params?.loading;

  const onChangeEndCode = () => {
    if (!loading?.endCode || !data?.setAlert) return null;

    data.setAlert({
      code: loading.endCode,
      uuid: uuid(),
      params: {},
    });
  };

  const onConfirm = async () => {
    setState({
      isSendConfirm: true,
    });

    await data.params?.onConfirm(state.attachedFields);

    onChangeEndCode();

    onDestroy();
  };

  const onChangeFields = (key) => (e) => {
    const { value } = e.target;

    setError(key, value);

    setState({
      change: true,
      fields: {
        ...state.fields,
        [key]: value,
      },
    });
  };

  const onChangeFieldsEmail = (key) => (option) => {
    const { firstName, lastName, patronymic, email } = option;

    setError(key, email);

    setState({
      change: true,
      fields: {
        ...state.fields,
        [key]: email,
        firstName,
        lastName,
        patronymic,
      },
    });
  };

  const onAttachField = () => {
    const { fields } = state;

    setError(state.fields)
      .then(() => {
        setState({
          fields: {
            email: '',
            firstName: '',
            lastName: '',
            patronymic: '',
            ...(data?.params?.role && {
              role: data?.params?.role,
            }),
          },
          attachedFields: [
            ...state.attachedFields,
            {
              ...fields,
              uuid: uuid(),
            },
          ],
        });
      })
      .catch(() => {
        setState({
          isSendConfirm: false,
        });
      });
  };

  const onDeleteField = (data) => () => {
    const cloneAttachedFields = [...state.attachedFields];

    const filtered = cloneAttachedFields.filter((el) => el.uuid !== data.uuid);

    setState({
      attachedFields: filtered,
    });
  };

  const renderAttachFields = () => {
    const { attachedFields } = state;

    return (
      attachedFields.length > 0 && (
        <div className="modal-invitations__attach">
          <div className="modal-invitations__attach-fields">
            <div className="modal-invitations__attach-field modal-invitations__attach-field--label">
              <Text className="modal-invitations__attach-name">
                <strong>{translate('g.usr')}</strong>
              </Text>
              <Text className="modal-invitations__attach-email">
                <strong>{translate('g.email')}</strong>
              </Text>
            </div>
            {attachedFields.map((el, i) => (
              <div className="modal-invitations__attach-field" key={i}>
                <Text className="modal-invitations__attach-name">
                  {parserName(el.firstName, el.lastName, el.patronymic)}
                </Text>
                <Text className="modal-invitations__attach-email">{el.email}</Text>
                <Icon name="ELCloseModal" className="modal-invitations__attach-icon" onClick={onDeleteField(el)} />
              </div>
            ))}
          </div>
          <div className="modal-invitations__attach-send">
            <Button
              size="md"
              loading={state.isSendConfirm}
              off={state.isSendConfirm || !state.change}
              onClick={onConfirm}
            >
              {translate('g.btn.ivt')}
            </Button>
          </div>
        </div>
      )
    );
  };

  return (
    <div className="modal-invitations">
      <ButtonClose />
      <h1 className="modal-invitations__title">{params?.name}</h1>
      <div className="modal-invitations__fields">
        <MiddlewareIntraSystemSearch
          placeholder={translate('g.fld.email.pls')}
          className="modal-invitations__field"
          label={translate('g.email')}
          error={!error.email}
          value={state.fields.email}
          onChange={onChangeFields('email')}
          onOption={onChangeFieldsEmail('email')}
        />
        <Input
          size="md"
          label={translate('g.fld.last.name')}
          placeholderNode={translate('g.fld.last.name.pls')}
          className="modal-invitations__field"
          template="column"
          onChange={onChangeFields('lastName')}
          error={!error.lastName}
          value={state.fields.lastName}
        />
        <Input
          size="md"
          label={translate('g.fld.first.name')}
          placeholderNode={translate('g.fld.first.name.pls')}
          className="modal-invitations__field"
          template="column"
          onChange={onChangeFields('firstName')}
          error={!error.firstName}
          value={state.fields.firstName}
        />
        <Input
          size="md"
          label={translate('g.fld.middle.name')}
          placeholderNode={translate('g.fld.middle.name.pls')}
          className="modal-invitations__field"
          template="column"
          onChange={onChangeFields('patronymic')}
          error={!error.patronymic}
          value={state.fields.patronymic}
        />
        <Button
          off={state.isSendConfirm || !state.change}
          className="modal-invitations__field"
          size="md"
          variant="outlined"
          monochrome={state.isSendConfirm || !state.change}
          onClick={onAttachField}
        >
          {translate('g.btn.add')}
        </Button>
      </div>
      {renderAttachFields()}
    </div>
  );
};

export default ModalInvitations;
