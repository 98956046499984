import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELImage(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 7a6 6 0 016-6h10a6 6 0 016 6v10a6 6 0 01-6 6H7a6 6 0 01-6-6V7z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M22.673 17.192l-.404-.346-.807-.692-1.616-1.385-2.838-2.432a.5.5 0 00-.716.067l-4.866 6.083a.5.5 0 01-.744.041L8.2 16.046a.5.5 0 00-.707 0L5.308 18.23l-1.27 1.27-.634.635-.317.317"
      />
      <circle cx="8.769" cy="7.846" r="1.385" fill="var(--el-fonts--icon-color)" />
    </svg>
  );
}
