import React from 'react';

export default function Cup() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none" viewBox="0 0 21 20">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M16.825 2a2 2 0 00-2-2H6.341a2 2 0 00-2 2v.548H1a1 1 0 00-1 1v4.258c0 2.806 2.93 5.115 5.806 5.355.944 1.08 1.61 1.866 3.087 2.2v1.898C7.48 17.723 5.951 18.813 5.806 20h9.698c-.146-1.187-1.932-2.279-3.348-2.741V15.36c1.483-.334 2.223-1.12 3.169-2.2C18.199 12.92 21 10.612 21 7.806V3.548a1 1 0 00-1-1h-3.175V2zM2.328 4.924H4.24v3.252c0 1.234.297 2.407.821 3.464-2.174-.74-2.733-2.646-2.733-4.877V4.924zm13.613 6.916a8.376 8.376 0 00.83-3.62V4.966h1.918v1.78c0 2.331-.566 4.322-2.748 5.095z"
        clipRule="evenodd"
      />
    </svg>
  );
}
