export const typesField = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  QUESTIONS_AMOUNT: 'questionsAmount',
  MAX_TRIES: 'maxTries',
  STATUS: 'status',
  BEST_ATTEMPT_SCORE: 'bestAttemptScore',
  BEST_ATTEMPT_TIME: 'bestAttemptTime',
  CREATED_AT: 'createdAt',
  CLOSED_AT: 'closedAt',
  CORRECT_ANSWER_COUNT: 'correctAnswerCount',
  FALSE_ANSWER_COUNT: 'falseAnswerCount',
  SPENT_TIME: 'spentTime',
  FINAL_SCORE: 'finalScore',
};

export const typesAnswer = {
  CORRECT: 'correct',
  NO_CORRECT: 'no-correct',
  NO_ANSWER: 'no-answer',
  EMPTY: 'empty',
};

export const typesPanel = {
  ADMIN_PANEL: 'ADMIN_PANEL',
  DASHBOARD_PANEL: 'DASHBOARD_PANEL',
};

export const typeViewCloseAttemptTable = {
  CLOSE: 'CLOSE',
  OPEN: 'OPEN',
};

export const typesParticipantEventStatus = {
  NOT_YET: 'notYet',
  COMPLETED: 'completed',
  IN_PROGRESS: 'inProgress',
};

export default {
  EVENTS: 'STATS_EVENTS',
};
