import '../index.scss';
import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import Modal from '../../../components/ModalDeprecated';
import ConfirmDelete from '../../../components/modals/ConfirmDelete';
import DuplicateFolder from '../../../components/modals/DuplicateFolder';
import { useGetParams } from '../../../hooks';
import translate from '../../../i18n/translate';
import { Button, Icon, Input, Loader, Portal, Scroll, Select, Square, Text } from '../../app.modules';
import Bridge from '../Data/Bridge';
import StorageContext from '../Data/StorageContext';
import StorageNewFolder from '../Data/StorageNewFolder';
import StorageService from '../Data/StorageService';
import DuplicateFile from '../DuplicateFile';
import ProgressBar from '../ProgressBar';
import StopUploadFile from '../StopUploadFile';
import { IStorageFileFrom } from '../storage.resources';
import { typesAccept } from '../storage.types';

interface IProps {
  accept?: Array<string>;
  onChange?(data: IStorageFileFrom): void;
}

const StoragePolygon: FC<IProps> = ({ onChange, accept }): any => {
  const polygonRef = useRef(null);

  const queryAccept = useGetParams('accept');

  const renderGrid = (service, action) => {
    const { data, loading } = service;

    return loading ? (
      <Loader />
    ) : (
      data.map(
        (item, index) =>
          item?.type &&
          Bridge.getType({
            file: item,
            service,
            action,
            index,
          })
      )
    );
  };

  const renderRoots = (service, action) => {
    const { onChangeRootFolder } = action;

    const { roots, options } = service;

    return roots.map((item, i) => {
      const isActive = item.root_folder.type === options.root_folder.type;

      const classes = classNames('storage__aside-li', {
        'storage__aside-li--active': isActive,
      });

      return (
        <div key={i} className={classes} onClick={() => onChangeRootFolder(item)}>
          <Icon name="ArrowSwipe" />
          <Text variant="md" className="storage__aside-li-text">
            <strong>{item?.name ?? '--'}</strong>
          </Text>
        </div>
      );
    });
  };

  const onChangeFileType = (action) => (data) => {
    action.onChangeFileType(data);
  };

  const renderFileType = (action) => {
    const searchType = accept ?? [queryAccept ?? typesAccept.ALL];

    const dataSelect = Bridge.getAcceptTypesSelect(searchType);

    return (
      <div className="storage__select">
        <div className="storage__select-type">
          <Select update={false} onChange={onChangeFileType(action)} data={dataSelect} />
        </div>
      </div>
    );
  };

  const renderPanel = (service, action) => {
    // const { processType } = service;

    const isAccept = Bridge.getAcceptType(accept ?? [queryAccept ?? typesAccept.ALL]);

    // const types = {
    //   upload: (
    //     <Button size="sm">
    //       <input
    //         type="file"
    //         accept={isAccept}
    //         onChange={action.onProcessStorage()}
    //         onClick={(event: any) => {
    //           event.target.value = null;
    //         }}
    //       />
    //       Загрузить
    //     </Button>
    //   ),
    //   open: (
    //     <Button size="sm" variant="secondary" onClick={action.onProcessStorage()}>
    //       Открыть
    //     </Button>
    //   ),
    //   choose: (
    //     <Button size="sm" onClick={action.onProcessStorage()}>
    //       Выбрать
    //     </Button>
    //   ),
    // };

    return (
      <div className="storage__panel">
        {/*{types[processType]}*/}
        <Button size="sm">
          <input
            type="file"
            accept={isAccept}
            onChange={action.onProcessStorage()}
            onClick={(event: any) => {
              event.target.value = null;
            }}
          />
          {translate('g.btn.upload')}
        </Button>
        {renderFileType(action)}
      </div>
    );
  };

  const renderDescriptionStorage = (service) => {
    const { roots, options } = service;

    const activeType = options.root_folder.type;

    const isActive = roots.find((el) => el.root_folder.type === activeType);

    if (!isActive) return null;

    const maxSpace = (isActive?.maxSpace / 1024 / 1024)?.toFixed(0);

    const usedSpace = (isActive?.usedSpace / 1024 / 1024)?.toFixed(0);

    return (
      <Text className="storage__description" variant="md">
        {translate('storage.free')} {usedSpace} Мб {translate('g.from.s')}{' '}
        {+maxSpace >= 10000
          ? `${+maxSpace / 1024} Гб`
          : `${+maxSpace === 0 ? `${(isActive?.maxSpace / 1024).toFixed(0)} Кб` : `${maxSpace} Мб`}`}
      </Text>
    );
  };

  const onChangeStorage = (data) => {
    onChange && onChange(data);
  };

  return (
    <StorageService onChange={onChangeStorage} accept={accept ?? [queryAccept ?? typesAccept.ALL]}>
      {({ service, action }) => (
        <div className="storage" onContextMenu={action.clearContextMenu}>
          <div className="storage__head">
            <h1 className="storage__head-title">{translate('storage.tlt')}</h1>
          </div>
          <div className="storage__middle">
            <div className="storage__controls">
              <Square monochrome size="lg" className="storage__arrow-left" onClick={action.onPrevThreeFolder}>
                <Icon name="ELArrowLineLeft" className="storage__control-arrow" />
              </Square>
              <Square monochrome size="lg" className="storage__arrow-right" onClick={action.onNextThreeFolder}>
                <Icon name="ELArrowLineRight" className="storage__control-arrow" />
              </Square>
              {service.createFolderActivate && <StorageNewFolder action={action} />}
              <Square onClick={action.onCreateFolder} monochrome size="lg" className="storage__to-grid">
                <Icon name="ELAddDir" className="storage__control-dir" />
              </Square>
              <Square toggle monochrome size="lg" className="storage__to-list" onClick={action.onChangeViewFolder}>
                <Icon
                  name="ELGrid"
                  className={classNames('storage__control-dir', {
                    'storage__control-dir--active': service.typeView,
                  })}
                />
              </Square>
            </div>
            <div className="storage__search">
              <Input size="md" value={service.inputPath} onChange={action.onChangeInputPath}>
                <Icon name="ElStorageSearch" className="storage__search-icon" />
              </Input>
            </div>
          </div>
          <div className="storage__body">
            <div className="storage__body-aside">
              <div className="storage__aside">
                <div className="storage__aside-ul">{renderRoots(service, action)}</div>
              </div>
            </div>
            <div className="storage__body-view">
              <div className="storage__polygon" onClick={action.clearContextMenu} ref={polygonRef}>
                <Scroll>
                  <div className={`${service.typeView ? 'storage__polygon-grid' : 'storage__polygon-list'}`}>
                    {renderGrid(service, action)}
                  </div>
                </Scroll>
              </div>
            </div>
          </div>
          <div className="storage__footer">{renderPanel(service, action)}</div>
          <div className="storage__addition">{renderDescriptionStorage(service)}</div>
          {/*{service.copperActivate && (*/}
          {/*  <Index close={action.onUploadCopperClose}>*/}
          {/*    <Copper copperFile={service.copperFile} onSaveFile={action.onUploadFile} />*/}
          {/*  </Index>*/}
          {/*)}*/}
          {service.contextActivate && (
            <Portal>
              <StorageContext contextData={service.contextData} service={service} action={action} />
            </Portal>
          )}
          {service.isDuplicate && (
            <Modal close={action.onToggleDuplicateModal}>
              <DuplicateFile
                text="Файл с таким именем уже существует."
                copy={action.onCopyDuplicateFile}
                confirm={action.onToggleDuplicateModal}
                close={action.onToggleDuplicateModal}
              />
            </Modal>
          )}
          {service.uploadProcessStart && (
            <ProgressBar
              progress={service.preloader}
              close={action.onToggleUploadProgressModal}
              retry={action.onRetryUploadFile}
            />
          )}
          {service.isCancelUpload && (
            <StopUploadFile close={action.onToggleCancelUploadModal} stop={action.onCancelUploadFile} />
          )}
          {service.confirmDelete && (
            <Modal close={action.onToggleDuplicateModal}>
              <ConfirmDelete
                close={action.onToggleConfirmDeleteModal}
                confirm={action.deleteFile}
                info={service.contextData}
              />
            </Modal>
          )}
          {service.isDuplicateFolder && (
            <Modal close={action.onToggleDuplicateFolderModal}>
              <DuplicateFolder
                text="Папка с таким именем уже существует."
                copy={action.onSaveFolder}
                close={action.onToggleDuplicateFolderModal}
              />
            </Modal>
          )}
        </div>
      )}
    </StorageService>
  );
};

export default StoragePolygon;
