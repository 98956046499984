import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect } from 'react';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { Icon } from '../../app.modules';

interface IProps {
  close(): void;
  progress: any;
  retry(): any;
}

const ProgressBar: FC<IProps> = ({ progress, close, retry }): ReactElement => {
  const [state, setState] = useToState({
    loadError: false,
  });

  const getColor = (ratio) => {
    if (ratio <= 0.4) {
      return '#586DEB';
    }

    if (ratio >= 0.4 && ratio <= 0.8) {
      return '#FDC63B';
    }

    if (ratio > 0.8) {
      return '#3BC078';
    }

    if (!ratio) {
      return '#EB5757';
    }
  };

  useEffect(() => {
    if (!progress && progress !== 0) {
      setState({
        loadError: true,
      });
    }

    if (progress) {
      setState({
        loadError: false,
      });
    }
  }, [progress, state.loadError]);

  const uploadFinished = progress === 100;

  const classesProgress = classNames('progress-bar__upload', {
    'progress-bar__upload-active': uploadFinished,
  });

  const classesProgressPercent = classNames('progress-bar__percent', {
    'progress-bar__percent-active': uploadFinished,
  });

  const classesBicycle = classNames('progress-bar__bicycle', {
    'progress-bar__bicycle--active': state.loadError,
  });

  return (
    <div className="modal">
      <div className="modal__container">
        <div className="progress-bar__close" onClick={close}>
          <Icon name="ELCloseModal" />
        </div>
        <div className="progress-bar__container">
          <div className="progress-bar__background">
            <div
              style={{
                width: progress + '%',
                transition: 'width 0.2s ease-in-out',
                backgroundColor: getColor(progress / 100),
              }}
              className="progress-bar__filler"
            >
              {progress && progress !== 100 && <Icon className="progress-bar__dust" name="Dust" />}
              <Icon className={classesBicycle} name="Bicycle" />
            </div>
          </div>
          <div className="progress-bar__content">
            {!state.loadError && (
              <div className={classesProgress}>
                {uploadFinished ? translate('storage.upload.end') : translate('storage.upload.tlt')}
              </div>
            )}
            {state.loadError && (
              <div className="progress-bar__percent">
                {translate('alert.err')}: ({' '}
                <span className="progress-bar__retry" onClick={retry}>
                  {translate('storage.upload.repeat')}
                </span>
              </div>
            )}
            <div className={classesProgressPercent}>
              <span>{`${progress ? +progress?.toFixed(0) : progress || 99}%`}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
