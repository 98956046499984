import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELTimerAttempt(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="none" viewBox="0 0 19 21" {...props}>
      <circle
        cx="8.947"
        cy="12.053"
        r="7.947"
        fill="var(--el-fonts--icon-fill-color)"
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M6.842 0a1 1 0 000 2V0zm4.21 2a1 1 0 100-2v2zm-4.21 0h4.21V0h-4.21v2zm3.105 6.895a1 1 0 00-2 0h2zm-2 3.158a1 1 0 102 0h-2zm0-3.158v3.158h2V8.895h-2zm9.076-5.971a1 1 0 10-1.414 1.415l1.414-1.415zm-.362 2.467a1 1 0 101.414-1.414l-1.414 1.414zM15.61 4.34l1.05 1.05 1.414-1.414-1.052-1.053-1.414 1.415z"
      />
    </svg>
  );
}
