import React from 'react';

interface IProps {
  onClick?(): void;
}

export default function ELAgency(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" fill="none" viewBox="0 0 24 21" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeWidth="2" d="M16 4V2a1 1 0 00-1-1H9a1 1 0 00-1 1v2" />
      <rect width="4" height="5" x="10" y="11" stroke="var(--el-fonts--icon-color)" strokeWidth="2" rx="1" />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M9 13.071L2.57 12l-.064-.01a2.996 2.996 0 01-1.936-1.2v6.496a3 3 0 003 3h16.857a3 3 0 003-3V10.79a2.995 2.995 0 01-1.935 1.198l-.065.011L15 13.071V15c0 .033 0 .066-.002.1l6.43-1.072v3.258a1 1 0 01-1 1H3.571a1 1 0 01-1-1v-3.259L9.002 15.1A2.038 2.038 0 019 15v-1.929z"
        clipRule="evenodd"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M3 5.143h18a1 1 0 011 1v4.887a1 1 0 01-.836.986L15 13.044V15l-.001.072 6.494-1.083A3 3 0 0024 11.03V6.143a3 3 0 00-3-3H3a3 3 0 00-3 3v4.887a3 3 0 002.507 2.96L9 15.071v-2.027l-6.164-1.028A1 1 0 012 11.03V6.143a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}
