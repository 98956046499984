export default {
  RU: {
    'events.name': 'Мероприятия',
    'events.overview': 'Обзор',
    'events.active': 'Активные',
    'events.completed': 'Завершенные',
    'events.archive': 'Архив',
    'events.srh.name': 'Введите название мероприятия',
    'events.not.found': 'Мероприятий не найдено',
    'events.desc': 'Введите описание мероприятия',
    'events.create': 'Новое мероприятие',
    'events.cover': 'Обложка мероприятия',
    'events.cover.mini': 'Миниатюра мероприятия',
    'events.author': 'Автор мероприятия',
    'events.author.pls': 'Введите автора',
    'events.stt.pass': 'Настройки прохождения',
    'events.mrl': 'Материалы по завершению',
    'events.dates': 'Даты мероприятия',
    'events.start': 'Старт мероприятия',
    'events.end': 'Окончание мероприятия',
    'events.site': 'Сайт мероприятия',
    'events.site.start': 'Запустить сайт мероприятия',
    'events.site.start.lbl':
      'Создайте и запустите уникальный сайт мероприятия, который поможет участникам узнать необходимую информацию и зарегистрироваться.',
    'events.site.start.lnk': 'Ссылка на сайт',
    'events.reg.usr': 'Регистрация участников',
    'events.reg.usr.on': 'Разрешить регистрацию на мероприятие',
    'events.reg.usr.lbl': 'Самостоятельная регистрация участников на мероприятие',
    'events.reg.usr.cnt': 'Количество участников',
    'events.reg.usr.cnt.hint': 'Максимальное количество участников, которые могут зарегистрироваться на мероприятие',
    'events.reg.usr.pay': 'Оплата участия',
    'events.reg.usr.confirm': 'Подтвердить участие',
    'events.reg.usr.confirm.hint': 'Подтверждать заявку каждого участника вручную',
    'events.reg.usr.payment': 'Стоимость участия',
    'events.reg.usr.payment.pls': 'Введите стоимость участия',
    'events.site.start.hint':
      'Ссылка ведёт на отдельную страницу, где можно зарегистрироваться на мероприятие и оплатить участие, если оно платное. Если вы хотите протестировать - откройте ссылку в другом браузере, либо в приватном окне',
    'events.reg.usr.table': 'Зарегистрированы на мероприятие',
    'events.reg.time': 'Время регистрации',
    'events.reg.status': 'Подтверждение',
    'events.sts.rqs': 'Статус заявки:',
    'events.sts.wait': 'На рассмотрении',
    'events.ended': 'Мероприятие завершено',
    'events.mmb.i.s': 'Отправлено',
    'events.role.Reviewer': 'Рецензент',
    'events.role.Author': 'Автор',
    'events.role.Editor': 'Редактор',
    'events.ivt.lbl': 'Приглашены на мероприятие',
    'events.ivt.not.found': 'Приглашений не найдено',
    'events.ivt.is.cancel': 'Хотите отменить приглашение в мероприятие?',
    'events.ivt.is.repeat': 'Хотите пригласить повторно?',
    'events.ivt': 'Пригласить на мероприятие',
    'events.str.prt.tlt': 'Создать раздел',
    'events.str.prt.desc': 'Введите описание',
    'events.str.file.tlt': 'Информационный файл',
    'events.str.ath.tlt': 'Заголовок',
    'events.str.ath.tlt.pls': 'Впишите заголовок',
    'events.str.ath.desc': 'Описание',
    'events.str.ath.desc.pls': 'Добавьте описание',
    'events.str.ath.acc.sett': 'Настройки доступа',
    'events.str.ath.acc.sett.opt.event': 'Заданы настройками мероприятия',
    'events.str.ath.acc.sett.opt.set': 'Заданы отдельно',
    'events.str.ath.start.pbl': 'Старт публикации',
    'events.str.ath.end.pbl': 'Окончание публикации',
    'events.str.page.tlt': 'Информационная страница',
    'events.str.ath.filling': 'Наполнение',
    'events.str.video.tlt': 'Видео-материал',
    'events.str.test.lbl': 'Перейти в создание теста',
    'events.str.test.add': 'Добавление теста',
    'events.str.test.add.lbl': 'Выберите способ добавления теста:',
    'events.str.test.add.copy': 'Новая копия',
    'events.str.test.add.copy.lbl': 'Будет создан отдельный экземпляр теста.',
    'events.str.test.add.ath': 'Связанный тест',
    'events.str.test.add.ath.lbl': 'Изменения теста в библиотеке приведет к изменениям в этом связанном тесте.',
    'events.str.test.stt.lbl':
      'При добавлении связанного теста вы можете настроить даты доступа и важность прохождения теста.',
    'events.str.test.stt.lbl.2': 'Остальные настройки можно изменить только в библиотеке тестов.',
    'events.str.homework.tlt': 'Домашнее задание',
    'events.str.homework.scr': 'Баллы за задание',
    'events.str.homework.scr.psg': 'Проходной балл',
    'events.str.brd.add': 'Добавить трансляцию',
    'events.str.brd.add.hint': 'Добавьте ссылку на трансляцию (YouTube,  Twitch) в поле или встройте код',
    'events.str.part.del': 'Хотите удалить раздел?',
    'events.str.el.del': 'Хотите удалить элемент?',
    'events.current': 'Текущие',
    'events.upcoming': 'Предстоящие',
    'events.request': 'Заявки',
    'events.usr.sort.completed': 'Выполненные',
    'events.usr.sort.completed.not': 'Не выполненные',
    'events.usr.sort.all': 'Все',
    'events.usr.btn.tst.result': 'Смотреть результат',
    'events.usr.btn.homework.view': 'Смотреть задание',
    'events.request.pay.tlt': 'Оплатите участие в мероприятии',
    'events.request.amount': 'Стоимость',
    'events.request.pay.pts': 'Оплатить участие',
    'events.delete': 'Хотите безвозвратно удалить мероприятие ?',
    'events.ath.homework.desc.pls': 'Добавьте описание задания',
    'events.ivt.from.in': 'Отправленно в',
    'events.ivt.reg.lbl': 'Для участия в мероприятии необходимо войти на платформу',
    'events.ivt.add.lbl': 'Мероприятие будет добавлено для аккаунта',
    'events.ivt.confirm': 'Принять приглащение на мероприятие?',
    'events.ivt.cancel': 'Отклонить приглащение на мероприятие?',
    'events.passing': 'Прохождение',
  },
};
