import { LOCAL } from '../local';
import AGENCY from './agency';
import ALERT from './alert';
import EVENTS from './events';
import EXPAND from './expand';
import GENERAL from './general';
import HOMEWORK from './homework';
import LEARNING from './learning';
import MEMBERS from './members';
import NOTIFICATION from './notification';
import PARTICIPANTS from './participants';
import PAYMENTS from './payments';
import PROFILE from './profile';
import QUESTIONS from './questions';
import STATISTIC from './statistic';
import STORAGE from './storage';
import SUPPORT from './support';
import TESTS from './tests';
import WARNING from './warning';

export default {
  [LOCAL.RU]: {
    ...GENERAL.RU,
    ...EXPAND.RU,
    ...TESTS.RU,
    ...LEARNING.RU,
    ...EVENTS.RU,
    ...AGENCY.RU,
    ...WARNING.RU,
    ...PROFILE.RU,
    ...ALERT.RU,
    ...PAYMENTS.RU,
    ...MEMBERS.RU,
    ...PARTICIPANTS.RU,
    ...HOMEWORK.RU,
    ...STORAGE.RU,
    ...STATISTIC.RU,
    ...SUPPORT.RU,
    ...QUESTIONS.RU,
    ...NOTIFICATION.RU,
  },
};
