import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELArrowLineTop(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" fill="none" viewBox="0 0 12 16" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 6L6 1 1 6m4.992 8.528V3.48"
      />
    </svg>
  );
}
