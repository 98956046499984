import './index.scss';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useToState } from '../../hooks';
import Icon from '../Icon';
import Select from '../Select';
import Text from '../Text';

interface IProps {
  className?: string;
  defaultValue: string;
  defaultTitle: string;
  data: Array<{ title: string; params: any }>;
  onChange?(data: { title: string; params: any }): void;
}

const Rolling: FC<IProps> = ({ defaultValue, defaultTitle, className, data, onChange }) => {
  const [state, setState] = useToState({
    activeSelect: false,
    title: defaultTitle,
    titleCash: defaultTitle,
    value: defaultValue,
    save: false,
    params: {},
  });

  const onChangeValue = (data) => {
    setState({
      titleCash: data.title,
      value: data.params.value,
      params: data.params,
    });
  };

  const onActiveSelect = () => {
    setState({
      activeSelect: !state.activeSelect,
      save: false,
    });
  };

  const onSaveValueSelect = () => {
    setState((state) => ({
      save: true,
      title: state.titleCash,
    }));
  };

  useEffect(() => {
    if (state.save) {
      onActiveSelect();

      onChange &&
        onChange({
          ...state.params,
          value: state.value,
        });
    }
  }, [state.save, state.value]);

  useEffect(() => {
    if (state.activeSelect) {
      window.addEventListener('click', onActiveSelect);
    } else {
      window.removeEventListener('click', onActiveSelect);
    }

    return () => {
      window.removeEventListener('click', onActiveSelect);
    };
  }, [state.activeSelect]);

  const classesPrompt = classNames('rolling', className);

  const renderName = () => {
    return (
      <Text className="rolling__name">
        {state.title}
        <Icon name="ELPencil" className="rolling__name-icon" onClick={onActiveSelect} />
      </Text>
    );
  };

  const renderSelect = () => {
    return (
      <div className="rolling__name">
        <Select data={data} onChange={onChangeValue} />
        <Icon name="ELCheckCircle" className="rolling__name-icon" onClick={onSaveValueSelect} />
      </div>
    );
  };

  return (
    <div className={classesPrompt} onClick={(e) => e.stopPropagation()}>
      {state.activeSelect ? renderSelect() : renderName()}
    </div>
  );
};

export default Rolling;
