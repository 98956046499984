import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useRef } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Text from '../Text';

interface IProps {
  className?: string;
  variant?: string;
  type?: string;
  placeholder?: string;
  placeholderNode?: ReactNode;
  value?: string;
  onChange?: Function;
  onBlur?: any;
  onFocus?: any;
  label?: ReactNode;
  children?: ReactNode;
  errorText?: ReactNode;
  error?: ReactNode;
  autoResize?: boolean;
  bold?: boolean;
  size?: string;
  width?: string;
}

const Textarea: FC<IProps> = function ({
  variant = 'primary',
  className = '',
  type = 'text',
  placeholder = '',
  placeholderNode,
  errorText,
  value = '',
  onChange,
  children,
  label,
  error,
  onBlur,
  size,
  bold,
  width,
  onFocus,
}): ReactElement {
  const ref = useRef(null) as any;

  const onInput = (e) => {
    const value = e?.target?.value ?? e;

    onChange && onChange(value, e);
  };

  const isParams = {
    onChange: onInput,
    ...(onBlur && { onBlur }),
    ...(onFocus && { onFocus }),
    placeholder,
    value,
    type,
    ref,
  };

  const classesTextarea = classNames('textarea--eject', `textarea--${variant}`, className, {
    'textarea--error': error,
    'textarea--b': bold,
    [`textarea--w-${width}`]: width,
    [`textarea--${size}`]: size,
  });

  const renderTextarea: Function = (): ReactNode => {
    return (
      <div className={classesTextarea}>
        <TextareaAutosize {...isParams} required autoComplete="new-password" />
        {label && <label>{label}</label>}
        {placeholderNode && <strong className="textarea__node">{placeholderNode}</strong>}
        {error && (
          <Text variant="sm" className="textarea--error-text">
            {errorText ? errorText : error}
          </Text>
        )}
        {children}
      </div>
    );
  };

  return renderTextarea();
};

export default Textarea;
