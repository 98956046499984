import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { Restrictions } from '../../app/app.modules';
import Permission from '../Permission';
import Text from '../Text';

interface IProps {
  data: Array<{
    title: string | ReactNode;
    onClick?: Function;
    permissions?: any;
    hidden?: boolean;
    restriction?: {
      limited: Array<string | number>;
      updateFollows: Array<number>;
      replace(): null;
    };
  }>;
  className?: string;
}

const ContextMenu: FC<IProps> = function ({ data, className }): ReactElement {
  const onChange = (option) => (e) => {
    const handler = option?.onClick;

    handler && handler(e, option);
  };

  const renderMenuItems = () => {
    return data.map((item, i) => {
      if (item?.hidden) {
        return null;
      }

      if (item?.restriction) {
        const { limited, updateFollows, replace } = item?.restriction;

        return (
          <Restrictions key={i} limited={limited} updateFollows={updateFollows} replace={replace}>
            <Permission is={item.permissions}>
              <Text variant="md" className="content-menu__item" onClick={onChange(item)}>
                {item.title}
              </Text>
            </Permission>
          </Restrictions>
        );
      }

      if (item?.permissions) {
        return (
          <Permission is={item.permissions} key={i}>
            <Text variant="md" className="content-menu__item" onClick={onChange(item)}>
              {item.title}
            </Text>
          </Permission>
        );
      }

      return (
        <Text key={i} variant="md" className="content-menu__item" onClick={onChange(item)}>
          {item.title}
        </Text>
      );
    });
  };

  const classesContextMenu = classNames('content-menu', className);

  return <div className={classesContextMenu}>{renderMenuItems()}</div>;
};

export default ContextMenu;
