import React from 'react';

export default function CoverEvent() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="308" height="213" fill="none" viewBox="0 0 308 213">
      <path
        fill="var(--el-layout--events-color)"
        d="M10.458 95.506a38.033 38.033 0 01-3.586-27.172c6.3-25.99 24.184-71.603 71.516-65.144 65.673 8.97 84.764 11.213 111.71 2.8 26.945-8.411 73.536-8.401 88.131 10.643 8.848 11.544 38.733 63.29 19.647 81.23-19.085 17.94 9.24 53.41 10.104 72.26.864 18.85-26.382 21.274-60.625 21.847-34.242.573-233.267-5.952-233.267-5.952s-25.515-43.912-5.306-59.597c12.506-9.701 6.907-21.327 1.676-30.915z"
      />
      <path
        fill="var(--el-layout--events-color)"
        d="M30.369 165.478c-4.38-.152-20.788 2.083-21.75 2.94-.25.232-3.838 10.879 11.951 24.641 25.26 22.017 93.239 22.769 115.27 14.395 31.963-12.153 66.987 10.634 139.587 1.913 23.201-2.786 38.705-38.248 28.157-42.845-6.315-2.741-13.876 4.533-20.226 1.341-7.309-3.681-22.119-5.981-31.289-3.157a.708.708 0 00.32 1.378l-4.829 1.537c-5.366 3.987-4.158-1.311-16.746-1.993l-37.114-.915c-13.269.399-64.327 2.639-84.304 1.841H98.409c-7.331-.914-26.349-1.348-27.033-1.37l-41.007.294z"
      />
      <path fill="var(--el-ui--image-event-1-color)" d="M70.72 53.68V171h177.238l.179-122.165-177.416-.18v5.024z" />
      <path
        fill="var(--el-ui--image-event-2-color)"
        d="M57.445 64.084v117.321h177.237l.18-122.164-177.417-.18v5.023z"
      />
      <path
        fill="var(--el-ui--image-event-1-color)"
        d="M68.03 71.26a2.69 2.69 0 10-5.382 0 2.69 2.69 0 005.382 0zm8.252 0a2.69 2.69 0 10-5.382 0 2.69 2.69 0 005.382 0zm8.25 0a2.691 2.691 0 10-5.382 0 2.691 2.691 0 005.382 0z"
      />
      <g fill="var(--el-general--layout-color)" opacity="0.5">
        <path d="M67.67 86.15h-.717v36.595h.718V86.149zm3.231 0h-.717v36.595h.717V86.149zm2.867 0h-.717v36.595h.717V86.149zm3.231 0h-.718v36.595H77V86.149zm2.871 0h-.718v36.595h.718V86.149zm3.228 0h-.717v36.595h.717V86.149zm2.872 0h-.718v36.595h.718V86.149zm2.869 0h-.718v36.595h.718V86.149zm2.871 0h-.718v36.595h.718V86.149zm3.228 0h-.717v36.595h.717V86.149zm2.872 0h-.718v36.595h.717V86.149zm3.226 0h-.718v36.595h.718V86.149zm2.873 0h-.718v36.595h.718V86.149z" />
        <path d="M103.907 86.15H66.953v.717h36.954v-.718zm0 3.587H66.953v.718h36.954v-.718zm0 3.588H66.953v.718h36.954v-.718zm0 3.588H66.953v.717h36.954v-.717zm0 3.587H66.953v.718h36.954v-.718zm0 3.588H66.953v.718h36.954v-.718zm0 3.588H66.953v.718h36.954v-.718zm0 3.588H66.953v.717h36.954v-.717zm0 3.588H66.953v.717h36.954v-.717zm0 3.587H66.953v.718h36.954v-.718zm0 3.588H66.953v.718h36.954v-.718zm8.971 10.046h-.718v36.595h.718v-36.595zm3.23 0h-.717v36.595h.717v-36.595zm2.869 0h-.717v36.595h.717v-36.595zm3.229 0h-.718v36.595h.718v-36.595zm2.869 0h-.718v36.595h.718v-36.595zm3.23 0h-.717v36.595h.717v-36.595zm2.872 0h-.718v36.595h.718v-36.595zm2.869 0h-.718v36.595h.718v-36.595zm2.871 0h-.718v36.595h.718v-36.595zm3.228 0h-.717v36.595h.717v-36.595zm2.871 0h-.717v36.595h.717v-36.595zm3.227 0h-.718v36.595h.718v-36.595zm2.873 0h-.718v36.595h.718v-36.595z" />
        <path d="M149.114 132.073H112.16v.718h36.954v-.718zm0 3.588H112.16v.717h36.954v-.717zm0 3.588H112.16v.717h36.954v-.717zm0 3.587H112.16v.718h36.954v-.718zm0 3.588H112.16v.718h36.954v-.718zm0 3.588H112.16v.718h36.954v-.718zm0 3.588H112.16v.717h36.954v-.717zm0 3.588H112.16v.717h36.954v-.717zm0 3.587H112.16v.718h36.954v-.718zm0 3.588H112.16v.718h36.954v-.718zm0 3.588H112.16v.717h36.954v-.717zm4.305-77.855h-.718v36.595h.718V90.095zm3.23 0h-.717v36.595h.717V90.095zm2.867 0h-.717v36.595h.717V90.095zm3.231 0h-.718v36.595h.718V90.095zm2.869 0h-.718v36.595h.718V90.095zm3.23 0h-.717v36.595h.717V90.095zm2.872 0H171v36.595h.718V90.095zm2.869 0h-.718v36.595h.718V90.095zm2.871 0h-.718v36.595h.718V90.095zm3.226 0h-.717v36.595h.717V90.095zm2.871 0h-.717v36.595h.717V90.095zm3.229 0h-.718v36.595h.718V90.095zm2.873 0h-.718v36.595h.718V90.095z" />
        <path d="M189.655 90.096h-36.954v.717h36.954v-.718zm0 3.587h-36.954v.718h36.954v-.718zm0 3.588h-36.954v.718h36.954v-.718zm0 3.588h-36.954v.717h36.954v-.717zm0 3.588h-36.954v.717h36.954v-.717zm0 3.587h-36.954v.718h36.954v-.718zm0 3.588h-36.954v.718h36.954v-.718zm0 3.588h-36.954v.718h36.954v-.718zm0 3.588h-36.954v.717h36.954v-.717zm0 3.588h-36.954v.717h36.954v-.717zm0 3.587h-36.954v.718h36.954v-.718z" />
      </g>
      <path fill="var(--el-ui--image-event-1-color)" d="M137.272 97.63h-52.74v36.596h52.74V97.63z" />
      <path fill="var(--el-ui--image-event-3-color)" d="M142.655 102.294h-52.74v36.596h52.74v-36.596z" />
      <path
        fill="var(--el-general--layout-color)"
        d="M130.817 107.317H100.68v.718h30.137v-.718zm-5.382 1.794H100.68v.718h24.755v-.718zm7.894 1.435H100.68v.718h32.649v-.718zm-18.298 4.664H100.68v.718h14.351v-.718zm7.534 1.794H100.68v.718h21.885v-.718zm-1.435 1.435h-20.45v.718h20.45v-.718zm-6.099 5.023H100.68v.718h14.351v-.718zm7.534 1.794H100.68v.718h21.885v-.718zm-1.435 1.435h-20.45v.718h20.45v-.718zm-24.937-15.069a2.332 2.332 0 100-4.664 2.332 2.332 0 000 4.664zm0 7.535a2.332 2.332 0 100-4.664 2.332 2.332 0 000 4.664zm.36 8.61a2.332 2.332 0 100-4.664 2.332 2.332 0 000 4.664zm127.187-25.473h-26.191v.718h26.191v-.718zm0 3.947h-26.191v.718h26.191v-.718zm0 3.946h-26.191v.718h26.191v-.718zm0 3.947h-26.191v.718h26.191v-.718zm0 3.946h-26.191v.718h26.191v-.718zm0 3.947h-26.191v.718h26.191v-.718zm0 3.947h-26.191v.717h26.191v-.717zm0 3.946h-26.191v.718h26.191v-.718zm0 3.947h-26.191v.717h26.191v-.717zm2.153-57.046H61.573v.718h164.32v-.718zm-15.25 21.168a8.072 8.072 0 008.072-8.073 8.072 8.072 0 10-16.145 0 8.073 8.073 0 008.073 8.073z"
      />
    </svg>
  );
}
