export const typesPlansState = {
  EMPTY: 'EMPTY',
  PENDING: 'PENDING',
  UPDATE: 'UPDATE',
  EXTEND: 'EXTEND',
  UPGRADE: 'UPGRADE',
};
//
export const typesPlan = {
  BASE: 'Базовый',
  STANDARD: 'Стандарт',
};

export const typesPanel = {
  EMPTY: 'EMPTY',
  PENDING: 'PENDING',
};

export const typePanelPay = {
  EMPTY: 'EMPTY',
  UPDATE: 'UPDATE',
  UPGRADE: 'UPGRADE',
  EXTEND: 'EXTEND',
};

export const typesStatusPlan = {
  PENDING: 'pending',
  NEXT: 'succeeded',
  REQUEST: 'request',
  ACTIVE: 'active',
};

export const typesPlansActivity = {
  BASE: 'Базовый',
  STANDARD: 'Стандарт',
  PRO: 'PRO',
  EXPECTATION: 'EXPECTATION',
};

export const typesPlansTemplate = {
  EMPTY: 'EMPTY',
  WAIT: 'WAIT',
};

export const typesPlansPending = {
  IMMEDIATELY: 'IMMEDIATELY',
  IMMEDIATELY_NOT: 'IMMEDIATELY_NOT',
};

export const typesStatusPayment = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  CANCELED: 'canceled',
};

export const typesConfirmsButtons = {
  ON_CANCEL: 'ON_CANCEL',
  ON_CANCEL_SEND: 'ON_CANCEL_SEND',
  ON_CONFIRM: 'ON_CONFIRM',
};

export default {
  ON_ACTIVITY_PLAN: 'ON_ACTIVITY_PLAN',
  ON_PENDING_PLAN: 'ON_PENDING_PLAN',
  ON_NEXT_PLAN: 'ON_NEXT_PLAN',
  REQUEST_PLAN: 'REQUEST_PLAN',
  ON_PAYMENT_HISTORY: 'ON_PAYMENT_HISTORY',
};
