import '../index.scss';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { useEvent } from '../../../hooks';
import translate from '../../../i18n/translate';
import { Icon, Input, Text } from '../../app.modules';

interface IProps {
  action: {
    checkDuplicateFolder(data: string): void;
    onCreateFolder(): void;
  };
}

const StorageNewFolder: FC<IProps> = ({ action }): any => {
  const [value, setValue] = useState('');

  const onChangeInput = (e) => {
    const { value } = e.target;

    setValue(value);
  };

  const onBlur = (e) => {
    if (!e.target.closest('.storage__to-input')) {
      action.onCreateFolder();
    }
  };

  useEvent('click', onBlur, document);

  const onSave = () => {
    if (value.length > 0) {
      action.checkDuplicateFolder(value);
      action.onCreateFolder();
    }
  };

  const classesInputIcon = classNames('storage__to-input__icon', {
    'storage__to-input__icon--active': value.length > 0,
  });

  return (
    <div className="storage__to-input">
      <Text>{translate('storage.crt.folder')}</Text>
      <Input
        autoFocus
        placeholderNode={translate('storage.crt.folder.pls')}
        size="sm"
        value={value}
        onChange={onChangeInput}
      />
      <Icon name="ELCheckCircle" className={classesInputIcon} onClick={onSave} />
    </div>
  );
};

export default StorageNewFolder;
