export default {
  DE: {
    'qs.stt.tlt': 'Frageneinstellungen',
    'qs.stt.awr': 'Antworteneinstellungen',
    'qs.awr.mix': 'Antworten mischen ',
    'qs.cmt.view': 'Kommentare anzeigen',
    'qs.cmt.view.all': 'Zu allen Antworten',
    'qs.cmt.view.correct': 'Ausschliesslich zu den richtigen Antworten',
    'qs.cmt.view.usr': 'Ausschl. zu den Studentenantwort',
    'qs.view.not': 'Nicht abbilden',
    'qs.stt.cmt.view': 'Die eingegebenen Kommentare werden erst angezeigt wenn der Student die Fragen beantwortet hat',
    'qs.up.view': 'Frage zusammenklappen',
    'qs.down.view': 'Frage auseinanderklappen',
    'qs.rqd.up': 'Alle Fragen sind obligatorisch, eine Frage zu einer nicht obligatorischen machen',
    'qs.rqd.down': 'Diese Frage kann man überspringen. Klicken Sie um sie obligatorisch zur Ablegung zu machen.',
    'qs.once': 'Eine Antwort',
    'qs.more': 'Mehrere Antworten',
    'qs.more.up': 'Unvollständigen in Betracht nehmen',
    'qs.awr.pls': 'Geben Sie eine Antwortvariante ein',
    'qs.cmt.pls': 'Geben Sie einen kurzen Kommentar zu der Antwort ein',
    'qs.add.awr': 'Eine Frage hinfügen',
    'qs.save': 'Frage abspeichern',
    'qs.audio.tlt': 'Audiomaterial',
    'qs.audio.add': 'Audio hinfügen',
    'qs.video.tlt': 'Videomaterial',
    'qs.video.upload.lbl':
      'Laden Sie ein neues Vidoe hoch oder fügen sie einen Link zu den bereits hochgeladenen hinzu.',
    'qs.video.add': 'Video hinzufügen',
    'qs.video.link': 'Link zum Video hinzufügen',
    'qs.video.storage': 'Aus dem Cloud-Speicher hinfügen',
    'qs.video.cover': 'Titelblatt fürs Video wählen',
    'qs.crt': 'Frage erstellen',
    'qs.type.choose': 'Antwortauswahl',
    'qs.type.free': 'Freie Antwort',
    'qs.awr.txt': 'Textantwort',
    'qs.awr.manual': 'Manuelle Prüfung',
    'qs.awr.number': 'Numerische Antwort',
    'qs.delete': 'Frage löschen',
    'qs.name': 'Frage',
    'qs.err.scr.txt': 'Geben Sie Punkte ein',
    'qs.err.awr.txt': 'Geben Sie Antworttext ein',
  },
};
