import { Dispatch } from 'redux';
import { uuid } from '../utils';
import { IAppBreadcrumbs } from './app.resources';
import types from './app.types';

export function setAlert(alert: any, empty: boolean = false) {
  return (dispatch: Dispatch, getStore: Function) => {
    const cloneStoreAlert = empty
      ? alert
      : [
          ...getStore().app.alert,
          {
            ...alert,
            uuid: uuid(),
          },
        ];

    dispatch({
      type: types.ALERT,
      alert: cloneStoreAlert,
    });
  };
}

// export function setAsidePanel(asidePanel: boolean) {
//   return (dispatch: Dispatch) => {
//     dispatch({
//       type: types.ASIDE_PANEL,
//       asidePanel,
//     });
//   };
// }

export function setDisplayView(view: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.VIEW,
      view,
    });
  };
}

export function permission(data: string) {
  return (dispatch: Dispatch, getStore: Function) => {
    return new Promise((resolve) => {
      const [roleIAm] = getStore().source?.roleIAm;

      const { scopes } = roleIAm;

      const isHavePermissions = scopes?.find((el) => `${el.privilege} ${el.resource}` === data);

      isHavePermissions && resolve(isHavePermissions);
    });
  };
}

export function setBreadcrumbs(breadcrumbs: IAppBreadcrumbs = []) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.BREADCRUMBS,
      breadcrumbs,
    });
  };
}
