import api from './profile.api';

export function profileUserUpdate(data: any): Promise<any> {
  const request = api.init('profileUserUpdate');

  return request({
    params: data,
  });
}

export function profileUserPasswordUpdate(data: any): Promise<any> {
  const request = api.init('profileUserPasswordUpdate');

  return request({
    params: data,
  });
}

export function getTimeZones(): Promise<any> {
  const request = api.init('getTimeZones');

  return request(null);
}
