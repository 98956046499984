import React from 'react';

interface IProps {
  className?: string;
}

export default function ELLearning(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" fill="none" viewBox="0 0 24 18" {...props}>
      <g stroke="var(--el-fonts--icon-color)" strokeWidth="2" clipPath="url(#a)">
        <path d="M2.08 7.08c-1.45-.63-1.45-1.63 0-2.26l7.3-3.16c2.07-.89 3.15-.89 5.22 0l7.3 3.16c1.45.63 1.45 1.63 0 2.26l-7.3 3.16m-5.55 4.91c.84.16 1.75.25 2.71.25h.32c4.1 0 7.41-1.64 7.41-3.67v-3.6l-4.88 2.11M4.35 8.05v3.69c0 .97.76 1.85 2 2.5" />
        <path
          strokeLinecap="round"
          d="M5.07 8.48l-2.99-1.4c-1.45-.63-1.45-1.63 0-2.26l1.82-.78 5.49-2.37c2.07-.89 3.15-.89 5.22 0l7.3 3.16c1.45.63 1.45 1.63 0 2.26l-7.3 3.16s-2.5 1.07-2.86 1.07c-.35 0-.68-.18-.68-.18"
        />
        <path strokeLinecap="round" d="M12.29 5.6L8.84 8.4s-.56.46-.85.95c-.29.49-.24 1.07-.24 1.07v1.87" />
        <path
          fill="#fff"
          d="M7.78 12.97h-.1c-.59 0-1.07.34-1.07.75v2.23c0 .42.48.75 1.07.75h.11c.59 0 1.07-.34 1.07-.75v-2.23c-.01-.41-.48-.75-1.08-.75z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h23.99v17.71H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
