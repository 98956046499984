import { CONFIG } from './config';
import $history from './historyCreate';
import modesActivate from './modesActivite';

const $body = document.body;

const $root = document.getElementById('root');

const $CASE_LG = 1420;

const $CASE_MD_LG = 1320;

const $CASE_MD = 1200;

const $CASE_SM = 992;

const $CASE_XS = 768;

const $CASE_LESS = 320;

export {
  $body,
  $root,
  CONFIG,
  $history,
  $CASE_LG,
  $CASE_MD_LG,
  $CASE_MD,
  $CASE_SM,
  $CASE_XS,
  $CASE_LESS,
  modesActivate,
};
