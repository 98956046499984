// import './index.scss';
// import React, { FC } from 'react';
// import { useToState } from '../../../../../hooks';
//
// interface IProps {
//   data: {
//     name: string;
//     description: string;
//   };
//   onConfirm(data: { name: string; description: string }): void;
//   onClose(): void;
//   params?: any;
// }
//
// const EventAttachFile: FC<IProps> = ({ data, onConfirm, onClose, params }) => {
//   console.log(params, 'paramsparamsparams');
//
//   const [state, setState] = useToState({
//     name: '',
//     description: '',
//   });
//
//   // const [test, setTest] = useState(null);
//
//   // const elemRef: any = useRef(null);
//
//   // useEffect(() => {
//   //   setTest(test);
//   // }, [elemRef]);
//
//   // useEffect(() => {
//   //   setTest(elemRef);
//   //   elemRef?.current?.focus();
//   // }, [test]); //TODO [это пиздец]: переделать (почемуто непроисходит обновления компонента - приходиться обновлять принудительно)
//   //
//   // const [error, setError] = useErrorFields(state, (value, key) => {
//   //   switch (key) {
//   //     case 'name':
//   //       return value?.length;
//   //     default:
//   //       return true;
//   //   }
//   // });
//   //
//   // const onChangeField = (key) => (e) => {
//   //   const value = e?.target?.value ?? e;
//   //
//   //   setError(key, value);
//   //
//   //   setState({
//   //     [key]: value,
//   //   });
//   // };
//   //
//   // const onConfirmData = () => {
//   //   setError(state).then(() => {
//   //     onConfirm(state);
//   //   });
//   // };
//   //
//   // const title = params?.title ?? 'Новое мероприятие';
//   //
//   // const placeholderInput = params?.placeholderInput ?? 'Введите название мероприятия';
//   //
//   // const placeholderTextarea = params?.placeholderTextarea ?? 'Введите описание мероприятия';
//
//   return (
//     <div className="create-event">
//       <h1 className="create-event__head" />
//       <div className="create-event__row" />
//       <div className="create-event__foot">
//         {/*<Button size="md" variant="outlined" monochrome onClick={onClose}>*/}
//         {/*  Отмена*/}
//         {/*</Button>*/}
//         {/*<Button off={!error.name} size="md" onClick={onConfirmData}>*/}
//         {/*  Создать*/}
//         {/*</Button>*/}
//       </div>
//     </div>
//   );
// };
//
// export default EventAttachFile;
export default {};
