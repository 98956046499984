import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELTariff(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="22" fill="none" viewBox="0 0 21 22" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.25"
        d="M11.284 9.1l.143.337.363.04 1.765.196-1.34 1.27-.252.239.069.339.366 1.813-1.485-.9-.324-.197-.324.196-1.485.9.365-1.812.069-.34-.251-.237-1.341-1.27h0l1.765-.197.364-.04.142-.337.696-1.64.695 1.64z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.25"
        d="M11.456 4.222a2.172 2.172 0 012.229.736c.37.457.852.811 1.4 1.027a2.172 2.172 0 011.37 1.906c.03.587.213 1.157.529 1.654a2.172 2.172 0 01-.012 2.347 3.422 3.422 0 00-.544 1.648 2.172 2.172 0 01-1.389 1.893c-.549.21-1.034.56-1.41 1.014a2.172 2.172 0 01-2.235.714 3.422 3.422 0 00-1.736-.008 2.172 2.172 0 01-2.229-.736 3.421 3.421 0 00-1.4-1.027l-.23.58.23-.58a2.172 2.172 0 01-1.37-1.906 3.422 3.422 0 00-.529-1.654 2.172 2.172 0 01.011-2.347c.32-.493.508-1.061.545-1.648a2.172 2.172 0 011.388-1.893 3.422 3.422 0 001.41-1.013 2.172 2.172 0 012.235-.715 3.422 3.422 0 001.737.008z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.25"
        d="M7.604 16.48v4.512c0 .228.236.38.443.284l2.38-1.098a.312.312 0 01.261 0l2.38 1.098a.312.312 0 00.443-.284V16.48"
      />
    </svg>
  );
}
