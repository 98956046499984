import React from 'react';

export default function Ellipsis() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" fill="none" viewBox="0 0 18 4">
      <circle cx="2" cy="2" r="2" fill="var(--el-fonts--icon-color)" />
      <circle cx="9" cy="2" r="2" fill="var(--el-fonts--icon-color)" />
      <circle cx="16" cy="2" r="2" fill="var(--el-fonts--icon-color)" />
    </svg>
  );
}
