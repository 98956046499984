export const TYPES_ROLES = {
  Author: 'Author',
  Reviewer: 'Reviewer',
  Editor: 'Editor',
  Owner: 'Owner',
};

export const NAME_ROLES = {
  Author: 'Автор',
  Reviewer: 'Ревьюер',
  Editor: 'Редактор',
  Owner: 'Супер Админ',
};

export default {
  PROFILE: 'AGENCY_PROFILE',
  SETTINGS_PAY: 'AGENCY_SETTINGS_PAY',
};
