import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELBook(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="26" fill="none" viewBox="0 0 21 26" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 21.821a3.179 3.179 0 013.179-3.178h15.25V23a2 2 0 01-2 2H4.179A3.179 3.179 0 011 21.821z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M5 2h10.429a3 3 0 013 3v12.643h2V5a5 5 0 00-5-5H5a5 5 0 00-5 5v17h.004A4.176 4.176 0 012 18.255V5a3 3 0 013-3z"
        clipRule="evenodd"
      />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M5.107 21.821h13.465" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M9.915 0v8.317a.5.5 0 00.812.39l1.661-1.329a.5.5 0 01.625 0l1.66 1.33a.5.5 0 00.813-.391V.5"
      />
    </svg>
  );
}
