export const typeFileType = {
  FILE: 'File',
  TEST: 'Test',
  BOOK: 'Book',
  PAGE: 'Page',
  IMAGE: 'Image',
  VIDEO: 'Video',
  AUDIO: 'Audio',
  HOMEWORK: 'Homework',
  STREAM_LINK: 'StreamLink',
  WEBINAR: 'Webinar',
};

const typesFile = {
  File: 'FileEventPart',
  Book: 'BookEventPart',
  Page: 'PageEventPart',
  Image: 'ImageEventPart',
  Video: 'VideoEventPart',
  Audio: 'AudioEventPart',
  Test: 'TestsEventPart',
  Homework: 'HomeworkEventPart',
};

export const TYPES_MODALS = {
  Test: (type) => typesFile[type],
  File: (type) => typesFile[type],
  Homework: (type) => typesFile[type],
};

export const TYPES_FILE_ATTACH_SERVE = {
  Test: (data, params, type, id) => ({
    parentId: id ? id : params?.parentId ?? data.id,
    position: params.position,
    params: {
      startDate: params?.startDate ?? null,
      endDate: params?.endDate ?? null,
      testId: params?.testId,
      sync: params.sync,
      testParams: {
        ...params.testInfo,
      },
    },
    type,
  }),
  File: (data, params, type) => ({
    parentId: data?.parentId ?? data.id,
    position: params?.position ?? data.data.length,
    params: {
      title: params.title,
      description: params.description,
      fileId: params.fileId,
      type: params.type,
      startDate: params.startDate ?? null,
      endDate: params.endDate ?? null,
      downloadMaterials: params.downloadMaterials ?? true,
    },
    type,
    title: params.title,
    description: params.description,
  }),
  Page: (data, params) => ({
    parentId: params?.parentId ?? data.id,
    position: params?.position ?? data.data.length,
    params: params.params,
    type: params.type,
    title: params.params.title,
    description: params.params.description,
  }),
  StreamLink: (data, params) => ({
    parentId: params?.parentId ?? data.id,
    position: params?.position ?? data.data.length,
    params: params.params,
    type: params.type,
    title: params.params.title,
    description: params.params.description,
  }),
  Webinar: (data, params) => ({
    parentId: params?.parentId ?? data.id,
    position: params?.position ?? data.data.length,
    params: params.params,
    type: params.type,
    title: params.params.title,
    description: params.params.description,
  }),
  Homework: (data, params) => ({
    parentId: params?.parentId ?? data.id,
    position: params?.position ?? data.data.length,
    params: params.params,
    type: params.type,
    title: params.params.title,
    description: params.params.description,
  }),
};

export const typeSignatureEventsList = {
  CURRENT: 'current',
  UPCOMING: 'upcoming',
  COMPLETED: 'completed',
  INVITATIONS: 'invitations',
};

export const typesViewCards = {
  DESKTOP: 'ELRows',
  ROWS: 'ELMiniRows',
  CELLS: 'ELCells',
};

export const typesSortCards = {
  PERFORM: 'Perform',
  UNFULFILLED: 'Unfulfilled',
  ALL: 'All',
};

export const typesEventRoles = {
  Author: 'Автор',
  Reviewer: 'Рецензент',
  Editor: 'Редактор',
};

export default {
  RESOURCES_DP: 'RESOURCES_DP',
  STRUCTURE_DP: 'STRUCTURE_DP',
  SINGLES: 'EVENTS_SINGLES',
};
