import './index.scss';
import React, { FC, Fragment, ReactElement } from 'react';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { Button, Icon, Text, Toggle } from '../../app.modules';

interface IProps {
  data: {
    params: any;
    setAlert?(data: any): void;
  };
  onDestroy: any;
  ButtonClose?: any;
}

const ModalCompleteTest: FC<IProps> = function ({ data, onDestroy, ButtonClose }): ReactElement {
  const [state, setState] = useToState({
    check: false,
  });

  const { params } = data;

  const loading = params?.loading;

  const onChangeEndCode = () => {
    if (!loading?.endCode || !data?.setAlert) return null;

    data.setAlert({
      code: loading.endCode,
      uuid: uuid(),
      params: {},
    });
  };

  const onConfirm = async () => {
    await data.params?.onConfirm();

    onChangeEndCode();

    onDestroy();
  };

  const renderFields = () => {
    return Object.values(params.fields).filter((el: any, i) => <Fragment key={i}>{el}</Fragment>);
  };

  const onChangeConfirm = () => {
    setState({
      check: !state.check,
    });
  };

  return (
    <div className="modal-complete-test">
      <ButtonClose />
      <h1 className="modal-complete-test__title">Вы заканчиваете тестирование не завершив все попытки.</h1>
      <div className="modal-complete-test__fields">
        <Text className="modal-complete-test__desc">Ваш текущий результат:</Text>
        {renderFields()}
      </div>
      <Text variant="sm" className="modal-complete-test__confirm">
        <Toggle replace variant="success" checked={state.checked} onChange={onChangeConfirm}>
          <Icon name="CheckSuccess" />Я подтверждаю, что хочу завершить тестирование досрочно
        </Toggle>
      </Text>
      <div className="modal-complete-test__panel">
        <Button off={!state.check} size="md" onClick={onConfirm}>
          {translate('g.end')}
        </Button>
        <Button variant="outlined" monochrome size="md" onClick={onDestroy}>
          {translate('g.btn.cancel.s')}
        </Button>
      </div>
    </div>
  );
};

export default ModalCompleteTest;
