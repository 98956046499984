import React from 'react';

export default function TypeFree() {
  return (
    <i>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
        <rect width="22" height="22" fill="var(--el-fonts--icon-color)" rx="2" />
        <path
          fill="var(--el-general--layout-color)"
          d="M4.4 14.85v2.75h2.75l8.11-8.11-2.75-2.75-8.11 8.11zm12.986-7.486a.73.73 0 000-1.034L15.67 4.614a.73.73 0 00-1.034 0l-1.342 1.342 2.75 2.75 1.342-1.342z"
        />
      </svg>
    </i>
  );
}
