import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

const ELCloseModal = (props: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M11 1L1 11M1 1l10 10" />
    </svg>
  );
};

export default ELCloseModal;
