import { useEffect, useRef } from 'react';

export default (eventName: string, handler: Function, element: any = window): void => {
  const savedHandler = useRef(null) as any;

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler, savedHandler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;

    if (!isSupported) return;

    const eventListener = (event) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener);

    return () => {
      element.removeEventListener(eventName, eventListener);
    };
  }, [eventName, element, savedHandler]);
};
