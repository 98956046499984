import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELSearch(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeWidth="2" d="M7 13a6 6 0 116-6 6.008 6.008 0 01-6 6z" />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M12 12l5 5" />
    </svg>
  );
}
