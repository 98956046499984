import React from 'react';

export default function ArrowUp(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M2 7l5-5 5 5"
      />
    </svg>
  );
}
