export default {
  RU: {
    'storage.tlt': 'Менеджер файлов',
    'storage.free': 'свободно',
    'storage.file.type.all': 'Все типы файлов (*.*)',
    'storage.file.s': 'Файлы',
    'storage.image.s': 'Изображения (jpg / jpeg, png, tiff)',
    'storage.book.s': 'Книги',
    'storage.video.s': 'Видеофайлы (mp4, avi, WMA, WebM, MKV, RM, RMVB, MOV, MPG, FLV, WMV, ASF, TS, 3G2, 3GP, OGV)',
    'storage.audio.s': 'Аудиофайлы (mp3, WMA)',
    'storage.crt.folder': 'Создать папку',
    'storage.crt.folder.pls': 'Введите название',
    'storage.upload.tlt': 'Загружаем',
    'storage.upload.not.tlt': 'Остановить загрузку файла?',
    'storage.upload.err.copy': 'Файл с таким именем уже существует.',
    'storage.upload.end': 'Загрузка завершена!',
    'storage.upload.repeat': 'Повторить загрузку',
    'storage.delete.cnf': 'Вы действительно хотите безвозвратно удалить',
  },
};
