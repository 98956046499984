export default {
  EN: {
    'status.pending': 'Invited',
    'status.rejected': 'Rejected',
    'status.accepted': 'Accepted',
    'status.statistic.Completed': 'Completed',
    'status.statistic.InProgress': 'In progress',
    'g.canceled': 'Cancelled',
    'g.payment': 'Paid',
    'g.pending': 'Pending',
    'g.fao': 'English (En)',
    'g.forbidden': 'Forbidden',
    'g.allowed': 'Allowed',
    'g.unlimited': 'Unlimited',
    'g.accidentally': 'Accidentally',
    'g.consistently': 'Consistently',
    'g.teach': 'Teaching',
    'g.studying': 'Studying',
    'g.available': 'Available',
    'g.available.not': 'Unavailable',
    'g.sts.rejected.1': 'Rejected',
    'g.sts.payment_error': 'Payment error',
    'g.sts.pay.not': 'Not paid',
    'g.sts.active.s': 'Active',
    'g.sts.completed': 'Completed',
    'g.sts.not.yet': 'Not started yet',
    'g.sts.not.yet.s': 'Not started yet',
    'g.sts.cpl.end': 'Studied',
    'g.sts.cpl.end.s': 'Studied',
    'g.sts.cpl.now': 'Studying',
    'g.sts.cpl': 'Studying',
    'g.sts.fulfilled': 'Fullfilled',
    'g.sts.comply.not': 'Not complying',
    'g.sts.start.not': 'Not started',
    'g.gb': 'Gb',
    'g.month': 'month',
    'g.month.s': 'months',
    'g.months': 'months',
    'g.members': 'participants',
    'g.gigabytes': 'gigabytes',
    'g.period': 'period',
    'g.month.m': 'month',
    'g.year': 'year',
    'g.amount': 'Amount',
    'g.languages': 'Languages',
    'g.name': 'Name',
    'g.description': 'Description',
    'g.cover': 'Cover',
    'g.cover.mini': 'Mincover',
    'g.from': 'from',
    'g.to': 'to',
    'g.point.a': 'Points',
    'g.question': 'Question',
    'g.question.s': 'Questions',
    'g.questions': 'Questions',
    'g.minute': 'minute',
    'g.minute.s': 'minutes',
    'g.minutes': 'minutes',
    'g.point': 'point',
    'g.point.s': 'points',
    'g.points': 'points',
    'g.attempt': 'Attempt',
    'g.attempt.s': 'Attempts',
    'g.attempts': 'Attempts',
    'g.day': 'Day',
    'g.days.s': 'Days',
    'g.days': 'Days',
    'g.domain': 'Domain',
    'g.NDS': 'VAT',
    'g.NDS.not': 'w/o VAT',
    'g.dates': 'Dates',
    'g.author': 'Author',
    'g.mmb': 'Users',
    'g.usr.s': 'Participants',
    'g.event': 'Event',
    'g.settings': 'Settings',
    'g.content': 'Contents',
    'g.site': 'Site',
    'g.paid': 'For a fee',
    'g.free': 'Free',
    'g.limited.not': 'Unlimited',
    'g.money.ru': 'RUB',
    'g.user': 'User',
    'g.email': 'e-mail',
    'g.search': 'Search',
    'g.pgn.view': 'View on page',
    'g.pgn.lbl': 'Go over to',
    'g.sts.pay.wait': 'Waiting for payment',
    'g.sts.pay.scc': 'Paid',
    'g.sts': 'Status',
    'g.role': 'Role',
    'g.usr.clock': 'User blocked',
    'g.choose': 'chosen',
    'g.usr': 'Participant',
    'g.ivt.tlt': 'Invitations',
    'g.progress': 'Progress',
    'g.search.pls': 'Enter text',
    'g.middle': 'Middle',
    'g.usr.all': 'All participants',
    'g.download.s': 'Download',
    'g.btn.cancel': 'Cancelling',
    'g.btn.cancel.s': 'Cancel',
    'g.btn.send': 'Send',
    'g.btn.pay': 'Pay',
    'g.btn.yes': 'Yes',
    'g.btn.no': 'No',
    'g.btn.delete': 'Delete',
    'g.btn.save': 'Save',
    'g.btn.create': 'Create',
    'g.btn.restore': 'Restore',
    'g.btn.activate': 'Activate',
    'g.btn.move.archive': 'Move to archive',
    'g.btn.stop': 'Stop',
    'g.btn.canceled': 'Reject',
    'g.btn.confirmed': 'Confirm',
    'g.btn.ivt': 'Invite',
    'g.btn.add': 'Add',
    'g.btn.ext.excel': 'Export to Excel',
    'g.btn.view.more': 'View more',
    'g.btn.upload.new.s': 'Upload new',
    'g.btn.upload': 'Upload',
    'g.btn.back': 'Back',
    'g.btn.overwrite': 'Overwrite',
    'g.btn.copy': 'Copy',
    'g.btn.accept': 'Accept',
    'g.btn.verify': 'Verify',
    'g.btn.change': 'Change',
    'g.btn.change.not': 'Do not change',
    'g.btn.awr': 'Answer',
    'g.study.mode': 'Study mode',
    'g.teaching.mode': 'Teaching mode',
    'g.my.profile': 'My profile',
    'g.my.agency': 'My agency',
    'g.change.agency': 'change agency',
    'g.exit': 'Exit',
    'g.shared.info': 'General information',
    'g.usr.not.found': 'No users found',
    'g.opt.ivt.cancel': 'Cancel invitation',
    'g.opt.ivt.repeat': 'Invite again',
    'g.opt.unlock': 'Unlock',
    'g.opt.lock': 'Lock',
    'g.opt.delete.usr': 'delete user',
    'g.date.actives': 'Activity',
    'g.ivt.role': 'Invite for a role',
    'g.fld.email.pls': 'Enter e-mail',
    'g.fld.last.name.pls': 'Enter surname',
    'g.fld.first.name.pls': 'Enter name',
    'g.fld.middle.name.pls': 'Enter patronym',
    'g.fld.last.name': 'Surname',
    'g.fld.first.name': 'Name',
    'g.fld.middle.name': 'Patronym',
    'g.usr.s.delete': 'Do you want to delete chosen users?',
    'g.usr.s.unlock': 'Do you want to unlock chosen users?',
    'g.usr.s.lock': 'Do you want to lock chosen users?',
    'g.tbl.opt.lbl': 'Tasks showed at the table',
    'g.search.tst': 'Find task',
    'g.video.instruction': 'Video manual',
    'g.support': 'Technical support',
    'g.materials': 'Materials',
    'g.materials.s': 'Materials',
    'g.multimedia': 'Multimedia',
    'g.task': 'Task',
    'g.task.s': 'Taska',
    'g.online.lesson': 'Online-lesson',
    'g.file': 'File',
    'g.book': 'Book',
    'g.page': 'Page',
    'g.image': 'Image',
    'g.video': 'Video',
    'g.audio': 'Audio',
    'g.test': 'Test',
    'g.homework': 'Home work',
    'g.broadcast': 'Broadcasting',
    'g.load.file.s': 'Loas file',
    'g.time': 'Time',
    'g.createdAt': 'Created',
    'g.access': 'Access',
    'g.add.link': 'Add link',
    'g.embed.code': 'Embed code',
    'g.embed.code.vide': 'Embed video code',
    'g.date': 'Date',
    'g.duration': 'Duration',
    'g.min.t': 'min.',
    'g.from.s': 'of',
    'g.copy': 'Copy',
    'g.time.sent': 'Sent at',
    'g.read.more': 'Read further',
    'g.collapse': 'Collapse',
    'g.btn.ath.file': 'Attach file',
    'g.comments': 'Comments',
    'g.comments.etr': 'Enter a comment',
    'g.comments.del': 'Delete comment',
    'g.comments.s': 'comments',
    'g.date.time': 'Date and time',
    'g.min': 'minimum',
    'g.max': 'maximum',
    'g.pass': 'pass',
    'g.other': 'Other',
    'g.change.s': 'Change',
    'g.questions.s': 'Questions',
    'g.active.not': 'Inactive',
    'g.active': 'Active',
    'g.file.new': 'New file',
    'g.choose.s': 'Choose',
    'g.download': 'Download',
    'g.btn.open': 'Open',
    'g.date.chg': 'Change date',
    'g.size': 'Size',
    'g.date.s': 'Date of',
    'g.or': 'or',
    'g.register': 'register',
    'g.btn.participate': 'participate',
    'g.material': 'Material',
    'g.end': 'Finish',
    'g.cancel': 'Rejected',
    'g.webinar': 'Webinar',
    'g.sing.in.tlt': 'Sign in',
    'g.sing.in.pass': 'Password',
    'g.sing.in.pass.save': 'Save password',
    'g.sing.in.forgot': 'Forgot password?',
    'g.sing.in.confirm.tlt': 'By signing in you confirm processing of your personal data.',
    'g.sing.in': 'Sign in',
    'g.sing.up': 'Sign up',
    'g.sing.up.confirm.tlt': 'By signing up you confirm processing of your personal data.',
    'g.sing.in.me': 'I have an account already',
    'g.sing.forgot.email': 'Put in your e-mail and we will send you a link to restore your password',
    'g.sing.forgot': 'Restore password',
    'g.sing.forgot.up': 'I remembered the password',
    'g.sing.forgot.link': 'We sent you link to restore your password',
    'g.sing.forgot.check.mail': 'Check up your e-mail',
    'g.sing.forgot.check.mail.link': 'your link for password restoration is allready there',
    'g.sing.forgot.thanks': 'Thank you, I will check it!',
    'g.dates.time': 'Choose date & time',
    'g.connect': 'Connect',
    'g.reconnect': 'Reconnect',
    'g.back.page': 'Leave page',
    'g.ivt': 'Invitation',
    'g.ivt.prt': 'to participation',
    'g.ivt.prt.cancel': 'Decline invitation',
    'g.ivt.prt.confirm': 'Accept invitation',
    'g.ivt.prt.cancel.time': 'Invitation will be rejected in:',
    'g.seconds': 'sec.',
    'g.continue': 'Continue',
    'g.control.role': 'to control in a role',
    'g.join': 'Join',
    'g.see': 'View',
    'g.statistic': 'Statistic',
    'g.roles': 'Roles',
    'g.not.found': 'Not found',
  },
};
