export default {
  DE: {
    'learning.cmp.test': 'Gratulation! Test wurde abgeschlossen.',
    'learning.repeat': 'Nochmals testen',
    'learning.cmp.attempt': 'Dieser Versuch wurde beendet',
    'learning.rating': 'Ihre Note',
    'learning.answered': 'Beantwortet',
    'learning.back': 'Zu Profil',
    'learning.back.event': 'Zurück zur Maßnahme',
    'learning.alw.tlt': 'Im Test ist es erlaubt',
    'learning.alw.change.awr': 'Eigene Antworten auf Fragen ändern.',
    'learning.alw.qs.awr': 'Die Fragen in einer beliebigen Reihenfolge zu beantworten .',
    'learning.alw.know.not': 'Die "Ich weiss nicht" Antwort zur Beurteilung eigenen realen Kentnissen geben.',
    'learning.test.start': 'Test beginnen',
    'learning.progress': '% abgeschlossen',
    'learning.chs.once.awr': 'Eine Antwortvariante anwählen',
    'learning.chs.more.awr': 'Mehrere Antwortvarianten anwählen',
    'learning.know.no': 'Ich weiss nicht',
    'learning.qs.next': 'Nächste Frage',
    'learning.test.end': 'Möchten Sie den Test beenden?',
    'learning.inp.txt': 'Setzen Sie Ihre Antwort ins Feld ein',
    'learning.inp.txt.pls': 'Geben Sie eine Anwortvariante ein',
    'learning.att.warning.tlt': 'Sie beenden das Testen ohne alle Versuche verbraucht zu haben.',
    'learning.result.now': 'Ihr aktuelles Resultat:',
    'learning.att.close.arg': 'Ich bestätige hiermit, dass ich das Testen vorzeitig beenden möchte.',
  },
};
