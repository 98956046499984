import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAttemptBest(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="25" fill="none" viewBox="0 0 23 25" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.891 11.591l4.053-4.249c.573-.573.907-1.405.907-2.586 0-1.516.04-2.399 1.233-2.705 1.444-.37 2.493 1.368 2.699 2.322.285 1.326.393 2.68-.177 4.037-.46 1.098-1.151 1.698-1.151 1.698s-.196.29-.196.364c0 .112.196.104.196.104h5.14c1.26 0 1.431.87 1.402 1.414-.057 1.08-.8 1.46-1.086 1.647.172.245.593.771.538 1.59-.06.923-.697 1.288-1.069 1.545.251.403.613.955.496 1.648-.145.86-.95 1.214-1.244 1.412.157.36.539.834.438 1.57-.184 1.333-1.474 1.534-1.799 1.534h-6.828c-1.668 0-3.682-1.419-3.682-1.419l.13-9.926z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.069 10.128H1.702a.702.702 0 00-.702.703v11.976c0 .388.314.702.702.702h5.367a.702.702 0 00.702-.702V10.831a.702.702 0 00-.702-.703z"
      />
    </svg>
  );
}
