import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELClose(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M18 1L1 18M1 1l17 17" />
    </svg>
  );
}
