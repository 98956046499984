import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { useEvent, useToState } from '../../hooks';
import Scroll from '../Scroll';
import Search from '../Search';
import Text from '../Text';
import ContextMenu2Option from './ContextMenu2Option';

interface IProps {
  data: Array<any>;
  className?: string;
  onClose?: any;
  onChange?: any;
  withScroll?: boolean;
  search?: {
    label?: string | ReactNode;
    placeholder?: string;
    placeholderNode?: string | ReactNode;
  };
}

const ContextMenu2: FC<IProps> = function ({ data, className, onClose, onChange, withScroll, search }): ReactElement {
  const [state, setState] = useToState({
    searchValue: '',
    data: data ?? [],
  });

  const onChangeSearch = (searchValue) => {
    setState({ searchValue });
  };

  const onClearSearch = () => {
    setState({ searchValue: '' });
  };

  const onCloseContextMenu = (e) => {
    if (!e.target.closest('.content-menu-2')) {
      onClose && onClose();
    }
  };

  useEvent('click', onCloseContextMenu);

  const renderMenuItems = () => {
    const { data, searchValue } = state;

    const filteredView = data.filter((el) => el.view || el.view === undefined);

    const filteredState = filteredView.filter((el) => !searchValue || el.title.toLowerCase().includes(searchValue));

    return filteredState.map((item, i) => <ContextMenu2Option data={item} key={item?.key ?? i} onChange={onChange} />);
  };

  const classesContextMenu = classNames('content-menu-2', className, {
    'content-menu-2--scroll': withScroll,
  });

  const renderSearch = () => {
    if (!withScroll) return null;

    const label = search?.label;

    const placeholder = search?.placeholder;

    const placeholderNode = search?.placeholderNode;

    return (
      <div className="content-menu-2__head">
        {label && <Text className="content-menu-2__label">{label}</Text>}
        <Search
          onClear={onClearSearch}
          size="md"
          onChange={onChangeSearch}
          placeholder={placeholder}
          placeholderNode={placeholderNode}
        />
      </div>
    );
  };

  const renderContent = () => {
    const content = renderMenuItems();

    if (!withScroll)
      return (
        <>
          {search && renderSearch()}
          {content}
        </>
      );

    return (
      <>
        {search && renderSearch()}
        <div className="content-menu-2__scroll">
          <Scroll>{content}</Scroll>
        </div>
      </>
    );
  };

  return <div className={classesContextMenu}>{renderContent()}</div>;
};

export default ContextMenu2;
