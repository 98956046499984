import { IEditorAction, IEditorState } from './builder.page.resources';
import types from './builder.page.types';

const initialState: IEditorState = {
  template: null,
  multiple: false,
  screens: false,
  injectIndex: 0,
  selection: false,
};

export default function editorReducer(state: IEditorState = initialState, action: IEditorAction): IEditorState {
  switch (action.type) {
    case types.TEMPLATE:
      return { ...state, template: action.template };

    case types.MULTIPLE:
      return { ...state, multiple: action.multiple };

    case types.SCREENS:
      return { ...state, screens: action.screens };

    case types.INJECT_INDEX:
      return { ...state, injectIndex: action.injectIndex };

    case types.SELECTION:
      return { ...state, selection: action.selection };

    default:
      return state;
  }
}
