import React, { FC, ReactNode } from 'react';
import RefTarget from './RefTarget';

interface IProps {
  defaultValue?: string;
  placeholder?: string | ReactNode;
  onBlur?(data: string): void;
  onKeyPress?(e): void;
  children: any;
}

const ContentEditable: FC<IProps> = ({ onBlur, placeholder, children, onKeyPress }) => {
  const onBlurChange = (e) => {
    onBlur && onBlur(e.target.innerText);
  };

  const onKeyPressChange = (e) => {
    onKeyPress && onKeyPress(e);
  };

  const onPasteChange = (event) => {
    let paste = event.clipboardData.getData('text');

    paste = paste.toUpperCase();

    const selection: any = window.getSelection();

    if (!selection.rangeCount) return false;
    selection.deleteFromDocument();
    selection.getRangeAt(0).insertNode(document.createTextNode(paste));

    event.preventDefault();
  };

  const params = {
    onBlur: onBlurChange,
    onKeyPress: onKeyPressChange,
    onPaste: onPasteChange,
    contentEditable: true,
    suppressContentEditableWarning: true,
    ...(placeholder && { placeholder }),
  };

  return (
    <div className="editable--eject editable--primary">
      <RefTarget params={{ ...params }}>{children}</RefTarget>
    </div>
  );
};

export default ContentEditable;
