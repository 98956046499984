import React from 'react';

interface IProps {
  className?: string;
}

export default function ELCheck(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="8" fill="none" viewBox="0 0 11 8" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.816 1.214L4.57 6.462a.5.5 0 01-.707 0L1 3.6"
      />
    </svg>
  );
}
