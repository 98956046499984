export default {
  RU: {
    'support.tlt': 'Написать в Техподдержку',
    'support.desc': 'Нашли ошибку в работе платформы? Помогите нам её исправить.',
    'support.label.1':
      'Для этого опишите, пожалуйста, подробно шаги воспроизведения проблемы и прикрепите ссылку на страницу и скриншот.',
    'support.label.2': 'Мы получим Ваше сообщение и внесём исправления в ближайшее время.',
    'support.problem.pls.2': 'Опишите проблему',
    'support.problem.link': 'Вставьте ссылку на страницу платформы',
  },
};
