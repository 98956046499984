import { IPortalsAction, IPortalsState } from './portals.resources';

const initialState: IPortalsState = {};

export default function portalsReducer(state: IPortalsState = initialState, action: IPortalsAction): IPortalsState {
  switch (action.type) {
    default:
      return state;
  }
}
