import { IParticipantsAction, IParticipantsState } from './participants.resources';
import types from './participants.types';

const initialState: IParticipantsState = {
  events: {},
};

export default function participantsReducer(
  state: IParticipantsState = initialState,
  action: IParticipantsAction
): IParticipantsState {
  const actionType = {
    [types.EVENTS]: {
      ...state,
      events: action.events,
    },
  };

  return actionType[action.type] ?? state;
}
