import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useToState } from '../../hooks';
import translate from '../../i18n/translate';
import Hint from '../Hint';
import Icon from '../Icon';
import Input from '../Input';
import Text from '../Text';

interface IProps {
  label?: string | ReactNode;
  hint?: string;
  onChange?(e): void;
  time: {
    hour?: string;
    minute?: number;
  };
  type?: string;
}

const ExistTime: FC<IProps> = function ({ label, time, onChange, hint, type = 'default' }): ReactElement {
  const [state, setState] = useToState({
    valueHour: '',
    valueMinute: time?.minute ?? '',
    limitedType: time?.minute === 0 ? 'notLimited' : 'limited',
  });

  useEffect(() => {
    setState({
      valueMinute: time?.minute ?? '',
    });
  }, [time]);

  const inputRef = useRef<HTMLInputElement>(null);

  const onChangeHour = (e) => {
    const { value } = e.target;

    setState({
      valueHour: value,
    });
  };

  const onChangeMinute = (key) => (e) => {
    const { value } = e.target;

    const isValid = value.replace(/\D/g, '');

    const valueNumber = isValid <= 999 ? isValid : 999;

    setState({
      [key]: valueNumber,
    });

    onChange && onChange(valueNumber);
  };

  const onToggleType = (type, e) => {
    e.stopPropagation();

    const valueLimited = state.valueMinute;

    const types = {
      notLimited: ['limited', valueLimited],
      limited: ['notLimited', 0],
      attempt: ['notLimited', 0],
    };

    const [limitedType, valueMinute] = types[type];

    setState({
      limitedType,
    });

    onChange && onChange(valueMinute);
  };

  const classesIntervalExistTime = classNames('exist-time', {
    'exist-time--hint': hint,
  });

  const renderHint = () => {
    return (
      hint && (
        <Hint label={hint} move={['right', 'bottom']}>
          {(ref) => <Icon name="ELHint" innerRef={ref} />}
        </Hint>
      )
    );
  };

  const renderHour = () => {
    const isHour = time?.hour;

    return (
      isHour && (
        <div className="exist-time__field">
          <input
            type="text"
            ref={inputRef}
            maxLength={2}
            value={state.valueHour}
            onChange={onChangeHour}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <Text>ч.</Text>
        </div>
      )
    );
  };

  const renderMinute = () => {
    return (
      <div className="exist-time__field">
        <input
          type="text"
          ref={inputRef}
          maxLength={3}
          value={state.valueMinute}
          onChange={onChangeMinute('valueMinute')}
          placeholder="0"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        <Text>мин.</Text>
      </div>
    );
  };

  const handleFocus = (e) => e.target.select();

  const handleBlur = (e) => {
    +state.valueMinute === 0 && onToggleType('limited', e);
  };

  const onClickInput = (e) => {
    onToggleType('notLimited', e);
    inputRef.current?.focus();
  };

  const renderValue = () => {
    return (
      <div className="exist-time__field">
        <Input
          variant="inline"
          maxLength={999}
          size="md"
          value={state.valueMinute}
          onChange={onChangeMinute('valueMinute')}
          placeholder="0"
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
      </div>
    );
  };

  const renderContentLimit = () => {
    const types = {
      default: (
        <>
          {renderHour()}
          {renderMinute()}
          <div className="exist-time__close" onClick={(e) => onToggleType('limited', e)}>
            <Icon name="ELCloseCircle" />
          </div>
        </>
      ),
      attempt: (
        <>
          {renderValue()}
          <div className="exist-time__close" onClick={(e) => onToggleType('attempt', e)}>
            <Icon name="ELCloseCircle" />
          </div>
        </>
      ),
    };

    return types[type];
  };

  const renderLimited = () => {
    const { limitedType } = state;

    const types = {
      notLimited: (
        <Text>
          {translate('g.limited.not')}
          <div className="exist-time__close" onClick={(e) => onToggleType('notLimited', e)}>
            <Icon name="ELCloseCircle" />
          </div>
        </Text>
      ),
      limited: renderContentLimit(),
    };

    return types[limitedType];
  };

  return (
    <div className={classesIntervalExistTime}>
      {renderHint()}
      <div className="exist-time__input" onClick={onClickInput}>
        {renderLimited()}
      </div>
      {label && <Text>{label}</Text>}
    </div>
  );
};

export default ExistTime;
