import { ILocationAction, ILocationState } from './location.resources';
import types from './location.types';

const initialState: ILocationState = {
  langName: 'RU',
};

export default function locationReducer(state: ILocationState = initialState, action: ILocationAction): ILocationState {
  const actionType = {
    [types.LANG_NAME]: {
      ...state,
      langName: action.langName,
    },
  };

  return actionType[action.type] ?? state;
}
