import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELArrowLineBottom(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" fill="none" viewBox="0 0 13 16" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.764 9.764l-5 5-5-5m4.992-8.528v11.048"
      />
    </svg>
  );
}
