import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  error?: ReactNode;
  variant?: string;
  type?: string;
  name?: string;
  value?: any;
  defaultValue?: string;
  checked?: boolean;
  defaultChecked?: boolean;
  replace?: boolean;
  off?: boolean;
  className?: string;
  onChange?: Function;
}

const Toggle: FC<IProps> = function ({
  type = 'checkbox',
  onChange,
  value,
  name,
  checked,
  defaultValue,
  defaultChecked,
  children,
  error,
  off,
  className,
  replace = false,
  variant = 'primary',
}): ReactElement {
  const onInput: ReactEventHandler = (e): void => {
    onChange && onChange(e);
  };

  const isParams = {
    onChange: onInput,
    ...((type === 'checkbox' || type === 'radio') && { type }),
    value,
    defaultValue,
    defaultChecked,
    name,
    checked,
  };

  const classes = classNames('toggle--eject', `toggle--${variant}`, className, {
    error: error,
    'toggle--off': off,
  });

  return (
    <div className={classes}>
      <label>
        <input {...isParams} />
        {!replace && <span className={`toggle--${variant}__switch`} />}
        {children}
      </label>
    </div>
  );
};

export default Toggle;
