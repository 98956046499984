import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELGrid(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <rect width="7" height="7" x="12" y="1" stroke="var(--el-fonts--icon-color)" strokeWidth="2" rx="2" />
      <rect width="7" height="7" x="1" y="1" stroke="var(--el-fonts--icon-color)" strokeWidth="2" rx="2" />
      <rect width="7" height="7" x="1" y="12" stroke="var(--el-fonts--icon-color)" strokeWidth="2" rx="2" />
      <rect width="7" height="7" x="12" y="12" stroke="var(--el-fonts--icon-color)" strokeWidth="2" rx="2" />
    </svg>
  );
}
