import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { GROUPS } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: any;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...GROUPS };
  }

  protected getGroupsAdmin(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.ROUTINE, data);
  }

  protected groupCreate(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.ROUTINE, data);
  }

  protected groupRead(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.READ, data);
  }

  protected groupUpdate(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.patch(ADMIN_PANEL.UPDATE, data);
  }

  protected groupInvitations(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.INVITATIONS, data);
  }

  protected groupSendInvite(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.SEND_INVITE, data);
  }

  protected groupInviteRead(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.INVITE_ROUTINE, data);
  }

  protected groupSubmitInvite(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.INVITE_ROUTINE, data);
  }

  protected groupResendInvite(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.RESEND_INVITE, data);
  }

  protected groupCanceledInvite(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.delete(ADMIN_PANEL.INVITATIONS, data);
  }

  protected groupGetParticipants(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.PARTICIPANTS, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
