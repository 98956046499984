import { FC, ReactElement } from 'react';
import types from './types';

interface IProps {
  name: string;
  className?: string;
  innerRef?: any;
  onClick?(e: any): any;
}

const Icon: FC<IProps> = function ({ name, className, onClick }): ReactElement {
  const props = {
    className,
    onClick,
  };

  return types[name](props);
};

export default Icon;
