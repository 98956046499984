import { Dispatch } from 'redux';
import { uuid } from '../../utils';
import { types } from './modal.types';

export function setDataModal(idObject: string | object, data: any) {
  return (dispatch: Dispatch, getStore) => {
    const cloneModals =
      typeof idObject === 'string'
        ? {
            ...getStore().modal.data,
            [idObject]: data,
          }
        : {
            ...getStore().modal.data,
            ...idObject,
          };

    dispatch({
      type: types.DATA,
      data: cloneModals,
    });
  };
}

export function setModal(alert: any, empty: boolean = false) {
  return (dispatch: Dispatch, getStore: Function) => {
    const cloneStoreAlert = empty
      ? alert
      : [
          ...getStore().modal.register,
          {
            ...alert,
            uuid: uuid(),
          },
        ];

    dispatch({
      type: types.REGISTER,
      register: cloneStoreAlert,
    });
  };
}
