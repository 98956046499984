import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { LocationDate } from '../../app/app.modules';
import { useHover } from '../../hooks';
import { URI } from '../../routes/uri';
import { clearHTML } from '../../utils';
import Icon from '../Icon';
import Square from '../Square';
import Text from '../Text';

interface IProps {
  icon: string;
  data: any;
  description: string;
  title: string;
  variant?: string;
  active?: boolean;
  showSettings?: boolean;
  className?: string;
}

const Attach: FC<IProps> = function ({
  icon,
  description,
  title,
  variant,
  active,
  className,
  data,
  showSettings = false,
}): ReactElement {
  const [ref, isHovered] = useHover();

  const classes = classNames('attach--eject', className, {
    [`attach--${variant}`]: variant,
    'attach--event-active': active,
  });

  const classesAttachFileIcon = classNames('attach__data-icon', {
    'attach__data-icon--task': data.type !== 'File',
  });

  const renderImage = () => {
    const loadImage = URI.STORAGE.FILE(data.params.fileId);

    return (
      data.params.fileId && (
        <>
          <img src={loadImage} className="attach__data-img" alt="" ref={ref} />
          {isHovered && !data?.hidden && <img src={loadImage} className="attach__data-img--scale" alt="" ref={ref} />}
        </>
      )
    );
  };

  const renderTypesSignature = () => {
    const types = {
      ELImage: renderImage,
    };

    const searchSignature = types[icon] ?? (() => <Icon name={icon} />);

    return searchSignature();
  };

  return (
    <div className={classes}>
      <div className="attach__data">
        <Square size="lg" className={classesAttachFileIcon}>
          {renderTypesSignature()}
        </Square>
        <div className="attach__desc">
          <Text variant="lg">{title}</Text>
          {description && <Text variant="md">{clearHTML(description)}</Text>}
        </div>
      </div>
      {!showSettings && (
        <LocationDate startDate={data?.startDate} endDate={data?.endDate} combineInfinities={false}>
          {({ getStartDate, getEndDate, withInfinity }) => (
            <Text className="attach__date" variant="sm">
              {withInfinity(getStartDate('dd.MM.yyyy'), getEndDate('dd.MM.yyyy'), ' - ')}
            </Text>
          )}
        </LocationDate>
      )}
      {/*{renderContent()}*/}
    </div>
  );
};

export default Attach;
