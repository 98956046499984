import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAttempt(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none" viewBox="0 0 22 20" {...props}>
      <path
        fill="var(--el-fonts--icon-fill-color)"
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M10.245 2.856l.755.87.755-.87a5.17 5.17 0 011.08-.938A5.73 5.73 0 0115.95 1C18.804 1 21 3.182 21 5.935c0 1.017-.242 1.998-.675 2.957-1.378 3.054-4.585 5.699-7.587 8.15l-.004.002c-.59.482-1.169.954-1.71 1.413l.646.763-.647-.763a.034.034 0 01-.023.008.034.034 0 01-.023-.008c-.542-.459-1.121-.931-1.71-1.413l-.005-.003c-3.002-2.45-6.21-5.095-7.587-8.149C1.242 7.933 1 6.952 1 5.935 1 3.182 3.196 1 6.05 1a5.73 5.73 0 013.114.918c.406.265.77.58 1.08.938z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M16.433 8.694c.941-.496 1.616-1.634 1.596-2.69-.012-.59-.464-1.045-1.026-1.034-.606.012-.942.362-1.025 1.069-.05.415-.168.623-.47.828-.422.285-.611.594-.602.981.02.748.845 1.206 1.527.846z"
      />
    </svg>
  );
}
