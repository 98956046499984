import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELFullScreenOut(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M17 6.5a1 1 0 100-2v2zM13.5 1a1 1 0 10-2 0h2zm-1 4.4h-1 1zm4.5-.9h-4.4v2H17v-2zm-3.5.9V1h-2v4.4h2zm-.9-.9a.9.9 0 01.9.9h-2a1.1 1.1 0 001.1 1.1v-2zM1 6.5a1 1 0 010-2v2zM4.5 1a1 1 0 012 0h-2zm1 4.4h1-1zM1 4.5h4.4v2H1v-2zm3.5.9V1h2v4.4h-2zm.9-.9a.9.9 0 00-.9.9h2a1.1 1.1 0 01-1.1 1.1v-2zm11.6 7a1 1 0 110 2v-2zM13.5 17a1 1 0 01-2 0h2zm-1-4.4h-1 1zm4.5.9h-4.4v-2H17v2zm-3.5-.9V17h-2v-4.4h2zm-.9.9a.9.9 0 00.9-.9h-2a1.1 1.1 0 011.1-1.1v2zM1 11.5a1 1 0 000 2v-2zM4.5 17a1 1 0 102 0h-2zm1-4.4h1-1zm-4.5.9h4.4v-2H1v2zm3.5-.9V17h2v-4.4h-2zm.9.9a.9.9 0 01-.9-.9h2a1.1 1.1 0 00-1.1-1.1v2z"
      />
    </svg>
  );
}
