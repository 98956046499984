import React from 'react';

export default function StorageToRow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M9.09 6.391V2.755C9.09.482 8.61.027 6.365.027L2.727 0C.564 0 0 .51 0 2.727v3.637C0 8.61.455 9.09 2.727 9.09h3.637c2.272 0 2.727-.454 2.727-2.7zm0 10.909v-3.637c0-2.273-.48-2.727-2.726-2.727l-3.637-.027c-2.163 0-2.727.51-2.727 2.727v3.637C0 19.519.455 20 2.727 20h3.637c2.272 0 2.727-.454 2.727-2.7zM20 6.39V2.755C20 .482 19.52.027 17.273.027L13.636 0c-2.163 0-2.727.51-2.727 2.727v3.637c0 2.246.455 2.727 2.727 2.727h3.637c2.273 0 2.727-.454 2.727-2.7zm0 10.91v-3.637c0-2.273-.48-2.727-2.727-2.727l-3.637-.027c-2.163 0-2.727.51-2.727 2.727v3.637c0 2.246.455 2.727 2.727 2.727h3.637C19.546 20 20 19.546 20 17.3z"
      />
    </svg>
  );
}
