export default {
  EN: {
    'learning.cmp.test': 'Congratulations! Test completed.',
    'learning.repeat': 'Run test again',
    'learning.cmp.attempt': 'This attempt has been completed',
    'learning.rating': 'Your mark',
    'learning.answered': 'Answered',
    'learning.back': 'Back to profile',
    'learning.back.event': 'Back to event',
    'learning.alw.tlt': 'While testing it is allowed',
    'learning.alw.change.awr': 'to change own answers to the questions.',
    'learning.alw.qs.awr': 'to answer the questions in any sequence',
    'learning.alw.know.not': 'to answer “I dont know” in oder to estimate real own knowledges.',
    'learning.test.start': 'Start test',
    'learning.progress': '% completed',
    'learning.chs.once.awr': 'Choose one answer variant',
    'learning.chs.more.awr': 'Choose several answer variants',
    'learning.know.no': 'I dont know',
    'learning.qs.next': 'Next question',
    'learning.test.end': 'Do you want to end the test?',
    'learning.inp.txt': 'Input answer into the text field',
    'learning.inp.txt.pls': 'Input answer variant',
    'learning.att.warning.tlt': 'You are finishing  test without having spent all attempts.',
    'learning.result.now': 'Your current result:',
    'learning.att.close.arg': 'I confirm herewith  that I want to finish test earlier.',
  },
};
