import './index.scss';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import EditorPage from '../../../components/EditorPage';
import FileFields from '../../../components/FileFields';
import { useErrorFields, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { Button, Input, Select, Textarea } from '../../app.modules';
import Tags from '../../BuilderQuestions/BuilderQuestionsCreate/CreateTags';

interface IProps {
  data: {
    params: {
      title: string | ReactNode;
      fields: {
        name?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
        };
        description?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
          required?: boolean;
        };
        author?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
          required?: boolean;
        };
        title?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
          required?: boolean;
        };
        body?: {
          label: string | ReactNode;
          placeholder: string;
          required?: boolean;
        };
        type?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
          required?: boolean;
          data: Array<{
            title: string | ReactNode;
            params: any;
          }>;
        };
        tags?: {
          label: string | ReactNode;
          placeholder: string | ReactNode;
          required?: boolean;
        };
        file?: {
          label: string | ReactNode;
          required?: boolean;
        };
      };
      onConfirm(data: object): Promise<any>;
    };
    setAlert?(data: any): void;
  };
  onDestroy: any;
  ButtonClose: Function;
}

interface IState {
  loading: boolean;
  fields: {
    name?: string;
    description?: string;
    author?: string;
    title?: string;
  };
  autofocus: boolean;
}

const ModalCreate: FC<IProps> = function ({ data, onDestroy, ButtonClose }): ReactElement {
  const defaultFields = data.params.fields;

  const [state, setState] = useToState<IState>({
    loading: false,
    fields: {
      ...(defaultFields.name && { name: '' }),
      ...(defaultFields.title && { title: '' }),
      ...(defaultFields.description && { description: '' }),
      ...(defaultFields.author && { author: '' }),
      ...(defaultFields.body && { body: '' }),
      ...(defaultFields.type && { type: '' }),
      ...(defaultFields.tags && { tags: [] }),
    },
    autofocus: false,
  });

  const [error, setError] = useErrorFields(state.fields, (value, key) => {
    switch (key) {
      case 'title':
        return defaultFields.title ? value?.length : true;
      case 'name':
        return defaultFields.name ? value?.length : true;
      case 'description':
        return defaultFields.description?.required ? value.length : true;
      case 'author':
        return defaultFields.author?.required ? value.length : true;
      case 'body':
        return defaultFields.body?.required ? value : true;
      case 'type':
        return defaultFields.type?.required ? value.length : true;
      case 'tags':
        return defaultFields.tags?.required ? value.length : true;
      default:
        return true;
    }
  });

  const onChangeField = (key) => (e) => {
    const value = e?.target?.value ?? e?.params ?? e;

    setError(key, value);

    setState((state) => ({
      fields: {
        ...state.fields,
        [key]: value,
      },
    }));
  };

  const onChangeAttachFile = (files) => {
    setState({
      fields: {
        ...state.fields,
        file: files,
      },
    });
  };

  const onConfirm = async () => {
    const { fields } = state;

    await setError(fields).then(async () => {
      setState({
        loading: true,
      });

      await data.params?.onConfirm(fields);

      onDestroy();
    });
  };

  useEffect(() => {
    setState({
      autofocus: true,
    });
  }, []);

  return (
    state.autofocus && (
      <div className="modal-create">
        {ButtonClose()}
        <h1 className="modal-create__head">{data.params.title}</h1>
        {defaultFields.name && (
          <div className="modal-create__row">
            <Input
              placeholderNode={defaultFields.name.placeholder}
              label={defaultFields.name.label}
              size="md"
              value={state.fields.name}
              onChange={onChangeField('name')}
              error={!error.name}
              autoFocus={true}
            />
          </div>
        )}
        {defaultFields.title && (
          <div className="modal-create__row">
            <Input
              placeholderNode={defaultFields.title.placeholder}
              label={defaultFields.title.label}
              size="md"
              value={state.fields.title}
              onChange={onChangeField('title')}
              error={!error.title}
            />
          </div>
        )}
        {defaultFields.description && (
          <div className="modal-create__row">
            <Textarea
              placeholderNode={defaultFields.description.placeholder}
              label={defaultFields.description.label}
              value={state.fields.description}
              onChange={onChangeField('description')}
              error={!error.description}
            />
          </div>
        )}
        {defaultFields.file && (
          <div className="modal-create__row">
            <FileFields label={defaultFields.file.label} onChange={onChangeAttachFile} />
          </div>
        )}
        {defaultFields.author && (
          <div className="modal-create__row">
            <Input
              placeholderNode={defaultFields.author.placeholder}
              label={defaultFields.author.label}
              size="md"
              value={state.fields.author}
              onChange={onChangeField('author')}
              error={!error.author}
            />
          </div>
        )}
        {defaultFields.body && (
          <div className="modal-create__row">
            <EditorPage
              label={defaultFields.body.label}
              placeholder={defaultFields.body.placeholder}
              data={state.fields.body}
              onChange={(api, data) => onChangeField('body')(data)}
              error={!error.body}
            />
          </div>
        )}
        {defaultFields.type && (
          <div className="modal-create__row">
            <Select
              error={!error.type}
              label={defaultFields.type.label}
              data={defaultFields.type.data}
              placeholder={defaultFields.type.placeholder}
              onChange={onChangeField('type')}
            />
          </div>
        )}
        {defaultFields.tags && (
          <div className="modal-create__row">
            <Tags
              error={!error.tags}
              label={defaultFields.tags.label}
              variant="field"
              onChange={onChangeField('tags')}
            />
          </div>
        )}
        <div className="modal-create__foot">
          <Button size="md" variant="outlined" monochrome onClick={onDestroy}>
            {translate('g.btn.cancel')}
          </Button>
          <Button size="md" onClick={onConfirm} loading={state.loading} off={state.loading}>
            {translate('g.btn.create')}
          </Button>
        </div>
      </div>
    )
  );
};

export default ModalCreate;
