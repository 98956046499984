import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function Arrow({ className, onClick }: IProps) {
  const params = {
    className,
    onClick,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" fill="none" viewBox="0 0 18 15" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M7.45 1L1.262 7.186l6.186 6.186M17 7.196H3.331"
      />
    </svg>
  );
}
