import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowSmall({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" fill="none" viewBox="0 0 9 12" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.5 10.333L8 5.666m-3.5 4.667L1 5.666"
      />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M4.5 1v9.333" />
    </svg>
  );
}
