export const questionType = {
  CHOICE: 'ChoiceOfAnswer',
  FREE: 'FreeAnswer',
  NONE: 'NONE',
};

export const answerType = {
  ChoiceOfAnswer: 'choiceQuestionAnswers',
  FreeAnswer: 'studentQuestionAnswers',
  NONE: 'NONE',
};

export const buttonType = {
  NEXT: 'next',
  ANSWER: 'answer',
  ANSWER_NEXT: 'answerNext',
  FINISH: 'finish',
  NONE: 'none',
};

export const continueType = {
  START: 'Start',
  CONTINUE: 'InProgress',
  FINISH: 'Finished',
  WAIT: 'Wait',
};

export const loadingPassingType = {
  LOCATION: 'location',
  SERVE: 'serve',
  NONE: 'none',
};

export const loadingAttemptType = {
  LOCATION: 'location',
  SERVE: 'serve',
  NONE: 'none',
};

export const loadingQuestionType = loadingAttemptType;

export const answerChoseType = {
  MORE: 'More',
  ONE: 'One',
};

export const answeredType = {
  ANSWERED: 'answered',
  NOT_ANSWERED: 'notAnswered',
  ANSWERED_UNLOCK: 'answeredUnLock',
};

export default {
  questionType,
  answerType,
  buttonType,
  continueType,
  loadingPassingType,
  loadingAttemptType,
  loadingQuestionType,
  answerChoseType,
  answeredType,
};
