import React from 'react';

interface IProps {
  className?: string;
}

export default function ELExpandLg(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="90" fill="none" viewBox="0 0 86 90" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="3"
        d="M46.996 35.551l.343.809.873.097 6.859.76c1.269.142 1.793 1.703.866 2.58l-5.23 4.957-.603.57.164.815 1.419 7.033c.258 1.281-1.13 2.257-2.248 1.58l-5.722-3.47-.778-.472-.778.472-5.721 3.47c-1.117.677-2.507-.299-2.248-1.58l1.419-7.033.164-.814-.603-.572-5.23-4.955c-.927-.879-.403-2.44.866-2.58l6.859-.761.872-.097.343-.809 2.676-6.312c.517-1.22 2.245-1.22 2.762 0l1.381-.586-1.38.586 2.675 6.312z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="3"
        d="M46.36 13.993a10.473 10.473 0 0110.744 3.547 13.471 13.471 0 005.512 4.045 10.473 10.473 0 016.607 9.186c.122 2.313.84 4.556 2.081 6.512a10.473 10.473 0 01-.054 11.314 13.478 13.478 0 00-2.143 6.492 10.473 10.473 0 01-6.694 9.122 13.473 13.473 0 00-5.55 3.992 10.472 10.472 0 01-10.778 3.445 13.473 13.473 0 00-6.837-.032 10.473 10.473 0 01-10.744-3.548 13.474 13.474 0 00-5.512-4.045 10.473 10.473 0 01-6.607-9.185 13.472 13.472 0 00-2.081-6.512 10.473 10.473 0 01.054-11.315 13.478 13.478 0 002.143-6.492 10.473 10.473 0 016.695-9.122 13.472 13.472 0 005.55-3.992 10.472 10.472 0 0110.777-3.445 13.47 13.47 0 006.837.033z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="3"
        d="M30.16 69.598V86.91a1.337 1.337 0 001.897 1.214l10.185-4.7a1.337 1.337 0 011.121 0l10.185 4.7a1.337 1.337 0 001.898-1.214V69.598"
      />
    </svg>
  );
}
