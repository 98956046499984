export const typography = `
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

body {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: #494949;
}

.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before{
   color: #B0B7CB;
   opacity: .7;
}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  h1 {
    font-size: 26px;
    line-height: 32.5px;
    margin: 17.42px 0;
  }

  h2 {
    font-size: 16px;
    margin: 13.28px 0;
  }

  h3 {
    font-size: 14px;
    margin: 14px 0;
  }

  h4, h5, h6 {
    font-size: 12px;
    margin: 15.96px 0;
  }

  b, strong, em {
    font-weight: 700;
  }

  p {
    margin: 15px 0;
    font-size: 14px;
  }

  ul + ul, ul + ol, ol + ol, ol + ul {
    padding-top: 10px;
  }

  ul,
  ol {
    padding: 0;
    margin: 10px 0;
    padding-inline-start: 20px;
  }

  ul li:not(:last-child), ol li:not(:last-child) {
    margin-bottom: 10px;
  }

  ul > li {
    list-style: disc;
  }

  ul > li ul li {
    list-style: circle;
  }

  ol > li ol li {
    list-style: lower-latin;
  }

  ol > li {
    list-style: auto;
  }
`;
