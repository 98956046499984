import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode, useMemo } from 'react';
import { useToState } from '../../hooks';
import Text from '../Text';

interface IProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  defaultChecked?: boolean;
  defaultValue?: string;
  onChange?(checked: boolean, value: string): void;
}

const LabelSwitch: FC<IProps> = ({ title, description, defaultChecked, onChange, defaultValue }) => {
  const [state, setState] = useToState({
    activeTumbler: defaultChecked ?? false,
  });

  const onChangeTumbler = (e) => {
    const { checked, value } = e.target;

    setState({
      activeTumbler: checked,
    });

    onChange && onChange(checked, value);
  };

  const renderTitle = () => {
    return <Text className="label-switch__title">{title}</Text>;
  };

  const renderDescription = () => {
    return (
      description && (
        <Text variant="md" className="label-switch__desc">
          {description}
        </Text>
      )
    );
  };

  const renderSwitch = () => {
    const isParams = {
      type: 'checkbox',
      onChange: onChangeTumbler,
      // defaultChecked,
      defaultValue,
      checked: state.activeTumbler,
    };

    return (
      <label className="label-switch__tumbler">
        <input {...isParams} className="label-switch__input" />
        <span className="label-switch__target" />
      </label>
    );
  };

  const classesLabelSwitch = classNames('label-switch', {
    'label-switch--active': state.activeTumbler,
  });

  return useMemo(
    () => (
      <div className={classesLabelSwitch}>
        {renderTitle()}
        {renderSwitch()}
        {renderDescription()}
      </div>
    ),
    [state.activeTumbler, defaultChecked]
  );
};

export default LabelSwitch;
