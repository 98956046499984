import './index.scss';
import React, { FC, ReactElement } from 'react';
import translate from '../../../../i18n/translate';
import { Card, Loader } from '../../../app.modules';
import { typesTests } from '../../../Tests/tests.types';
import TestsService from '../../../Tests/TestsService';

const LibraryTests: FC = function (): ReactElement {
  return (
    <div className="library-tests">
      <h1 className="library-tests__title">{translate('ts.library')}</h1>
      <TestsService status={typesTests.COMPLETE}>
        {({ state, dataCard }) => (state.isLoading ? <Loader /> : <Card data={dataCard} withScroll />)}
      </TestsService>
    </div>
  );
};

export default LibraryTests;
