import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELBurger(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" fill="none" viewBox="0 0 22 18" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 1H1m20 8H1m20 8H1"
      />
    </svg>
  );
}
