import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELMarkAttempt(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="var(--el-fonts--icon-fill-color)"
        d="M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10c-.006 5.52-4.48 9.994-10 10z"
      />
      <circle cx="10" cy="10" r="9" stroke="var(--el-fonts--icon-color)" strokeWidth="2" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="1.7"
        d="M4.605 13.052l2.516-7.025a.2.2 0 01.376 0L10 13.053m-4.691-1.675h3.61M15.2 8.599h-3.197m1.597 1.605V7.005"
      />
    </svg>
  );
}
