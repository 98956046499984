import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { NOTIFICATIONS } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string | Function;
  };

  wait: any;

  waiting: any;

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...NOTIFICATIONS };
    this.wait = false;
    this.waiting = null;
  }

  private withCash(serveAction) {
    clearTimeout(this.waiting);

    if (this.wait) {
      this.waiting = setTimeout(() => {
        this.wait = false;
      }, 20000);
    } else {
      serveAction();
      this.wait = true;
    }
  } //TODO [Move, Rework]: Move in core middleware

  public getNotifications(data: IRequestDataParse): void {
    const { LIST } = this.ROUTE;

    this.get(LIST, data);
  }

  public deleteNotification(data: IRequestDataParse): void {
    const { REMOVE_NOTIFICATION } = this.ROUTE;

    return this.patch(REMOVE_NOTIFICATION, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
