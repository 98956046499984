import './index.scss';
import React, { FC } from 'react';
import { ITestMark } from '../../app/Tests/tests.resources';
import translate from '../../i18n/translate';
import Button from '../Button';
import Icon from '../Icon';
import Mark from './Mark';

interface IProps {
  data: Array<ITestMark>;
  onAdd?(data: ITestMark): void;
  onDelete?(data: ITestMark): void;
  onChange?(markId: number, data: ITestMark): void;
}

const Marks: FC<IProps> = ({ data, onAdd, onDelete, onChange }) => {
  const onAddMark = () => {
    onAdd &&
      onAdd({
        title: '',
        description: '',
        minScore: 0,
      });
  };

  const onDeleteMark = (data) => {
    onDelete && onDelete(data);
  };

  const onChangeMark = (markId, markData) => {
    onChange && onChange(markId, markData);
  };

  const renderMark = () => {
    return data.map((item, i) => (
      <Mark
        key={item.id}
        data={item}
        viewLabel={i === 0}
        showDescription={i === 0}
        onDelete={onDeleteMark}
        onChange={onChangeMark}
      />
    ));
  };

  return (
    <div className="marks">
      {renderMark()}
      <Button variant="text" size="md" className="marks__button" onClick={onAddMark}>
        <Icon name="ELAddLightCircle" className="marks__button-icon" />
        {translate('ts.score.add')}
      </Button>
    </div>
  );
};

export default Marks;
