export default {
  RU: {
    'alert.copy.in': 'Скопировано в буфер обмена',
    'alert.event.save': 'Мероприятие успешно сохранено',
    'alert.qs.crt': 'Создан новый вопрос',
    'alert.qs.udt': 'Вопрос успешно сохранен',
    'alert.qs.prev.fill': 'Заполните предыдущий вопрос',
    'alert.err': 'Что-то пошло не так...',
    'alert.usr.err.exist': 'Пользователь с таким адресом электронной почты уже существует.',
    'alert.usr.err.incorrect': 'Адрес электронной почты и/или пароль введены неверно',
    'alert.required.fill': 'Заполните обязательные поля',
    'alert.evt.del': 'Мероприятие было удалено',
    'alert.evt.arch': 'Мероприятие перемещено в архив',
    'alert.evt.restore': 'Мероприятие восстановленно',
    'alert.ath.file': 'Добавлен новый файл',
    'alert.ath.file.err': 'Файл не добавился: обновите страницу!',
    'alert.prt.err': 'Раздел не сохранился: обновите страницу!',
    'alert.prt.add.new': 'Добавлен новый раздел',
    'alert.prt.change': 'Раздел изменен!',
    'alert.awr.set': 'Ответ записан!',
    'alert.awr.reset': 'Ответ перезаписан!',
    'alert.homework.err': 'Что - то сломалось - обновите страницу!',
    'alert.evt.crt': 'Создано новое мероприятие!',
    'alert.ath.file.change': 'Прикрепленный файл изменен',
    'alert.homework.add': 'Добавлено домашнее задание',
    'alert.ivt.accept': 'Приглашение было принято',
    'alert.ivt.cancel': 'Приглашение было отклонено',
    'alert.ivt.err.email': 'Приглашение на этот E-mail уже отправлено!',
    'alert.test.crt': 'Создан новый тест!',
    'alert.group.crt': 'Создана новая группа!',
    'alert.awr.been.gin': 'Ответ был дан ранее!',
    'alert.ivt.repeat': 'Приглашения повтороно отправленны!',
    'alert.canceled': 'Отмененно!',
    'alert.locked': 'Заблокированно!',
    'alert.unlocked': 'Разблокированно!',
    'alert.deleted': 'Удалено!',
    'alert.changed': 'Изменено!',
    'alert.data.changed': 'Данные успешно изменены',
    'alert.acc.not.email': 'Данный email не доступен!',
    'alert.token.not.found': 'Токен не найден!',
    'alert.acc.access.email': 'Данный email подтвержден!',
    'alert.usr.not.found': 'Пользователь не найден',
    'alert.link.err': 'Ссылка не действителена',
    'alert.change.pass': 'пароль изменен',
    'alert.pass.match.not': 'Пороли не должны совпадать',
    'alert.pass.invalid': 'Неверный пароль',
    'alert.send': 'Отправлено',
    'alert.pay.success': 'Оплата прошла успешно',
    'alert.ath.video': 'Видео добавлено',
    'alert.agc.change': 'Вы сменили агенство',
    'alert.pay.not.yoo': 'Не подключен способ оплаты',
  },
};
