import './index.scss';
import React, { FC, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Hint from '../../../components/Hint';
import { useEvent, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { URI } from '../../../routes/uri';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Icon, Square, Text, Toggle } from '../../app.modules';
import { setModal } from '../../Modal/modal.actions';
import { MODALS_TRIGGER } from '../../Modal/modal.types';
import { typesAccept } from '../../Storage/storage.types';
import { questionUpdate } from '../builder.questions.services';
import Bridge from '../BuilderQuestionsCreate/questions.create.pipes';

interface IProps {
  data?: any;
  setModalAction?: any;
  questionUpdateAction?: any;
  setAlertAction?: any;
  onChange: any;
  questionData: any;
  onClose: any;
}

const QuestionVideo: FC<IProps> = function ({
  data,
  setModalAction,
  questionUpdateAction,
  setAlertAction,
  onChange,
  questionData,
  onClose,
}) {
  const [state, setState] = useToState({
    checked: !!data.cover,
    file: data.file || data?.url,
    cover: data.cover ?? null,
    code: data.code ?? null,
    url: data.url ?? null,
    showPreview: false,
    dataServe: false,
  });

  const onSend = (data) => {
    const requestData = Bridge.onRequestData(questionData);

    questionUpdateAction(requestData.id, {
      ...requestData,
      video: {
        ...requestData.video,
        ...data,
      },
    })
      .then(() => {
        // onChange({
        //   ...requestData.video,
        //   ...data
        // }, 'video');
        setState({
          dataServe: false,
        });

        setAlertAction({
          code: '080104',
          params: {},
        });
      })
      .catch(() => {
        setAlertAction({
          code: '089999',
          uuid: uuid(),
          params: {},
        });
      });
  };

  const onDeleteVideo = () => {
    setModalAction({
      code: MODALS_TRIGGER.CONDITION,
      params: {
        title: 'Удалить добавленное видео?',
        prevBtnTitle: translate('g.btn.cancel'),
        nextBtnTitle: translate('g.btn.delete'),
        onConfirm() {
          setState({
            file: null,
            fileId: null,
            coverId: null,
            cover: null,
            url: null,
            code: null,
            dataServe: true,
          });
        },
      },
    });
  };

  const onOpenStorage = (typesAccept, key) => () => {
    setModalAction({
      code: MODALS_TRIGGER.STORAGE.OPEN,
      uuid: uuid(),
      params: {
        accept: [typesAccept],
        onConfirm(data) {
          setState({
            dataServe: true,
            [key]: data,
          });
        },
      },
      onDestroy() {
        setState({
          checked: false,
        });
      },
    });
  };

  useEffect(() => {
    if (state.dataServe) {
      const requestData = Bridge.onRequestData(questionData);

      onChange(
        {
          ...requestData.video,
          file: state.file,
          fileId: state.file?.id ?? null,
          cover: state.cover,
          coverId: state.cover?.id ?? null,
          url: state.url ?? null,
          code: state.code ?? null,
        },
        'video'
      ); //TODO [Thunk]: !!!

      onSend({
        file: state.file,
        fileId: state.file?.id ?? null,
        cover: state.cover,
        coverId: state.cover?.id ?? null,
        url: state.url ?? null,
        code: state.code ?? null,
      });
    }
  }, [state.dataServe]);

  useEffect(() => {
    return () => {
      setState({
        dataServe: false,
      });
    };
  }, []);

  const onChangeCoverField = (e) => {
    const { checked } = e.target;

    checked
      ? onOpenStorage(typesAccept.IMAGE, 'cover')()
      : onSend({
          cover: null,
        });

    setState({
      checked: checked,
      ...(!checked && {
        cover: null,
      }),
    });
  };

  const renderVideo = () => {
    const { file, code } = state;

    if (!file && !code) return null;

    if (file) {
      const formationStreamLink = typeof file === 'string' ? file : URI.STORAGE.FILE(file?.id);

      return (
        <ReactPlayer
          className="react-player question-video__stream"
          url={formationStreamLink}
          width="100%"
          height="100%"
          controls={true}
        />
      );
    }

    if (code) {
      return <div className="question-video__stream" dangerouslySetInnerHTML={{ __html: state.code }} />;
    }
  };

  const onCloseMount = (e) => {
    if (!e.target.closest('.question-video') && !e.target.closest('.modal') && !e.target.closest('.modal__close')) {
      onClose();
    }
  };

  useEvent('click', onCloseMount);

  return (
    <div className="question-video">
      <Square
        size="xs"
        className="question-video__icon question-video__icon--trash"
        monochrome
        hover
        onClick={onDeleteVideo}
      >
        <Icon name="Trash" />
      </Square>
      <div className="question-video__preview">{renderVideo()}</div>
      {!data?.url && !data?.code && (
        <Toggle
          variant="success"
          replace
          className="question-video__panel-control"
          checked={state.checked}
          onChange={onChangeCoverField}
        >
          <Icon name="CheckSuccess" />
          <Text className="question-video__panel-label">Выбрать обложку</Text>
        </Toggle>
      )}
      {state.cover && (
        <div className="question-video__cover">
          <img src={URI.STORAGE.FILE(state.cover?.id)} alt="" className="question-video__cover-image" />
          <Hint label="Заменить обложку" move={['right', 'bottom']}>
            {(ref) => (
              <Square
                innerRef={ref}
                size="xs"
                monochrome
                hover
                className="question-video__icon"
                onClick={onOpenStorage(typesAccept.IMAGE, 'cover')}
              >
                <Icon name="ELPencil" />
              </Square>
            )}
          </Hint>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
  questionUpdateAction: bindActionCreators(questionUpdate, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(QuestionVideo);
