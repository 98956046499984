export default {
  DE: {
    'ts.results': 'Resultaten',
    'ts.formation': 'Test ausbilden',
    'ts.overview': 'Grundinformation',
    'ts.library': 'Testdatenbank',
    'ts.create.qs': 'Fragen erstellen',
    'ts.add.rating': 'Note wurde gegeben',
    'ts.delete.rating': 'Note wurde gelöscht',
    'ts.change.rating': 'Note wurde geändert',
    'ts.change.qs': 'Fragen bearbeiten',
    'ts.save.success': 'Der Test wurde erfolgreich abgespeichert',
    'ts.allowed.after.test': 'Erlaubt nach der Testablegung',
    'ts.allowed.period.test': 'Erlaubt nach Ablauf der Testzeit',
    'ts.add.event': 'Zur Maßnahmen hinzugefügt',
    'ts.stt.tlt': 'Testeinstellungen',
    'ts.desc': 'Testbeschreibung',
    'ts.remove': 'Test entfehrnen',
    'ts.desc.plc': 'Geben Sie den Namen des Testes ein',
    'ts.cover': 'Test Titelblatt',
    'ts.cover.mini': 'Test Minititelblatt',
    'ts.passing': 'Ablegungsparameter',
    'ts.order.qs': 'Fragenreihenfolge',
    'ts.move.qs': 'Übergang zwischen Fragen',
    'ts.change.aws': 'Antwortänderung',
    'ts.change.nkw': 'Mit "Ich weiß es nicht" antworten',
    'ts.see.results': 'Resultaten anschauen',
    'ts.see.correct': 'Richtigen Antworten anschauen',
    'ts.atm': 'Versuchsparameter',
    'ts.count.atm': 'Versucheanzahl',
    'ts.time.atm': 'Zeit für einen Versuch',
    'ts.interval.atm': 'Interval zwischen Versuche',
    'ts.fix.atm': 'Versuch fixieren',
    'ts.qs.atm': 'Anzahl Fragen in einem Versuch',
    'ts.order.qs.hint':
      'Testfragen werden einander in so eine Reihenfolge folgen, wie Sie es im Fragen-Zusammensteller definiert haben. Wählen Sie "Zufällig" an, damit die Fragen automatisch zufällig gemischt kamen.',
    'ts.fix.atm.hint':
      'Wählen Sie "Ja" an, damit die Fragen, die während den ersten Versuch ausgebildet waren(ihren Reihnefolge inkl.), bei allen weiteren Versuche sich wiederholten.',
    'ts.move.qs.hint':
      'Wählen Sie "Erlaubt" an, damit der Student während dem Testen zu einer beliebege Frage überspringen könnte.',
    'ts.change.aws.hint':
      'Wählen Sie "Erlaubt" an, damit der Student während dem Testen zu jeder Frage zurückkommen- und eigene Antwort auf diese Frage ändern könnte.',
    'ts.change.nkw.hint':
      'Die “Ich weiss nicht” Antwort wird angegeben zwecks objektiven Beurteilung eigener Kentnisse, wenn der Student, ohne die Antwort auf die Frage zu wissen, sie durch zufälligen Antwortauswahl raten kann.',
    'ts.see.results.hint': 'Stellen Sie eine Möglichkeit ein zum Anschauen des Versuchsresultaten vom Teilnehmer',
    'ts.see.correct.hint': 'Stellen Sie eine Möglichkleit ein zum Anschauen von richtigen Antworten vom Teilnehmer',
    'ts.count.atm.hint':
      'Man kann die Anzahl der Testablegungsversuche durch das Klicken auf Kreuzsymbol in der Eingabefeld und eingeben der Ihnen benötighten Zahl ändern. Wenn Sie das Feld leerlassen sollen oder Nullwert angeben sollen - wird die Anzahl der Versuche unbegrenzt sein. Bei solchen Parameterwert kann der Student den Test unendlich ablegen versuchen, solange er f. die Ablegung zur Verfügung steht.',
    'ts.interval.atm.hint':
      'Man kann das Zeitinterval durch das Klicken auf Kreuzsymbol in der Eingabefeld und eingeben der Ihnen benötighten Zahl zwischen jeden Versuch ändern. Wenn Sie das Feld leerlassen sollen oder  Nullwert angeben sollen - kann der Student den Versuch so oft wie er will starten.',
    'ts.amount.rts': 'Parameter der Zusammenfassung',
    'ts.rate': 'Eine Note stellen',
    'ts.rate.grate':
      'Eine Note f. Test wird basierend auf der Summe der gesammelten Punkte oder Prozentfortschritt errechnet',
    'ts.grade': 'Note',
    'ts.prc': 'Prozente',
    'ts.scores': 'Punkte',
    'ts.score.desc': 'Erklärung zu der Note',
    'ts.score.desc.plc': 'Erklärung zu der Note eingeben',
    'ts.score.add': 'Ein Note hinfügen',
    'ts.rts.desc': 'Allgemeine Beschreibung zu den Testresultaten',
    'ts.rts.desc.text': 'Man kann zu den Testresultat eine allgemeine Beschreibung-, Bild u.a. Elemente hinzufügen.',
    'ts.enter.desc': 'Beschreibung eingeben',
    'ts.desc.hint': 'Zu der Gesamtnote f.Testen kann man eine allgemeine Beschreibung-, Bild u.a. Elemente hinzufügen.',
    'ts.enter.rating': 'Note eingeben',
    'ts.crt.tlt': 'Neuer Test',
    'ts.crt.name.pls': 'Geben Sie Testnamen ein',
    'ts.crt.desc.pls': 'Geben Sie Testbeschreibung ein',
    'ts.mini.cover.field': 'Test Minititelblatt',
    'ts.field.know.no': '"Ich weiss es nicht" Antwort',
  },
};
