import React from 'react';

export default function Clip() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="26" fill="none" viewBox="0 0 27 26">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M13.563 2.192c2.918.002 5.319 2.403 5.322 5.32l-.003 10.646c.002 2.843-2.256 5.161-5.068 5.294-.083.008-.166.03-.257.03-2.917-.002-5.31-2.395-5.32-5.32l.004-7.457c-.005-1.38.894-2.55 2.124-2.997.34-.113.694-.197 1.063-.19a3.188 3.188 0 013.191 3.191l.003 7.449a1.067 1.067 0 01-1.816.755 1.06 1.06 0 01-.31-.751l-.003-7.45a1.058 1.058 0 00-1.466-.981 1.053 1.053 0 00-.652.978l-.005 7.456a3.186 3.186 0 002.128 2.99c.332.12.686.202 1.063.201.377 0 .732-.084 1.063-.205a3.179 3.179 0 002.132-2.99l.003-10.645a3.22 3.22 0 00-3.2-3.198 3.232 3.232 0 00-2.157.863 1.14 1.14 0 01-.837.431 1.1 1.1 0 01-1.077-1.106c0-.242.091-.453.212-.634.091-.12.197-.212.318-.287.943-.854 2.173-1.391 3.545-1.393z"
      />
    </svg>
  );
}
