import { LOCAL } from '../local';
import AGENCY from './agency';
import ALERT from './alert';
import EVENTS from './events';
import EXPAND from './expand';
import GENERAL from './general';
import HOMEWORK from './homework';
import LEARNING from './learning';
import MEMBERS from './members';
import NOTIFICATION from './notification';
import PARTICIPANTS from './participants';
import PAYMENTS from './payments';
import PROFILE from './profile';
import QUESTIONS from './questions';
import STATISTIC from './statistic';
import STORAGE from './storage';
import SUPPORT from './support';
import TESTS from './tests';
import WARNING from './warning';

export default {
  [LOCAL.DE]: {
    ...GENERAL.DE,
    ...EXPAND.DE,
    ...TESTS.DE,
    ...LEARNING.DE,
    ...EVENTS.DE,
    ...AGENCY.DE,
    ...WARNING.DE,
    ...PROFILE.DE,
    ...ALERT.DE,
    ...PAYMENTS.DE,
    ...MEMBERS.DE,
    ...PARTICIPANTS.DE,
    ...HOMEWORK.DE,
    ...STORAGE.DE,
    ...STATISTIC.DE,
    ...SUPPORT.DE,
    ...QUESTIONS.DE,
    ...NOTIFICATION.DE,
  },
};
