import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELPage(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="26" fill="none" viewBox="0 0 23 26" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M6.805 1A5.792 5.792 0 001 6.805v12.39A5.792 5.792 0 006.805 25h8.483a5.792 5.792 0 005.805-5.805v-4.52c0-3.253-.072-4.65-2.233-6.81l-2.232-2.288-2.233-2.289C12.625 1.518 11.97 1 9.484 1h-2.68z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M5 15l1.87-6.402a.2.2 0 01.381-.01L9.5 15m-4-1.182h3M5 20h12"
      />
      <path stroke="var(--el-fonts--icon-color)" strokeWidth="2" d="M17 13a2 2 0 11-4 0 2 2 0 014 0z" />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M17.25 10.75v4.5" />
    </svg>
  );
}
