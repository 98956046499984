import { IAgencyAction, IAgencyState } from './agency.resources';
import types from './agency.types';

const initialState: IAgencyState = {
  profile: null,
  settingsPay: null,
};

export default function agencyReducer(state: IAgencyState = initialState, action: IAgencyAction): IAgencyState {
  const actionType = {
    [types.PROFILE]: {
      ...state,
      profile: action.profile,
    },
    [types.SETTINGS_PAY]: {
      ...state,
      settingsPay: action.settingsPay,
    },
  };

  return actionType[action.type] ?? state;
}
