import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { WEBINAR } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: any;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...WEBINAR };
  }

  protected getWebinarJWT(data: IRequestDataParse): void {
    const { GET_JWT } = this.ROUTE;

    this.get(GET_JWT, data);
  }

  protected getWebinarAdminJWT(data: IRequestDataParse): void {
    const { GET_JWT_ADMIN } = this.ROUTE;

    this.get(GET_JWT_ADMIN, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
