import React from 'react';

interface IProps {
  onClick?(): void;
  className?: string;
}

export default function ELEye(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" fill="none" viewBox="0 0 19 14" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1.357 7.752a1.81 1.81 0 010-1.504C2.774 3.148 5.889 1 9.5 1c3.61 0 6.726 2.147 8.143 5.248a1.81 1.81 0 010 1.504C16.226 10.852 13.111 13 9.5 13c-3.61 0-6.726-2.147-8.143-5.248z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M10.914 8.414a2 2 0 11-2.828-2.828 2 2 0 012.828 2.828z"
      />
    </svg>
  );
}
