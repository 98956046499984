export default {
  RU: {
    'expand.rate': 'Тариф',
    'expand.payment.history': 'История платежей',
    'expand.opportunities': 'Расширить возможности',
    'expand.type.status.plan.active': 'Текущий тарифный план',
    'expand.type.status.plan.pending': 'Улучшение тарифа',
    'expand.type.status.plan.next': 'Следующий тарифный план',
    'expand.type.status.plan.request': 'Индивидуальный расчет',
    'expand.request.sent': 'Запрос отправлен',
    'expand.request.canceled': 'Запрос отменен',
    'expand.request.payment': 'Ожидает оплаты',
    'expand.time.left': 'Осталось',
    'expand.unlimited': 'безлимит',
    'expand.about.collapse': 'Свернуть',
    'expand.about.tariff': 'Больше о тарифе',
    'expand.participants': 'участников',
    'expand.change.tariff': 'Изменить тариф',
    'expand.more': 'подробнее',
    'expand.plan': 'Улучшить тарифный план',
    'expand.per.month': 'в месяц',
    'expand.period.year': '*При оплате за год',
    'expand.period': 'Период',
    'expand.expire': 'Выберите срок действия тарифного плана',
    'expand.month': 'мес',
    'expand.year': 'год',
    'expand.payable': 'Итого к оплате:',
    'expand.yu.money': 'Для оплаты вы будете перенаправлены на сервер системы ЮMoney.',
    'expand.pay.notfound': 'Платежей не найдено',
    'expand.type.Базовый': 'Базовый',
    'expand.type.Стандарт': 'Стандарт',
    'expand.type.PRO': 'Стандарт',
    'expand.cancel.pay': 'Отменить оплату ?',
    'expand.cancel.send': 'Отменить запрос ?',
    'expand.canceled.pay': 'Оплата отменена',
    'expand.id.quote': 'Получить индивидуальный расчёт',
    'expand.contact': 'В ближайшее время наш менеджер свяжется с Вами!',
    'expand.up': 'Улучшить тариф',
    'expand.up.text': 'Новые условия продления вступят в силу после окончания срока действия текущего тарифа',
    'expand.next': 'Продлить тариф',
    'expand.next.text':
      'Если вы измените параметры тарифа, Edbee автоматически пересчитает остаток оплаченных дней по новым условиям тарифа',
    'expand.event': 'создание мероприятий',
    'expand.test': 'формирование тестовых заданий',
    'expand.roles': 'конструктор ролей',
    'expand.delegation': 'делегирование обязанностей сотрудникам',
    'expand.subdomain': 'создание уникального поддомена подробнее',
    'expand.groups': 'создание и управление учебными группами',
    'expand.storage': 'облачное хранилище для файлов и мультимедиа',
    'expand.homework': 'модуль "Домашнее задание"',
    'expand.support': 'техническая поддержка',
  },
};
