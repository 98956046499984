import React from 'react';

export default function CheckJack() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" fill="none" viewBox="0 0 10 8">
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M8.863 1.538L4.227 6.174a.5.5 0 01-.707 0L1.412 4.067"
      />
    </svg>
  );
}
