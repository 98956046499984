import './index.scss';
import React, { FC, ReactNode } from 'react';
import { useToState } from '../../hooks';
import Button from '../Button';

interface IProps {
  children: any;
  buttons: {
    confirm: {
      title: string | ReactNode;
      onChange(): Promise<any>;
    };
  };
}

const SettingsForm: FC<IProps> = ({ children, buttons }) => {
  const [state, setState] = useToState({
    confirmLoading: false,
  });

  const confirm = buttons?.confirm;

  const onChangeConfirm = () => {
    setState({
      confirmLoading: true,
    });

    confirm.onChange &&
      confirm.onChange()?.then(() => {
        setState({
          confirmLoading: false,
        });
      });
  };

  const renderConfirm = () => {
    if (!confirm) return null;

    return (
      <Button size="md" variant="outlined" monochrome loading={state.confirmLoading.loading} onClick={onChangeConfirm}>
        {confirm.title}
      </Button>
    );
  };

  return (
    <div className="settings-form">
      <div className="settings-form__body">{children}</div>
      <div className="settings-form__panel">{renderConfirm()}</div>
    </div>
  );
};

export default SettingsForm;
