import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { Text } from '../../../app.modules';

interface IProps {
  error?: boolean;
  data: number;
  variant?: string;
  errorText?: string | ReactNode;
  onChange(e): void;
}

const CreateScores: FC<IProps> = function ({ data, onChange, variant = 'primary', error, errorText }): ReactElement {
  const [state, setState] = useToState({
    activeScoreField: false,
    inputValue: data ?? 0,
  });

  const onChangeField = (e) => {
    const { value } = e.target;

    const isValid = value.replace(/[^\d.]/g, '');

    setState({
      inputValue: isValid,
    });

    onChange(e);
  };

  const onFocusScoresField = () => {
    const { inputValue } = state;

    setState((state) => ({
      activeScoreField: !state.activeScoreField,
      inputValue: inputValue !== 0 ? inputValue : '',
    }));
  };

  const onBlurScoresField = () => {
    const { inputValue } = state;

    setState((state) => ({
      activeScoreField: !state.activeScoreField,
      inputValue: inputValue === '' ? 0 : inputValue,
    }));
  };

  const classes = classNames('scores--eject', {
    [`scores--${variant}`]: variant,
    'scores--active': data > 0 || state.activeScoreField,
    'scores--error': error,
  });

  return (
    <div className={classes} onFocus={onFocusScoresField} onBlur={onBlurScoresField}>
      <Text variant="lg">{translate('g.point.a')}:</Text>
      <input maxLength={4} value={state.inputValue} onChange={onChangeField} />
      {error && errorText && (
        <Text variant="sm" className="scores--error-text">
          {errorText}
        </Text>
      )}
    </div>
  );
};

export default CreateScores;
