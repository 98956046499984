import './index.scss';
import React, { FC } from 'react';
import { Select } from '../../../app.modules';

interface IProps {
  onMandatory?(e): void;
  onSettings?(e): void;
  onHide?(e): void;
}

const QuestionCriteria: FC<IProps> = () => {
  const dataCriteria = [
    {
      label: 'Предмет',
      data: [
        {
          title: 'Математика',
          active: true,
        },
      ],
    },
    {
      label: 'Возрастная группа',
      data: [
        {
          title: '1',
          active: true,
        },
      ],
    },
    {
      label: 'Любой',
      data: [
        {
          title: 'Любой',
          active: true,
        },
        {
          title: 'Мужской',
        },
        {
          title: 'Женский',
        },
      ],
    },
    {
      label: 'Тип вопроса',
      data: [
        {
          title: 'Выбор ответа',
          active: true,
        },
      ],
    },
    {
      label: 'Тип вопроса',
      data: [
        {
          title: 'Выбор ответа',
          active: true,
        },
      ],
    },
    {
      label: 'Тип вопроса',
      data: [
        {
          title: 'Выбор ответа',
          active: true,
        },
      ],
    },
  ];

  const renderSelects = () => {
    return dataCriteria.map((item, i) => <Select key={i} variant="secondary" data={item.data} label={item.label} />);
  };

  return <div className="question-criteria">{renderSelects()}</div>;
};

export default QuestionCriteria;
