export default {
  DE: {
    'support.tlt': 'Technischer Support anschreiben',
    'support.desc': 'Haben Sie einen Bock auf unseren Plattform festgestellt? Bitte helfen Sie uns ihn zu eliminieren.',
    'support.label.1':
      'Dafür beschreiben Sie detailiert die Schritte zum Abspielen des Problems und fügen Sie einen Link zur Seite und einen Bildschirmabdrück hinzu.',
    'support.label.2': 'Wir bekommen Ihre Meldung und eliminieren Fehler in Kürze.',
    'support.problem.pls.2': 'Beschreiben Sie das Problem',
    'support.problem.link': 'Fügen Sie einen Link auf die Platformseite hinzu',
  },
};
