import { useState } from 'react';

export default <T>(initialValue: T): any => {
  const [state, setState] = useState(initialValue);

  const onState = (data) => {
    setState((state) => ({
      ...state,
      ...(typeof data === 'function' ? data({ ...state }) : { ...data }),
    }));
  };

  return [state, onState];
};
