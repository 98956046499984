import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAudio(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" fill="none" viewBox="0 0 16 17" {...props}>
      <mask id="a" fill="#fff">
        <path
          fillRule="evenodd"
          d="M10.385 1.677v12.8c0 1.497-1.97 2.241-3.108 1.174L3.98 12.404H1.167A1.162 1.162 0 010 11.254V4.9c0-.633.525-1.15 1.167-1.15H3.98L7.277.503C8.416-.564 10.385.18 10.385 1.677z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M7.277 15.651l-1.404 1.425.018.018.018.017 1.368-1.46zM3.98 12.404l1.403-1.425-.584-.575h-.82v2zm0-8.654v2h.82l.583-.575L3.98 3.75zM7.277.503L5.909-.957l-.018.017-.018.018L7.277.503zm5.108 13.974v-12.8h-4v12.8h4zm-6.476 2.634c1.198 1.122 2.805 1.258 4.056.786 1.255-.475 2.42-1.683 2.42-3.42h-4a.432.432 0 01.088-.253.278.278 0 01.056-.057.066.066 0 01.022-.012l.002-.001.016.001a.16.16 0 01.075.036l-2.735 2.92zm-3.333-3.282l3.297 3.247 2.807-2.85-3.297-3.247-2.807 2.85zm1.404-3.425H1.167v4H3.98v-4zm-2.813 0c.434 0 .833.35.833.85h-4c0 1.765 1.449 3.15 3.167 3.15v-4zm.833.85V4.9h-4v6.354h4zM2 4.9c0 .5-.399.85-.833.85v-4C-.551 1.75-2 3.135-2 4.9h4zm-.833.85H3.98v-4H1.167v4zM5.873-.922L2.576 2.325l2.807 2.85L8.68 1.928 5.873-.922zm6.512 2.599c0-1.737-1.165-2.945-2.42-3.42-1.251-.472-2.858-.336-4.056.786l2.735 2.92a.16.16 0 01-.075.036.06.06 0 01-.016 0h-.002a.098.098 0 01-.022-.012.281.281 0 01-.056-.057.434.434 0 01-.088-.253h4z"
        mask="url(#a)"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M12.692 4.615S15 5.493 15 8.077s-2.308 3.462-2.308 3.462"
      />
    </svg>
  );
}
