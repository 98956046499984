import { IAppAction, IAppState } from './app.resources';
import types, { typesView } from './app.types';

const initialState: IAppState = {
  alert: [],
  view: typesView.DEFAULT,
  breadcrumbs: [],
};

export default function appReducer(state: IAppState = initialState, action: IAppAction): IAppState {
  const actionType = {
    [types.ALERT]: {
      ...state,
      alert: action.alert,
    },
    [types.VIEW]: {
      ...state,
      view: action.view,
    },
    [types.BREADCRUMBS]: {
      ...state,
      breadcrumbs: action.breadcrumbs,
    },
  };

  return actionType[action.type] ?? state;
}
