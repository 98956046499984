import './index.scss';
import uniqBy from 'lodash/uniqBy';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useRouter, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { getRoute } from '../../../routes/client';
import { DivTarget, Icon, Permission, PopoverTarget, Text } from '../../app.modules';
import NotificationBell from '../../Notifications/NotificationsBell';

interface IProps {
  user?: any;
  onLogout: any;
  data: Array<{
    title: string | ReactNode;
    data: Array<any>;
    type: string;
    permissions?: Array<string>;
    mode?: string;
    path?: string;
    icon: ReactNode;
  }>;
  notificationsLink: string;
  mode?: string | ReactNode;
}

interface IState {
  isActiveMenu: boolean;
  me: string;
  mode: string | null | ReactNode;
  data: any;
}

const ProfileControl: FC<IProps> = function ({ user, onLogout, data = [], mode }): ReactElement {
  const { history } = useRouter();

  const [state, setState] = useToState<IState>({
    isActiveMenu: false,
    me: '',
    mode: mode ?? '',
    data: [
      {
        title: translate('g.exit'),
        data: [],
        type: 'logout',
        icon: 'ELLogout',
        handler: () => {
          localStorage.setItem('session', 'false'); //TODO [Deprecated]: Need tests and remove
          onLogout().then(() => {
            history.push(getRoute('AL', 'path'));
          });
        },
      },
    ],
  });

  const onChange = (item) => {
    setState((state) => ({
      isActiveMenu: !state.isActiveMenu,
      ...(item?.mode && { mode: item.mode }),
    }));

    item?.handler && item.handler();
  };

  useEffect(() => {
    const isName =
      user?.firstName || user?.lastName
        ? `${user?.lastName ?? '-'} ${user?.firstName ?? '-'}`
        : `${user?.email ?? '-'}`;

    setState({
      me: isName,
    });
  }, [user]);

  useEffect(() => {
    if (state.data.length === 1) {
      setState({
        data: uniqBy([...data, ...state.data], 'title'),
      });
    } //TODO [Thunk]: Rework
  }, [state.data]);

  const renderMenu = () => {
    const { data } = state;

    return (
      <div className="profile-control__menu">
        {data.map((item, i) => {
          const link = (
            <>
              <Icon name={item.icon} className="profile-control__menu-icon" />
              <Text>{item.title}</Text>
            </>
          );

          const content = item?.path ? (
            <NavLink to={item.path} key={i} className="profile-control__menu-item" onClick={() => onChange(item)}>
              {link}
            </NavLink>
          ) : (
            <div key={i} className="profile-control__menu-item" onClick={() => onChange(item)}>
              {link}
            </div>
          );

          return item?.permissions ? (
            <Permission is={item.permissions} key={i}>
              {content}
            </Permission>
          ) : (
            content
          );
        })}
      </div>
    );
  };

  return (
    <div className="profile-control">
      <div className="profile-control__data">
        <NotificationBell />
        <PopoverTarget target={renderMenu} mode="right">
          <DivTarget className="profile-control__name">
            <Text>{state.me}</Text>
            <div className="profile-control__arrow">
              <Icon name="ArrowDown" />
            </div>
          </DivTarget>
        </PopoverTarget>
      </div>
      {state.mode && (
        <div className="profile-control__mode">
          <span>{state.mode}</span>
        </div>
      )}
    </div>
  );
};

export default ProfileControl;
