import './index.scss';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Button, Icon, Text } from '../../app.modules';
import { questionUpdateAudio } from '../../BuilderQuestions/builder.questions.services';
import Bridge from '../../BuilderQuestions/BuilderQuestionsCreate/questions.create.pipes';
import { typesAccept } from '../../Storage/storage.types';
import { setModal } from '../modal.actions';
import { MODALS_TRIGGER } from '../modal.types';

interface IProps {
  data: any;
  onConfirm: any;
  onDestroy: any;
  setModalAction: any;
  questionUpdateAudioAction: any;
  setAlertAction: any;
}

const ModalQuestionAudio: FC<IProps> = function ({
  data,
  onConfirm,
  setModalAction,
  setAlertAction,
  questionUpdateAudioAction,
}): ReactElement {
  const [state, setState] = useToState({
    dataServe: null,
    attachment: false,
    audio: data?.params?.questionData?.audio ?? null,
  });

  const onOpenStorage = () => {
    setModalAction({
      code: MODALS_TRIGGER.STORAGE.OPEN,
      uuid: uuid(),
      params: {
        accept: [typesAccept.AUDIO],
        onConfirm(data) {
          setState({
            audio: data,
            dataServe: {
              audioId: data?.id,
            },
          });
        },
      },
    });
  };

  const onSend = () => {
    const requestData = Bridge.onRequestData(data.params.questionData);

    setState({
      attachment: true,
    });

    questionUpdateAudioAction(requestData.id, {
      audioId: state.dataServe.audioId,
    })
      .then(() => {
        onConfirm(
          {
            audioId: state.dataServe.audioId,
            audio: state.audio,
          },
          'audio'
        );

        setAlertAction({
          code: '080202',
          params: {},
        });
      })
      .catch(() => {
        setAlertAction({
          code: '089999',
          uuid: uuid(),
          params: {},
        });
      });
  };

  const renderLoadingTemplate = () => {
    return <Text className="modal-question__desc">Добавление, подождите...</Text>;
  };

  const renderNotAudio = () => {
    return (
      <>
        <Button variant="outlined" size="md" onClick={onOpenStorage}>
          <Icon name="ELAddLightCircle" className="modal-question__attached-icon" />
          {translate('qs.audio.add')}
        </Button>
        <Button size="md" onClick={onSend} off={!state.dataServe}>
          {data?.params?.questionData?.audio ? translate('g.btn.change') : translate('g.btn.add')}
        </Button>
      </>
    );
  };

  const renderAudio = () => {
    return (
      <>
        <Text className="modal-question__attached" onClick={onOpenStorage}>
          {state.audio?.originalName ?? '--'}
        </Text>
        <Button size="md" off={!state.dataServe} onClick={onSend}>
          {data?.params?.questionData?.audio ? translate('g.btn.change') : translate('g.btn.add')}
        </Button>
      </>
    );
  };

  const renderContentTemplate = () => {
    return (
      <>
        <div className="modal-question__buttons">{state.audio ? renderAudio() : renderNotAudio()}</div>
      </>
    );
  };

  return (
    <div className="modal-question modal-question--audio">
      <h1 className="modal-question__title">{translate('qs.audio.tlt')}</h1>
      {state.attachment ? renderLoadingTemplate() : renderContentTemplate()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
  questionUpdateAudioAction: bindActionCreators(questionUpdateAudio, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(ModalQuestionAudio);
