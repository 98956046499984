export default {
  EN: {
    'agency.p.tlt': 'Basic information',
    'agency.p.name.plh': 'Enter agency name',
    'agency.p.name.dmn': 'Input domain name of the agency',
    'agency.p.pay': 'Payment settings',
    'agency.p.pay.lbl.tlt': 'Enable UKassa Wallet ',
    'agency.p.pay.lbl.dc': 'Allow payment acceptance over the UKassa Wallet',
    'agency.p.pay.number.name': 'Shop number',
    'agency.p.pay.number.plh': 'Enter shop number',
    'agency.p.pay.number.hint':
      'Enter shop number which will get money transfer from sender, look it up at UKassa profile settings.',
    'agency.p.pay.key.name': 'Private key',
    'agency.p.pay.key.plh': 'Enter private key',
    'agency.p.pay.key.hint': 'Enter private key you got at UKassa profile',
    'agency.p.pay.check.name': 'Check transfer',
    'agency.p.pay.check.hint':
      'Setting for transfer of digital checks to be processed on side of payment system over the fiscal data operator by enabled online-cash register',
    'agency.sw.tlt': 'Agency choice',
    'agency.staff': 'Staff',
    'agency.staff.tlt': 'Agency staff',
    'agency.staff.lbl': 'Invite new user and define his role.',
    'agency.staff.ivt': 'Invited to agency',
    'agency.usr.chg.role': 'Change role',
    'agency.profile': 'Agency profile',
    'agency.switch': 'Change agency',
  },
};
