import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowDown({ className }: IProps) {
  const params = { className };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="none" viewBox="0 0 11 11" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.771 5.229l3.536 3.535 3.536-3.535"
      />
    </svg>
  );
}
