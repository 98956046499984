interface IRouters {
  [key: string]: string;
}

export const GENERAL: IRouters = {
  SIGN_IN: '/auth/login',
  SIGN_UP: '/auth/register/agent',
  AUTH_SCOPES: '/scopes',
  ME: '/users/iam',
  ROLE_ME: '/roles/iam',
  STORAGE: '/Storage',
  LOGOUT: '/auth/logout',
  AUTH_LOGIN: '/auth/login',
  AUTH_ADMIN_SIGN_UP: '/auth/register/agent',
  AUTH_USER_SIGN_UP: '/auth/register',
  AUTH_USER_FORGOT_EMAIL: '/auth/send-reset-password-request',
  AUTH_USER_RESET_PASSWORD: '/auth/reset-password',
  ROLES: '/roles',
};

export const STORAGE = {
  ENDPOINT: '/storage',
  GET_ROOTS: '/storage/roots',
  GET_ITEMS: '/storage/get-items',
  FOLDER: '/storage/folder',
  DELETE: '/storage/mass-delete',
  REQUEST: '/storage/request',
  PART_UPLOAD: '/storage/request/part-upload',
  ABORT: (uploadId) => `/storage/request/abort/${uploadId}`,
};

const TYPES_ENDPOINTS = {
  EVENTS: '/events',
  EVENT: '/event',
  PARTICIPANTS: '/participants',
  QUESTIONS: '/questions',
  QUESTIONS_ANSWER: '/question-answers',
  ATTEMPT_STATS: '/attempt-stats',
  EDUCATIONS: '/tests',
  PORTALS: '/portals',
  VIEW_TEST: '/attempts',
  HOMEWORKS: '/homeworks',
  HOMEWORK_ANSWER: '/homework-answers',
  GROUPS: '/groups',
};

export const ADMIN_PANEL = {
  PORTALS: {
    LIST: TYPES_ENDPOINTS.PORTALS,
    CREATE: `${TYPES_ENDPOINTS.PORTALS}/create`,
    ROUTINE: (portalId) => `${TYPES_ENDPOINTS.PORTALS}/${portalId}`,
  },
  EVENTS: {
    LIST: TYPES_ENDPOINTS.EVENTS,
    CREATE: TYPES_ENDPOINTS.EVENTS,
    ROUTINE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}`,
    ARCHIVE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/soft-delete`,
    RESTORE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/restore`,
    UPDATE_STATUS_STOP: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/stop`,
    UPDATE_STATUS_ACTIVATE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/start`,
    STRUCTURES_ROUTINE: (ids) => `${TYPES_ENDPOINTS.EVENT}-structures/${ids}`,
    STRUCTURES_UPDATE_ATTACH_TESTS: (eventIdElementId) => `/event-structures/${eventIdElementId}/test`,
    STRUCTURES_UPDATE_SECTION: (eventIdElementId) => `/event-structures/${eventIdElementId}/section`,
    STRUCTURES_UPDATE_HOMEWORK: (eventIdElementId) => `/event-structures/${eventIdElementId}/homework`,
    STRUCTURES_UPDATE_FILE: (eventIdElementId) => `/event-structures/${eventIdElementId}/file`,
    STRUCTURES_UPDATE_PAGE: (eventIdElementId) => `/event-structures/${eventIdElementId}/page`,
    STRUCTURES_UPDATE_STREAM_LINK: (eventIdElementId) => `/event-structures/${eventIdElementId}/stream-link`,
    STRUCTURES_UPDATE_WEBINAR: (eventIdElementId) => `/event-structures/${eventIdElementId}/webinar`,
    STRUCTURES_POSITION: (ids) => `${TYPES_ENDPOINTS.EVENT}-structures/${ids}/position`,
    //
    INVITES_LIST: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/invites`,
    PARTICIPANTS_LIST: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants`,
    PARTICIPANTS_LOCK: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants/soft-delete`,
    PARTICIPANTS_DELETE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants`,
    PARTICIPANTS_RESTORE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants/restore`,
    SEND_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}/send-invite`,
    RESEND_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}/resend-invite`,
    DELETE_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}`,
    //
    STATISTIC_ATTEMPT_USER: (eventIdElementIdUserId) => `${TYPES_ENDPOINTS.ATTEMPT_STATS}/${eventIdElementIdUserId}`,
    STATISTIC_ATTEMPT_DELETE: (eventIdElementIdUserIdAttemptId) =>
      `${TYPES_ENDPOINTS.ATTEMPT_STATS}/${eventIdElementIdUserIdAttemptId}`,
    STATISTIC_ATTEMPT_MORE: (eventIdElementIdUserIdAttemptId) =>
      `${TYPES_ENDPOINTS.ATTEMPT_STATS}/${eventIdElementIdUserIdAttemptId}`,
    STATISTIC_ATTEMPT_RECOUNT: (eventIdElementIdUserIdAttemptId) =>
      `${TYPES_ENDPOINTS.ATTEMPT_STATS}/${eventIdElementIdUserIdAttemptId}/recount`,
    STATISTIC_ATTEMPT_USER_BEST: (eventIdElementIdUserId) =>
      `${TYPES_ENDPOINTS.ATTEMPT_STATS}/${eventIdElementIdUserId}/best-attempt`,
    //
    // EVENT_MEMBERS_INVITATIONS_READ: (token) => `/event-members/invitations/${token}`,
    // EVENT_MEMBERS_INVITATIONS_ACCEPT: (token) => `/event-members/invitations/${token}`,
  },
  EDUCATIONS: {
    LIST: TYPES_ENDPOINTS.EDUCATIONS,
    CREATE: TYPES_ENDPOINTS.EDUCATIONS,
    CREATE_MARK: (testId) => `${TYPES_ENDPOINTS.EDUCATIONS}/${testId}/marks`,
    DELETE_MARK: (testId, markId) => `${TYPES_ENDPOINTS.EDUCATIONS}/${testId}/marks/${markId}`,
    UPDATE_MARK: (testId, markId) => `${TYPES_ENDPOINTS.EDUCATIONS}/${testId}/marks/${markId}`,
    ROUTINE: (educationId) => `${TYPES_ENDPOINTS.EDUCATIONS}/${educationId}`,
  },
  HOMEWORK: {
    READ: (eventIdElementId) => `${TYPES_ENDPOINTS.HOMEWORKS}/${eventIdElementId}`,
    REVIEW: (homeworkAnswerId) => `${TYPES_ENDPOINTS.HOMEWORK_ANSWER}/${homeworkAnswerId}/review`,
    COMMENT: (homeworkAnswerId) => `${TYPES_ENDPOINTS.HOMEWORKS}/${homeworkAnswerId}/comment`,
    COMMENT_DELETE: (homeworkAnswerId, commentId) =>
      `${TYPES_ENDPOINTS.HOMEWORKS}/${homeworkAnswerId}/comment/${commentId}`,
  },
  GROUPS: {
    ROUTINE: TYPES_ENDPOINTS.GROUPS,
    READ: (groupId) => `${TYPES_ENDPOINTS.GROUPS}/${groupId}`,
    UPDATE: (groupId) => `${TYPES_ENDPOINTS.GROUPS}/${groupId}`,
    SEND_INVITE: (groupId) => `${TYPES_ENDPOINTS.GROUPS}/${groupId}/invitations/send-invite`,
    INVITATIONS: (groupId) => `${TYPES_ENDPOINTS.GROUPS}/${groupId}/invitations`,
    INVITE_ROUTINE: (token) => `${TYPES_ENDPOINTS.GROUPS}/invitations/${token}`,
    PARTICIPANTS: (groupId) => `${TYPES_ENDPOINTS.GROUPS}/${groupId}/members`,
  },
};

export const DASHBOARD_PANEL = {
  EVENTS: {
    LIST: `${TYPES_ENDPOINTS.PARTICIPANTS}/events`,
    ROUTINE: (eventId) => `${TYPES_ENDPOINTS.PARTICIPANTS}/events/${eventId}`,
    CONFIRM_INVITATIONS: `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations`,
    CONFIRM_INVITATIONS_UPDATE: (invitationId) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${invitationId}`,
    STATISTIC_ATTEMPT_ME: (eventIdElementId) => `${TYPES_ENDPOINTS.ATTEMPT_STATS}/participant/${eventIdElementId}`,
    STATISTIC_ATTEMPT_MORE: (eventIdElementIdAttemptId) =>
      `${TYPES_ENDPOINTS.ATTEMPT_STATS}/participant/${eventIdElementIdAttemptId}`,
    STATISTIC_ATTEMPT_BEST_ME: (eventIdElementId) =>
      ` ${TYPES_ENDPOINTS.ATTEMPT_STATS}/participant/${eventIdElementId}/best-attempt`,
    STATISTIC_ATTEMPT_ELEMENT_INFO: (eventId) =>
      `${TYPES_ENDPOINTS.PARTICIPANTS}/event-structures/${eventId}/elements-info`,
    STATISTIC_ATTEMPT_ELEMENT_COMPLETE: (eventIdElementId) =>
      `/participants/event-structures/${eventIdElementId}/complete`,
  },
  HOMEWORK: {
    CREATE: (eventIdElementId) => `${TYPES_ENDPOINTS.PARTICIPANTS}${TYPES_ENDPOINTS.HOMEWORKS}/${eventIdElementId}`,
    READ: (eventIdElementId) => `${TYPES_ENDPOINTS.PARTICIPANTS}${TYPES_ENDPOINTS.HOMEWORKS}/${eventIdElementId}`,
    UPDATE: (homeworkAnswerIdHomeworkAnswerId) =>
      `${TYPES_ENDPOINTS.PARTICIPANTS}${TYPES_ENDPOINTS.HOMEWORKS}/${homeworkAnswerIdHomeworkAnswerId}`,
    COMMENT: (eventIdElementId) =>
      `${TYPES_ENDPOINTS.PARTICIPANTS}${TYPES_ENDPOINTS.HOMEWORKS}/${eventIdElementId}/comment`,
    COMMENT_DELETE: (eventIdElementId, commentId) =>
      `${TYPES_ENDPOINTS.PARTICIPANTS}${TYPES_ENDPOINTS.HOMEWORKS}/${eventIdElementId}/comment/${commentId}`,
  },
  GROUPS: {
    ROUTINE: TYPES_ENDPOINTS.GROUPS,
  },
};

export const EVENTS = {
  DASHBOARD_PANEL: { ...DASHBOARD_PANEL.EVENTS },
  ADMIN_PANEL: { ...ADMIN_PANEL.EVENTS },
};

export const HOMEWORK = {
  DASHBOARD_PANEL: { ...DASHBOARD_PANEL.HOMEWORK },
  ADMIN_PANEL: { ...ADMIN_PANEL.HOMEWORK },
};

export const EDUCATIONS = {
  ADMIN_PANEL: { ...ADMIN_PANEL.EDUCATIONS },
};

export const PORTALS = {
  ADMIN_PANEL: { ...ADMIN_PANEL.PORTALS },
};

export const GROUPS = {
  DASHBOARD_PANEL: { ...DASHBOARD_PANEL.GROUPS },
  ADMIN_PANEL: { ...ADMIN_PANEL.GROUPS },
};

//TODO [Work]: Recycle everything above

export const BUILDER_TEST: any = {
  QUESTIONS: (questionId) => `${TYPES_ENDPOINTS.QUESTIONS}/${questionId}`,
  QUESTIONS_CREATE: (testId) => `${TYPES_ENDPOINTS.QUESTIONS}/create/tests/${testId}`,
  QUESTIONS_READ: (testId) => `${TYPES_ENDPOINTS.QUESTIONS}/tests/${testId}`,
  QUESTIONS_POSITION: (testId) => `${TYPES_ENDPOINTS.QUESTIONS}/${testId}/position`,
  QUESTIONS_CREATE_ANSWER: (questionId) => `${TYPES_ENDPOINTS.QUESTIONS_ANSWER}/${questionId}/empty`,
  QUESTIONS_DELETE_ANSWER: (questionIdAnswerId) => `${TYPES_ENDPOINTS.QUESTIONS_ANSWER}/${questionIdAnswerId}`,
  QUESTIONS_COPY: (questionId) => `${TYPES_ENDPOINTS.QUESTIONS}/${questionId}/copy`,
  QUESTIONS_ADD_ADUIO: (questionId) => `${TYPES_ENDPOINTS.QUESTIONS}/${questionId}/set-audio`,
  QUESTIONS_ADD_VIDEO: (questionId) => `${TYPES_ENDPOINTS.QUESTIONS}/${questionId}/set-video`,
};

export const VIEW_TEST = {
  ROUTINE: (ids) => `${TYPES_ENDPOINTS.VIEW_TEST}/${ids}`,
  ATTEMPT_CLOSE: (ids) => `${TYPES_ENDPOINTS.VIEW_TEST}/${ids}/close`,
  QUESTIONS: (ids) => `/attempt-questions/${ids}`,
};

export const AGENCY = {
  PROFILE: '/agency',
  USERS: '/agency/users',
  INVITATIONS: '/agency/invitations',
  SEND_INVITE: '/agency/invitations/send-invite',
  RESEND_INVITE: '/agency/invitations/resend-invite',
  LOCK_USERS: '/agency/users/soft-delete',
  UNLOCK_USERS: '/agency/users/restore',
  CHANGE_ROLE: '/agency/users/change-role',
  LIST: '/agency/list',
  SWAP: (agencyId) => `/agency/${agencyId}/swap`,
  PAY_YOO: '/yoo-checkouts/set',
  PAY_YOO_DELETE: '/yoo-checkouts',
};

export const SUPPORT = {
  SEND_INFO: '/bug-report',
};

export const NOTIFICATIONS = {
  LIST: '/notifications',
  REMOVE_NOTIFICATION: (eventId, elementId, participantId): string =>
    `/notifications/${eventId}/${elementId}?participantId=${participantId}`,
};

export const PROFILE = {
  IAM: '/users', //TODO [BackEnd]: !!!
  ROUTINE: '/users',
  UPDATE_PASSWORD: '/users/update-password',
  TIMEZONES: 'https://raw.githubusercontent.com/dmfilipenko/timezones.json/master/timezones.json',
};

export const ADMIN = {
  GET_USERS: '/admin/users',
  GET_USER: (userId) => `/admin/users/${userId}`,
  UPDATE_USER: (userId) => `/admin/users/${userId}`,
  DELETE_USER: (userId) => `/admin/users/${userId}`,
  CREATE_USER: '/admin/users',
  GET_AGENCIES: '/admin/agencies',
  CREATE_AGENCY: '/admin/agencies',
  GET_AGENCY: (agencyId) => `/admin/agencies/${agencyId}`,
  UPDATE_AGENCY: (agencyId) => `/admin/agencies/${agencyId}`,
  UPDATE_AGENCY_PLAN: (agencyId) => `/admin/agencies/${agencyId}/set-plan`,
  DELETE_AGENCY: (agencyId) => `/admin/agencies/${agencyId}`,
  GET_AGENCIES_USERS: (agencyId) => `/admin/agencies/${agencyId}/users`,

  GET_AGENCY_REGISTER_REQUEST: '/admin/agencies/register-request',
  DELETE_AGENCY_REGISTER_REQUEST: (requestId) => `/admin/agencies/register-request/${requestId}`,
  RESEND_AGENCY_REGISTER_REQUEST: (requestId) => `/admin/agencies/register-request/${requestId}`,
  ACTIVATE_AGENCY_REGISTER_REQUEST: (requestId) => `/admin/agencies/register-request/${requestId}/activate`,

  GET_USER_IMPERSONATE: (userId) => `/admin/impersonate/${userId}`,

  CREATE_POST: '/admin/posts',
  GET_POSTS: '/admin/posts',
  GET_POST: (postId) => `/admin/posts/${postId}`,
  UPDATE_POST: (postId) => `/admin/posts/${postId}`,
  DELETE_POST: (postId) => `/admin/posts/${postId}`,
};

export const PLANS = {
  GET: '/plans',
  CREATE: '/plans',
  CONFIRM: '/plans',
  CANCEL: '/plans/cancel',
  SEND: '/plans/send-request',
};

export const MEMBERS = {
  EVENT_ACTIVE: (eventId) => `/event-members/${eventId}`,
  EVENT_INVITATIONS: (eventId) => `/event-members/${eventId}/invitations`,
  EVENT_SEND_INVITATIONS: (eventId) => `/event-members/${eventId}/invitations/send-invites`,
  CHANGE_ROLE: (eventId, userId) => `/event-members/${eventId}/${userId}`,
  EVENT_RESEND_INVITATIONS: (eventId) => `/event-members/${eventId}/invitations/resend-invite`,
  EVENT_DELETE_INVITATIONS: (eventId) => `/event-members/${eventId}/invitations`,
  LOCK: (eventId) => `/event-members/${eventId}/soft-delete`,
  DELETE: (eventId) => `/event-members/${eventId}`,
  RESTORE: (eventId) => `/event-members/${eventId}/restore`,
};

export const PARTICIPANTS = {
  INVITATIONS: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/invites`,
  SEND_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}/send-invites`,
  RESEND_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}/resend-invite`,
  DELETE_INVITE: (id) => `${TYPES_ENDPOINTS.PARTICIPANTS}/invitations/${id}`,
  ACTIVE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants`,
  LOCK: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants/soft-delete`,
  DELETE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants`,
  RESTORE: (eventId) => `${TYPES_ENDPOINTS.EVENTS}/${eventId}/participants/restore`,
};

export const STATISTIC = {
  EVENT: (eventId) => `/events/${eventId}/statistic`,
};

export const MIDDLEWARE = {
  SEARCH_USERS: '/users/search',
};

export const MAIL = {
  VERIFY_EMAIL: '/auth/verify-email',
  EVENT_INVITATION_PARTICIPANT: (token) => `/participants/invitations/${token}`,
  EVENT_INVITATION_MEMBERS: (token) => `/event-members/invitations/${token}`,
  AGENCY_INVITATION: (token) => `/agency/invitations/${token}`,
};

export const EVENTS_NEW = {
  GET_REGISTER_LIST_PARTICIPANTS: (eventId) => `/event-requests/${eventId}`,
  REQUEST_GET_EVENT: (eventId) => `/participants/event-requests/${eventId}`,
  REQUEST_GET_EVENTS_LIST: '/participants/event-requests',
  REQUEST_SET_SEND: (eventId) => `/participants/event-requests/${eventId}/send-request`,
  CONFIRM_REQUEST_REGISTER: (eventId, requestId) => `/event-requests/${eventId}/${requestId}`,
  GET_REGISTER_LIST: '/participants/event-requests',
};

export const PAYMENTS = {
  GET_HISTORY: '/payments',
};

export const SITE = {
  GET_EVENT_PAGE: EVENTS_NEW.REQUEST_GET_EVENT,
  SET_SEND_REQUEST: EVENTS_NEW.REQUEST_SET_SEND,
};

export const WEBINAR = {
  GET_JWT: (eventId, elementId) => `/participants/event-structures/${eventId}/${elementId}/webinar/jwt`,
  GET_JWT_ADMIN: (eventId, elementId) => `/event-structures/${eventId}/${elementId}/webinar/jwt`,
};

export const CONFERENCE = {
  STREAM: '/create-room',
  ROOM_PUBLIC: (romaName) => `/connect-room/${romaName}/public`,
  ROOM_PRIVATE: (romaName) => `/connect-room/${romaName}`,
};
