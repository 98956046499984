export default {
  RU: {
    'qs.stt.tlt': 'Настройки вопросов',
    'qs.stt.awr': 'Настройки ответов',
    'qs.awr.mix': 'Перемешивать ответы',
    'qs.cmt.view': 'Показывать комментарии',
    'qs.cmt.view.all': 'Ко всем ответам',
    'qs.cmt.view.correct': 'Только к верным ответам',
    'qs.cmt.view.usr': 'Только к ответу ученика',
    'qs.view.not': 'Не отображать',
    'qs.stt.cmt.view': 'Введенные комментарии будут отображаться после ответа ученика на вопросы',
    'qs.up.view': 'Свернуть вопрос',
    'qs.down.view': 'Развернуть вопрос',
    'qs.rqd.up': 'Все вопросы обязательные, сделать вопрос необязательным',
    'qs.rqd.down': 'Этот вопрос можно пропустить. Нажмите, чтобы сделать его обязательным к прохождению.',
    'qs.once': 'Один ответ',
    'qs.more': 'Несколько ответов',
    'qs.more.up': 'Учитывать неполный',
    'qs.awr.pls': 'Введите вариант ответа',
    'qs.cmt.pls': 'Введите короткий комментарий к ответу',
    'qs.add.awr': 'Добавить ответ',
    'qs.save': 'Сохранить вопрос',
    'qs.audio.tlt': 'Аудио-материал',
    'qs.audio.add': 'Добавить аудио',
    'qs.video.tlt': 'Видео-материал',
    'qs.video.upload.lbl': 'Загрузите новое видео или добавьте ссылку на уже заруженное.',
    'qs.video.add': 'Добавить видео',
    'qs.video.link': 'Вставьте ссылку на видео',
    'qs.video.storage': 'Добавить из облака',
    'qs.video.cover': 'Выбрать обложку для видео',
    'qs.crt': 'Создать вопрос',
    'qs.type.choose': 'Выбор ответа',
    'qs.type.free': 'Свободный ответ',
    'qs.awr.txt': 'Текстовый ответ',
    'qs.awr.manual': 'Ручная проверка',
    'qs.awr.number': 'Числовой ответ',
    'qs.delete': 'Удаление вопроса',
    'qs.name': 'Вопрос',
    'qs.err.scr.txt': 'Введите баллы',
    'qs.err.awr.txt': 'Введите текст ответа',
  },
};
