import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAttemptWorst(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" fill="none" viewBox="0 0 23 24" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.891 12.918l4.053 4.25c.573.572.907 1.404.907 2.586 0 1.516.04 2.398 1.233 2.704 1.444.371 2.493-1.368 2.699-2.322.285-1.326.393-2.679-.177-4.037-.46-1.098-1.151-1.698-1.151-1.698s-.196-.29-.196-.364c0-.112.196-.104.196-.104h5.14c1.26 0 1.431-.87 1.402-1.413-.057-1.081-.8-1.46-1.086-1.648.172-.245.593-.771.538-1.589-.06-.924-.697-1.288-1.069-1.546.251-.403.613-.955.496-1.648-.145-.86-.95-1.214-1.244-1.412.157-.36.539-.833.438-1.57-.184-1.333-1.474-1.534-1.799-1.534h-6.828c-1.668 0-3.682 1.419-3.682 1.419l.13 9.926z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.069 14.38H1.702a.702.702 0 01-.702-.7V1.702C1 1.314 1.314 1 1.702 1h5.367c.387 0 .702.314.702.702v11.977a.702.702 0 01-.702.702z"
      />
    </svg>
  );
}
