import './index.scss';
import classNames from 'classnames';
import React, { Component, FC, ReactElement, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setDisplayView } from '../../app/app.actions';
import { DisplayView } from '../../app/app.pipes';
import { typesView } from '../../app/app.types';
import { useEvent } from '../../hooks';
import { IReduxStore } from '../../store/serviceReducer';
import Icon from '../Icon';

interface IProps {
  children?: ReactNode;
  className?: string;
  hide?: string;
  initialWidth?: number;
  minWidth?: number;
  dark?: boolean;
  type?: string;
  session?: any;
  onChange?(data: boolean): void;
  onClick?(): void;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
  params?: any;
}

const field: IFields = {
  app: 'app',
  head: 'app__head',
  foot: 'app__foot',
  body: 'app__body',
  aside: 'app__aside',
  asideHead: 'app__aside-head',
  asideBody: 'app__aside-body',
  asideInner: 'app__aside-inner',
  scroller: 'app__body-scroller',
  main: 'app__body-main',
  widget: 'app__body-widget',
  content: 'app__body-content',
  context: 'app__body-context',
  route: 'app__route',
  container: 'app__container',
  containerInner: 'app__container-inner',
  settings: 'app__settings',
  settingsRow: 'app__settings__row',
  mobileModal: 'app__mobile-modal',
};

const layoutPart: FC<IParts> | Function = (children, className, params: any = {}): ReactElement | Function => (
  <div className={className} {...params}>
    {children}
  </div>
);

export const Head: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.head);

export const Body: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.body);

export const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

export const Aside: any = ({ children, initialWidth = 380, view, setDisplayViewAction }: any): ReactNode => {
  const { COMPACT, DEFAULT, MOBILE } = typesView;

  const { onCalculateDesktop, onCalculateCompact, onCalculateMobile } = DisplayView();

  let stopView = null as any;

  const onChangeDisplayView = () => {
    if (onCalculateCompact(window.innerWidth)) {
      //if tablet
      return setDisplayViewAction(COMPACT);
    }

    if (onCalculateMobile(window.innerWidth)) {
      //if mobile
      return setDisplayViewAction(MOBILE);
    }

    setDisplayViewAction(DEFAULT); //if desktop
  };

  const onChangeStopControlView = () => {
    clearTimeout(stopView);

    stopView = setTimeout(onChangeDisplayView, 0); // [Timeout === 0 for last event] -> optimization
  };

  useEffect(onChangeDisplayView, []);

  useEvent('resize', onChangeStopControlView);

  const onToggleCompact = () => {
    setDisplayViewAction(view === COMPACT ? DEFAULT : COMPACT);
  };

  const renderScroller = () => {
    return (
      view !== MOBILE &&
      !onCalculateCompact() && (
        <div className={field.scroller}>
          <span onClick={onToggleCompact}>
            <Icon name="ArrowSwipe" />
          </span>
        </div>
      )
    );
  };

  const params = {
    className: classNames(field.aside, {
      [`${field.aside}--compact`]: view === COMPACT,
      [`${field.aside}--active`]: view === COMPACT && onCalculateDesktop(window.innerWidth),
      [`${field.aside}--mobile`]: view === MOBILE,
    }),
    ...(view !== MOBILE && {
      style: {
        minWidth: initialWidth,
        width: initialWidth,
      },
    }),
  };

  return (
    <div {...params}>
      {children}
      {renderScroller()}
    </div>
  );
};

export const Main: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.main);

export const Widget: any = ({ children, view }: any): ReactNode => {
  const isMobile = view === typesView.MOBILE;

  return !isMobile && <div className={field.widget}>{children}</div>;
};

export const Content: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.content} ${className}`);

export const Container: Function = ({ children, className = '', offset = '' }): ReactNode =>
  layoutPart(
    children,
    classNames(field.container, className, {
      [`${field.container}--${offset}`]: offset,
    })
  );

export const Settings: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.settings} ${className}`);

export const SettingsRow: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.adminSettingsRow} ${className}`);

export const ContainerInner: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.containerInner} ${className}`);

export const Route: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.route} ${className}`);

export const AsideHead: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.asideHead} ${className}`);

export const AsideBody: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.asideBody} ${className}`);

export const AsideInner: Function = ({ children, className = '' }: IProps): ReactNode =>
  layoutPart(children, `${field.asideInner} ${className}`);

export const MobileModal: Function = ({ children, className = '', onClick }: IProps): ReactNode =>
  layoutPart(children, `${field.mobileModal} ${className}`, { onClick });

const mapStateToProps__Aside = (store: IReduxStore) => ({
  view: store.app.view!,
});

const mapStateToProps__Widget = (store: IReduxStore) => ({
  view: store.app.view!,
});

const mapDispatchToProps__Aside = (dispatch: Dispatch) => ({
  setDisplayViewAction: bindActionCreators(setDisplayView, dispatch),
});

class Layout extends Component<IProps> {
  public static Head: Function = Head;

  public static Body: Function = Body;

  public static Foot: Function = Foot;

  public static Aside: Function = connect(mapStateToProps__Aside, mapDispatchToProps__Aside)(Aside);

  public static Main: Function = Main;

  public static Widget: Function = connect(mapStateToProps__Widget)(Widget);

  public static Content: Function = Content;

  public static Route: Function = Route;

  public static Container: Function = Container;

  public static ContainerInner: Function = ContainerInner;

  public static Settings: Function = Settings;

  public static SettingsRow: Function = SettingsRow;

  public static AsideHead: Function = AsideHead;

  public static AsideBody: Function = AsideBody;

  public static AsideInner: Function = AsideInner;

  public static MobileModal: Function = MobileModal;

  render(): JSX.Element {
    const { children, dark, type, className } = this.props;

    const classes = classNames(field.app, className, {
      dark: dark,
      [`app--${type}`]: type,
    });

    return <div className={classes}>{children}</div>;
  }
}

export default Layout;
