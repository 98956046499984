import React from 'react';

interface IProps {
  className?: string;
}

export default function ELTelegram(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="77" height="64" fill="none" viewBox="0 0 77 64" {...props}>
      <g stroke="var(--el-fonts--icon-color)">
        <path
          strokeWidth="3"
          d="M73.578 9.668L43.86 57.198c-2.795 4.841-9.037 7.16-13.982 2.214l-6.644-7.337-5.266 4.671c-1.373 1.05-2.06 1.266-3.646.812l-7.11-1.964c-2.401-.644-3.567-2.342-1.652-4.256l2.215-2.077-3.708-2.792c-2.316-1.675-1.703-3.484.415-4.707l5.907-4.522-6.675-7.73c-3.62-3.62-1.205-11.226 4.43-12.735L67.625 2.33s4.69-1.25 6.507 1.108c2.236 2.9-.554 6.23-.554 6.23zm-.887-5.914L5.287 51.55m51.6-46.59L10.129 37.335"
        />
        <path
          strokeWidth="2.547"
          d="M16.563 49.398L12.8 52.082s1.44.397 2.138.23c1.036-.25 2.675-1.749 2.675-1.749l-1.05-1.165z"
        />
      </g>
    </svg>
  );
}
