import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELDownload(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 17h16M9.19 1.5v10.477m4.19-3.667l-4.12 4.12a.1.1 0 01-.14 0L5 8.31"
      />
    </svg>
  );
}
