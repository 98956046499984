import { IBuilderTestsAction, IBuilderTestsState } from './builder.questions.resources';
import types from './builder.questions.types';

const initialState: IBuilderTestsState = {
  template: null,
  injectIndex: 0,
  storage: [],
};

export default function editorReducer(
  state: IBuilderTestsState = initialState,
  action: IBuilderTestsAction
): IBuilderTestsState {
  const actionType = {
    [types.TEMPLATE]: { ...state, template: action.template },
    [types.INJECT_INDEX]: { ...state, injectIndex: action.injectIndex },
    [types.ON_STORAGE_QUESTION]: { ...state, storage: action.storage },
  };

  return actionType[action.type] ?? state;
}
