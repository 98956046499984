import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useToState } from '../../hooks';
import Icon from '../Icon';
import Input from '../Input';

interface IProps {
  onChange?(data: string): void;
  onClear?(): void;
  size?: string;
  width?: string;
  placeholder?: string;
  onKeyPress?: string;
  onEnter?: any;
  onSearch?: any;
  defaultValue?: string;
  label?: string | ReactNode;
  withoutIcon?: boolean;
  error?: boolean;
  value?: string;
  placeholderNode?: string | ReactNode;
  errorNode?: string | ReactNode;
  type?: 'default' | 'square';
}

interface IState {
  searchData: string;
  changeEmpty: boolean;
  openSearch: boolean;
  typingTimeout: any;
}

const Search: FC<IProps> = function ({
  onChange,
  size = 'lg',
  placeholder,
  placeholderNode,
  onEnter,
  onSearch,
  type = 'default',
  width = 'md',
  onClear,
  defaultValue = '',
  withoutIcon = false,
  label,
  error,
  value,
  errorNode,
}): ReactElement {
  const [state, setState] = useToState<IState>({
    searchData: value || defaultValue,
    changeEmpty: false,
    typingTimeout: null,
    openSearch: false || (type === 'square' && !!defaultValue), //TODO [!!]
  });

  const onChangeData = (e) => {
    const { typingTimeout } = state;

    const { value } = e.target;

    clearTimeout(typingTimeout);

    setState({
      ...state,
      searchData: value,
      typingTimeout: setTimeout(() => {
        onSearch && onSearch(value);
      }, 300),
    });
  };

  useEffect(() => {
    if (value !== undefined) {
      setState({ searchData: value });
    }
  }, [value]);

  useEffect(() => {
    onChange && onChange(state.searchData);
  }, [state.searchData]);

  const onSearchChange = () => {
    const { searchData } = state;

    onSearch && onSearch(searchData);
  };

  const onEnterChange = () => {
    const { searchData } = state;

    onEnter && onEnter(searchData);
  };

  const onKeyPressSearch = (e) => {
    const types = {
      13: onEnterChange,
    };

    const search = types[e.which];

    search && search(state.searchData);
  };

  const onSearchOpenInput = () => {
    setState({
      openSearch: !state.openSearch,
    });
  };

  const onSearchClearValue = () => {
    setState({
      searchData: '',
    });

    onSearch && onSearch('');
  };

  const renderClear = () => {
    return (
      <div className="search__clear" onClick={onSearchClearValue}>
        <Icon name="ELClose" className="search__clear-icon" />
      </div>
    );
  };

  const renderInputSearch = (className = 'search__input') => {
    const classesInput = classNames(className, {
      [`search--without-icon`]: withoutIcon,
    });

    return (
      <Input
        size={size}
        placeholder={placeholder}
        placeholderNode={placeholderNode}
        value={state.searchData}
        onChange={onChangeData}
        onKeyPress={onKeyPressSearch}
        className={classesInput}
        label={label}
        template={label && 'column'}
        error={error}
        errorNode={errorNode}
      >
        {onClear && state.searchData && renderClear()}
      </Input>
    );
  };

  const renderDefaultTemplate = () => {
    return (
      <div className="search__default">
        {!withoutIcon && <Icon className="search__icon" name="ELSearch" onClick={onSearchChange} />}
        {renderInputSearch()}
      </div>
    );
  };

  const renderSquareTemplate = () => {
    const classesSquare = classNames('search__square-hider', {
      'search__square-hider--active': state.openSearch,
    });

    return (
      <div className="search__square">
        <div className={classesSquare}>
          {!withoutIcon && <Icon className="search__square-icon" name="ELSearch" onClick={onSearchOpenInput} />}
          {renderInputSearch('search__square-input')}
        </div>
      </div>
    );
  };

  const renderTemplateTypes = () => {
    const types = {
      default: renderDefaultTemplate,
      square: renderSquareTemplate,
    };

    return types[type]();
  };

  const classesSearch = classNames('search', {
    [`search--${size}`]: size,
    [`search--w-${width}`]: width,
  });

  return <div className={classesSearch}>{renderTemplateTypes()}</div>;
};

export default Search;
