import React from 'react';

export default function Tags() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" viewBox="0 0 19 19">
      <rect
        width="2.5"
        height="18"
        x="6.658"
        y="0.048"
        fill="var(--el-fonts--icon-color)"
        rx="1.25"
        transform="rotate(15 6.658 .048)"
      />
      <rect
        width="2.5"
        height="18"
        x="13.658"
        y="0.048"
        fill="var(--el-fonts--icon-color)"
        rx="1.25"
        transform="rotate(15 13.658 .048)"
      />
      <rect
        width="2.5"
        height="16"
        x="18.1"
        y="4.3"
        fill="var(--el-fonts--icon-color)"
        rx="1.25"
        transform="rotate(90 18.1 4.3)"
      />
      <rect
        width="2.5"
        height="16"
        x="16.1"
        y="11.3"
        fill="var(--el-fonts--icon-color)"
        rx="1.25"
        transform="rotate(90 16.1 11.3)"
      />
    </svg>
  );
}
