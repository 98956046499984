import React from 'react';

interface IProps {
  className?: string;
}

export default function ELExpandMd(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="34" fill="none" viewBox="0 0 33 34" {...props}>
      <path
        stroke="#545047"
        strokeWidth="2"
        d="M17.168 13.464l.229.539.582.064 2.825.314-2.146 2.033-.402.381.11.543.585 2.902-2.377-1.442-.519-.314-.518.314-2.377 1.442.586-2.902.11-.543-.403-.38-2.146-2.034 2.825-.314.582-.064.229-.54 1.112-2.624 1.113 2.625z"
      />
      <path
        stroke="#545047"
        strokeWidth="2"
        d="M5.72 17.834l-.845.536.844-.536a3.477 3.477 0 01.018-3.756c.513-.79.813-1.7.871-2.64A3.477 3.477 0 018.831 8.41a5.477 5.477 0 002.256-1.622 3.477 3.477 0 013.578-1.144c.91.243 1.866.248 2.779.013a3.477 3.477 0 013.566 1.178 5.477 5.477 0 002.24 1.644 3.477 3.477 0 012.194 3.05c.05.94.341 1.851.846 2.646a3.477 3.477 0 01-.018 3.757 5.475 5.475 0 00-.871 2.639 3.477 3.477 0 01-2.222 3.028 5.476 5.476 0 00-2.256 1.622 3.477 3.477 0 01-3.578 1.144 5.477 5.477 0 00-2.78-.013A3.476 3.476 0 0111 25.174a5.476 5.476 0 00-2.24-1.644 3.477 3.477 0 01-2.194-3.05 5.476 5.476 0 00-.846-2.646z"
      />
      <path
        stroke="#545047"
        strokeWidth="2"
        d="M11.277 25.275v7.22a.5.5 0 00.71.455l3.808-1.758a.5.5 0 01.419 0l3.808 1.758a.5.5 0 00.71-.454v-7.221"
      />
    </svg>
  );
}
