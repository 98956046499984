import './index.scss';
import React, { FC, ReactElement, useMemo } from 'react';

const Preloader: FC = (): ReactElement => {
  const renderUseTags = (...rest) => {
    return rest.map(() => '<use class="text" xlink:href="#s-text"/>');
  };

  const renderSymbolContent = (n, a, m, e, s) => {
    return `
     <symbol id="s-text">
          <text text-anchor="middle" x="50%" y="50%" dy=".35em">
            <tspan class="bold">${n}</tspan>${a}${m}<tspan class="bold">${e}${s}</tspan>
          </text>
        </symbol>
        ${renderUseTags(n, a, m, e, s)}
    `;
  };

  return useMemo(
    () => (
      <div className="preloader">
        <svg viewBox="0 0 600 300" dangerouslySetInnerHTML={{ __html: renderSymbolContent('e', 'd', 'b', 'e', 'e') }} />
        {/*<div className="preloader__spinner">Loading...</div>*/}
      </div>
    ),
    []
  );
};

export default Preloader;
