import api from './groups.api';

export function getGroupsAdmin(data: any): Promise<any> {
  const request = api.init('getGroupsAdmin');

  return request({
    query: data,
  });
}

export function groupCreate(data: any): Promise<any> {
  const request = api.init('groupCreate');

  return request({
    params: data,
  });
}

export function groupRead(id: number): Promise<any> {
  const request = api.init('groupRead');

  return request({
    path: id,
  });
}

export function groupUpdate(id: number, data: any): Promise<any> {
  const request = api.init('groupUpdate');

  return request({
    path: id,
    params: data,
  });
}

export function groupSendInvite(id: number, data: any) {
  const request = api.init('groupSendInvite');

  return request({
    params: data,
    path: id,
  });
}

export function groupCancelInvite(data: any) {
  const request = api.init('groupCancelInvite');

  return request({
    params: data,
  });
}

export function groupGetParticipants(id: number) {
  const request = api.init('groupGetParticipants');

  return request({
    path: id,
  });
}

export function groupResendInvite(data: any) {
  const request = api.init('groupResendInvite');

  return request({
    params: data,
  });
}

export function groupInvitations(id: number) {
  const request = api.init('groupInvitations');

  return request({
    path: id,
  });
}

export function groupLockUsers(data: any): Promise<any> {
  const request = api.init('agencyLockUsers');

  return request({
    params: data,
  });
}

export function groupDeleteUsers(data: any): Promise<any> {
  const request = api.init('agencyDeleteUsers');

  return request({
    params: data,
  });
}

export function groupChangeRoleUsers(data: any): Promise<any> {
  const request = api.init('agencyChangeRoleUsers');

  return request({
    params: data,
  });
}

export function groupInviteRead(token): Promise<any> {
  const request = api.init('groupInviteRead');

  return request({
    path: token,
  });
}

export function groupSubmitInvite(token, data): Promise<any> {
  const request = api.init('groupSubmitInvite');

  return request({
    path: token,
    params: data,
  });
}
