import React from 'react';

interface IProps {
  className?: string;
}

export default function Clock({ className }: IProps) {
  const props = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M15.364 2.636A9 9 0 102.636 15.365 9 9 0 0015.364 2.636zM9.008 4a.75.75 0 00-.743.648l-.007.102v4.5a.747.747 0 00.75.75l3.002.007.102-.007a.75.75 0 00-.102-1.493l-2.252-.005V4.75l-.007-.102A.75.75 0 009.008 4z"
        clipRule="evenodd"
      />
    </svg>
  );
}
