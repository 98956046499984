import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELDefender(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" fill="none" viewBox="0 0 16 20" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.333"
        d="M11.072 3.408c-.82-.382-2.059-1.172-2.853-1.73a.737.737 0 00-.872.026A26.216 26.216 0 014.83 3.408c-1.046.623-2.515.95-3.494 1.067-.361.042-.653.34-.64.703.096 2.897.812 7.472 2.217 9.707 1.495 2.377 3.22 2.83 4.472 3.443a.83.83 0 00.76-.025c1.047-.58 3.031-1.313 4.443-3.418 1.493-2.227 1.996-7.15 2.083-9.803a.684.684 0 00-.534-.678c-.902-.218-2.255-.62-3.065-.996z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="1.333"
        d="M10.514 8.404l-3.18 3.18a.333.333 0 01-.472 0L5.138 9.86"
      />
    </svg>
  );
}
