import api from './middleware.api';
import { IMiddlewareIntraSystem } from './middleware.resources';

export function middlewareUserRead(): Promise<any> {
  const request = api.init('middlewareUserRead');

  return request(null);
}

export function middlewareUserLogin(data: any): Promise<any> {
  const request = api.init('middlewareUserLogin');

  return request({
    params: data,
  });
}

export function middlewareUserSignUp(data: any): Promise<any> {
  const request = api.init('middlewareUserSignUp');

  return request({
    params: data,
  });
}

export function middlewareUserForgotEmail(data: any): Promise<any> {
  const request = api.init('middlewareUserForgotEmail');

  return request({
    params: data,
  });
}

export function middlewareUserResetPassword(data: any): Promise<any> {
  const request = api.init('middlewareUserResetPassword');

  return request({
    params: data,
  });
}

export function middlewareLogout(): Promise<any> {
  const request = api.init('middlewareLogout');

  return request(null);
}

export function middlewareGetUserRole(): Promise<any> {
  const request = api.init('middlewareGetUserRole');

  return request(null);
}

export function middlewareGetRoles(): Promise<any> {
  const request = api.init('middlewareGetRoles');

  return request(null);
}

export function middlewareIntraSystemSearch(data: IMiddlewareIntraSystem): Promise<any> {
  const request = api.init('middlewareIntraSystemSearch');

  return request({
    query: data,
  });
}
