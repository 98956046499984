import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELFile(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="none" viewBox="0 0 20 23" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M5.92 1A4.91 4.91 0 001 5.92v10.505a4.91 4.91 0 004.92 4.921h7.193a4.91 4.91 0 004.921-4.92v-3.833c0-2.758-.06-3.941-1.893-5.773L14.25 4.88l-1.893-1.94C10.855 1.439 10.3 1 8.192 1H5.921z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M8.83 1.017v6.469a2.376 2.376 0 002.375 2.375h6.687c0-1.09-.667-1.948-1.75-3.031l-1.906-1.938-1.875-1.937C11.023 1.617 10.444.986 8.83 1.017z"
      />
    </svg>
  );
}
