import React from 'react';

interface IProps {
  className?: string;
}

export default function ELCalendarNot(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M18 4.753v-3m-5 3v-3m5 7h-4.144M8 3.435V1.753"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M23 16.984V7a4 4 0 00-4-4H7.515M21 21H7a4 4 0 01-4-4V5.25"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M7 12.503a1 1 0 102 0 1 1 0 00-2 0zm10 0a1 1 0 102 0 1 1 0 00-2 0zm-10 3.5a1 1 0 102 0 1 1 0 00-2 0z"
      />
      <circle r="1" fill="var(--el-fonts--icon-color)" transform="matrix(-1 0 0 1 13 16.003)" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="1.782"
        d="M23.609 23.164L11.804 12.473 5.902 7.127 2.951 4.455 1.475 3.118l-.368-.334-.185-.167"
      />
    </svg>
  );
}
