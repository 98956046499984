export default {
  EN: {
    'stats.start': 'Start',
    'stats.end': 'End',
    'stats.qs.all': 'Questions total',
    'stats.att.best.time': 'Best attempt time',
    'stats.att.scr': 'Best attempt score',
    'stats.test.sts': 'Test status',
    'stats.att.worst': 'Worst attempt',
    'stats.att.best': 'Best attempt',
    'stats.awr.know.not': 'Number of “I dont know” answers',
    'stats.awr.correct': 'Correct answers',
    'stats.tbl.awr.correct': '- correct answer',
    'stats.tbl.awr.correct.no': '- wrong answer`',
    'stats.tbl.awr.know.not': '-  “I dont know” answer',
    'stats.tbl.awr.skip': '- skip answer',
    'stats.tbl.time': 'Time, sec',
    'stats.dtl.tlt': 'Question content and student answer',
    'stats.dtl.scr': 'Time and score',
    'stats.view.correct': 'View correct answer',
    'stats.view.more': 'View details',
    'stats.att.best.usr': 'Best attempt',
    'stats.att.worst.usr': 'Worst attempt',
  },
};
