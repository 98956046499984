import './index.scss';
import React, { FC, ReactNode } from 'react';
import { useHover, useToggle } from '../../hooks';
import Icon from '../Icon';
import Square from '../Square';

interface IProps {
  data: Array<{
    icon: ReactNode;
    handler?(data: any): any;
    hint?: string;
  }>;
  active: boolean;
  unpack?: boolean;
  target?: ReactNode;
  event?: string;
  variant?: string;
}

const Ability: FC<IProps> = ({
  data,
  active = false,
  target,
  unpack,
  event = 'click',
  variant = 'hover-dark',
}): any => {
  const [toggle, setToggle] = useToggle(false);

  const [ref, isHovered] = useHover();

  const onToggleAbility = (e) => {
    e.stopPropagation();
    setToggle();
  };

  const renderButtons = () => {
    return data.map(({ icon, handler }, i) => {
      const isParams = {
        ...(handler && {
          onClick: (e) => {
            handler(e);
            onToggleAbility(e);
          },
        }),
      };

      return (
        <Square variant={variant} size="sm" key={i} {...isParams}>
          {icon}
        </Square>
      );
    });
  };

  const typesRender = () => {
    const types = {
      click: (() => (
        <>
          {(toggle || unpack) && renderButtons()}
          {!unpack && <i onClick={onToggleAbility}>{target ? target : <Icon name="Dots" />}</i>}
        </>
      ))(),
      hover: (() => {
        return (
          <>
            {isHovered && renderButtons()}
            {!unpack && <i>{target ? target : <Icon name="Dots" />}</i>}
          </>
        );
      })(),
    };

    return types[event];
  };

  return (
    active && (
      <div className="ability-settings" ref={ref}>
        {typesRender()}
      </div>
    )
  );
};

export default Ability;
