import { Dispatch } from 'redux';
import types from './builder.questions.types';

export function setTemplate(template) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.TEMPLATE,
      template,
    });
  };
}

export function setInjectIndex(injectIndex) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.INJECT_INDEX,
      injectIndex,
    });
  };
}

export function setStorageQuestions(storage) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.ON_STORAGE_QUESTION,
      storage,
    });
  };
}

export function updateStorageQuestions(data, testId) {
  return (dispatch: Dispatch, getStore) => {
    const storageQuestions = getStore().builderTests.storage;

    const cloneStorageQuestions = [...storageQuestions];

    const searchIndex = cloneStorageQuestions.findIndex((el) => el.testId === testId);

    if (searchIndex !== -1) {
      cloneStorageQuestions[searchIndex] = {
        ...cloneStorageQuestions[searchIndex],
        ...data,
      };
    } else {
      cloneStorageQuestions.push({
        ...data,
        testId,
      });
    }

    dispatch({
      type: types.ON_STORAGE_QUESTION,
      storage: cloneStorageQuestions,
    });
  };
}

export function addStorageQuestion(data: object) {
  return (dispatch: Dispatch, getStore) => {
    const resources = getStore().builderTests.storage;

    dispatch({
      type: types.ON_STORAGE_QUESTION,
      storage: [...resources, data],
    });
  };
}
