import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { useToState } from '../../hooks';
import translate from '../../i18n/translate';
import Text from '../Text';
import Textarea from '../Textarea';
import { typesFile } from './attach.types';
import AttachTripleFIle from './AttachTripleFIle';

interface IProps {
  label?: string | ReactNode;
  onChange: any;
  type: any;
  defaultValue: any;
  hiddenAttachFile?: boolean;
  update?: boolean;
}

const AttachTriple: FC<IProps> = function ({
  label,
  type,
  onChange,
  defaultValue,
  hiddenAttachFile = false,
  update = false,
}): ReactElement {
  const [state, setState] = useToState({
    activeIndexRow: -1,
    valueTextarea: defaultValue[type]?.code ?? '',
    valueFile: defaultValue[type]?.file ?? null,
    valueCover: defaultValue[type]?.coverId ?? null,
    valueInput: defaultValue[type]?.url ?? '',
    ...(update && {
      update: update,
    }),
  });

  useEffect(() => {
    if (update && state.update) {
      setState({
        activeIndexRow: -1,
        valueTextarea: defaultValue[type]?.code ?? '',
        valueFile: defaultValue[type]?.file ?? null,
        valueCover: defaultValue[type]?.coverId ?? null,
        valueInput: defaultValue[type]?.url ?? '',
      });
    }
  }, [defaultValue, update, state.update]);

  const onChangeVideoData = () => {
    switch (state.activeIndexRow) {
      case 'Stream':
        onChange({
          video: {
            file: state.valueFile ?? null,
            fileId: state.valueFile?.id ?? null,
            coverId: state.valueCover?.id ?? null,
            cover: state.valueCover ?? null,
            url: null,
            code: null,
          },
        });
        break;
      case 'Link':
        onChange({
          video: {
            url: state.valueInput ?? null,
            code: null,
            fileId: null,
            file: null,
            cover: null,
            coverId: null,
          },
        });
        break;
      case 'Code':
        onChange({
          video: {
            code: state.valueTextarea ?? null,
            url: null,
            file: null,
            cover: null,
            fileId: null,
            coverId: null,
          },
        });
        break;
    }
  };

  const onChangeTypesDataServe = () => {
    const types = {
      [typesFile.VIDEO]: onChangeVideoData,
      [typesFile.AUDIO]: () => null,
      [typesFile.IMAGE]: () => null,
    };

    types[type]();
  };

  useEffect(() => {
    onChangeTypesDataServe();
  }, [state]);

  const onChangeField = (key) => (value) => {
    setState({
      [key]: value,
    });
  };

  const onChangeRow = (activeIndexRow) => () => {
    setState({
      activeIndexRow,
      ...(update && {
        update: false,
      }),
    });
  };

  const renderVideoTemplate = () => {
    const { activeIndexRow } = state;

    const dataList = [
      {
        title: translate('g.add.link'),
        type: 'Link',
        component: [
          () => (
            <Textarea
              variant="answer"
              value={state.valueInput}
              onChange={onChangeField('valueInput')}
              placeholderNode={translate('qs.video.link')}
            />
          ),
        ],
      },
      {
        title: translate('g.embed.code'),
        type: 'Code',
        component: [
          () => (
            <Textarea
              variant="answer"
              value={state.valueTextarea}
              onChange={onChangeField('valueTextarea')}
              placeholderNode={translate('g.embed.code.vide')}
            />
          ),
        ],
      },
    ];

    const isFile = !hiddenAttachFile
      ? [
          {
            title: translate('qs.video.storage'),
            type: 'Stream',
            component: [
              () => (
                <AttachTripleFIle
                  defaultFile={state.valueFile}
                  defaultCover={state.valueCover}
                  onChangeFile={onChangeField('valueFile')}
                  onChangeCover={onChangeField('valueCover')}
                />
              ),
            ],
          },
        ]
      : [];

    const formationData: any = [...isFile, ...dataList];

    return (
      <div className="attach-triple__data">
        {formationData.map(({ title, component, type }, i) => {
          const classesDataRow = classNames('attach-triple__data-row', {
            'attach-triple__data-row--active': type === activeIndexRow || activeIndexRow === -1,
          });

          const [renderComponent] = component;

          return (
            <div className={classesDataRow} key={i} onClick={onChangeRow(type)}>
              <Text className="attach-triple__data-label">{title}</Text>
              {renderComponent()}
            </div>
          );
        })}
      </div>
    );
  };

  const renderAudioTemplate = () => {
    return null;
  };

  const renderImageTemplate = () => {
    return null;
  };

  const renderTemplate = () => {
    const types = {
      [typesFile.VIDEO]: renderVideoTemplate,
      [typesFile.AUDIO]: renderAudioTemplate,
      [typesFile.IMAGE]: renderImageTemplate,
    };

    return types[type]();
  };

  return (
    <div className="attach-triple">
      {renderTemplate()}
      {label && <Text className="attach-triple__label">{label}</Text>}
    </div>
  );
};

export default AttachTriple;
