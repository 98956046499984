import { Dispatch } from 'redux';
import types from './middleware.types';

export function middlewareSetUser(user) {
  return (dispatch: Dispatch) => {
    return dispatch({
      type: types.USER,
      user,
    });
  };
}

export function middlewareSession(session) {
  return (dispatch) =>
    dispatch({
      type: types.SESSION,
      session,
    });
}

export function middlewareSetUserRole(roleIAm) {
  return (dispatch) =>
    dispatch({
      type: types.ROLE_IAM,
      roleIAm,
    });
}

export function middlewareSetRoles(roles) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.ROLES,
      roles,
    });
  };
}

export function middlewareSocketStream(socketStream) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.SOCKET_STREAM,
      socketStream,
    });
  };
}

export function middlewareForceUpdate(forceUpdate) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.FORCE_UPDATE,
      forceUpdate,
    });
  };
}
