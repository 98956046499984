import { IDashboardAction, IDashboardState } from './dashboard.resources';
import types from './dashboard.types';

const initialState: IDashboardState = {
  typeUser: JSON.parse(localStorage.getItem('typeUser') as any) ?? ('student' as any),
};

export default function dashboardReducer(
  state: IDashboardState = initialState,
  action: IDashboardAction
): IDashboardState {
  const actionType = {
    [types.TYPE_USER]: {
      ...state,
      typeUser: action.typeUser,
    },
  };

  return actionType[action.type] ?? state;
}
