import { IStatsAction, IStatsState } from './statistic.resources';
import types from './statistic.types';

const initialState: IStatsState = {
  events: {},
};

export default function statsReducer(state: IStatsState = initialState, action: IStatsAction): IStatsState {
  const actionType = {
    [types.EVENTS]: {
      ...state,
      events: action.events,
    },
  };

  return actionType[action.type] ?? state;
}
