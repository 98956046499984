import { Dispatch } from 'redux';
import types from './location.types';

export function setLangName(langName) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.LANG_NAME,
      langName,
    });
  };
}
