export default {
  EN: {
    'alert.copy.in': 'Copied to clipboard',
    'alert.event.save': 'Event saved succesfully',
    'alert.qs.crt': 'New question created',
    'alert.qs.udt': 'Question succesfully updated',
    'alert.qs.prev.fill': 'Fill the previous question out',
    'alert.err': 'Something went wrong...',
    'alert.usr.err.exist': 'User with this email exists already.',
    'alert.usr.err.incorrect': 'Email adress and/or password entered wrong',
    'alert.required.fill': 'Fill out required fields',
    'alert.evt.del': 'Event has been deleted',
    'alert.evt.arch': 'Event has been moved to archive',
    'alert.evt.restore': 'Event has been restored',
    'alert.ath.file': 'New file has been added ',
    'alert.ath.file.err': 'New file has not been added: refresh the page!',
    'alert.prt.err': 'Partition has not been saved: refresh the page!',
    'alert.prt.add.new': 'New partition added',
    'alert.prt.change': 'Partition has been changed!',
    'alert.awr.set': 'Answer has been saved!',
    'alert.awr.reset': 'Answer has been overwritten!',
    'alert.homework.err': 'Something is broken - refresh the page!',
    'alert.evt.crt': 'New event created!',
    'alert.ath.file.change': 'Attached file has been changed',
    'alert.homework.add': 'Homework has been added',
    'alert.ivt.accept': 'Invitation has been accepted',
    'alert.ivt.cancel': 'Invitation has been declined',
    'alert.ivt.err.email': 'Invitation to this email has been sent already!',
    'alert.test.crt': 'New test has been created!',
    'alert.group.crt': 'New group has been created!',
    'alert.awr.been.gin': 'That answer has been given already!',
    'alert.ivt.repeat': 'Invitations have been resended!',
    'alert.canceled': 'Cancelled!',
    'alert.locked': 'Locked!',
    'alert.unlocked': 'Unlocked!',
    'alert.deleted': 'Deleted!',
    'alert.changed': 'Has been changed!',
    'alert.data.changed': 'Data has been changed succesfully',
    'alert.acc.not.email': 'That email is not available!',
    'alert.token.not.found': 'Token was not found!',
    'alert.acc.access.email': 'This email has been approved !',
    'alert.usr.not.found': 'User not found',
    'alert.link.err': 'Link is not available',
    'alert.change.pass': 'Password has been changed',
    'alert.pass.match.not': 'Passwords should not match',
    'alert.pass.invalid': 'Invalid password',
    'alert.send': 'Sent',
    'alert.pay.success': 'Payment succesfull',
    'alert.ath.video': 'Video attached',
    'alert.agc.change': 'You have changed the agency',
  },
};
