export default {
  RU: {
    'agency.p.tlt': 'Основная информация',
    'agency.p.name.plh': 'Введите название агентства',
    'agency.p.name.dmn': 'Впишите доменное имя агентства',
    'agency.p.pay': 'Настройки платежей',
    'agency.p.pay.lbl.tlt': 'Подключить кошелек ЮКасса',
    'agency.p.pay.lbl.dc': 'Разрешить прием платежей через кошелек ЮКасса',
    'agency.p.pay.number.name': 'Номер магазина',
    'agency.p.pay.number.plh': 'Введите номер магазина',
    'agency.p.pay.number.hint':
      'Введите номер магазина из настроек кабинета ЮКасса, на который нужно зачислять деньги отправителя',
    'agency.p.pay.key.name': 'Секретный ключ',
    'agency.p.pay.key.plh': 'Введите секретный ключ',
    'agency.p.pay.key.hint': 'Введите секретный ключ, полученный в кабинете Юкасса',
    'agency.p.pay.check.name': 'Передавать чек',
    'agency.p.pay.check.hint':
      'Настройка для передачи электронных чеков для обработки на стороне платежной системы через ОФД при подключении услуги онлайн-кассы',
    'agency.sw.tlt': 'Выбор агентства',
    'agency.staff': 'Сотрудники',
    'agency.staff.tlt': 'Сотрудники агентства',
    'agency.staff.lbl': 'Пригласите нового пользователя и назначьте ему роль.',
    'agency.staff.ivt': 'Приглашены в агентство',
    'agency.usr.chg.role': 'Изменить роль',
    'agency.profile': 'Профиль Агентства',
    'agency.switch': 'Сменить агентство',
  },
};
