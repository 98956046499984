import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowXS({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" fill="none" viewBox="0 0 10 11" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.035 1.965L1.5 5.5l3.535 3.536"
      />
    </svg>
  );
}
