import './index.scss';
import React, { FC, ReactElement, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setModal } from '../../app/Modal/modal.actions';
import { MODALS_TRIGGER } from '../../app/Modal/modal.types';
import { typesAccept } from '../../app/Storage/storage.types';
import { useToState } from '../../hooks';
import translate from '../../i18n/translate';
import { URI } from '../../routes/uri';
import { uuid } from '../../utils';
import Button from '../Button';
import Icon from '../Icon';
import Text from '../Text';
import Toggle from '../Toggle';

interface IProps {
  setModalAction: any;
  onChangeFile: any;
  onChangeCover: any;
  defaultFile: any;
  defaultCover: any;
}

const AttachTripleFIle: FC<IProps> = function ({
  setModalAction,
  onChangeFile,
  onChangeCover,
  defaultFile,
  defaultCover,
}): ReactElement {
  const [state, setState] = useToState({
    typeTemplateFile: 'None',
    file: defaultFile ?? null,
    cover: typeof defaultCover === 'number' ? { id: defaultCover } : defaultCover ?? null,
    checked: defaultFile && !!defaultCover,
  });

  useEffect(() => {
    const { file } = state;

    setState({
      typeTemplateFile: file ? 'Attach' : 'None',
    });

    onChangeFile(file);
  }, [state.file]);

  useEffect(() => {
    onChangeCover(state.cover);
  }, [state.cover]);

  const onChangeCoverField = (e) => {
    const { checked } = e.target;

    checked && onOpenStorage(typesAccept.IMAGE, 'cover')();

    setState({
      checked: checked,
      ...(!checked && {
        cover: null,
      }),
    });
  };

  const onResetAttach = () => {
    setState({
      typeTemplateFile: 'None',
      file: null,
      cover: null,
      checked: false,
    });
  };

  const onOpenStorage = (typesAccept, key) => () => {
    setModalAction({
      code: MODALS_TRIGGER.STORAGE.OPEN,
      uuid: uuid(),
      params: {
        accept: [typesAccept],
        onConfirm(data) {
          setState({
            [key]: data,
          });
        },
      },
      onDestroy() {
        setState({
          checked: false,
        });
      },
    });
  };

  const renderTemplateNotAttachFile = () => {
    return (
      <Button
        className="attach-triple__btn"
        variant="outlined"
        size="md"
        onClick={onOpenStorage(typesAccept.VIDEO, 'file')}
      >
        <Icon name="ELAddLightCircle" className="attach-triple__btn-icon" />
        {translate('g.file.new')}
      </Button>
    );
  };

  const renderTemplateAttachFile = () => {
    return (
      <div className="attach-triple__attach">
        <Text className="attach-triple__attach-file">
          {state.file.originalName}
          <div className="attach-triple__attach-trash" onClick={onResetAttach}>
            <Icon name="Trash" />
          </div>
        </Text>
        <div className="attach-triple__attach-cover">
          <Toggle replace variant="success" checked={state.checked} onChange={onChangeCoverField}>
            <Icon name="CheckSuccess" />
            <Text className="attach-triple__attach-label">{translate('qs.video.cover')}</Text>
          </Toggle>
        </div>
        {state.cover?.id && (
          <div className="attach-triple__cover">
            <img src={URI.STORAGE.FILE(state.cover.id)} alt="" className="attach-triple__cover-image" />
          </div>
        )}
      </div>
    );
  };

  const renderFile = () => {
    const { typeTemplateFile } = state;

    const typesTemplates = {
      Attach: () => renderTemplateAttachFile(),
      None: () => renderTemplateNotAttachFile(),
    };

    return typesTemplates[typeTemplateFile]();
  };

  return useMemo(() => <div className="attach-triple__file">{renderFile()}</div>, [state]);
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
});

export default connect(null, mapDispatchToProps)(AttachTripleFIle);
