import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { GENERAL, MIDDLEWARE } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...GENERAL, ...MIDDLEWARE };
  }

  protected middlewareUserRead(data: IRequestDataParse): void {
    const { ME } = this.ROUTE;

    this.get(ME, data);
  }

  protected middlewareUserLogin(data: IRequestDataParse): void {
    const { AUTH_LOGIN } = this.ROUTE;

    this.post(AUTH_LOGIN, data);
  }

  protected middlewareUserSignUp(data: IRequestDataParse): void {
    const { AUTH_USER_SIGN_UP } = this.ROUTE;

    this.post(AUTH_USER_SIGN_UP, data);
  }

  protected middlewareUserForgotEmail(data: IRequestDataParse): void {
    const { AUTH_USER_FORGOT_EMAIL } = this.ROUTE;

    this.post(AUTH_USER_FORGOT_EMAIL, data);
  }

  protected middlewareUserResetPassword(data: IRequestDataParse): void {
    const { AUTH_USER_RESET_PASSWORD } = this.ROUTE;

    this.post(AUTH_USER_RESET_PASSWORD, data);
  }

  public middlewareLogout(data: IRequestDataParse): void {
    const { LOGOUT } = this.ROUTE;

    this.post(LOGOUT, data);
  }

  public middlewareGetUserRole(data: IRequestDataParse): void {
    const { ROLE_ME } = this.ROUTE;

    this.get(ROLE_ME, data);
  }

  public middlewareGetRoles(data: IRequestDataParse): void {
    const { ROLES } = this.ROUTE;

    this.get(ROLES, data);
  }

  public middlewareIntraSystemSearch(data: IRequestDataParse): void {
    const { SEARCH_USERS } = this.ROUTE;

    this.get(SEARCH_USERS, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
