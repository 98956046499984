import React from 'react';

interface IProps {
  className?: string;
}

export default function Trash2({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" fill="none" viewBox="0 0 10 16" {...params}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M-3 14.286C-3 15.233-2.105 16-1 16h8c1.105 0 2-.767 2-1.714V4H-3v10.286zM6.5 1l-1-1h-5l-1 1H-4v2h14V1H6.5z"
      />
    </svg>
  );
}
