import React from 'react';

interface IProps {
  className?: string;
}

export default function ELCalendar(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M16 4V1m-5 3V1M6 8h10M6 4V1"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 6.25a4 4 0 014-4h12a4 4 0 014 4v10a4 4 0 01-4 4H5a4 4 0 01-4-4v-10z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M5 11.75a1 1 0 102 0 1 1 0 00-2 0zm5 0a1 1 0 102 0 1 1 0 00-2 0zm5 0a1 1 0 102 0 1 1 0 00-2 0zm-10 3.5a1 1 0 102 0 1 1 0 00-2 0z"
      />
      <circle r="1" fill="var(--el-fonts--icon-color)" transform="matrix(-1 0 0 1 11 15.25)" />
      <circle r="1" fill="var(--el-fonts--icon-color)" transform="matrix(-1 0 0 1 16 15.25)" />
    </svg>
  );
}
