import './index.scss';
import React, { Component, FC, ReactElement, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  className?: string;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 't--source',
  head: 't--source__head',
  foot: 't--source__foot',
  body: 't--source__body',
  content: 't--source__body-content',
  grid: 't--source__body-grid',
  cell: 't--source__body-cell',
  view: 't--source__body-view',
  block: 't--source__block',
};

const layoutPart: FC<IParts> | Function = (children, className): ReactElement | Function => (
  <div className={className}>{children}</div>
);

const Head: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.head);

const Body: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.body);

const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

const Content: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.content);

const Grid: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.grid);

const Cell: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.cell);

const View: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.view);

const Block: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.block);

class TSource extends Component<IProps> {
  public static Head: Function = Head;

  public static Body: Function = Body;

  public static Foot: Function = Foot;

  public static Content: Function = Content;

  public static Grid: Function = Grid;

  public static Cell: Function = Cell;

  public static View: Function = View;

  public static Block: Function = Block;

  render(): JSX.Element {
    const { children } = this.props;

    return <div className={field.app}>{children}</div>;
  }
}

export default TSource; //TODO [Deprecated]: Rework on one "Skeleton"
