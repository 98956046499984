import React from 'react';

interface IProps {
  className?: string;
}

export default function Lock({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="19" fill="none" viewBox="0 0 16 19" {...params}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M14.286 8.313h-.857V5.64C13.429 2.53 10.993 0 8 0 5.007 0 2.571 2.53 2.571 5.64v2.673h-.857C.768 8.313 0 9.11 0 10.092v7.126C0 18.202.768 19 1.714 19h12.572c.946 0 1.714-.798 1.714-1.781v-7.125c0-.984-.768-1.781-1.714-1.781zm-3.715 0H5.43V5.64c0-1.474 1.153-2.672 2.571-2.672 1.418 0 2.571 1.198 2.571 2.672v2.671z"
      />
    </svg>
  );
}
