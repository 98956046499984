import { useMemo } from 'react';

export default (itemRef) => {
  const targetSelected: HTMLDivElement = itemRef.current!;

  const top = targetSelected?.getBoundingClientRect().top;

  const left = targetSelected?.getBoundingClientRect().left;

  const width = targetSelected?.getBoundingClientRect().width;

  const height = targetSelected?.getBoundingClientRect().height;

  return useMemo(
    () => ({
      top,
      left,
      width,
      height,
    }),
    [top, left, width, height, itemRef]
  );
};
