import React from 'react';

interface IProps {
  className?: string;
}

export default function ELTestPointsReward(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <circle
        cx="10"
        cy="10"
        r="9"
        fill="var(--el-fonts--icon-fill-color)"
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.7"
        d="M11.868 9.21l-1.775-2.131a.086.086 0 00-.132 0L8.185 9.21a.086.086 0 01-.084.029l-2.244-.493a.086.086 0 00-.1.108l1.225 4.085c.01.036.044.061.082.061h5.899a.086.086 0 00.082-.062l1.2-4.084a.086.086 0 00-.101-.108l-2.19.493a.086.086 0 01-.086-.03z"
      />
    </svg>
  );
}
