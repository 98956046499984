import './index.scss';
import classNames from 'classnames';
import React from 'react';
import ContentLoader from 'react-content-loader';

interface IProps {
  className?: string;
}

const Mount = (props: IProps) => {
  const params = {
    ...props,
    className: classNames('loader--content', props?.className),
  };

  return (
    <div {...params}>
      <ContentLoader
        backgroundColor="var(--el-fonts--sheet-color)"
        foregroundColor="#fcfcfc"
        viewBox="0 0 778 116"
        width={778}
        height={116}
      >
        <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
        <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
        <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
        <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
        <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--el-fonts--sheet-color)"
        foregroundColor="#fcfcfc"
        viewBox="0 0 778 116"
        width={778}
        height={116}
        {...props}
      >
        <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
        <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
        <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
        <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
        <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--el-fonts--sheet-color)"
        foregroundColor="#fcfcfc"
        viewBox="0 0 778 116"
        width={778}
        height={116}
        {...props}
      >
        <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
        <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
        <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
        <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
        <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
      </ContentLoader>
      <ContentLoader
        backgroundColor="var(--el-fonts--sheet-color)"
        foregroundColor="#fcfcfc"
        viewBox="0 0 778 116"
        width={778}
        height={116}
        {...props}
      >
        <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
        <rect x="28" y="29" rx="0" ry="0" width="258" height="32" />
        <rect x="28" y="71" rx="0" ry="0" width="465" height="32" />
        <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
        <rect x="29" y="116" rx="0" ry="0" width="749" height="32" />
      </ContentLoader>
    </div>
  );
};

export default Mount;
