export const typBell = {
  EVENTS: 'ParticipantInvitation',
  AGENCY: 'AgencyInvitation',
  EVENT_INVITATIONS: 'EventInvitation',
  HOMEWORK_REVIEW: 'HomeworkReviewNotification',
  EVENT_NEW_PARTICIPANT: 'EventNewParticipantNotification',
};

export default {
  RESOURCES: 'RESOURCES',
  LOADING: 'LOADING',
};
