import './index.scss';
import React, { FC } from 'react';
import Icon from '../Icon';

interface IProps {
  handle: any;
  variant?: string;
}

const Handle: FC<IProps> = ({ handle, variant = 'question' }) => {
  return (
    <div className={`handle--${variant}`} {...handle}>
      <Icon name="Handle" />
    </div>
  );
};

export default Handle;
