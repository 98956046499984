import React from 'react';

interface IProps {
  className?: string;
}

export default function Question({ className }: IProps) {
  const props = {
    ...(className && { className }),
  };

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="var(--el-fonts--icon-color)"
      viewBox="0 0 15 15"
    >
      <path d="M7.5 0a7.5 7.5 0 10.001 15.001A7.5 7.5 0 007.5 0zm0 13.728a6.229 6.229 0 010-12.456 6.229 6.229 0 010 12.456z" />
      <path d="M9.368 4.23A2.822 2.822 0 007.5 3.55a2.83 2.83 0 00-1.868.68c-.523.458-.81 1.072-.81 1.73v.127c0 .074.06.134.133.134h.804c.073 0 .134-.06.134-.134V5.96c0-.738.721-1.34 1.607-1.34.885 0 1.607.602 1.607 1.34 0 .52-.368.998-.94 1.217a1.871 1.871 0 00-1.205 1.771v.36c0 .074.06.134.134.134H7.9c.074 0 .134-.06.134-.134v-.38a.809.809 0 01.517-.75c.988-.38 1.626-1.25 1.626-2.218.001-.658-.287-1.272-.809-1.73zM6.83 11.184a.67.67 0 101.34 0 .67.67 0 00-1.34 0z" />
    </svg>
  );
}
