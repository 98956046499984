import './index.scss';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import translate from '../../../../../i18n/translate';
import { IReduxStore } from '../../../../../store/serviceReducer';
import { Button, EditorText, Text, Toggle, TQuestion } from '../../../../app.modules';
import { setTemplate } from '../../../builder.questions.actions';
import CreateAttach from '../../CreateAttach';
import CreateHeading from '../../CreateHeading';
import QuestionUpper from '../../CreateUpper';
import Bridge from '../../questions.create.pipes';
import QuestionService from '../questions.types.services';
import FreeAnswer from './FreeAnswer';

interface IProps {
  data: any;
  template?: any;
  setTemplateAction?: any;
}

const Free: FC<IProps> = ({ data, template, setTemplateAction }): ReactElement => {
  const findBlock = () => [[...template], [...template[data.indexUp].data]];

  const onChangeAnswer = (item, field) => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const newCloneBlock = {
      ...cloneBlock,
      [data.typeAnswer]: {
        ...cloneBlock[data.typeAnswer],
        ...field,
      },
    };

    questions[data.indexDown] = {
      ...newCloneBlock,
      ...Bridge.onErrorChange(newCloneBlock),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  const onChangeUnderSettings = (e, key, radio = true) => {
    const { value, checked } = e.target;

    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const newCloneBlock = {
      ...cloneBlock,
      [data.typeAnswer]: {
        ...cloneBlock[data.typeAnswer],
        [key]: radio ? value : checked,
      },
    };

    questions[data.indexDown] = {
      ...newCloneBlock,
      ...Bridge.onErrorChange(newCloneBlock),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  useEffect(() => {
    const isNumberAnswer = data[data?.typeAnswer]?.type === 'NumberAnswer';

    isNumberAnswer && onChangeUnderSettings({ target: { checked: false } }, `manualCheck`, false);
  }, [data[data?.typeAnswer]?.type]);

  const isErrorBody = Bridge.parseBodyError(data);

  return (
    <QuestionService data={data}>
      {({ data, onChangeFieldName, onSaveQuestion, onChangeFieldAudio }) => (
        <TQuestion className="choice">
          <TQuestion.Head>
            <CreateHeading number={data.indexDown + 1} name={data?.name || data?.body} />
            <QuestionUpper data={data} onChange={onChangeFieldName} />
          </TQuestion.Head>
          <TQuestion.Body>
            <EditorText
              error={isErrorBody}
              data={data.body}
              onChange={(e) => onChangeFieldName(e, 'body')}
              plugins={['default', 'wiris']}
            />
            <CreateAttach data={data} onChangeField={onChangeFieldName} onChangeFieldAudio={onChangeFieldAudio} />
          </TQuestion.Body>
          <TQuestion.Foot>
            <TQuestion.Free>
              <Toggle
                type="radio"
                variant="radio"
                name="free"
                value="TextAnswer"
                defaultChecked={data[data?.typeAnswer]?.type === 'TextAnswer'}
                onChange={(e) => onChangeUnderSettings(e, 'type')}
              >
                <Text variant="lg">{translate('qs.awr.txt')}</Text>
              </Toggle>
              <Toggle
                off={data[data?.typeAnswer]?.type === 'NumberAnswer'}
                variant="primary"
                type="checkbox"
                name={data.uuid}
                value={false}
                checked={data[data?.typeAnswer]?.manualCheck}
                onChange={(e) => onChangeUnderSettings(e, `manualCheck`, false)}
              >
                <Text variant="lg">{translate('qs.awr.manual')}</Text>
              </Toggle>
              <Toggle
                type="radio"
                variant="radio"
                name="free"
                value="NumberAnswer"
                defaultChecked={data[data?.typeAnswer]?.type === 'NumberAnswer'}
                onChange={(e) => onChangeUnderSettings(e, 'type')}
              >
                <Text variant="lg">{translate('qs.awr.number')}</Text>
              </Toggle>
            </TQuestion.Free>
            <FreeAnswer question={data} data={data[data.typeAnswer]} onChange={onChangeAnswer} />
            <div className="choice__bot">
              <Button off={data.error} variant="primary" size="md" onClick={onSaveQuestion}>
                {translate('qs.save')}
              </Button>
            </div>
          </TQuestion.Foot>
        </TQuestion>
      )}
    </QuestionService>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  template: store.builderTests.template!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTemplateAction: bindActionCreators(setTemplate, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Free);
