import { Dispatch } from 'redux';
import types from './notifications.types';

export function setNotificationData(resources) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.RESOURCES,
      resources,
    });
    dispatch({
      type: types.LOADING,
      loading: false,
    });
  };
}

export function notificationAdd(data: object) {
  return (dispatch: Dispatch, getStore) => {
    const notificationData = getStore().notification.resources;

    dispatch({
      type: types.RESOURCES,
      resources: {
        total: notificationData.total + 1,
        data: [data, ...notificationData.data],
      },
    });
  };
}

export function notificationDelete(dataId) {
  return (dispatch: Dispatch, getStore) => {
    const notificationData = getStore().notification.resources;

    const filtered = notificationData.data.filter(
      (el) =>
        // el?.meta?.id ? (el?.meta?.id !== eventId) : el?.id !== eventId
        el?.id !== dataId
    ); //TODO [!!]

    dispatch({
      type: types.RESOURCES,
      resources: {
        total: notificationData.total - 1,
        data: filtered,
      },
    });
  };
}
