import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELPlay(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" fill="none" viewBox="0 0 18 24" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M15.846 11.688a.994.994 0 010 1.616L2.573 22.807A.994.994 0 011 21.999V2.993a.994.994 0 011.573-.808l13.273 9.503z"
      />
    </svg>
  );
}
