import React from 'react';

interface IProps {
  className?: string;
}

export default function Bicycle(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 59 45" {...props}>
      <path
        fill="#F9D205"
        d="M23.457 19.727h12.33v8.63h-12.33v-8.63zm-1.014 10.596a8.67 8.67 0 11-17.342 0 8.67 8.67 0 0117.342 0zm30.405.001a8.457 8.457 0 11-16.914 0 8.457 8.457 0 0116.914 0z"
      />
      <path
        fill="#4C506A"
        d="M35.614 21.961l9.8 7.194c.83.61 1.077 1.678.551 2.393-.525.716-1.617.8-2.448.19l-9.8-7.194c-.831-.61-1.077-1.677-.552-2.393.525-.715 1.617-.8 2.449-.19z"
      />
      <path
        fill="#4C506A"
        d="M47.919 30.327a3.53 3.53 0 11-7.06 0 3.53 3.53 0 017.06 0zm-31.846.003a2.302 2.302 0 11-4.605 0 2.302 2.302 0 014.605 0z"
      />
      <path
        fill="#4C506A"
        d="M22.464 21.961l-9.799 7.194c-.831.61-1.078 1.678-.552 2.393.525.716 1.617.8 2.449.19l9.799-7.194c.831-.61 1.077-1.677.552-2.393-.525-.715-1.617-.8-2.449-.19zm11.71-11.993l5.633.01c1.031.001 1.86.712 1.859 1.593v.024c-.002.881-.833 1.59-1.864 1.587l-5.633-.009c-1.032-.002-1.86-.712-1.86-1.593v-.025c.002-.881.834-1.589 1.865-1.587z"
      />
      <path
        fill="#4C506A"
        d="M17.306 30.327a3.53 3.53 0 11-7.06 0 3.53 3.53 0 017.06 0zm2.143-25.213l5.074.009a2.818 2.818 0 012.82 2.828 2.818 2.818 0 01-2.829 2.82l-5.075-.01a2.818 2.818 0 01-2.819-2.828 2.818 2.818 0 012.829-2.819z"
      />
      <path
        fill="#4C506A"
        d="M24.68 5.11c-.796 0-1.496.281-2.03.816-.536.535-.817 1.235-.817 2.031V19.26c-2.36-1.727-5.045-2.65-8.058-2.65-3.792 0-7.077 1.36-9.757 4.04C1.338 23.331 0 26.616 0 30.408c0 3.792 1.337 7.054 4.018 9.735 2.68 2.68 5.965 4.04 9.757 4.04 3.76 0 7.024-1.361 9.735-4.04 2.682-2.65 4.04-5.912 4.04-9.735V7.958c0-.8-.287-1.52-.839-2.054-.548-.53-1.248-.795-2.03-.795zm8.852 0c-.796 0-1.495.281-2.03.816-.536.535-.817 1.235-.817 2.031v22.45c0 3.793 1.359 7.055 4.04 9.736 2.68 2.68 5.943 4.04 9.735 4.04 3.76 0 7.045-1.361 9.757-4.04 2.682-2.65 4.04-5.912 4.04-9.735 0-1.896-.342-3.685-1.016-5.32-.674-1.637-1.683-3.113-3.024-4.438-2.712-2.679-5.996-4.04-9.757-4.04-3.056 0-5.735.93-8.058 2.672V7.957c0-.8-.287-1.519-.839-2.053-.548-.53-1.248-.795-2.03-.795zM13.775 22.327a7.7 7.7 0 013.135.64 8.256 8.256 0 014.305 4.327c.42.97.617 2.007.617 3.113 0 1.106-.197 2.12-.618 3.09a8.358 8.358 0 01-1.721 2.583 8.245 8.245 0 01-2.583 1.744 7.7 7.7 0 01-3.135.64c-1.138 0-2.202-.212-3.179-.64a8.088 8.088 0 01-2.539-1.744 8.214 8.214 0 01-1.721-2.56c-.421-.98-.618-2.007-.618-3.113 0-1.106.197-2.156.618-3.135a8.214 8.214 0 011.721-2.56 8.09 8.09 0 012.54-1.745c.976-.427 2.04-.64 3.178-.64zm30.685 0c1.122 0 2.178.213 3.157.64a8.208 8.208 0 012.56 1.766 8.26 8.26 0 011.722 2.561c.42.97.64 2.007.64 3.113 0 1.106-.22 2.12-.64 3.09a8.358 8.358 0 01-1.722 2.583 8.147 8.147 0 01-2.56 1.744 7.804 7.804 0 01-3.157.64 7.75 7.75 0 01-3.157-.64 8.189 8.189 0 01-2.56-1.744 8.155 8.155 0 01-1.7-2.56 7.775 7.775 0 01-.64-3.113c0-1.106.219-2.156.64-3.135a8.155 8.155 0 011.7-2.56 8.189 8.189 0 012.56-1.745 7.75 7.75 0 013.157-.64z"
      />
    </svg>
  );
}
