import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELFullScreen(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M12.5 0a1 1 0 100 2V0zM16 5a1 1 0 102 0h-2zm-3.5-3h4.4V0h-4.4v2zm3.5-.9V5h2V1.1h-2zm.9.9a.9.9 0 01-.9-.9h2A1.1 1.1 0 0016.9 0v2zM5.5 0a1 1 0 010 2V0zM2 5a1 1 0 01-2 0h2zm3.5-3H1.1V0h4.4v2zM2 1.1V5H0V1.1h2zm-.9.9a.9.9 0 00.9-.9H0A1.1 1.1 0 011.1 0v2zm11.4 16a1 1 0 110-2v2zm3.5-5a1 1 0 112 0h-2zm-3.5 3h4.4v2h-4.4v-2zm3.5.9V13h2v3.9h-2zm.9-.9a.9.9 0 00-.9.9h2a1.1 1.1 0 01-1.1 1.1v-2zM5.5 18a1 1 0 100-2v2zM2 13a1 1 0 10-2 0h2zm3.5 3H1.1v2h4.4v-2zm-3.5.9V13H0v3.9h2zm-.9-.9a.9.9 0 01.9.9H0A1.1 1.1 0 001.1 18v-2z"
      />
    </svg>
  );
}
