import React from 'react';

interface IProps {
  className?: string;
}

export default function Permissions({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" fill="none" viewBox="0 0 17 18" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        d="M6.625 2L1.531 7.094m14.141.01H1.533M10.594 16l5.094-5.095m-14.153-.009h14.139"
      />
    </svg>
  );
}
