import './assets/scss/root.scss';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import LoadingBar from 'react-redux-loading-bar';
import { Route, Router, Switch } from 'react-router-dom';
import Preloader from './app/Preloader';
import { $history, $root, modesActivate } from './env';
import ProviderINTL from './i18n/provider';
import { getRoute } from './routes/client';
import * as serviceWorker from './serviceWorker';
import { store } from './store/configureStore';

const AdminPanel = lazy(() => import('./app/AdminPanel'));

const Site = lazy(() => import('./app/Site'));

const Admin = lazy(() => import('./app/Admin'));

const BuilderTest = lazy(() => import('./app/BuilderQuestions'));

const Mail = lazy(() => import('./app/Mail'));

const Learning = lazy(() => import('./app/Learning'));

const Dashboard = lazy(() => import('./app/Dashboard'));

const MiddlewareLogin = lazy(() => import('./app/Middleware/MiddlewareLogin'));

const MiddlewareSignUp = lazy(() => import('./app/Middleware/MiddlewareSignUp'));

const MiddlewareForgot = lazy(() => import('./app/Middleware/MiddlewareForgot'));

const MiddlewareForgotSuccess = lazy(() => import('./app/Middleware/MiddlewareForgotSuccess'));

const MiddlewareResetPassword = lazy(() => import('./app/Middleware/MiddlewareResetPassword'));

const Modal = lazy(() => import('./app/Modal'));

const ModalRoute = lazy(() => import('./app/Modal/ModalRoute'));

import Middleware from './app/Middleware';

const Alert = lazy(() => import('./app/Alert'));

const Conference = lazy(() => import('./app/Conference'));

modesActivate();

ReactDOM.render(
  <Provider store={store}>
    <ProviderINTL>
      <Suspense fallback={<Preloader />}>
        <Router history={$history}>
          <Middleware exception={<Conference />}>
            <LoadingBar className="loader" updateTime={100} maxProgress={85} />
            <Switch>
              <Route path={getRoute('AP', 'path')} component={AdminPanel} />
              <Route path={getRoute('BQ', 'path')} component={BuilderTest} />
              <Route path={getRoute('L', 'path')} component={Learning} />
              <Route path={getRoute('DP', 'path')} component={Dashboard} />
              <Route path={getRoute('AHP', 'path')} component={Admin} />
              <Route path={getRoute('S', 'path')} component={Site} />
              {/*<Route path={getRoute('C', 'path')} component={Conference} />*/}
              <Route path={getRoute('AL', 'path')} component={MiddlewareLogin} exact />
              <Route path={getRoute('AS', 'path')} component={MiddlewareSignUp} exact />
              <Route path={getRoute('AF', 'path')} component={MiddlewareForgot} exact />
              <Route path={getRoute('AFS', 'path')} component={MiddlewareForgotSuccess} exact />
              <Route path={getRoute('ARP', 'path')} component={MiddlewareResetPassword} exact />
              <Route path={getRoute('M', 'path')} component={Mail} />
            </Switch>
            <ModalRoute />
          </Middleware>
          <Modal />
          <Alert />
        </Router>
      </Suspense>
    </ProviderINTL>
  </Provider>,
  $root
);

serviceWorker.unregister();
