import classNames from 'classnames';
import get from 'lodash/get';
import React, { FC } from 'react';
import { Icon, Text } from '../../../app.modules';

interface IProps {
  data: {
    service: {
      activeIndex: number;
      focusinFiles: Array<any>;
    };
    action: {
      onContextMenuChange(e: any, data: File, index: number): void;
      onChangeFolder(data: File): void;
      onFocusFile(e: any, data: File, index: number): void;
    };
    file: File;
    index: number;
  };
}

const StorageFolder: FC<IProps> = ({ data }): any => {
  const { service, action, file, index } = data as any;

  const { activeIndex, focusinFiles } = service;

  const basename = get(file, 'filename', 'Название файла');

  const onContextMenuChange = (e) => {
    action.onContextMenuChange(e, file, index);
  };

  const onChangeFolder = () => {
    action.onChangeFolder(file);
  };

  const onFocusFile = (e) => {
    action.onFocusFile(e, file, index);
  };

  const classes = classNames('storage__folder', {
    'storage__file-active': activeIndex === index || focusinFiles.some((el) => el.id === file?.id),
  });

  return (
    <div
      onContextMenu={onContextMenuChange}
      onDoubleClick={onChangeFolder}
      onClick={onFocusFile}
      className={classes}
      title={basename}
    >
      <Icon name="ELFolder" className="storage__folder-icon" />
      <Text variant="sm">{basename}</Text>
    </div>
  );
};

export default StorageFolder;
