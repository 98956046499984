export default {
  EN: {
    'events.name': 'Events',
    'events.overview': 'Overview',
    'events.active': 'Active',
    'events.completed': 'Completed',
    'events.archive': 'Archive',
    'events.srh.name': 'Enter event name',
    'events.not.found': 'No events found',
    'events.desc': 'Enter event description',
    'events.create': 'Create new event',
    'events.cover': 'Event cover',
    'events.cover.mini': 'Event short story',
    'events.author': 'Event author',
    'events.author.pls': 'Enter event author',
    'events.stt.pass': 'Test pass settings',
    'events.mrl': 'Materials after completion',
    'events.dates': 'Event dates',
    'events.start': 'Event start',
    'events.end': 'Event end',
    'events.site': 'Event site',
    'events.site.start': 'Launch event site',
    'events.site.start.lbl':
      'Create and launch an unique event site to provide event participants with needed information and to register for the event.',
    'events.site.start.lnk': 'Link to event site',
    'events.reg.usr': 'Participants registration',
    'events.reg.usr.on': 'Allow registration for the event',
    'events.reg.usr.lbl': 'Self-registration of participants for the event',
    'events.reg.usr.cnt': 'Number of event participants',
    'events.reg.usr.cnt.hint': 'Maximum number of participants allowed to register for event',
    'events.reg.usr.pay': 'Pay for participation',
    'events.reg.usr.confirm': 'Confirm participation',
    'events.reg.usr.confirm.hint': 'Confirm participation of each participant manually',
    'events.reg.usr.payment': 'Participation costs',
    'events.reg.usr.payment.pls': 'Enter participation costs',
    'events.site.start.hint':
      'This link leads to a separate page where your can register for an event and pay for participation, if it is not for free.If you would like to test it - open the link with another browser, or in a private window',
    'events.reg.usr.table': 'Registered for the event',
    'events.reg.time': 'Registration time',
    'events.reg.status': 'Confirmation',
    'events.sts.rqs': 'Request status:',
    'events.sts.wait': 'In process',
    'events.ended': 'Event ended',
    'events.mmb.i.s': 'Sent',
    'events.role.Reviewer': 'Reviewer',
    'events.role.Author': 'Author',
    'events.role.Editor': 'Editor',
    'events.ivt.lbl': 'Invited to event',
    'events.ivt.not.found': 'No invitations found',
    'events.ivt.is.cancel': 'Do you want to cancel the invitation for the event?',
    'events.ivt.is.repeat': 'Do you want to invite once again?',
    'events.ivt': 'Invite to an event',
    'events.str.prt.tlt': 'Create partition',
    'events.str.prt.desc': 'Enter description',
    'events.str.file.tlt': 'Information file',
    'events.str.ath.tlt': 'Title',
    'events.str.ath.tlt.pls': 'Write in the title',
    'events.str.ath.desc': 'Description',
    'events.str.ath.desc.pls': 'Attach description',
    'events.str.ath.acc.sett': 'Access settings',
    'events.str.ath.acc.sett.opt.event': 'Defined by event settings',
    'events.str.ath.acc.sett.opt.set': 'Defined separately',
    'events.str.ath.start.pbl': 'Start publication',
    'events.str.ath.end.pbl': 'end publication',
    'events.str.page.tlt': 'Information page',
    'events.str.ath.filling': 'Filling',
    'events.str.video.tlt': 'Videomaterial',
    'events.str.test.lbl': 'Go to test creation',
    'events.str.test.add': 'Add test',
    'events.str.test.add.lbl': 'Add test attachment art:',
    'events.str.test.add.copy': 'New copy',
    'events.str.test.add.copy.lbl': 'Separate exemplar of the test will be created.',
    'events.str.test.add.ath': 'Associated test',
    'events.str.test.add.ath.lbl': 'Test alteration in data base will lead to alterations in that associated test.',
    'events.str.test.stt.lbl': 'While attaching associated test, you can set access dates and priority of test pass.',
    'events.str.test.stt.lbl.2': 'You can change other settings in test-database only.',
    'events.str.homework.tlt': 'Homework',
    'events.str.homework.scr': 'Points for homework',
    'events.str.homework.scr.psg': 'Passing score',
    'events.str.brd.add': 'Add broadcasting',
    'events.str.brd.add.hint': 'Add link to broadcasting (YouTube,  Twitch) into the field or embed code',
    'events.str.part.del': 'Do you want to delete the partition?',
    'events.str.el.del': 'Do you want to delete the element?',
    'events.current': 'Current',
    'events.upcoming': 'Upcoming',
    'events.request': 'Requests',
    'events.usr.sort.completed': 'Completed',
    'events.usr.sort.completed.not': 'Not completed',
    'events.usr.sort.all': 'All',
    'events.usr.btn.tst.result': 'Show test result',
    'events.usr.btn.homework.view': 'View homework',
    'events.request.pay.tlt': 'Pay event participation',
    'events.request.amount': 'Costs',
    'events.request.pay.pts': 'Pay participation',
    'events.delete': 'Do you want to delete the event permanently?',
    'events.ath.homework.desc.pls': 'Attach homework description',
    'events.ivt.from.in': 'Sent at',
    'events.ivt.reg.lbl': 'You should sign in to the client in order to participate in an event',
    'events.ivt.add.lbl': 'Event will be added for the account',
    'events.ivt.confirm': 'Confirm invitation to the event?',
    'events.ivt.cancel': 'Decline invitation to the event?',
    'events.passing': 'Passing',
  },
};
