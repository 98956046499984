import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELPayments(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="29" height="26" fill="none" viewBox="0 0 29 26" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M20.844 7.64H4.49c-.052 0-.104 0-.156-.009C3.032 7.552 2 6.344 2 4.881 2 3.35 3.12 2.114 4.49 2.114h16.354c1.38 0 2.642.981 2.642 2.22M4.316 7.64H20.62c1.197 0 2.16.823 2.16 1.84M2 10.023v11.481c0 1.314.972 2.382 2.16 2.382h16.45c1.197 0 2.16-1.068 2.16-2.382M2 5.065v4.975m25.064 4.659c-.009.227 0 .551 0 .797v.823c0 1.279-.946 1.681-2.108 1.681h-7.219c-1.162 0-2.108-1.042-2.108-2.32v-.184c0-1.279.946-2.321 2.108-2.321h8.086m1.24-1.375v3.871m-1.25-2.496c.563 0 1.232-.525 1.232-1.278l.008-.56c0-1.13-.286-1.876-1.24-1.876"
      />
    </svg>
  );
}
