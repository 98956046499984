export default {
  DE: {
    'stats.start': 'Beginn',
    'stats.end': 'Ende',
    'stats.qs.all': 'Fragen insgesamt',
    'stats.att.best.time': 'Bestversuch Zeit',
    'stats.att.scr': 'Bestversuch Punkte',
    'stats.test.sts': 'Test Status',
    'stats.att.worst': 'Schlimmster Versuch',
    'stats.att.best': 'Bestversuch',
    'stats.awr.know.not': '"Ich weiss nicht" Antworten',
    'stats.awr.correct': 'Richtige Antworten',
    'stats.tbl.awr.correct': '- richtiger Antwort',
    'stats.tbl.awr.correct.no': '- falscher Antwort',
    'stats.tbl.awr.know.not': '- "Ich weiss nicht" Antwort',
    'stats.tbl.awr.skip': '- Frage überspringen',
    'stats.tbl.time': 'Zeit, Sek',
    'stats.dtl.tlt': 'Inhalt der Frage und Studentenantwort',
    'stats.dtl.scr': 'Zeit und Punkte',
    'stats.view.correct': 'Richtige Antwort anschauen',
    'stats.view.more': 'Detailiert anschauen',
    'stats.att.best.usr': 'Stärkster Versuch',
    'stats.att.worst.usr': 'Schwächster Versuch',
  },
};
