import './index.scss';
import React, { Component, FC, ReactElement, ReactNode } from 'react';
import Icon from '../Icon';

interface IProps {
  children?: ReactNode;
  onClick?: Function;
  className?: string;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  onClick?: Function;
  className?: string;
}

const field: IFields = {
  app: 't--panel',
  head: 't--panel__head',
  foot: 't--panel__foot',
  back: 't--panel__back',
  tour: 't--panel__tour',
};

const layoutPart: FC<IParts> | Function = (children, className, onClick): ReactElement | Function => (
  <div className={className} {...(onClick && { onClick: onClick })}>
    {children}
  </div>
);

const Head: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.head);

const Back: Function = ({ children, onClick }: IProps): ReactNode =>
  layoutPart(
    <>
      <Icon name="Arrow" />
      {children}
    </>,
    field.back,
    onClick
  );

const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

const Tour: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.tour);

class TPanel extends Component<IProps> {
  public static Head: Function = Head;

  public static Back: Function = Back;

  public static Foot: Function = Foot;

  public static Tour: Function = Tour;

  render(): JSX.Element {
    const { children } = this.props;

    return <div className={field.app}>{children}</div>;
  }
}

export default TPanel; //TODO [Deprecated]: Rework on one "Skeleton"
