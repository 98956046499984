import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { useToState } from '../../hooks';

interface IProps {
  data: Array<{
    head: any;
    body: any;
  }>;
  activeIndex?: number;
  className?: string;
}

const Accordion: FC<IProps> = function ({ data, activeIndex = null, className }): ReactElement {
  const [state, setState] = useToState({
    activeIndex: activeIndex !== null ? activeIndex : 0,
  });

  const onChange = (data, index) => () => {
    const { activeIndex } = state;

    setState({
      activeIndex: activeIndex !== index ? index : -1,
    });
  };

  const renderContent = () => {
    const { activeIndex } = state;

    return data.map((item, i) => {
      const [Component] = item?.head; //TODO

      const isActive = activeIndex === i;

      return (
        <div
          className={classNames('accordion__item', {
            'accordion__item--active': isActive,
          })}
          key={i}
        >
          {Component({
            onClick: onChange(item, i),
          })}
          {isActive && item.body}
        </div>
      );
    });
  };

  const classesAccordion = classNames('accordion', className);

  return <div className={classesAccordion}>{renderContent()}</div>;
};

export default Accordion;
