import './index.scss';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useErrorFields, useGetParams, useRouter, useSetParams, useToState } from '../../../../../hooks';
import translate from '../../../../../i18n/translate';
import { URI_GENERAL } from '../../../../../routes/uri';
import { IReduxStore } from '../../../../../store/serviceReducer';
import { uuid } from '../../../../../utils';
import { setAlert } from '../../../../app.actions';
import {
  Accordion,
  Button,
  CoverLazy,
  EditorText,
  Icon,
  Input,
  Prompt,
  Select,
  Text,
  TimeFrame,
} from '../../../../app.modules';
import { typesAccept } from '../../../../Storage/storage.types';
import { ExistTime } from '../../../../Tests/tests.modules';
import { testRead } from '../../../../Tests/tests.services';
import { typesView } from '../../../../Tests/tests.types';
import { setModal } from '../../../modal.actions';
import { MODALS_TRIGGER } from '../../../modal.types';

interface IProps {
  close(): void;
  children: ReactNode;
  data: any;
  testReadAction: any;
  setModalAction: any;
}

const TestsSettings: FC<IProps> = function ({ data, testReadAction, setModalAction }): ReactElement {
  const { history } = useRouter();

  const part: any = useGetParams('part');

  const testId = useGetParams('testId');

  const sync = useGetParams('sync');

  const modal = data[MODALS_TRIGGER.STRUCTURE.TESTS];

  const element = modal?.data[+part]?.data?.find((el) => testId && el?.params?.id === +testId);

  const [state, setState] = useToState({
    tests: [],
    descriptionTest: {
      name: '',
      description: '',
      coverFileId: null,
      miniCoverFileId: null,
      orderType: 'Sequential',
      jumpBetweenQuestions: false,
      lockAttemptQuestionAnswer: false,
      lockAttemptQuestions: false,
      noAnswer: false,
      maxTries: 0,
      attemptTime: 0,
      attemptInterval: 0,
      startDate: element?.startDate ?? null,
      endDate: element?.endDate ?? null,
      viewStatsType: '',
      viewCorrectAnswerType: '',
      // shuffleAnswers: false,
      // commentsViewType: 'None', //All, Correct, None
    },
    accessDates: !!element?.startDate || !!element?.endDate,
  });

  const onValidate = (value, key) => {
    switch (key) {
      case 'name':
        return value.length;
      default:
        return true;
    }
  };

  const dataViewResults = [
    {
      title: translate('g.forbidden'),
      params: typesView.DISABLED,
    },
    {
      title: translate('g.allowed'),
      params: typesView.ENABLED,
    },
    {
      title: translate('ts.allowed.after.test'),
      params: typesView.ENABLE_DAFTER_COMPLETION,
    },
    {
      title: translate('ts.allowed.period.test'),
      params: typesView.ENABLED_AFTER_END,
    },
  ];

  const [error, setError] = useErrorFields(state.descriptionTest, onValidate);

  useEffect(() => {
    if (testId) {
      testReadAction(+testId).then((response) => {
        setState({
          tests: response,
          descriptionTest: response,
          paramsPassing: {},
        });
      });
    }
  }, [testId]);

  const onConfirm = () => {
    const { descriptionTest } = state;

    const modal = data[MODALS_TRIGGER.STRUCTURE.TESTS];

    if (testId) {
      const params = {
        testId: +testId,
        type: 'Test',
        parentId: part && modal.data[+part].id,
        position: part && modal.data[+part].data.length,
        sync: sync !== 'false' ? true : false,
        startDate: descriptionTest.startDate,
        endDate: descriptionTest.endDate,
        testInfo: {
          name: descriptionTest.name,
          description: descriptionTest.description,
          coverFileId: descriptionTest.coverFileId,
          miniCoverFileId: descriptionTest.miniCoverFileId,
          orderType: descriptionTest.orderType,
          jumpBetweenQuestions: descriptionTest.jumpBetweenQuestions,
          lockAttemptQuestionAnswer: descriptionTest.lockAttemptQuestionAnswer,
          lockAttemptQuestions: descriptionTest.lockAttemptQuestions,
          noAnswer: descriptionTest.noAnswer,
          maxTries: descriptionTest.maxTries,
          attemptTime: descriptionTest.attemptTime,
          attemptInterval: descriptionTest.attemptInterval,
          startDate: descriptionTest.startDate ?? null,
          endDate: descriptionTest.endDate ?? null,
          viewStatsType: descriptionTest.viewStatsType,
          viewCorrectAnswerType: descriptionTest.viewCorrectAnswerType,
        },
      };

      modal.onConfirm(
        {
          ...data,
          indexUp: part && +part,
        },
        params
      );
    }
  };

  const onConfirmCopy = () => {
    const { descriptionTest } = state;

    const modal = data[MODALS_TRIGGER.STRUCTURE.TESTS];

    if (testId) {
      const params = {
        testId: +testId,
        type: 'Test',
        parentId: part && modal.data[+part].id,
        position: part && modal.data[+part].data.length,
        sync: sync !== 'false' ? true : false,
        testInfo: {
          name: descriptionTest.name,
          description: descriptionTest.description,
          coverFileId: descriptionTest.coverFileId,
          miniCoverFileId: descriptionTest.miniCoverFileId,
          orderType: descriptionTest.orderType,
          jumpBetweenQuestions: descriptionTest.jumpBetweenQuestions,
          lockAttemptQuestionAnswer: descriptionTest.lockAttemptQuestionAnswer,
          lockAttemptQuestions: descriptionTest.lockAttemptQuestions,
          noAnswer: descriptionTest.noAnswer,
          maxTries: descriptionTest.maxTries,
          attemptTime: descriptionTest.attemptTime,
          attemptInterval: descriptionTest.attemptInterval,
          startDate: descriptionTest.startDate ?? null,
          endDate: descriptionTest.endDate ?? null,
          viewStatsType: descriptionTest.viewStatsType,
          viewCorrectAnswerType: descriptionTest.viewCorrectAnswerType,
        },
      };

      modal.onConfirm(
        {
          ...data,
          indexUp: part && +part,
        },
        params
      );
    }
  };

  const onBack = () => {
    history.push(
      useSetParams({
        m: MODALS_TRIGGER.STRUCTURE.TESTS_ATTACH,
        part,
        testId,
        sync,
      })
    );
  };

  const onChangeTextField = (e, key) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState({
      descriptionTest: {
        ...state.descriptionTest,
        [key]: value,
      },
    });
  };

  const renderDateFrame = (className = 'settings-tests__row') => {
    const data = state.descriptionTest;

    const { accessDates } = state;

    return (
      accessDates && (
        <>
          <div className={className}>
            <TimeFrame
              size="md"
              label={translate('events.str.ath.start.pbl')}
              placeholder="Выберите дату и время"
              onChange={(e) => onChangeTextField(e, 'startDate')}
              value={data?.startDate ? new Date(data.startDate) : null}
              startDate={data?.startDate ? new Date(data.startDate) : null}
              endDate={data?.endDate ? new Date(data.endDate) : null}
              selectsStart
              error={!error.startDate}
            />
          </div>
          <div className={className}>
            <TimeFrame
              size="md"
              label={translate('events.str.ath.end.pbl')}
              placeholder="Выберите дату и время"
              onChange={(e) => onChangeTextField(e, 'endDate')}
              value={data?.endDate ? new Date(data.endDate) : null}
              startDate={data?.startDate ? new Date(data.startDate) : null}
              endDate={data?.endDate ? new Date(data.endDate) : null}
              minDate={data?.startDate ? new Date(data.startDate) : null}
              selectsEnd
              error={!error.endDate}
            />
          </div>
        </>
      )
    );
  };

  const onChangeSelectDate = (data) => {
    const { params } = data;

    setState({
      descriptionTest: {
        ...state.descriptionTest,
        startDate: params.startDate,
        endDate: params.endDate,
      },
      accessDates: params.showDate,
    });
  };

  const dataSelectDate = [
    {
      title: translate('events.str.ath.acc.sett.opt.event'),
      active: !state.accessDates,
      params: {
        showDate: false,
        startDate: modal?.event?.startDate,
        endDate: modal?.event?.endDate,
      },
    },
    {
      title: translate('events.str.ath.acc.sett.opt.set'),
      active: state.accessDates,
      params: {
        showDate: true,
        startDate: null,
        endDate: null,
      },
    },
  ];

  const renderSyncTemplate = () => {
    return (
      <div className="settings-tests__template settings-tests__template--default">
        <Text className="settings-tests__label">
          {translate('events.str.test.stt.lbl')}
          <br />
          {translate('events.str.test.stt.lbl.2')}
        </Text>
        <div className="settings-tests__field">
          <Select data={dataSelectDate} onChange={onChangeSelectDate} label={translate('events.str.ath.acc.sett')} />
        </div>
        {renderDateFrame('settings-tests__field')}
        <div className="settings-tests__panel">
          <Button variant="outlined" monochrome size="md" className="settings-tests__btn" onClick={onBack}>
            {translate('g.btn.back')}
          </Button>
          <Button size="md" onClick={onConfirm} className="settings-tests__btn">
            {translate('g.btn.add')}
          </Button>
        </div>
      </div>
    );
  };

  const onChangeFiled = (e, key, Fn: any = null) => {
    const value = e?.target ? e?.target.value : e;

    setState({
      descriptionTest: {
        ...state.descriptionTest,
        [key]: Fn ? Fn(value) : value,
      },
    });

    setError(key, value);
  };

  const openStorage = (key) => () => {
    setModalAction({
      code: MODALS_TRIGGER.STORAGE.OPEN,
      uuid: uuid(),
      params: {
        accept: [typesAccept.IMAGE],
        onConfirm(data) {
          onChangeFiled(data.id, key);
        },
      },
    });
  };

  const renderDescriptionTest = () => {
    const { descriptionTest } = state;

    return {
      head: [
        ({ onClick }) => (
          <h2 onClick={onClick} className="settings-tests__target">
            {translate('ts.desc')}
          </h2>
        ),
      ],
      body: (
        <>
          <div className="settings-tests__row">
            <Input
              autoFocus
              label={translate('g.name')}
              size="md"
              value={descriptionTest.name}
              onChange={(e) => onChangeFiled(e, 'name')}
              error={!error.name}
              placeholderNode={translate('ts.desc.plc')}
            />
          </div>
          <div className="settings-tests__row">
            {descriptionTest.description && (
              <EditorText
                label={translate('g.description')}
                onChange={(e) => onChangeFiled(e, 'description')}
                data={descriptionTest.description}
                error={!error.description}
                minHeight={100}
                placeholder="Введите описание тестирования"
              />
            )}
          </div>
          <div className="settings-tests__row">
            <CoverLazy
              value={descriptionTest.coverFileId}
              label={translate('ts.cover')}
              onClick={openStorage('coverFileId')}
              uri={URI_GENERAL.download(descriptionTest.coverFileId)}
              covering={<Icon name="CoverEvent" />}
            />
          </div>
          <div className="settings-tests__row">
            <CoverLazy
              value={descriptionTest.miniCoverFileId}
              label={translate('ts.mini.cover.field')}
              variant="mini"
              onClick={openStorage('miniCoverFileId')}
              uri={URI_GENERAL.download(descriptionTest.miniCoverFileId)}
              covering={<Icon name="CoverEvent" />}
            />
          </div>
          <div className="settings-tests__row">
            <Select data={dataSelectDate} onChange={onChangeSelectDate} label={translate('events.str.ath.acc.sett')} />
          </div>
          {renderDateFrame()}
        </>
      ),
    };
  };

  const onChangeSelect = (data, key) => {
    const { params } = data;

    setState({
      descriptionTest: {
        ...state.descriptionTest,
        [key]: params,
      },
    });

    setError(key, params);
  };

  const renderParamsPassingTest = () => {
    const { descriptionTest } = state;

    const dataOrderType = [
      {
        title: translate('g.consistently'),
        params: 'Sequential',
      },
      {
        title: translate('g.accidentally'),
        params: 'Random',
      },
    ];

    const dataLockType = [
      {
        title: translate('g.btn.yes'),
        params: true,
      },
      {
        title: translate('g.btn.no'),
        params: false,
      },
    ];

    const dataForbiddenType = [
      {
        title: translate('g.allowed'),
        params: true,
      },
      {
        title: translate('g.forbidden'),
        params: false,
      },
    ];

    const dataForbiddenReverseType = [
      {
        title: translate('g.allowed'),
        params: false,
        active: !data.lockAttemptQuestionAnswer,
      },
      {
        title: translate('g.forbidden'),
        params: true,
        active: data.lockAttemptQuestionAnswer,
      },
    ];

    return {
      head: [
        ({ onClick }) => (
          <h2 onClick={onClick} className="settings-tests__target">
            {translate('ts.passing')}
          </h2>
        ),
      ],
      body: (
        <>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.order.qs.hint')}>
              <Select
                data={dataOrderType}
                label={<>{translate('ts.order.qs')}</>}
                placeholder={dataOrderType.find((el) => el.params === descriptionTest.orderType)?.title}
                onChange={(data) => onChangeSelect(data, 'orderType')}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.fix.atm.hint')}>
              <Select
                data={dataLockType}
                label={translate('ts.fix.atm')}
                placeholder={dataLockType.find((el) => el.params === descriptionTest.lockAttemptQuestions)?.title}
                onChange={(data) => onChangeSelect(data, 'lockAttemptQuestions')}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.move.qs.hint')}>
              <Select
                data={dataForbiddenType}
                label={translate('ts.move.qs')}
                placeholder={dataForbiddenType.find((el) => el.params === descriptionTest.jumpBetweenQuestions)?.title}
                onChange={(data) => onChangeSelect(data, 'jumpBetweenQuestions')}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.change.aws.hint')}>
              <Select
                data={dataForbiddenReverseType}
                label={translate('ts.change.aws')}
                update={false}
                placeholder={
                  dataForbiddenReverseType.find((el) => el.params === descriptionTest.lockAttemptQuestionAnswer)?.title
                }
                onChange={(data) => onChangeSelect(data, 'lockAttemptQuestionAnswer')}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.change.nkw.hint')}>
              <Select
                data={dataForbiddenType}
                update={false}
                label={translate('ts.field.know.no')}
                placeholder={dataForbiddenType.find((el) => el.params === descriptionTest.noAnswer)?.title}
                onChange={(data) => onChangeSelect(data, 'noAnswer')}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <Select
              data={dataViewResults}
              label={translate('ts.see.results')}
              placeholder={dataViewResults.find((el) => el.params === descriptionTest.viewStatsType)?.title}
              onChange={(data) => onChangeSelect(data, 'viewStatsType')}
            />
          </div>
          <div
            className="settings-tests__row"
            style={{
              ...(descriptionTest.viewStatsType === typesView.DISABLED && {
                opacity: 0.5,
                pointerEvents: 'none',
              }),
            }}
          >
            <Select
              data={dataViewResults}
              label={translate('ts.see.correct')}
              placeholder={dataViewResults.find((el) => el.params === descriptionTest.viewCorrectAnswerType)?.title}
              onChange={(data) => onChangeSelect(data, 'viewCorrectAnswerType')}
            />
          </div>
        </>
      ),
    };
  };

  const renderParamsAttempts = () => {
    const { descriptionTest } = state;

    return {
      head: [
        ({ onClick }) => (
          <h2 onClick={onClick} className="settings-tests__target">
            {translate('ts.atm')}
          </h2>
        ),
      ],
      body: (
        <>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.count.atm.hint')}>
              <ExistTime
                type="attempt"
                label={translate('ts.count.atm')}
                time={{
                  minute: descriptionTest.maxTries,
                }}
                onChange={(e) => onChangeFiled(e, 'maxTries', Number)}
              />
            </Prompt>
          </div>
          <div className="settings-tests__row">
            <ExistTime
              label={translate('ts.time.atm')}
              time={{
                minute: descriptionTest.attemptTime,
              }}
              onChange={(e) => onChangeFiled(e, 'attemptTime', Number)}
            />
          </div>
          <div className="settings-tests__row">
            <Prompt label={translate('ts.interval.atm.hint')}>
              <ExistTime
                label={translate('ts.interval.atm')}
                time={{
                  minute: descriptionTest.attemptInterval,
                }}
                onChange={(e) => onChangeFiled(e, 'attemptInterval', Number)}
              />
            </Prompt>
          </div>
        </>
      ),
    };
  };

  const renderCopyTemplate = () => {
    return (
      <div className="settings-tests__template settings-tests__template--copy">
        <Accordion
          activeIndex={0}
          className="settings-tests__copy"
          data={[renderDescriptionTest(), renderParamsPassingTest(), renderParamsAttempts()]}
        />
        <div className="settings-tests__panel">
          <Button variant="outlined" monochrome size="md" className="settings-tests__btn" onClick={onBack}>
            {translate('g.btn.back')}
          </Button>
          <Button size="md" onClick={onConfirmCopy} className="settings-tests__btn">
            {translate('g.btn.add')}
          </Button>
        </div>
      </div>
    );
  };

  const renderTemplates = () => {
    const type = sync !== 'false' ? 'sync' : 'copy';

    const types = {
      sync: renderSyncTemplate,
      copy: renderCopyTemplate,
    };

    return types[type]();
  };

  return (
    <div className="settings-tests">
      <h1 className="settings-tests__title">{translate('ts.stt.tlt')}</h1>
      {renderTemplates()}
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  data: store.modal.data!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  testReadAction: bindActionCreators(testRead, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
  setModalAction: bindActionCreators(setModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TestsSettings);
