import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { MAIL } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string | Function;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...MAIL };
  }

  protected emailSendInvite(data: IRequestDataParse): void {
    const { VERIFY_EMAIL } = this.ROUTE;

    this.post(VERIFY_EMAIL, data);
  }

  public getEventInvitation(data: IRequestDataParse): void {
    const { EVENT_INVITATION_PARTICIPANT } = this.ROUTE;

    this.get(EVENT_INVITATION_PARTICIPANT, data);
  }

  public setEventInvitation(data: IRequestDataParse): void {
    const { EVENT_INVITATION_PARTICIPANT } = this.ROUTE;

    this.post(EVENT_INVITATION_PARTICIPANT, data);
  }

  public getEventInvitationMembers(data: IRequestDataParse): void {
    const { EVENT_INVITATION_MEMBERS } = this.ROUTE;

    this.get(EVENT_INVITATION_MEMBERS, data);
  }

  public setEventInvitationMembers(data: IRequestDataParse): void {
    const { EVENT_INVITATION_MEMBERS } = this.ROUTE;

    this.post(EVENT_INVITATION_MEMBERS, data);
  }

  public getAgencyInvitation(data: IRequestDataParse): void {
    const { AGENCY_INVITATION } = this.ROUTE;

    this.get(AGENCY_INVITATION, data);
  }

  public setAgencyInvitation(data: IRequestDataParse): void {
    const { AGENCY_INVITATION } = this.ROUTE;

    this.post(AGENCY_INVITATION, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
