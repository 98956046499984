import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';
import Text from '../Text';

interface IProps {
  value: number;
  label?: boolean;
  className?: string;
}

const Progress: FC<IProps> = ({ value, label = false, className }) => {
  const renderProgressBar = () => {
    const style = {
      width: `${value}%`,
    };

    return (
      <div className="progress__bar">
        <div className="progress__bar-indicator" style={style} />
      </div>
    );
  };

  const renderProgressLabel = () => {
    return label && <Text className="progress__label">{value}%</Text>;
  };

  const classesProgress = classNames('progress', className);

  return (
    <div className={classesProgress}>
      {renderProgressBar()}
      {renderProgressLabel()}
    </div>
  );
};

export default Progress;
