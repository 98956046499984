import { useEffect, useRef, useState } from 'react';

export default (): any => {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);

  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const { current } = ref as any;

      if (current) {
        current.addEventListener('mouseenter', handleMouseOver);
        current.addEventListener('mouseleave', handleMouseOut);

        return () => {
          current.removeEventListener('mouseenter', handleMouseOver);
          current.removeEventListener('mouseleave', handleMouseOut);
        };
      }
    },
    [ref] // Recall only if ref changes
  );

  return [ref, value];
};

//example:
// const [ref, isHovered] = useHover();
// <div ref={ref}>
//   {isHovered ? '😁' : '☹️'}
// </div>
