import './index.scss';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { typesFile } from '../../../components/AttachTriple/attach.types';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { AttachTriple, Button, Text } from '../../app.modules';
import { questionUpdateVideo } from '../../BuilderQuestions/builder.questions.services';
import Bridge from '../../BuilderQuestions/BuilderQuestionsCreate/questions.create.pipes';
import { setModal } from '../modal.actions';

interface IProps {
  data: any;
  onConfirm: any;
  onDestroy: any;
  setModalAction: any;
  questionUpdateVideoAction: any;
  setAlertAction: any;
}

const ModalQuestionVideo: FC<IProps> = function ({
  data,
  onConfirm,
  onDestroy,
  setAlertAction,
  questionUpdateVideoAction,
}): ReactElement {
  const [state, setState] = useToState({
    dataServe: null,
    attachment: false,
  });

  const onChangeAttach = (data) => {
    setState({
      dataServe: { ...data },
    });
  };

  const onSend = () => {
    const requestData = Bridge.onRequestData(data.params.questionData);

    setState({
      attachment: true,
    });

    questionUpdateVideoAction(requestData.id, state.dataServe.video)
      .then(() => {
        onConfirm(state.dataServe.video, 'video');

        setAlertAction({
          code: '080104',
          params: {},
        });
      })
      .catch(() => {
        setAlertAction({
          code: '089999',
          uuid: uuid(),
          params: {},
        });
      });
  };

  const renderLoadingTemplate = () => {
    return <Text className="modal-question__desc">Добавление, подождите...</Text>;
  };

  const renderContentTemplate = () => {
    return (
      <>
        <Text className="modal-question__desc">{translate('qs.video.upload.lbl')}</Text>
        <AttachTriple
          label={translate('qs.video.add')}
          defaultValue={{
            [typesFile.VIDEO]: data.params.questionData?.video,
          }}
          type={typesFile.VIDEO}
          onChange={onChangeAttach}
        />
        <div className="modal-question__panel">
          <Button variant="outlined" size="md" monochrome onClick={onDestroy}>
            {translate('g.btn.cancel')}
          </Button>
          <Button size="md" onClick={onSend} off={!state.dataServe}>
            {translate('g.btn.add')}
          </Button>
        </div>
      </>
    );
  };

  return (
    <div className="modal-question modal-question--video">
      <h1 className="modal-question__title">{translate('qs.video.tlt')}</h1>
      {state.attachment ? renderLoadingTemplate() : renderContentTemplate()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
  questionUpdateVideoAction: bindActionCreators(questionUpdateVideo, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(ModalQuestionVideo);
