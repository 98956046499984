import { FC, ReactElement } from 'react';
import types from './types';

interface IProps {
  name?: string;
  className?: string;
}

const Loader: FC<IProps> = function (props): ReactElement {
  const { name } = props;

  return types[name ?? 'Mount'](props);
};

export default Loader;
