import React from 'react';

interface IProps {
  className?: string;
}

export default function ELLearn(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23" fill="none" viewBox="0 0 18 23" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.521 1A6.505 6.505 0 001 7.518l.028 1.194c.312 3.704 3.713 6.603 7.893 6.603s7.58-2.899 7.892-6.603l.025-1.194C16.912 3.907 13.932 1 10.32 1H7.521z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M14.396 2.355c-3.838.49-5.457 2.943-5.526 6.612-.03-3.677-1.888-6.284-5.428-6.575"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M14.303 7.036a1.018 1.018 0 10-2.035 0 1.018 1.018 0 002.035 0zm-8.785 0a1.017 1.017 0 10-2.034 0 1.017 1.017 0 002.034 0z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M16.831 7.926l.022 13.055c0 .553-.446 1.005-1 1.012-3.075.04-7.63.041-11.274-3.686C.96 14.606 1.01 7.091 1.01 7.091"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M13.12 14.146s-.71 2.04.13 4.401c.817 2.3 2.686 3.205 2.686 3.205"
      />
    </svg>
  );
}
