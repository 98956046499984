import { ITestsAction, ITestsState } from './tests.resources';
import types from './tests.types';

const initialState: ITestsState = {
  resources: {},
};

export default function testsReducer(state: ITestsState = initialState, action: ITestsAction): ITestsState {
  const actionType = {
    [types.RESOURCES_TESTS]: {
      ...state,
      resources: action.resources,
    },
  };

  return actionType[action.type] ?? state;
}
