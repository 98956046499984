import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { useToState } from '../../hooks';
import Button from '../Button';
import Icon from '../Icon';
import Square from '../Square';
import Text from '../Text';

interface IProps {
  label?: ReactNode;
  className?: string;
  onChange?(data: any): void;
  preview?: {
    type: 'image';
    url: string;
    id?: number;
  };
  defaultValue?: any;
}

const FileFields: FC<IProps> = function ({ className = '', label, onChange, preview }): ReactElement {
  const [state, setState] = useToState({
    dataFile: [],
  });

  const onChangeAttach = (e) => {
    const { files } = e.target;

    const dataFile = Object.keys(files).map((key) => files[key]);

    setState({
      dataFile,
    });

    onChange && onChange(dataFile);
  };

  const onDeleteFile = () => {
    setState({
      dataFile: [],
    });

    onChange && onChange([]);
  };

  const renderFiles = () => {
    return (
      state.dataFile.length > 0 && (
        <Text className="file-field__attach">
          {[...state.dataFile].map((el) => (
            <Square variant="lg" auto key={el.name} className="file-field__attach-file">
              <span className="file-field__attach-name">{el.name}</span>
            </Square>
          ))}
          <Icon name="Trash" className="file-field__attach-trash" onClick={onDeleteFile} />
        </Text>
      )
    );
  };

  const renderValue__Image = () => {
    return (
      preview?.id && (
        <div className="file-field__preview-image">
          <img src={preview?.url} alt="" className="file-field__image" />
        </div>
      )
    );
  };

  const renderValue__Default = () => {
    return null;
  };

  const renderTypesPreview = () => {
    const types = {
      image: renderValue__Image,
      default: renderValue__Default,
    };

    const typeParse = types[preview?.type ?? 'default']();

    return typeParse && <div className="file-field__preview">{typeParse}</div>;
  };

  const renderInput: Function = (): ReactNode => {
    const classes = classNames('file-field', className);

    return (
      <div className={classes}>
        <div className="file-field__polygon">
          {preview && renderTypesPreview()}
          {renderFiles()}
          <label>
            <Button className="file-field__button" size="md" variant="outlined" monochrome>
              <Icon name="ELAddLightCircle" />
              Загрузить
              <input className="file-field__input" type="file" required multiple onChange={onChangeAttach} />
            </Button>
          </label>
        </div>
        {label && <Text className="file-field__label">{label}</Text>}
      </div>
    );
  };

  return renderInput();
};

export default FileFields;
