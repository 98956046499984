export default {
  DE: {
    'agency.p.tlt': 'Grundinformation',
    'agency.p.name.plh': 'Geben Sie Agenturnamen ein',
    'agency.p.name.dmn': 'Schreiben Sie Domainname der Agentur ein',
    'agency.p.pay': 'Zahlungseinstellungen',
    'agency.p.pay.lbl.tlt': 'UKassa Wallet anschliessen',
    'agency.p.pay.lbl.dc': 'Zahlungsannahme über UKassa Wallet erlauben',
    'agency.p.pay.number.name': 'Geschäftsnummer',
    'agency.p.pay.number.plh': 'Geschäftsnummer eingeben',
    'agency.p.pay.number.hint':
      'Geben Sie Geschäftsnummer aus der Einstellungen der UKassa-Profile an welcher das Geld eines Absenders eingezahlt werden muss ein',
    'agency.p.pay.key.name': 'Privatschlüssel',
    'agency.p.pay.key.plh': 'Geben Sie Privatschlüssel ein',
    'agency.p.pay.key.hint': 'Geben Sie Privatschlüssel ein, den Sie im UKassa Profil erworben haben',
    'agency.p.pay.check.name': 'Quittungstransfer',
    'agency.p.pay.check.hint':
      'Einstellung für Übergabe der digitalen Quittungen zur Bearbeitung an der Seite des Zahlungssystems über eine Sammelstelle f.fiskalische Daten bei der zugeschalteten Online-Kasse',
    'agency.sw.tlt': 'Agenturauswahl',
    'agency.staff': 'Mitarbeiter',
    'agency.staff.tlt': 'Agenturmitarbeiter',
    'agency.staff.lbl': 'Laden Sie einen neuen Benutzer ein und erteilen Sie ihn eine Rolle.',
    'agency.staff.ivt': 'In die Agentur eingeladen',
    'agency.usr.chg.role': 'Rolle ändern',
    'agency.profile': 'Agenturprofil',
    'agency.switch': 'Agentur wechseln',
  },
};
