import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { BUILDER_TEST } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...BUILDER_TEST };
  }

  public questionCreate(data: IRequestDataParse): void {
    const { QUESTIONS_CREATE } = this.ROUTE;

    this.post(QUESTIONS_CREATE, data);
  }

  public getQuestions(data: IRequestDataParse): void {
    const { QUESTIONS_READ } = this.ROUTE;

    this.get(QUESTIONS_READ, data);
  }

  public questionUpdate(data: IRequestDataParse): void {
    const { QUESTIONS } = this.ROUTE;

    this.patch(QUESTIONS, data);
  }

  public questionUpdateAudio(data: IRequestDataParse): void {
    const { QUESTIONS_ADD_ADUIO } = this.ROUTE;

    this.patch(QUESTIONS_ADD_ADUIO, data);
  }

  public questionUpdateVideo(data: IRequestDataParse): void {
    const { QUESTIONS_ADD_VIDEO } = this.ROUTE;

    this.patch(QUESTIONS_ADD_VIDEO, data);
  }

  public questionDelete(data: IRequestDataParse): void {
    const { QUESTIONS } = this.ROUTE;

    this.delete(QUESTIONS, data);
  }

  protected questionCopy(data: IRequestDataParse): void {
    const { QUESTIONS_COPY } = this.ROUTE;

    this.post(QUESTIONS_COPY, data);
  }

  public questionPosition(data: IRequestDataParse): void {
    const { QUESTIONS_POSITION } = this.ROUTE;

    this.patch(QUESTIONS_POSITION, data);
  }

  public questionCreateAnswer(data: IRequestDataParse): void {
    const { QUESTIONS_CREATE_ANSWER } = this.ROUTE;

    this.post(QUESTIONS_CREATE_ANSWER, data);
  }

  public questionDeleteAnswer(data: IRequestDataParse): void {
    const { QUESTIONS_DELETE_ANSWER } = this.ROUTE;

    this.delete(QUESTIONS_DELETE_ANSWER, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
