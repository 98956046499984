import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { uuid } from '../../utils';
import Portal from '../Portal';

interface IProps {
  children: ReactNode;
  close?(element: Element): void;
  notEvent?: Array<string>;
}

const Modal: FC<IProps> = ({ children, close, notEvent }): ReactElement => {
  const generateKey = uuid();

  const $selector = classNames('modal', generateKey);

  const onNotEvent = () => {
    if (!notEvent?.length) return '';

    const transform = classNames(notEvent).split(' ').join(', ');

    return `, ${transform}`;
  };

  const onCloseModal = (e) => {
    // e.stopPropagation();
    const { target } = e as any;

    const isModal = target.classList.contains(generateKey);

    if (isModal && !target.closest(`.modal__container${onNotEvent()}`) && !target.closest('[class*=__selection]')) {
      close && close(target);
    }
  };

  return (
    <Portal close={close}>
      <div className={$selector} onClick={onCloseModal}>
        <div className="modal__container">{children}</div>
      </div>
    </Portal>
  );
};

export default Modal;
