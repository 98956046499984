export default {
  DE: {
    'expand.rate': 'Tarif',
    'expand.payment.history': 'Zahlungsgeschichte',
    'expand.opportunities': 'Features erweitern',
    'expand.type.status.plan.active': 'Aktueller Tarifplan',
    'expand.type.status.plan.pending': 'Tarif verbessern',
    'expand.type.status.plan.next': 'Nächster Tarifplan',
    'expand.type.status.plan.request': 'Individuelle Berechnung',
    'expand.request.sent': 'Anfrage abgeschickt',
    'expand.request.canceled': 'Anfrage zurückgezogen',
    'expand.request.payment': 'Wartet auf Bezahlung',
    'expand.time.left': 'Zeit geblieben',
    'expand.unlimited': 'Unbegrenzt',
    'expand.about.collapse': 'Zusammenklappen',
    'expand.about.tariff': 'Mehr zum Tarif',
    'expand.participants': 'Teilnehmer',
    'expand.change.tariff': 'Tarif wechseln',
    'expand.more': 'Mehr',
    'expand.plan': 'Tarifplan verbessern',
    'expand.per.month': 'mönatlich',
    'expand.period.year': '*jährlich',
    'expand.period': 'Periode',
    'expand.expire': 'Wählen Sie Gültigkeitsperiode für den Tarifplan ',
    'expand.month': 'Mon',
    'expand.year': 'Jahr',
    'expand.payable': 'Insgesamt zum Bezahlen:',
    'expand.yu.money': 'Zum Bezahlen werdern Sie auf UMoney System Server umgeleitet',
    'expand.pay.notfound': 'Keine Zahlungen gefunden',
    'expand.type.Базовый': 'Base',
    'expand.type.Стандарт': 'Standart',
    'expand.type.PRO': 'PRO',
    'expand.cancel.pay': 'Bezahlung abbrechen?',
    'expand.cancel.send': 'Anfrage abbrechen?',
    'expand.canceled.pay': 'Bezahlung abgebrochen',
    'expand.id.quote': 'Individuelle Berechnung bekommen',
    'expand.contact': 'Unser Kundenmanager kontaktiert Sie in Kürze!',
    'expand.up': 'Tarif verbessern',
    'expand.up.text':
      'Neue Verlängerungsbedingungen treten in Kraft nach dem die Gültigkeitsperiode  des aktuellen Tarifs enden wird',
    'expand.next': 'Tarif verlängern',
    'expand.next.text':
      'Wenn Sie die Tarifparameter ändern sollen, tut Edbee den Rest den bezahlten Tage gemäß neuen Tarifbedingungen umrechnen',
    'expand.event': 'Maßnahmenerstellung',
    'expand.test': 'Ausbildung der Testaufgaben',
    'expand.roles': 'Rollenzusammensteller',
    'expand.delegation': 'Aufgabendelegierung an Mitarbeiter',
    'expand.subdomain': 'Details zur Erstellung einer unikalen Subdomain',
    'expand.groups': 'Erstellung und Verwaltung der Lerngruppen',
    'expand.storage': 'Cloud-Speicher f. Dateien und Multimedia',
    'expand.homework': ' "Hausaufgaben" Modul',
    'expand.support': 'Technische Unterstützung',
  },
};
