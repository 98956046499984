import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import Icon from '../Icon';

interface IProps {
  className?: string;
  variant?: string;
  label?: ReactNode;
  children?: ReactNode;
  covering?: ReactNode;
  onClick?: any;
  value?: any;
  uri?: string;
}

const Cover: FC<IProps> = function ({
  className = '',
  variant = 'primary',
  children,
  label,
  onClick,
  value,
  uri,
  covering = <Icon name="City" />,
}): ReactElement {
  const renderCover: Function = (): ReactNode => {
    const renderAddFiles = () => {
      return value ? <img src={uri} alt="" /> : covering;
    };

    const classes = classNames(`cover--${variant}`, className);

    return (
      <div className={classes}>
        <div className={`cover--${variant}__polygon`} onClick={onClick}>
          {renderAddFiles()}
        </div>
        {label && <label>{label}</label>}
        {children}
      </div>
    );
  };

  return renderCover();
};

export default Cover;
