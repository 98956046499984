import classNames from 'classnames';
import React, { FC } from 'react';
import { useToState } from '../../../../../hooks';
import translate from '../../../../../i18n/translate';
import { Icon, Square, Textarea } from '../../../../app.modules';
import Scores from '../../CreateScores';
import Bridge from '../../questions.create.pipes';

interface IProps {
  data: any;
  question: any;
  onChange(e, data: any): void;
}

const FreeAnswer: FC<IProps> = ({ data, onChange, question }) => {
  const [state, setState] = useToState({
    isComment: false,
    valueAnswer: data.text || '',
    valueComment: data.comment || '',
    valueScore: data.score || 0,
  });

  const onToggleComment = () => {
    setState((state) => ({
      isComment: !state.isComment,
    }));
  };

  const onChangeTextarea = (e) => {
    const { value } = e?.target ?? { value: e };

    const isNumber = question?.studentQuestionAnswers?.type === 'NumberAnswer'; //TODO [!!!]

    const isValid = isNumber ? value.replace(/[^\d.]/g, '') : value;

    setState({
      valueAnswer: isValid,
    });

    onChange(data, { text: isValid });
  };

  const onChangeTextComment = (e) => {
    const { value } = e?.target ?? { value: e };

    setState({
      valueComment: value,
    });

    onChange(data, { comment: value });
  };

  const onChangeTextScore = (e) => {
    const { value } = e.target;

    const isValid = value.replace(/[^\d.]/g, '');

    setState({
      valueScore: isValid,
    });

    onChange(data, { score: isValid });
  };

  const onAnswerTextTypes = () => {
    const types = {
      TextAnswer: {
        error: !state.valueAnswer.length,
        errorText: 'Введите текстовой ответ',
      },
      NumberAnswer: {
        error: isNaN(state.valueAnswer),
        errorText: 'Введите число',
      },
    };

    return Bridge.parseAnswerTextError(question) && types[data?.type ?? 'TextAnswer'];
  };

  const classesIconChat = classNames({
    'comment--active': state.isComment,
  });

  const classesComments = classNames('answer-free__comment', {
    'answer-free__comment--active': data.comment !== '',
  });

  const isErrorAnswerText = !state.valueAnswer && Bridge.parseAnswerTextError(question);

  const isErrorScores = !+state.valueScore && !+data.score && Bridge.parseScoresError(question);

  return (
    <div className="answer-free">
      <div className="answer-free__entry">
        <Textarea
          errorText={isErrorAnswerText ? translate('qs.err.awr.txt') : onAnswerTextTypes()?.errorText}
          error={isErrorAnswerText || onAnswerTextTypes()?.error}
          variant="answer"
          value={state.valueAnswer}
          onChange={onChangeTextarea}
          placeholderNode={translate('learning.inp.txt.pls')}
        />
        <Scores
          errorText={translate('qs.err.scr.txt')}
          error={isErrorScores}
          data={state.valueScore}
          onChange={onChangeTextScore}
        />
        <Square size="lg" className={classesIconChat} revert onClick={onToggleComment}>
          {data.comment !== '' ? <Icon name="Chat" /> : <Icon name="ChatEmpty" />}
        </Square>
      </div>
      {state.isComment && (
        <div className={classesComments}>
          <Textarea
            variant="comment"
            value={data.comment}
            onChange={onChangeTextComment}
            placeholderNode={translate('qs.cmt.pls')}
          />
        </div>
      )}
    </div>
  );
};

export default FreeAnswer;
