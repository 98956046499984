import api from './support.api';

export function sendSupportData(data: any): Promise<any> {
  const sideEffect = () =>
    api.sendSupportData({
      params: data,
    });

  return sideEffect as any;
}
