export default {
  DE: {
    'storage.tlt': 'Filemanager',
    'storage.free': 'Frei',
    'storage.file.type.all': 'Alle Dateitypen (*.*)',
    'storage.file.s': 'Dateien',
    'storage.image.s': 'Bilder (jpg / jpeg, png, tiff)',
    'storage.book.s': 'Bücher',
    'storage.video.s': 'Videodateien (mp4, avi, WMA, WebM, MKV, RM, RMVB, MOV, MPG, FLV, WMV, ASF, TS, 3G2, 3GP, OGV)',
    'storage.audio.s': 'Audiodateien (mp3, WMA)',
    'storage.crt.folder': 'Ordner erstellen',
    'storage.crt.folder.pls': 'Namen eingeben',
    'storage.upload.tlt': 'Wird hochgeladen',
    'storage.upload.not.tlt': 'Dateihochladen stoppen?',
    'storage.upload.err.copy': 'Eine Datei mit diesen Namen existiert bereits.',
    'storage.upload.end': 'Hochladen abgeschlossen!',
    'storage.upload.repeat': 'Hochladen wiederholen',
    'storage.delete.cnf': 'Möchten Sie tatsächlich unwiederruflich löschen?',
  },
};
