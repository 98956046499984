import React from 'react';

interface IProps {
  className?: string;
}

const ArrowSwipe = ({ className }: IProps) => {
  const params = { className };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" fill="none" viewBox="0 0 8 12" {...params}>
      <path fill="var(--el-fonts--icon-color)" d="M8 10.59L3.055 6 8 1.41 6.478 0 0 6l6.478 6L8 10.59z" />
    </svg>
  );
};

export default ArrowSwipe;
