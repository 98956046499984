import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function CloseBold(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M9.094 7l4.454-4.455A1.479 1.479 0 0012.507 0a1.48 1.48 0 00-1.052.452L7 4.906 2.545.452A1.48 1.48 0 10.452 2.545l4.454 4.454-4.454 4.456a1.48 1.48 0 102.093 2.093l4.454-4.454 4.456 4.454a1.478 1.478 0 002.111.018 1.48 1.48 0 00-.018-2.11L9.094 7z"
      />
    </svg>
  );
}
