import React from 'react';

interface IProps {
  className?: string;
}

export default function City(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="279" height="190" fill="none" viewBox="0 0 279 190" {...props}>
      <path
        fill="#F5F9FD"
        d="M16.686 150.773s-18.819 29.011 25.963 32.555c44.781 3.517 56.787-.879 64.425-1.758 7.637-.879 29.891-17.171 67.556 3.077 25.496 13.709 52.557-2.198 65.112-11.896 12.556-9.698 20.193-20.248 14.204-22.006-5.989-1.758-237.26.028-237.26.028z"
      />
      <path
        fill="#F5F9FD"
        d="M15.917 151.624s-26.704-39.396-10.934-54.672C20.945 81.485-.073 62.144 6.988 39.588 12.922 20.522 49.352-10.77 82.76 3.708c46.127 19.973 45.907 24.369 74.315 19.451 28.407-4.918 61.32-31.677 77.31 1.978 9.478 19.946 35.523 24.314 41.897 42.886 6.373 18.572-3.215 41.787-8.49 46.87-18.654 18.022-12.28 37.473-12.28 37.473l-239.595-.742z"
      />
      <path fill="#fff" d="M143.202 61.127a7.94 7.94 0 007.939-7.94 7.94 7.94 0 10-15.879 0 7.94 7.94 0 007.94 7.94z" />
      <path
        fill="#fff"
        d="M151.856 60.028a5.88 5.88 0 100-11.758 5.88 5.88 0 000 11.758zm56.924-2.555a7.28 7.28 0 100-14.561 7.28 7.28 0 000 14.561z"
      />
      <path
        fill="#fff"
        d="M200.648 56.347a5.385 5.385 0 100-10.77 5.385 5.385 0 000 10.77zm17.692.687a5.385 5.385 0 100-10.77 5.385 5.385 0 000 10.77zM32.566 61.65a7.28 7.28 0 100-14.561 7.28 7.28 0 000 14.56z"
      />
      <path fill="#fff" d="M24.654 58.874a5.385 5.385 0 100-10.77 5.385 5.385 0 000 10.77z" />
      <path fill="#E9F0FA" d="M34.818 40.494l.275 110.388h87.64l-.275-110.113-87.64-.275z" />
      <path
        fill="#BACDF2"
        d="M30.973 30.302l94.233.274.275 10.193H30.973V30.302zM125.206 61.1H30.973v3.021h94.233V61.1z"
      />
      <path
        fill="#BACDF2"
        d="M122.184 63.847h-87.09v3.022h87.09v-3.022zm-.275-23.902h-87.09v3.022h87.09v-3.022zm3.846 110.69H31.522v3.022h94.233v-3.022zM43.61 46.539l-3.022 3.296v4.67l2.747 2.748 70.607.275 2.747-2.473v-5.22l-3.297-3.296H43.61zm-8.792 27.198h5.495v3.572h-5.22l-.275-3.572zm0 35.715h3.297v3.572h-3.022l-.275-3.572zm38.464-5.22H64.49v3.297h8.792v-3.297zm-5.221 5.495h-8.792v3.297h8.792v-3.297zM63.803 89.48h-8.791v3.296h8.791v-3.297zm20.193 20.522h-8.79v3.297h8.79v-3.297zM68.418 70.495h-8.791v3.297h8.791v-3.297zm49.37 37.584h-8.792v3.296h8.792v-3.296zm1.373-40.386h-8.792v3.297h8.792v-3.297z"
      />
      <path
        fill="#BACDF2"
        d="M100.893 119.041h15.962c.522 0 .934.412.934.934v30.577a.927.927 0 01-.934.935h-15.962a.927.927 0 01-.934-.935v-30.55c0-.522.412-.961.934-.961z"
      />
      <path
        fill="#BACDF2"
        d="M102.596 122.695h12.583c.522 0 .934.412.934.934v3.324a.926.926 0 01-.934.934h-12.583a.926.926 0 01-.934-.934v-3.324c0-.495.412-.934.934-.934zM39.901 84.726h1.786l20.028-.027s1.099-6.044-3.297-9.616c-4.395-3.571-8.956-3.571-12.253-1.923-3.297 1.648-6.374 4.149-6.264 11.566z"
      />
      <path fill="#BACDF2" d="M41.687 84.726L41.55 99.37h18.626v-15l-18.764-.303.275.66z" />
      <path fill="#BACDF2" d="M41.549 99.37l-1.236-.083v2.473h21.072l-.083-2.473-19.753.083z" />
      <path
        fill="#fff"
        d="M50.369 85.55h-6.512v5.99h6.512v-5.99zm7.528-.082h-6.21v5.99h6.21v-5.99zm.11 7.555H51.66v5.99h6.347v-5.99zm-7.557 0h-6.51v5.99h6.51v-5.99z"
      />
      <path
        fill="#ADC5F3"
        d="M42.539 75.798l-.77 8.983 3.737-.137.742-11.484-2.336 1.374-1.099.961m16.621.495l.687 8.791-3.407-.137-.66-11.154 2.089 1.373 1.016.825m-11.703-.055l-.522 9.066 3.269.055.714-12.638-1.4.055-1.704.302m7.282 3.132l.44 9.039-2.913.055-.604-12.528 1.51.33 1.292.412"
      />
      <path
        fill="#BACDF2"
        d="M67.731 84.919h1.786l20.028-.028s1.1-6.044-3.297-9.615c-4.395-3.572-8.956-3.572-12.253-1.923C70.698 75 67.621 77.5 67.731 84.919z"
      />
      <path fill="#BACDF2" d="M69.544 84.919l-.164 14.643h18.654v-15l-18.792-.302.302.659z" />
      <path fill="#BACDF2" d="M69.379 99.562l-1.236-.082v2.472h21.1l-.11-2.472-19.754.082z" />
      <path
        fill="#fff"
        d="M78.226 85.743h-6.511v5.99h6.511v-5.99zm7.528-.083h-6.21v5.99h6.21v-5.99zm.083 7.556H79.49v5.989h6.347v-5.99zm-7.557 0h-6.51v5.989h6.51v-5.99z"
      />
      <path
        fill="#ADC5F3"
        d="M70.369 75.99l-.77 8.984 3.737-.138.742-11.483-2.335 1.373-1.1.962m16.621.494l.687 8.792-3.407-.11-.66-11.182 2.089 1.374 1.016.824m-11.703-.055l-.522 9.066 3.269.028.714-12.61-1.4.055-1.704.302m7.282 3.132l.44 9.038-2.913.055-.604-12.528 1.51.33 1.292.412"
      />
      <path
        fill="#BACDF2"
        d="M95.206 84.919h1.785l20.028-.028s1.099-6.044-3.296-9.615c-4.396-3.572-8.957-3.572-12.254-1.923-3.296 1.648-6.4 4.148-6.263 11.566z"
      />
      <path fill="#BACDF2" d="M96.992 84.919l-.165 14.643h18.654v-15L96.69 84.26l.302.659z" />
      <path fill="#BACDF2" d="M96.826 99.562l-1.209-.082v2.472h21.072l-.11-2.472-19.753.082z" />
      <path
        fill="#fff"
        d="M105.673 85.743h-6.51v5.99h6.51v-5.99zm7.528-.083h-6.209v5.99h6.209v-5.99zm.11 7.556h-6.346v5.989h6.346v-5.99zm-7.583 0h-6.511v5.989h6.511v-5.99z"
      />
      <path
        fill="#ADC5F3"
        d="M97.816 75.99l-.742 8.984 3.709-.138.769-11.483-2.362 1.373-1.072.962m16.593.494l.687 8.792-3.407-.11-.659-11.182 2.115 1.374.99.824m-11.704-.055l-.495 9.066 3.242.028.714-12.61-1.401.055-1.703.302m7.28 3.132l.467 9.038-2.94.055-.604-12.528 1.538.33 1.264.412"
      />
      <path
        fill="#BACDF2"
        d="M39.984 126.623h4.753l53.024-.027s4.313-6.676-7.335-10.33c-11.65-3.654-30.77-2.61-39.48-.934-8.709 1.676-11.291 3.709-10.962 11.291z"
      />
      <path fill="#BACDF2" d="M40.67 126.761l-.467 23.929h57.392v-24.534l-57.859-.494.934 1.099z" />
      <path
        fill="#fff"
        d="M94.024 127.09H43.445v12.198h50.579V127.09zm-18.188 14.149h-14.56v5.989h14.56v-5.989zm-17.472.11H43.803v5.989h14.56v-5.989zm35.166 0H78.969v5.989h14.56v-5.989z"
      />
      <path
        fill="#ADC5F3"
        d="M45.34 117.255l-1.043 8.654 4.945-.11.88-10.495-2.886.687-1.62.659m7.884.687l-.824 8.49 4.972-.083.88-11.484-2.418.275-2.17.22m36.539 2.417l.796 8.654-3.873-.109-.687-10.468 2.28.687 1.236.659m-6.207.687l.687 8.462-4.149-.055-.742-11.484 2.006.275 1.84.192m-7.254 2.528l.715 8.489-4.149-.055-.769-11.786 1.95.11 1.869.082m-8.598 3.49l.687 8.461-4.121-.055-.77-11.758 2.061-.083 1.923-.027"
      />
      <path fill="#E9F0FA" d="M129.519 97.941l-.248 55.002 92.943.247V97.447l-92.695.494z" />
      <path fill="#BACDF2" d="M222.983 150.387H128.75v3.023h94.233v-3.023zm4.45-52.473H124.381v3.654h103.052v-3.654z" />
      <path
        fill="#BACDF2"
        d="M126.029 98.49s1.099-16.374 11.814-16.676c8.819-.247 54.067-.027 69.645.11 9.176.082 17.033 6.868 18.16 15.99.027.192.054.412.054.604l-99.673-.028zm90.498 24.204h5.495v3.572h-5.22l-.275-3.572zm2.28-14.918h3.296v3.572h-3.022l-.274-3.572zm-76.459 26.677h-8.791v3.297h8.791v-3.297zm-3.103-14.506h-8.792v3.297h8.792v-3.297zm5.439-11.704h-8.791v3.297h8.791v-3.297zm31.266-2.912h-8.792v3.297h8.792v-3.297zm44.369 10.962h-8.792v3.297h8.792v-3.297zm-4.754 25.468h-8.792v3.297h8.792v-3.297zm-20.879-39.067h-8.791v3.297h8.791v-3.297zm-48.27 23.572h4.753l53.023-.027s4.314-6.676-7.335-10.33c-11.649-3.654-30.77-2.61-39.479-.935-8.709 1.676-11.292 3.709-10.962 11.292z"
      />
      <path fill="#BACDF2" d="M147.102 126.403l-.467 23.93h57.392v-24.534l-57.859-.495.934 1.099z" />
      <path
        fill="#fff"
        d="M200.455 126.733h-32.748v12.198h32.748v-12.198zm-18.187 14.149h-14.561v5.989h14.561v-5.989zm-17.473-13.929h-14.561v20.028h14.561v-20.028zm35.166 14.039H185.4v5.989h14.561v-5.989z"
      />
      <path
        fill="#AEC5F3"
        d="M151.772 116.898l-1.043 8.654 4.945-.11.879-10.495-2.885.687-1.621.659m7.885.687l-.825 8.489 4.973-.055.879-11.511-2.417.275-2.171.22m36.539 2.417l.797 8.682-3.874-.11-.687-10.495 2.281.687 1.264.659m-6.236.687l.687 8.489-4.148-.082-.742-11.484 2.006.275 1.84.22m-7.254 2.5l.714 8.489-4.148-.055-.769-11.759 1.95.083 1.868.11m-8.597 3.461l.686 8.49-4.121-.083-.769-11.758 2.061-.055 1.923-.055"
      />
      <path
        fill="#fff"
        d="M231.389 149.839a1.29 1.29 0 011.291 1.291v2.665a1.29 1.29 0 01-1.291 1.291 1.29 1.29 0 01-1.291-1.291v-2.693c.027-.686.604-1.263 1.291-1.263zm8.242 0a1.29 1.29 0 011.291 1.291v2.665a1.29 1.29 0 01-1.291 1.291 1.29 1.29 0 01-1.291-1.291v-2.693c.027-.686.604-1.263 1.291-1.263z"
      />
      <path
        fill="#BACDF2"
        d="M229.137 130.8s-1.978 20.632-1.621 20.934c.384.275 15.522.467 15.907.193.384-.275-1.127-20.935-1.429-21.018-.302-.082-12.39-.384-12.857-.109z"
      />
      <path
        fill="#fff"
        d="M231.637 135.992h8.325c.274 0 .522.22.522.522a.54.54 0 01-.522.522h-8.325a.522.522 0 010-1.044zm-1.127 3.324h10.413c.274 0 .522.22.522.522a.54.54 0 01-.522.522H230.51a.522.522 0 010-1.044z"
      />
    </svg>
  );
}
