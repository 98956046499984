import './index.scss';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import AttachPageFile from '../../../assets/images/stream/stream-prview.png';
import { useToState } from '../../../hooks';
import { Meet } from '../../app.modules';
import { getWebinarAdminJWT } from '../../Webinar/webinar.services';

interface IProps {
  data: any;
  onDestroy: any;
  ButtonClose: any;
  getWebinarAdminJWTAction: any;
}

const ModalMeet: FC<IProps> = function ({ data, onDestroy, ButtonClose, getWebinarAdminJWTAction }): ReactElement {
  const eventId = data.params.data.eventId;

  const elementId = data.params.data.id;

  const [state, setState] = useToState({
    dataWebinar: null,
  });

  useEffect(() => {
    getWebinarAdminJWTAction(eventId, elementId).then((response) => {
      setState({
        dataWebinar: response,
      });
    });
  }, []);

  return (
    <div className="modal-meet">
      {ButtonClose()}
      <h1 className="modal-meet__head">{data.params.title}</h1>
      <div className="modal-meet__body">
        <img src={AttachPageFile} alt="" className="modal-meet__icon" />
        {state.dataWebinar && (
          <Meet
            className="modal-meet__stream"
            room={state.dataWebinar.room}
            jwt={state.dataWebinar.jwt}
            onDisconnect={onDestroy}
          />
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getWebinarAdminJWTAction: bindActionCreators(getWebinarAdminJWT, dispatch),
});

export default connect(null, mapDispatchToProps)(ModalMeet);
