import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';
import Hint from '../Hint';
import Icon from '../Icon';

interface IProps {
  iconName?: string;
  error?: ReactNode;
  errorText?: ReactNode;
  className?: string;
  type?: string;
  placeholderNode?: ReactNode;
  size?: string;
  placeholder?: string;
  label?: ReactNode;
  value?: any;
  onChange?: Function | ReactEventHandler;
  onFocus?: Function | ReactEventHandler;
  onClick?(e): void;
  onBlur?: Function | ReactEventHandler;
  onKeyPress?: Function | ReactEventHandler;
  variant?: string;
  children?: ReactNode;
  autoComplete?: string;
  bold?: boolean;
  auto?: boolean;
  autoFocus?: boolean;
  replace?: boolean;
  defaultValue?: string;
  hint?: string;
  maxLength?: number;
  innerRef?: any;
  template?: string | ReactNode;
  errorNode?: string | ReactNode;
  text?: string | ReactNode;
}

const Input: FC<IProps> = function ({
  iconName,
  replace,
  defaultValue,
  className = '',
  type = 'text',
  placeholder = '',
  variant = 'primary',
  placeholderNode,
  error,
  onClick,
  errorText,
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyPress,
  children,
  label,
  autoComplete,
  bold,
  autoFocus,
  auto,
  hint,
  innerRef,
  maxLength,
  size = 'lg',
  template,
  errorNode,
  text,
}): ReactElement {
  const onInput: ReactEventHandler = (e): void => {
    onChange && onChange(e);
  };

  const onFocusChange: ReactEventHandler = (e): void => {
    onFocus && onFocus(e);
  };

  const onBlurChange: ReactEventHandler = (e): void => {
    onBlur && onBlur(e);
  };

  const onKeyPressChange: ReactEventHandler = (e): void => {
    onKeyPress && onKeyPress(e);
  };

  const renderHint = () => {
    return (
      hint && (
        <Hint label={hint} move={['right', 'bottom']}>
          {(ref) => <Icon name="ELHint" innerRef={ref} />}
        </Hint>
      )
    );
  };

  const renderInput: Function = (): ReactNode => {
    const isParamsInput = {
      onClick: onClick,
      onChange: onInput,
      onFocus: onFocusChange,
      onBlur: onBlurChange,
      onKeyPress: onKeyPressChange,
      placeholder,
      autoFocus,
      defaultValue,
      ...(value !== undefined && value !== null && { value }), //TODO [Deprecated]: Will be remove
      type,
      autoComplete,
      maxLength,
      ...(innerRef && { ref: innerRef }),
    };

    const classesInput = classNames('input--eject', className, {
      [`input--${variant}`]: variant,
      [`input--${size}`]: size,
      'input--b': bold,
      'input--error': error,
      'input--auto': auto,
      'input--hint': hint,
      [`input--t-${template}`]: template,
    });

    const renderPolygon = () => {
      const polygon = !replace && <input {...isParamsInput} required autoComplete="off" />;

      if (!polygon) return null;

      if (!text) return polygon;

      return (
        <div className="input__box">
          {polygon}
          <div className="input__box-text">{text}</div>
          {placeholderNode && <strong className="input__node">{placeholderNode}</strong>}
        </div>
      );
    };

    return (
      <div className={classesInput}>
        {iconName && <span className={iconName} />}
        {replace && children}
        {renderHint()}
        {renderPolygon()}
        {label && <label>{label}</label>}
        {error && errorText && <span>{errorText ? errorText : error}</span>}
        {error && errorNode && errorNode}
        {placeholderNode && !text && <strong className="input__node">{placeholderNode}</strong>}
        {!replace && children}
      </div>
    );
  };

  return renderInput();
};

export default Input;
