import './index.scss';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { uuid } from '../../../../utils';
import { Button, Hint, Icon, PopoverTarget, Square, Text } from '../../../app.modules';
import { setModal } from '../../../Modal/modal.actions';
import { MODALS_TRIGGER } from '../../../Modal/modal.types';
import { IBuilderTestsTemplateQuestion } from '../../builder.questions.resources';
import QuestionCriteria from '../CreateCriteria';
import CreateTags from '../CreateTags';

interface IProps {
  onMandatory?(e): void;
  onSettings?(e): void;
  onHide?(e): void;
  data?: IBuilderTestsTemplateQuestion;
  onChange(e, key: string): void;
  setModalAction: any;
  onChangeFieldAudio?: any;
}

const QuestionUpper: FC<IProps> = ({ data, onChange, setModalAction, onChangeFieldAudio }) => {
  const [state, setState] = useToState({
    onHide: false,
    isShowTags: false,
    isShowCriteria: false,
    isShowAdditions: false,
    isRequiredQuestion: data?.required ?? true,
  });

  const dataAdditions = [
    {
      title: translate('g.audio'),
      type: 'AttachAudio',
      icon: 'ELAudio',
    },
    {
      title: translate('g.video'),
      type: 'AttachVideo',
      icon: 'ELVideo',
    },
    // {
    //   title: 'Изображение',
    //   type: 'AttachImage',
    //   icon: 'ELImage',
    // },
    // {
    //   title: 'Подсказка',
    //   icon: 'ELQuestionSmall',
    // },
    // {
    //   title: 'Таймер',
    //   icon: 'ELTimerSmall',
    // },
  ];

  const onChangeField = (type) => {
    const value = !state[type];

    setState({
      [type]: value,
    });

    onChange(value, 'required');
  };

  const onChangeFields = (key, type: any = null) => () => {
    // console.log(key, 'dsa', type)

    setState({
      [key]: !state[key],
    });
    if (type) {
      setModalAction({
        code: MODALS_TRIGGER.QUESTIONS.ATTACH,
        uuid: uuid(),
        params: {
          type,
          questionData: data,
          onConfirm(data, key) {
            if (key === 'audio') {
              onChangeFieldAudio(data);
            } else {
              onChange(data, key);
            }
          },
        },
      });
    }
  };

  const renderAdditions = () => {
    return (
      <div className="question-upper__additions">
        {dataAdditions.map(({ title, icon, type }, i) => (
          <div key={i} className="question-upper__addition" onClick={onChangeFields('isShowAdditions', type)}>
            <Icon name={icon} className="question-upper__addition-icon" />
            <Text variant="md">{title}</Text>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="question-upper">
      <Hint
        label={!state.isRequiredQuestion ? translate('qs.rqd.down') : translate('qs.rqd.up')}
        move={['right', 'bottom']}
      >
        {(ref) => (
          <Square
            innerRef={ref}
            className="required"
            onClick={() => onChangeField('isRequiredQuestion')}
            active={state.isRequiredQuestion}
            monochrome
          >
            <Icon name="StarQuestion" />
          </Square>
        )}
      </Hint>
      <Square onClick={onChangeFields('isShowTags')} className="tags" toggle hover>
        <Icon name="Tags" />
      </Square>
      {/*<Button*/}
      {/*  size="sm"*/}
      {/*  variant="secondary"*/}
      {/*  active={state.isShowCriteria}*/}
      {/*  onClick={() => onChangeFields('isShowCriteria')}*/}
      {/*>*/}
      {/*  <IconFilter />*/}
      {/*  <strong>Критерии вопроса</strong>*/}
      {/*</Button>*/}
      <PopoverTarget target={renderAdditions}>
        <Button className="question-upper__add" size="sm" onClick={onChangeFields('isShowAdditions')}>
          <Icon name="ELAddLightCircle" className="question-upper__add-icon" />
          <strong>{translate('g.btn.add')}</strong>
        </Button>
      </PopoverTarget>
      {state.isShowTags && <CreateTags slug="#" />}
      {state.isShowCriteria && <QuestionCriteria />}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
});

export default connect(null, mapDispatchToProps)(QuestionUpper);
