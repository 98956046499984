import api from './tests.api';
import {
  IEducationsFrom,
  IEducationsGetTest,
  IEducationsTestCreate,
  IEducationsTo,
  ITest,
  ITestMark,
  ITestMarkMessage,
  ITestMarkServe,
} from './tests.resources';

export function getTests(data: IEducationsTo): Promise<IEducationsFrom> {
  const request = api.init('getTests');

  return request({
    query: data,
  });
}

export function testRead(id: number): Promise<ITest> {
  const request = api.init('testRead');

  return request({
    path: id,
  });
}

export function testDelete(id: number): Promise<ITest> {
  const request = api.init('testDelete');

  return request({
    path: id,
  });
}

export function testCreate(data: IEducationsTestCreate): Promise<IEducationsGetTest> {
  const request = api.init('testCreate');

  return request({
    params: data,
  });
}

export function testCreateMark(testId: number, data: ITestMark): Promise<ITestMarkServe> {
  const request = api.init('testCreateMark');

  return request({
    path: testId,
    params: data,
  });
}

export function testUpdateMark(testId: number, markId, data: ITestMark): Promise<ITestMarkServe> {
  const request = api.init('testUpdateMark');

  return request({
    path: [testId, markId],
    params: data,
  });
}

export function testDeleteMark(testId: number, markId): Promise<ITestMarkMessage> {
  const request = api.init('testDeleteMark');

  return request({
    path: [testId, markId],
  });
}

export function testUpdate(id: number, data: ITest): Promise<ITest> {
  const request = api.init('testUpdate');

  return request({
    path: id,
    params: data,
  });
}
