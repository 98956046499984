export default {
  DE: {
    'alert.copy.in': 'Zu Clipboard kopiert',
    'alert.event.save': 'Maßnahme erfolgreich abgespeichert',
    'alert.qs.crt': 'Neue Frage wurde erstellt',
    'alert.qs.udt': 'Frage erfolgreich abgespeichert',
    'alert.qs.prev.fill': 'Füllen Sie die vorherige Frage aus',
    'alert.err': 'Es ist was schief gelaufen...',
    'alert.usr.err.exist': 'Benutzer mit der angegebenen E-mail Adresse existiert bereits.',
    'alert.usr.err.incorrect': 'E-mail adresse und/oder Passwort sind falsch eingegeben',
    'alert.required.fill': 'Füllen Sie  Pflichtfelder aus',
    'alert.evt.del': 'Maßnahme wurde gelöscht',
    'alert.evt.arch': 'Maßnahme in die Archive verschoben',
    'alert.evt.restore': 'Maßnahme wiederaufgenommen',
    'alert.ath.file': 'Neue Datei hinzugefügt',
    'alert.ath.file.err': 'Fehler bei der Dateihinfügung: Frischen Sie die Seite auf!',
    'alert.prt.err': 'Der Abschnitt wurde nicht abgespeichert: Frischen Sie die Seite auf!',
    'alert.prt.add.new': 'Neuer Abschnitt hinzugefügt',
    'alert.prt.change': 'Abschnitt geändert!',
    'alert.awr.set': 'Antwort registriert!',
    'alert.awr.reset': 'Antwort überschrieben!',
    'alert.homework.err': 'Etwas lief schief - Frischen Sie die Seite auf!',
    'alert.evt.crt': 'Neue Maßnahme erstellt!',
    'alert.ath.file.change': 'Hinzugefügte Datei wurde geändert',
    'alert.homework.add': 'Hausaufgabe wurde hinzugefügt',
    'alert.ivt.accept': 'Die Einladung wurde akzeptiert',
    'alert.ivt.cancel': 'Die Einladung wurde abgelehnt',
    'alert.ivt.err.email': 'Die Einladung auf diesen E-mail wurde bereits abgeschickt!',
    'alert.test.crt': 'Neuer Test wurde erstellt!',
    'alert.group.crt': 'Neue Gruppe wurde erstellt!',
    'alert.awr.been.gin': 'Die Antwort gab es davor!',
    'alert.ivt.repeat': 'Die Einladungen wurden erneut gesendet!',
    'alert.canceled': 'Abgebrochen!',
    'alert.locked': 'Gesperrt!',
    'alert.unlocked': 'Freigesperrt!',
    'alert.deleted': 'Gelöscht!',
    'alert.changed': 'Geändert!',
    'alert.data.changed': 'Daten erfolgreich geändert',
    'alert.acc.not.email': 'Dieses E-mail ist nicht erreichbar!',
    'alert.token.not.found': 'Token nicht gefunden!',
    'alert.acc.access.email': 'Dieses E-mail wurde bestätigt!',
    'alert.usr.not.found': 'Benutzer nicht gefunden',
    'alert.link.err': 'Link ungültig!',
    'alert.change.pass': 'Passwort ist gewechselt',
    'alert.pass.match.not': 'Passwörter sollen nicht übereinstimmen',
    'alert.pass.invalid': 'Falsches Passwort',
    'alert.send': 'Abgeschickt',
    'alert.pay.success': 'Bezahlung erfolgreich',
    'alert.ath.video': 'Video hinzugefügt',
    'alert.agc.change': 'Sie haben die Agentur gewechselt',
  },
};
