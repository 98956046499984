import './index.scss';
import React, { FC, ReactElement } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useErrorFields, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Button, Icon, Input, Text, Textarea } from '../../app.modules';
import { sendSupportData } from '../../Support/support.services';

interface IProps {
  text?: string;
  onClose: any;
  sendSupportDataAction: any;
  setAlertAction: any;
  onDestroy?: any;
}

const TEST_API_KEY = '6LcpUk0dAAAAAB6qqUo5SxD4w1qzDs8d5ZNyeqH5';

const ModalSupport: FC<IProps> = function ({
  onClose,
  sendSupportDataAction,
  setAlertAction,
  onDestroy,
}): ReactElement {
  const [state, setState] = useToState({
    notRobot: false,
    validateCaptcha: false,
    files: [],
    link: '',
    message: '',
    loading: true,
  });

  const [error, setError] = useErrorFields(state, (value, key) => {
    switch (key) {
      case 'link':
      case 'message':
        return value.length;
      default:
        return true;
    }
  });

  const onChangeNotRobot = () => {
    setState({
      validateCaptcha: !state.validateCaptcha,
      loading: true,
    });
  };

  const handleChange = (value) => {
    if (value) {
      setState({
        validateCaptcha: false,
      });

      onConfirm(true);
    }
  };

  const onConfirm = (isValidated: boolean) => {
    isValidated &&
      setError(state).then(() => {
        const { link, message, files } = state;

        const formData = new FormData();

        formData.append('link', link);
        formData.append('message', message);

        for (const item of files) {
          formData.append('files', item);
        }

        sendSupportDataAction(formData)
          .then(() => {
            setState({
              showCaptcha: !state.showCaptcha,
            });

            setAlertAction({
              code: '080090',
              uuid: uuid(),
              params: {},
            });

            onDestroy();
          })
          .catch(() => {
            setState({
              showCaptcha: !state.showCaptcha,
            });

            setAlertAction({
              code: '089999',
              uuid: uuid(),
              params: {},
            });
          });
      });
  };

  const onChangeFields = (key) => (e) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState({
      [key]: value,
      loading: false,
    });
  };

  const removeFile = (file) => () => {
    const files = [...state.files];

    const filteredFiles = files.filter((el) => el.name !== file.name);

    setState({
      files: filteredFiles,
    });
  };

  const onAddFile = (e) => {
    const files = e.target.files;

    setState({
      files: [...state.files, ...Object.values(files)],
    });
  };

  const onCloseSupport = () => {
    onClose ? onClose() : onDestroy();
  };

  return (
    <div className="support">
      <div className="support__close" onClick={onCloseSupport}>
        <Icon name="ELCloseModal" />
      </div>
      <Text className="support__title" variant="lg">
        <strong>{translate('support.tlt')}</strong>
      </Text>
      <Text className="support__error-platform">{translate('support.desc')}</Text>
      <Text className="support__description">
        {translate('support.label.1')}
        <br />
        {translate('support.label.2')}
      </Text>
      <Textarea
        value={state.message}
        className="support__description-problem"
        onChange={onChangeFields('message')}
        placeholderNode={translate('support.problem.pls.2')}
        error={!error.message}
      />
      <Input
        value={state.link}
        onChange={onChangeFields('link')}
        className="support__link-page"
        placeholderNode={translate('support.problem.link')}
        error={!error.link}
      />
      {state.files &&
        state.files.map((item, i) => (
          <div key={i} className="support__file-info">
            <div className="support__file-remove">
              <Text>{item.name}</Text>
            </div>
            <div className="support__file-trash" onClick={removeFile(item)}>
              <Icon name="Trash" />
            </div>
          </div>
        ))}
      <Button variant="outlined" size="md" className="support__attach-file">
        <input className="support__buttons-input" multiple onChange={onAddFile} type="file" />
        <Icon name="Clip" />
        {translate('g.btn.ath.file')}
      </Button>
      <div className="support__footer">
        {state.validateCaptcha && (
          <GoogleReCaptchaProvider language="ru-RU" reCaptchaKey={TEST_API_KEY}>
            <GoogleReCaptcha onVerify={(token) => handleChange(token)} />
          </GoogleReCaptchaProvider>
        )}
        <Button className="support__send-data" size="md" off={state.loading} onClick={onChangeNotRobot}>
          {translate('g.btn.send')}
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  sendSupportDataAction: bindActionCreators(sendSupportData, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(ModalSupport);
