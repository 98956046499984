import './index.scss';
import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import { setBreadcrumbs } from '../../app/app.actions';
import { useRouter, useToState } from '../../hooks';
import { APP, getRoute } from '../../routes/client';
import { IReduxStore } from '../../store/serviceReducer';
import Text from '../Text';

interface IProps {
  breadcrumbs: any;
  setBreadcrumbsAction: any;
  rootRoute?: any;
}

const Breadcrumbs: FC<IProps> = ({ setBreadcrumbsAction }) => {
  const [state, setState] = useToState({
    data: [],
  });

  const { pathname, match } = useRouter();

  const rootRoute = pathname.includes(getRoute('AP', 'path'))
    ? APP.CHILDREN.ADMIN_PANEL.CHILDREN
    : APP.CHILDREN.DASHBOARD.CHILDREN;

  const formationString = [] as any;

  const renderLink = (title, path) => (
    <Link to={path} className="breadcrumbs__link">
      {title}
    </Link>
  );

  const renderStaticLink = (title) => <span className="breadcrumbs__current">{title}</span>;

  const setFetchingRouteData = ({ CHILDREN, TITLE, PATHNAME, fetchingRoutes, BACK = false }) => {
    if (CHILDREN) {
      formationString.push({
        title: TITLE,
        pathname: PATHNAME,
        parent: true,
        component: renderLink(TITLE, PATHNAME),
        back: BACK,
      });

      fetchingRoutes(CHILDREN);
    } else {
      formationString.push({
        title: TITLE,
        pathname: PATHNAME,
        component: renderStaticLink(TITLE),
        back: BACK,
      });
    }
  };

  const searchIdParamsInMatch = (fn, signature) => {
    const searchParams = signature.filter((el) => el.substring(1) in match.params).map((el) => el?.substring(1));

    const test = searchParams.map((el) => match.params[el]);

    return fn(...test);
  };

  const fetchingRoutes = (object) => {
    const sample = Object.keys(object);

    for (const key of sample) {
      const item = object[key];

      const PATHNAME = item?.PATHNAME;

      const CHILDREN = item?.CHILDREN;

      const TITLE = item?.TITLE;

      const SIGNATURES = item?.SIGNATURES;

      const BACK = item?.BACK;

      if (typeof PATHNAME === 'function') {
        const formationUri = PATHNAME(...SIGNATURES);

        if (match?.path.includes(formationUri)) {
          setFetchingRouteData({
            CHILDREN,
            TITLE,
            PATHNAME: searchIdParamsInMatch(PATHNAME, SIGNATURES),
            fetchingRoutes,
            BACK,
          });
        } else if (match?.url.includes(searchIdParamsInMatch(PATHNAME, SIGNATURES))) {
          setFetchingRouteData({ CHILDREN, TITLE, PATHNAME: match?.url, fetchingRoutes, BACK });
        }
      } else if (pathname.includes(PATHNAME)) {
        setFetchingRouteData({ CHILDREN, TITLE, PATHNAME, fetchingRoutes, BACK });
      }
    }

    return formationString;
  };

  const onFormationRoutesLoad = () => {
    const formationRoute = fetchingRoutes(rootRoute);

    const replaceLastItemOnCurrent = formationRoute.map((el, index) => ({
      ...el,
      ...(formationRoute.length - 1 === index && {
        component: renderStaticLink(el.title),
      }),
    }));

    setState({
      data: replaceLastItemOnCurrent,
    });
  };

  const setBreadcrumbs = () => {
    const { data } = state;

    setBreadcrumbsAction(data);
  };

  useEffect(onFormationRoutesLoad, [match]);

  useEffect(setBreadcrumbs, [state.data]);

  const renderBreadcrumbs = () => {
    const { data } = state;

    return data.map((el, i) => <Fragment key={i}>{el.component}</Fragment>);
  };

  return (
    <Text variant="sm" className="breadcrumbs">
      {renderBreadcrumbs()}
    </Text>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  breadcrumbs: store.app.breadcrumbs!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setBreadcrumbsAction: bindActionCreators(setBreadcrumbs, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs);
