import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELArrowLineLeft(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="none" viewBox="0 0 16 12" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 1L1 6l5 5m8.528-4.992H3.48"
      />
    </svg>
  );
}
