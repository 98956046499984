import './index.scss';
import format from 'date-fns/format';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { FC, ReactElement } from 'react';
import translate from '../../../i18n/translate';
import Button from '../../Button';
import Icon from '../../Icon';
import Text from '../../Text';

interface IProps {
  close(): void;
  confirm: any;
  info: any;
}

const ConfirmDelete: FC<IProps> = function ({ close, confirm, info }): ReactElement {
  const name = info.mimetype ? 'этот файл' : 'эту папку';

  const renderFileData = (fileData) => {
    return (
      <div className="confirm-delete__info">
        <div className="confirm-delete__info-name">
          <Icon name="ELFile" />
          <Text variant="md">{fileData.originalName}</Text>
        </div>
        <div className="confirm-delete__info-size">
          <Text variant="sm">
            {translate('g.size')}: {formatBytes(fileData.size)}
          </Text>
        </div>
        <div className="confirm-delete__info-date">
          <Text variant="sm">
            {translate('g.date.chg')}: {formatDate(fileData.timestamp)}
          </Text>
        </div>
      </div>
    );
  };

  const renderFolderData = (folderData) => {
    if (!folderData.timestamp) {
      folderData = {
        ...folderData,
        timestamp: Math.round(new Date().getTime() / 1000),
      };
    }

    return (
      <div className="confirm-delete__info">
        <div className="confirm-delete__info-name">
          <Icon name="ELFolder" />
          <Text variant="md">{folderData.basename}</Text>
        </div>
        <div className="confirm-delete__info-date">
          <Text variant="sm">
            {translate('g.date.chg')}: {formatDate(folderData?.timestamp)}
          </Text>
        </div>
      </div>
    );
  };

  return (
    <div className="confirm-delete">
      <div className="confirm-delete__title">
        <Text variant="lg">
          <strong>
            {translate('storage.delete.cnf')} {name}?
          </strong>
        </Text>
      </div>
      {info.size && renderFileData(info)}
      {!info.size && renderFolderData(info)}
      <div className="confirm-delete__actions">
        <Button size="sm" variant="outlined" onClick={confirm}>
          {translate('g.btn.delete')}
        </Button>
        <Button size="sm" onClick={close}>
          {translate('g.btn.cancel')}
        </Button>
      </div>
    </div>
  );
};

const formatBytes = (bytes, decimals?) => {
  if (bytes === 0) {
    return '0 Bytes';
  }
  const k = 1024;

  const dm = decimals <= 0 ? 0 : decimals || 2;

  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

const formatDate = (unix) => {
  return format(fromUnixTime(unix), 'dd.MM.yy HH:mm');
};

export default ConfirmDelete;
