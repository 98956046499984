import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ContextMenu2 from '../../../../components/ContextMenu2';
import { useToState } from '../../../../hooks';
import { setAlert } from '../../../app.actions';
import { Icon, Square, Text } from '../../../app.modules';
import { setModal } from '../../../Modal/modal.actions';
import { typesAccept } from '../../../Storage/storage.types';
import { questionUpdateAudio } from '../../builder.questions.services';
import QuestionAudio from '../../BuilderQuestionsAudio';
import QuestionVideo from '../../BuilderQuestionsVideo';
import Bridge from '../questions.create.pipes';

interface IProps {
  data: any;
  onChange: any;
  activeIndex: any;
  questionUpdateAudioAction: any;
  setAlertAction: any;
}

const AttachControl: FC<IProps> = ({ data, onChange, activeIndex, questionUpdateAudioAction, setAlertAction }) => {
  const [state, setState] = useToState({
    showContextMenu: false,
  });

  const onChangeControl = (e) => {
    e.stopPropagation();
    onChange();
  };

  const renderControlPreview = () => {
    const types = {
      [typesAccept.VIDEO]: (
        <QuestionVideo
          data={data.params}
          onChange={data.onChangeField}
          questionData={data.questionData}
          onClose={onChange}
        />
      ),
      [typesAccept.AUDIO]: <QuestionAudio data={data} onClose={onChange} onChange={data.onChangeFieldAudio} />,
    };

    return types[data.type];
  };

  const onToggleContextMenu = () => {
    setState({
      showContextMenu: !state.showContextMenu,
    });
  };

  const onChangeField = (option, checked) => {
    const requestData = Bridge.onRequestData(data.questionData);

    questionUpdateAudioAction(requestData.id, {
      ...requestData,
      [option.field]: {
        ...requestData[option.field],
        [option.key]: checked,
      },
    })
      .then(() => {
        data.onChangeField(
          {
            [option.key]: checked,
          },
          option.field
        );

        setAlertAction({
          code: '080202',
        });
      })
      .catch(() => {
        setAlertAction({
          code: '089999',
        });
      });
  };

  const onChangeFieldAudio = (option, checked) => {
    const requestData = Bridge.onRequestData(data.questionData);

    questionUpdateAudioAction(requestData.id, {
      ...requestData,
      [option.key]: checked,
    })
      .then(() => {
        data.onChangeFieldAudio(
          {
            [option.key]: checked,
          },
          option.field
        );

        setAlertAction({
          code: '080202',
        });
      })
      .catch(() => {
        setAlertAction({
          code: '089999',
        });
      });
  };

  const renderContentMenu = () => {
    return (
      data?.controls && (
        <>
          <div className="question-attach__control-dots" onClick={onToggleContextMenu}>
            <Icon name="Dots" />
          </div>
          {state.showContextMenu && (
            <ContextMenu2
              onChange={data?.type === typesAccept.AUDIO ? onChangeFieldAudio : onChangeField}
              onClose={onToggleContextMenu}
              className="question-attach__control-menu"
              data={data.controls}
            />
          )}
        </>
      )
    );
  };

  const classesAttachControl = classNames('question-attach__control', {
    'question-attach__control--active': activeIndex,
  });

  return (
    <div className={classesAttachControl}>
      <div className="question-attach__control-head">
        <Text className="question-attach__control-label">{data.title}</Text>
        {renderContentMenu()}
      </div>
      <Text className="question-attach__control-body">
        <span className="question-attach__control-text">{data.label ?? '--'}</span>
        <Square size="md" className="question-attach__control-switch" onClick={onChangeControl}>
          <Icon name={data.icon} className="question-attach__control-icon" />
        </Square>
        {activeIndex && (
          <div className="question-attach__control-preview">
            <Square hover monochrome size="xs" className="question-attach__close" onClick={onChangeControl}>
              <Icon name="ELCloseModal" className="question-attach__close-icon" />
            </Square>
            {renderControlPreview()}
          </div>
        )}
      </Text>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
  questionUpdateAudioAction: bindActionCreators(questionUpdateAudio, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(AttachControl);
