export default function dataParseBnd(dataRaw: any) {
  if (!dataRaw?.length) return null;

  let items = {};

  let columns = {};

  let columnOrder: Array<string> = [];

  Object.keys(dataRaw).forEach((key) => {
    const clone = dataRaw[key];

    const { data, uuid } = clone;

    const columnId = uuid; //col-${index}

    const itemIds = data.map((field) => {
      const uuid = field.uuid; // id: item-${field.id}

      items = {
        ...items,
        [uuid]: {
          ...field,
          uuid,
        },
      };
      return uuid;
    });

    columns = {
      ...columns,
      [columnId]: {
        ...clone,
        uuid: columnId,
        itemIds: itemIds,
      },
    };
    columnOrder = [...columnOrder, columnId];
  });

  return {
    items,
    columns,
    columnOrder,
  };
}
