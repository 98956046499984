import { $CASE_MD_LG, $CASE_SM } from '../env';

interface IPropsAlert {
  [key: string]: any;
}

export class Alert implements IPropsAlert {
  types: object;

  model: object;

  typesTemplate: object;

  time: number;

  defaultComponent: any;

  constructor() {
    this.time = 3000;
    this.defaultComponent = [];
    this.types = {};
    this.model = {};
    this.typesTemplate = {};
  }

  parserAlertCode(data) {
    const formation = String(data).split('');

    const type = formation.slice(0, 2).join('');

    const style = formation.slice(2, formation.length).join('');

    return [type, style];
  }

  getAlert(data, setAlert, alerts) {
    const { code } = data;

    const [type, style] = this.parserAlertCode(code);

    const [searchType, searchObject] = this.model[type];

    const searchStyle = searchObject[style];

    const [component] = this.types[searchType][searchStyle];

    return {
      ...data,
      component: component({
        ...data,
        alerts,
        time: 3, //TODO [Thunk]: Set own time
        setAlert,
      }),
    };
  }

  // parsingTypesTemplate(data) {
  //   return data;
  // };

  parsingTypesTemplate(data) {
    return data;
  }

  parsingDataTestEmptyAlert(alert, typeAlert, action) {
    // console.log(alert, typeAlert, action, 'alert, typeAlert, action')
    const dataModule = this.parsingTypesTemplate(alert);

    // console.log(dataModule,'dataModule')
    const dataModal = dataModule[typeAlert];

    // console.log(dataModal, dataModule, 'dataModal')
    const formationData = dataModal.filter((el) => !el.hidden);

    const target = !!formationData.length;

    const onUpdate = () => {
      const cloneUuidArray = dataModal.map((el) => el.uuid);

      const test = alert.filter((el) => cloneUuidArray.indexOf(el.uuid) === -1);

      if (dataModal.length > 0 && alert.length !== 0 && formationData.length === 0 && test.length !== 0) {
        action(test, true);
      }
    };

    return { target, onUpdate };
  }

  parsingDataDestroy(data) {
    const cloneAlerts = [...(data?.alerts ?? [])];

    const searchIndex = cloneAlerts.findIndex((el) => el.uuid === data.uuid);

    cloneAlerts.splice(searchIndex, 1, {
      ...cloneAlerts[searchIndex],
      hidden: true,
    });

    data.setAlert(cloneAlerts, true);
  }

  public getAlertType(data) {
    const { code } = data;

    const [typeTemplate] = this.parserAlertCode(code);

    return typeTemplate;
  }

  public createAlert(data, setAlert) {
    return data.map((el) => this.getAlert(el, setAlert, data));
  }
}

export const DisplayView = () => {
  const onCalculateDesktop = (windowWidth = window.innerWidth) => windowWidth > $CASE_MD_LG;

  const onCalculateCompact = (windowWidth = window.innerWidth) => windowWidth <= $CASE_MD_LG && windowWidth > $CASE_SM;

  const onCalculateMobile = (windowWidth = window.innerWidth) => windowWidth <= $CASE_SM;

  return { onCalculateDesktop, onCalculateCompact, onCalculateMobile };
};

export const PipeUsers = (participants) => {
  const { data, total } = participants;

  const onBlockUsers = (sample, value: boolean) => {
    const filtered = data.map((el) => ({
      ...el,
      ...(sample.indexOf(el.id) !== -1 && {
        deletedAt: value,
      }),
    }));

    return {
      data: filtered,
      total,
    };
  };

  const onFilterUsers = (sample) => {
    const filtered = data.filter((el) => sample.indexOf(el.id) === -1);

    return {
      data: filtered,
      total: total - 1,
    };
  };

  return { onBlockUsers, onFilterUsers };
};

export default {};
