import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAddLightCircle(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M12.5 25C19.404 25 25 19.404 25 12.5S19.404 0 12.5 0 0 5.596 0 12.5 5.596 25 12.5 25zm.188-19a1 1 0 011 1v4.688h4.687a1 1 0 110 2h-4.688v4.687a1 1 0 11-2 0v-4.688H7a1 1 0 110-2h4.688V7a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}
