import React from 'react';

export default function TestPoints() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M11.565 6.254l.658-1.225-1.4-.048a.479.479 0 01-.389-.223l-.74-1.178-.744 1.178a.479.479 0 01-.389.223l-1.399.048.658 1.225a.47.47 0 010 .446l-.658 1.225 1.4.048a.479.479 0 01.388.223l.742 1.178.742-1.178a.479.479 0 01.388-.223l1.4-.048-.658-1.225a.47.47 0 010-.446z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M10 0C5.94 0 2.635 3.276 2.635 7.302c0 2.54 1.314 4.78 3.303 6.088l.06.04.072.045.06.038.073.044a7.065 7.065 0 00.395.22l.071.037a6.906 6.906 0 00.418.194l.068.03.134.054.06.022a7.186 7.186 0 00.247.09l.063.022.143.046.05.015a6.51 6.51 0 00.269.076l.058.015.152.037.037.008c.041.01.083.018.124.027l.058.012.111.02.054.01c.053.01.106.019.16.027l.023.003a8.326 8.326 0 00.315.04l.052.006.166.014.011.001.158.01.052.002.125.005.05.002a7.474 7.474 0 00.342 0l.051-.002.125-.005.052-.002.157-.01h.012l.166-.015.051-.006.12-.013.053-.007.143-.02.023-.003a7.01 7.01 0 00.16-.027l.054-.01a6.986 6.986 0 00.293-.059l.036-.008a7.02 7.02 0 00.153-.037l.058-.015.1-.027.063-.018.107-.031.049-.015.143-.046.063-.022.09-.03.066-.025.091-.034.06-.023a7.095 7.095 0 00.35-.15c.026-.01.051-.023.077-.035l.079-.037.114-.056.07-.037.069-.035a7.34 7.34 0 00.073-.04l.066-.036a7.45 7.45 0 00.393-.237l.06-.039c1.989-1.308 3.303-3.549 3.303-6.088C17.363 3.276 14.06 0 9.999 0z"
      />
      <path
        fill="#fff"
        d="M9.883 6.155c1.157 0 2.01.221 2.557.663.555.442.833 1.034.833 1.775 0 .48-.125.918-.375 1.315-.25.39-.634.704-1.15.944-.508.232-1.138.348-1.888.348-.58 0-1.15-.071-1.713-.213a4.598 4.598 0 01-1.42-.63l.751-1.393c.313.225.673.4 1.08.528.406.12.824.18 1.255.18.477 0 .852-.09 1.126-.27.273-.18.41-.43.41-.753a.835.835 0 00-.434-.764c-.281-.172-.774-.258-1.478-.258h-2.1L7.76 3.2h5.009v1.46H9.343l-.129 1.495h.669z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M14.014 14.84a6.725 6.725 0 01-3.365.8l2.394 3.611a.424.424 0 00.342.235.438.438 0 00.383-.169l1.875-2.4 2.977-.192a.45.45 0 00.355-.226.453.453 0 00.013-.423l-2.394-3.611c-.63.97-1.5 1.799-2.58 2.375zm-8.031-.047a6.722 6.722 0 003.36.822l-2.42 3.595a.424.424 0 01-.343.233.438.438 0 01-.383-.172L4.34 16.858l-2.975-.21a.435.435 0 01-.354-.229.453.453 0 01-.01-.424L3.42 12.4a6.996 6.996 0 002.563 2.393z"
      />
    </svg>
  );
}
