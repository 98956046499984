export default {
  DE: {
    'status.pending': 'Eingeladen',
    'status.rejected': 'Abgelehnt',
    'status.accepted': 'Akzeptiert',
    'status.statistic.Completed': 'Abgeschlossen',
    'status.statistic.InProgress': 'Am laufen',
    'g.canceled': 'Abgebrochen',
    'g.payment': 'Bezahlt',
    'g.pending': 'In Bearbeitung',
    'g.fao': 'Deutsch (DE)',
    'g.forbidden': 'Verboten',
    'g.allowed': 'Erlaubt',
    'g.unlimited': 'Unbegrenzt',
    'g.accidentally': 'Zufällig',
    'g.consistently': 'Konsequent',
    'g.teach': 'Am unterrichten',
    'g.studying': 'Am studieren',
    'g.available': 'Vorhanden',
    'g.available.not': 'Nicht vorhanden',
    'g.sts.rejected.1': 'Abgelehnt',
    'g.sts.payment_error': 'Bezahlungsfehler',
    'g.sts.pay.not': 'Nicht bezahlt',
    'g.sts.active.s': 'Aktive',
    'g.sts.completed': 'Ausgeführt',
    'g.sts.not.yet': 'Nicht begonnen',
    'g.sts.not.yet.s': 'Nicht begonnen',
    'g.sts.cpl.end': 'Studiert',
    'g.sts.cpl.end.s': 'Studiert',
    'g.sts.cpl.now': 'Am studieren',
    'g.sts.cpl': 'Am studieren',
    'g.sts.fulfilled': 'Ausgeführt',
    'g.sts.comply.not': 'Nicht ausgeführt',
    'g.sts.start.not': 'Nicht gestartet',
    'g.gb': 'Gb',
    'g.month': 'Monat',
    'g.month.s': 'Monats',
    'g.months': 'Monate',
    'g.members': 'Teilnehmer',
    'g.gigabytes': 'Gigabytes',
    'g.period': 'Periode',
    'g.month.m': 'Mon',
    'g.year': 'Jahr',
    'g.amount': 'Summe',
    'g.languages': 'Sprachen',
    'g.name': 'Name',
    'g.description': 'Beschreibung',
    'g.cover': 'Titelblatt',
    'g.cover.mini': 'Minititelblatt',
    'g.from': 'von',
    'g.to': 'bis',
    'g.point.a': 'Punkte',
    'g.question': 'Frage',
    'g.question.s': 'Frage',
    'g.questions': 'Fragen',
    'g.minute': 'Minute',
    'g.minute.s': 'Minuten',
    'g.minutes': 'Minuten',
    'g.point': 'Punkt',
    'g.point.s': 'Punkte',
    'g.points': 'Punkten',
    'g.attempt': 'Versuch',
    'g.attempt.s': 'Versuche',
    'g.attempts': 'Versuche',
    'g.day': 'Tag',
    'g.days.s': 'Tages',
    'g.days': 'Tage',
    'g.domain': 'Domain',
    'g.NDS': 'MWSt',
    'g.NDS.not': 'Ohne MWSt',
    'g.dates': 'Daten',
    'g.author': 'Ersteller',
    'g.mmb': 'Benutzer',
    'g.usr.s': 'Teilnehmer',
    'g.event': 'Maßnahme',
    'g.settings': 'Einstellungen',
    'g.content': 'Inhalt',
    'g.site': 'Webseite',
    'g.paid': 'Kostenpfllichtig',
    'g.free': 'Kostenfrei',
    'g.limited.not': 'Unbegrenzt',
    'g.money.ru': 'RUB',
    'g.user': 'Benutzer',
    'g.email': 'E-mail',
    'g.search': 'Suche',
    'g.pgn.view': 'Auf eine Seite anzeigen',
    'g.pgn.lbl': 'Übergehen auf',
    'g.sts.pay.wait': 'Wartet auf Bezahlung',
    'g.sts.pay.scc': 'Bezahlt',
    'g.sts': 'Status',
    'g.role': 'Role',
    'g.usr.clock': 'Benutzer gesperrt',
    'g.choose': 'ausgewählt',
    'g.usr': 'Benutzer',
    'g.ivt.tlt': 'Einladungen',
    'g.progress': 'Fortschritt',
    'g.search.pls': 'Text eingeben',
    'g.middle': 'Durchschnittlich',
    'g.usr.all': 'Alle Teilnehmer',
    'g.download.s': 'Runterladen',
    'g.btn.cancel': 'Abbruch',
    'g.btn.cancel.s': 'Abbrechen',
    'g.btn.send': 'Send',
    'g.btn.pay': 'Bezahlen',
    'g.btn.yes': 'Ja',
    'g.btn.no': 'Nein',
    'g.btn.delete': 'Löschen',
    'g.btn.save': 'Speichern',
    'g.btn.create': 'Erstellen',
    'g.btn.restore': 'Wiederherstellen',
    'g.btn.activate': 'Aktivieren',
    'g.btn.move.archive': 'Zu Archive versetzen',
    'g.btn.stop': 'Stoppen',
    'g.btn.canceled': 'Ablehnen',
    'g.btn.confirmed': 'Bestätigen',
    'g.btn.ivt': 'Einladen',
    'g.btn.add': 'Zufügen',
    'g.btn.ext.excel': 'In Excel exportieren',
    'g.btn.view.more': 'Mehr schauen',
    'g.btn.upload.new.s': 'Neu hochladen',
    'g.btn.upload': 'Hochladen',
    'g.btn.back': 'Zurück',
    'g.btn.overwrite': 'Überschreiben',
    'g.btn.copy': 'Kopieren',
    'g.btn.accept': 'Akzeptieren',
    'g.btn.verify': 'Überprüfen',
    'g.btn.change': 'Ändern',
    'g.btn.change.not': 'Nicht ändern',
    'g.btn.awr': 'Beantworten',
    'g.study.mode': 'Studieren Modus',
    'g.teaching.mode': 'Unterrichten Modus',
    'g.my.profile': 'Mein Profil',
    'g.my.agency': 'Meine Agentur',
    'g.change.agency': 'Agentur wechseln',
    'g.exit': 'Rausgehen',
    'g.shared.info': 'Algemeine Info',
    'g.usr.not.found': 'Keine Benutzer gefunden',
    'g.opt.ivt.cancel': 'Einladung zurückziehen',
    'g.opt.ivt.repeat': 'Neueinalden',
    'g.opt.unlock': 'Entsperren',
    'g.opt.lock': 'Sperren',
    'g.opt.delete.usr': 'Benutzer löschen',
    'g.date.actives': 'Aktivität',
    'g.ivt.role': 'Auf eine Rolle einladen',
    'g.fld.email.pls': 'Geben Sie E-mail ein',
    'g.fld.last.name.pls': 'Geben Sie Nachnamen ein',
    'g.fld.first.name.pls': 'Geben Sie Vornamen ein',
    'g.fld.middle.name.pls': 'Geben Sie Vatersnamen an',
    'g.fld.last.name': 'Nachnamen',
    'g.fld.first.name': 'Vornamen',
    'g.fld.middle.name': 'Vatersnamen',
    'g.usr.s.delete': 'Möchten Sie die angewählten Benutzer löschen?',
    'g.usr.s.unlock': 'Möchten Sie die angewählten Benutzer entsperren?',
    'g.usr.s.lock': 'Möchten Sie die angewählten Benutzer sperren?',
    'g.tbl.opt.lbl': 'Die n der Tabelle abgebildeten Aufgaben',
    'g.search.tst': 'Aufgabe finden',
    'g.video.instruction': 'Videoanweisung',
    'g.support': 'Technischer Support',
    'g.materials': 'Materialien',
    'g.materials.s': 'Materialien',
    'g.multimedia': 'Muldimedien',
    'g.task': 'Aufgabe',
    'g.task.s': 'Aufgaben',
    'g.online.lesson': 'Online-Unterricht',
    'g.file': 'Datei',
    'g.book': 'Buch',
    'g.page': 'Seite',
    'g.image': 'Bild',
    'g.video': 'Video',
    'g.audio': 'Audio',
    'g.test': 'Test',
    'g.homework': 'Hausaufgabe',
    'g.broadcast': 'Live-Übertragung',
    'g.load.file.s': 'Laden Sie eine Datei hoch',
    'g.time': 'Zeit',
    'g.createdAt': 'Erstellung',
    'g.access': 'Zugriff',
    'g.add.link': 'Link hinfügen',
    'g.embed.code': 'Bauen Sie eine Kode hinein',
    'g.embed.code.vide': 'Bauen Sie eine Videokode hinein',
    'g.date': 'Datum',
    'g.duration': 'Länge',
    'g.min.t': 'Min.',
    'g.from.s': 'von',
    'g.copy': 'Kopie',
    'g.time.sent': 'Sended um',
    'g.read.more': 'Weiterlesen',
    'g.collapse': 'Zuklappen',
    'g.btn.ath.file': 'Datei hinzufügen',
    'g.comments': 'Kommentare',
    'g.comments.etr': 'Geben Sie Kommentar ein',
    'g.comments.del': 'Kommentar löschen',
    'g.comments.s': 'Kommentare',
    'g.date.time': 'Datum und Zeit',
    'g.min': 'Minimun',
    'g.max': 'Maximum',
    'g.pass': 'Schwelle',
    'g.other': 'Andere',
    'g.change.s': 'Wechseln',
    'g.questions.s': 'Fragen',
    'g.active.not': 'Nicht aktive',
    'g.active': 'Aktive',
    'g.file.new': 'Neue Datei',
    'g.choose.s': 'Auswählen',
    'g.download': 'Runterladen',
    'g.btn.open': 'Öffnen',
    'g.date.chg': 'Änderungsdatum',
    'g.size': 'Größe',
    'g.date.s': 'Durchführungsdaten',
    'g.or': 'oder',
    'g.register': 'Anmelden',
    'g.btn.participate': 'Teilnehmen',
    'g.material': 'Material',
    'g.end': 'Abschliessen',
    'g.cancel': 'Abgelehnt',
    'g.webinar': 'Webinar',
    'g.sing.in.tlt': 'Login',
    'g.sing.in.pass': 'Passwort',
    'g.sing.in.pass.save': 'Passwort abspeichern',
    'g.sing.in.forgot': 'Passwort vergessen?',
    'g.sing.in.confirm.tlt': 'Beim Einloggen akzeptieren Sie die personenbezogene Datenverarbeitung.',
    'g.sing.in': 'Einloggen',
    'g.sing.up': 'Registrieren',
    'g.sing.up.confirm.tlt': 'Beim Registrieren akzeptieren Sie die personenbezogene Datenverarbeitung.',
    'g.sing.in.me': 'Ich habe bereits einen Profil',
    'g.sing.forgot.email':
      'Geben Sie ihren E-mail ein, damit wir Ihnen Link zur Passwortwiederherstellung senden können',
    'g.sing.forgot': 'Passwort wiederherstellen',
    'g.sing.forgot.up': 'I habe mein Passwort wieder',
    'g.sing.forgot.link': 'Man hat Ihnen einen Link zur Passwortwiederherstellung geschickt',
    'g.sing.forgot.check.mail': 'Prüfen Sie ihren E-mail',
    'g.sing.forgot.check.mail.link': 'Ihre Link befindet sich bereits dort',
    'g.sing.forgot.thanks': 'Danke, ich schaue es mir an!',
    'g.dates.time': 'Datum und Zeit wählen',
    'g.connect': 'Anschleißen',
    'g.reconnect': 'Erneut anschließen',
    'g.back.page': 'Seite verlassen',
    'g.ivt': 'Einladung',
    'g.ivt.prt': 'Zur Teilnahme',
    'g.ivt.prt.cancel': 'Einladung ablehnen',
    'g.ivt.prt.confirm': 'Einladung akzeptieren',
    'g.ivt.prt.cancel.time': 'Einladung wird abgelehnt nach:',
    'g.seconds': 'Sek.',
    'g.continue': 'Fortfahren',
    'g.control.role': 'zur Kontrolle in der Rolle',
    'g.join': 'Beitreten',
    'g.see': 'Anschauen',
    'g.statistic': 'Statistik',
    'g.roles': 'Rollen',
    'g.not.found': 'Nicht gefunden',
  },
};
