import './index.scss';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import isEmail from 'validator/lib/isEmail';
import { TYPES_ROLES } from '../../../../app/Agency/agency.types';
import { useErrorFields, useEvent, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { IReduxStore } from '../../../../store/serviceReducer';
import Button from '../../../Button';
import Input from '../../../Input';
import Select from '../../../Select';
import Text from '../../../Text';

interface IProps {
  close?(): void;
  onConfirm(data: any): void;
  roles: any;
}

interface IState {
  email: string;
  firstName: string;
  lastName: string;
  patronymic: string;
  roleId: string;
}

const SendInviteAgency: FC<IProps> = function ({ onConfirm, roles }): ReactElement {
  const [state, setState] = useToState<IState>({
    email: '',
    firstName: '',
    lastName: '',
    patronymic: '',
    roleId: TYPES_ROLES.Author,
  });

  const dataSelect =
    roles?.data?.map((el) => ({
      ...el,
      title: el.name,
      params: el.id,
    })) ?? [];

  const [error, setError] = useErrorFields(state, (value, key) => {
    switch (key) {
      case 'email':
        return isEmail(value);
      case 'firstName':
      case 'lastName':
        return value?.length;
      default:
        return true;
    }
  });

  const onChangeField = (key) => (e) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const onChangeFieldSelect = (data) => {
    setState({
      roleId: data.params,
    });
  };

  const onAddUsers = () => {
    setError(state).then(() => {
      onConfirm(state);
    });
  };

  const onKeydownConfirm = (e) => {
    const { keyCode } = e;

    keyCode === 13 && onAddUsers();
  };

  useEvent('keydown', onKeydownConfirm);

  return (
    <div className="assign-user">
      <h1>Пригласить в агентство</h1>
      <div className="assign-user__data">
        <div className="assign-user__data-item">
          <Text variant="lg" className="assign-user__data-label">
            <strong>Фамилия*</strong>
          </Text>
          <Input
            error={!error.firstName}
            size="md"
            placeholder="Введите фамилию"
            onChange={onChangeField('firstName')}
          />
        </div>
        <div className="assign-user__data-item">
          <Text variant="lg" className="assign-user__data-label">
            <strong>Имя*</strong>
          </Text>
          <Input error={!error.lastName} size="md" placeholder="Введите имя" onChange={onChangeField('lastName')} />
        </div>
        <div className="assign-user__data-item">
          <Text variant="lg" className="assign-user__data-label">
            <strong>Отчество</strong>
          </Text>
          <Input size="md" placeholder="Введите отчество" onChange={onChangeField('patronymic')} />
        </div>
        <div className="assign-user__data-item">
          <Text variant="lg" className="assign-user__data-label">
            <strong>E-mail*</strong>
          </Text>
          <Input error={!error.email} size="md" placeholder="Введите e-mail" onChange={onChangeField('email')} />
        </div>
        <div className="assign-user__data-item">
          <Text variant="lg" className="assign-user__data-label">
            <strong>Роль*</strong>
          </Text>
          <Select update={false} data={dataSelect} onChange={onChangeFieldSelect} />
        </div>
        <div className="assign-user__data-item">
          <Button className="assign-user__data-button" size="md" onClick={onAddUsers}>
            {translate('g.btn.ivt')}
          </Button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  roles: store.middleware.roles!,
});

export default connect(mapStateToProps)(SendInviteAgency);
