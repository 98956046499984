import React from 'react';

interface IProps {
  className?: string;
}

export default function ELTimerSmall(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M12.002 21.998C17.524 21.998 22 17.522 22 12c0-5.522-4.476-9.998-9.998-9.998-5.521 0-9.998 4.476-9.998 9.998 0 5.522 4.477 9.998 9.998 9.998zm0 2C18.628 23.998 24 18.626 24 12 24 5.374 18.628.002 12.002.002 5.376.002.004 5.374.004 12c0 6.626 5.372 11.998 11.998 11.998zm.003-17.233a1 1 0 011 1v3.399h3.238a1 1 0 110 2H12.01a1 1 0 01-.999-1.061 1.008 1.008 0 01-.005-.104V7.765a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}
