import './index.scss';
import React, { FC, ReactElement } from 'react';
import translate from '../../../i18n/translate';
import { Button, Text } from '../../app.modules';

interface IProps {
  close(): void;
  stop(): any;
}

const StopUploadFile: FC<IProps> = ({ close, stop }): ReactElement => {
  return (
    <div className="modal">
      <div className="modal__container">
        <Text className="modal__title" variant="lg">
          <strong>{translate('storage.upload.not.tlt')}</strong>
        </Text>
        <div>
          <Button size="md" width="sm" onClick={close}>
            {translate('g.btn.cancel')}
          </Button>
          <Button className="modal__cancel-button" size="md" variant="outlined" onClick={stop} monochrome width="sm">
            {translate('g.btn.stop')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StopUploadFile;
