import React from 'react';

interface IProps {
  className?: string;
}

function DoubleArrow(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 11 12" {...props}>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M2.475 1.035a1.06 1.06 0 00-1.448 0c-.4.38-.4.998 0 1.379l3.75 3.571a1.06 1.06 0 001.448 0l3.75-3.571c.4-.38.4-.998 0-1.38a1.06 1.06 0 00-1.448 0L5.5 3.917 2.475 1.035zm0 5a1.06 1.06 0 00-1.448 0c-.4.38-.4.998 0 1.379l3.75 3.571a1.06 1.06 0 001.448 0l3.75-3.571c.4-.381.4-.998 0-1.38a1.06 1.06 0 00-1.448 0L5.5 8.917 2.475 6.035z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default DoubleArrow;
