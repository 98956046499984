import './index.scss';
import { Editor } from '@tinymce/tinymce-react';
import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import { CONFIG } from '../../env';
import { useToState } from '../../hooks';
import { uuid } from '../../utils';
import Loader from '../Loader';
import Text from '../Text';
import Ru from './i18n/ru';
import { typography } from './styles';

interface IPropsPreview {
  data: string;
  formulaMode?: boolean;
  target?: string;
  className?: string;
}

export const EditorTextPreview: FC<IPropsPreview> = ({ data, formulaMode, target = 'empty', className }) => {
  const formulaModeHandler = (id) => {
    if (!formulaMode) return null;

    const script = document.createElement('script');

    script.id = id;
    script.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    script.async = true;

    document.body.appendChild(script);
  };

  useEffect(() => {
    const scriptId = uuid();

    formulaModeHandler(scriptId);

    return () => {
      const searchScript = document.getElementById(scriptId);

      searchScript?.remove();
    };
  }, [target]);

  const classesEditorTextPreview = classNames('editor-text--preview', className);

  return (
    <Text className={classesEditorTextPreview}>
      <div dangerouslySetInnerHTML={{ __html: data }} />
    </Text>
  );
};

interface IProps {
  data?: string;
  onChange(data: string): void;
  add?: Array<string>;
  label?: string | ReactNode;
  error?: boolean;
  errorText?: ReactNode | string;
  dynamicWidth?: boolean;
  plugins?: Array<string>;
  minHeight?: number;
  variant?: string;
  placeholder?: string;
  className?: string;
  onBlur?(data: string): void;
}

interface IState {
  width: number;
  elemRef: any;
  text: string;
  blurText: string;
  load: boolean;
  plugins: object;
}

const EditorText: FC<IProps> = ({
  data = '',
  onChange,
  label,
  error,
  dynamicWidth,
  errorText,
  plugins = ['default'],
  minHeight = 360,
  variant = 'primary',
  placeholder = '',
  className,
  onBlur,
}) => {
  const [state, setState] = useToState<IState>({
    width: 0,
    text: '',
    load: true,
    elemRef: null,
    plugins: {},
    blurText: data,
  });

  const onLoadPlugins = () => {
    const types = {
      default: {
        toolbar: `formatselect | bold italic | numlist bullist | alignleft |aligncenter | alignright | alignjustify | media | link image`,
      },
      wiris: {
        toolbar: ' tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
        modes: {
          tiny_mce_wiris: 'https://www.wiris.net/demo/plugins/tiny_mce/plugin.js',
        },
      },
    };

    let params = {} as {
      toolbar: string;
      modes: object;
    };

    for (const item of plugins) {
      const field = types[item];

      params = {
        toolbar: (params?.toolbar ?? '') + field?.toolbar,
        modes: {
          ...params?.modes,
          ...field?.modes,
        },
      };
    }

    return params as any;
  };

  const onInitEditorText = () => {
    setState((state) => ({
      load: !state.load,
    }));
  };

  const handleEditorChange = (content) => {
    onChange && onChange(content);

    setState({
      blurText: content,
    });
  };

  const handleEditorBlur = () => {
    onBlur && onBlur(state.blurText);
  };

  const renderErrorText = () => {
    return (
      error && (
        <div className="editor-text__error">
          <Text variant="sm">{errorText ? errorText : 'Введите текст'}</Text>
        </div>
      )
    );
  };

  const classesEditorText = classNames('editor-text', className, {
    'editor-text--error': error,
  });

  const elemRef = useCallback((node) => {
    if (node !== null) {
      setState({
        width: node.getBoundingClientRect().width,
        elemRef: node,
      });
    }
  }, []);

  const handleEditorKeyManipulate = (e) => {
    const { innerHTML } = e.target;

    setState({ text: innerHTML });
  };

  const conditionForWidthWindow = () => {
    state.text !== '' && setState({ width: state.elemRef?.getBoundingClientRect().width + 200 });
  };

  const onChangeWidth = () => {
    const { width } = state;

    const windowWidth = window?.innerWidth;

    const widthToLeftSide = state.elemRef?.getBoundingClientRect().left;

    const istable = windowWidth - widthToLeftSide - 200;

    setState({
      width: istable > width ? width : 500,
    });
  };

  dynamicWidth &&
    useEffect(() => {
      conditionForWidthWindow();
    }, [state.text]);

  dynamicWidth &&
    useEffect(() => {
      onChangeWidth();
    }, [state.width]);

  const paramsEditorLoad = onLoadPlugins();

  const paramsEditorTextPolygon = {
    style: {
      ...(dynamicWidth && {
        width: `${state.width}px`,
      }),
      minHeight: minHeight,
    },
  };

  const paramsEditorText: any = {
    onEditorChange: handleEditorChange,
    onBlur: handleEditorBlur,
    ...(dynamicWidth && {
      onKeyDown: handleEditorKeyManipulate,
      onKeyUp: handleEditorKeyManipulate,
    }),
  };

  const classesEditorPolygon = classNames('editor-text__polygon', {
    [`editor-text__polygon--${variant}`]: variant,
  });

  return (
    <>
      <div className={classesEditorText}>
        <div className={classesEditorPolygon} {...paramsEditorTextPolygon}>
          {renderErrorText()}
          {state.load && <Loader name="EditorText" />}
          <Editor
            tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
            apiKey={CONFIG.REACT_APP_TINYMCE_API_KEY}
            initialValue={data}
            onInit={onInitEditorText}
            init={{
              language: 'ru',
              i18n: Ru,
              statusbar: false,
              strict_loading_mode: false,
              menubar: false,
              plugins: 'lists, autoresize, paste, media, image code',
              toolbar: paramsEditorLoad?.toolbar,
              content_style: typography,
              external_plugins: paramsEditorLoad?.modes ?? {},
              min_height: minHeight,
              placeholder: placeholder,
              paste_as_text: true,
            }}
            {...paramsEditorText}
          />
        </div>
        {label && <Text>{label}</Text>}
      </div>
      {dynamicWidth && (
        <div className="editor-text__width" ref={elemRef}>
          <EditorTextPreview data={state.text} />
        </div>
      )}
    </>
  );
};

export default EditorText;
