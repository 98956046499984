import { loadingBarReducer } from 'react-redux-loading-bar';
import { combineReducers } from 'redux';
import adminReducer from '../app/Admin/admin.reducer';
import agencyReducer from '../app/Agency/agency.reducer';
import appReducer from '../app/app.reducer';
import editorReducer from '../app/BuilderPage/builder.page.reducer';
import builderTestsReducer from '../app/BuilderQuestions/builder.questions.reducer';
import dashboardReducer from '../app/Dashboard/dashboard.reducer';
import eventsReducer from '../app/Events/events.reducer';
import expandReducer from '../app/Expand/expand.reducer';
import groupsReducer from '../app/Groups/groups.reducer';
import learningReducer from '../app/Learning/learning.reducer';
import locationReducer from '../app/Location/location.reducer';
import membersReducer from '../app/Members/members.reducer';
import middlewareReducer from '../app/Middleware/middleware.reducer';
import modalReducer from '../app/Modal/modal.reducer';
import notificationReducer from '../app/Notifications/notifications.reducer';
import participantsReducer from '../app/Participants/participants.reducer';
import portalsReducer from '../app/Portals/portals.reducer';
import statsReducer from '../app/Statistic/statistic.reducer';
import storageReducer from '../app/Storage/storage.reducer';
import testsReducer from '../app/Tests/tests.reducer';

export const rootReducer = combineReducers({
  loadingBar: loadingBarReducer,
  portals: portalsReducer,
  storage: storageReducer,
  dashboard: dashboardReducer,
  editor: editorReducer,
  builderTests: builderTestsReducer,
  app: appReducer,
  tests: testsReducer,
  learning: learningReducer,
  middleware: middlewareReducer,
  modal: modalReducer,
  agency: agencyReducer,
  groups: groupsReducer,
  notification: notificationReducer,
  events: eventsReducer,
  expand: expandReducer,
  members: membersReducer,
  participants: participantsReducer,
  stats: statsReducer,
  admin: adminReducer,
  location: locationReducer,
});
