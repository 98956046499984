import { LOCAL } from '../local';
import AGENCY from './agency';
import ALERT from './alert';
import EVENTS from './events';
import EXPAND from './expand';
import GENERAL from './general';
import HOMEWORK from './homework';
import LEARNING from './learning';
import MEMBERS from './members';
import NOTIFICATION from './notification';
import PARTICIPANTS from './participants';
import PAYMENTS from './payments';
import PROFILE from './profile';
import QUESTIONS from './questions';
import STATISTIC from './statistic';
import STORAGE from './storage';
import SUPPORT from './support';
import TESTS from './tests';
import WARNING from './warning';

export default {
  [LOCAL.EN]: {
    ...GENERAL.EN,
    ...EXPAND.EN,
    ...TESTS.EN,
    ...LEARNING.EN,
    ...EVENTS.EN,
    ...AGENCY.EN,
    ...WARNING.EN,
    ...PROFILE.EN,
    ...ALERT.EN,
    ...PAYMENTS.EN,
    ...MEMBERS.EN,
    ...PARTICIPANTS.EN,
    ...HOMEWORK.EN,
    ...STORAGE.EN,
    ...STATISTIC.EN,
    ...SUPPORT.EN,
    ...QUESTIONS.EN,
    ...NOTIFICATION.EN,
  },
};
