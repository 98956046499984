import classNames from 'classnames';
import get from 'lodash/get';
import React, { FC } from 'react';
import { URI_GENERAL } from '../../../../routes/uri';
import { Icon, Text } from '../../../app.modules';

interface IProps {
  data: {
    service: {
      activeIndex: number;
      focusinFiles: Array<any>;
    };
    action: {
      onContextMenuChange(e: any, data: File, index: number): void;
      onChangeFile(data: File): void;
      onFocusFile(e: any, data: File, index: number): void;
    };
    file: File;
    index: number;
  };
}

const StorageImage: FC<IProps> = ({ data }): any => {
  const { service, action, file, index } = data;

  const { activeIndex, focusinFiles } = service;

  const basename = get(file, 'originalName', 'Название файла');

  const id = get(file, 'id');

  const imageUrl = URI_GENERAL.download(id);

  const onContextMenuChange = (e) => {
    action.onContextMenuChange(e, file, index);
  };

  const onChangeFile = () => {
    id && action.onChangeFile(file);
  };

  const onFocusFile = (e) => {
    action.onFocusFile(e, file, index);
  };

  const classes = classNames('storage__file', 'storage__file--image', {
    'storage__file-active': activeIndex === index || focusinFiles.some((el) => el.id === id),
  });

  return (
    <div
      className={classes}
      title={basename}
      onClick={onFocusFile}
      onDoubleClick={onChangeFile}
      onContextMenu={onContextMenuChange}
    >
      {id ? <img src={imageUrl} alt={basename} /> : <Icon name="ELFile" />}
      <Text variant="sm">{basename}</Text>
    </div>
  );
};

export default StorageImage;
