import { useState } from 'react';
import { useEvent } from './index';

export default () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth) as any;

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEvent('resize', handleResize);

  return [windowWidth < 768];
};
