import './index.scss';
import React, { FC, ReactElement } from 'react';
import { uuid } from '../../../utils';
import StoragePolygon from '../../Storage/StoragePolygon';

interface IProps {
  data: {
    params: any;
    setAlert?(data: any): void;
  };
  onDestroy: any;
  ButtonClose?: any;
}

const ModalStorage: FC<IProps> = function ({ data, onDestroy, ButtonClose }): ReactElement {
  const { params } = data;

  const loading = params?.loading;

  const onChangeEndCode = () => {
    if (!loading?.endCode || !data?.setAlert) return null;

    data.setAlert({
      code: loading.endCode,
      uuid: uuid(),
      params: {},
    });
  };

  const onConfirm = async (file) => {
    await data.params?.onConfirm(file);

    onChangeEndCode();

    onDestroy();
  };

  return (
    <div className="modal-storage">
      <ButtonClose />
      <StoragePolygon onChange={onConfirm} accept={data.params?.accept} />
    </div>
  );
};

export default ModalStorage;
