import './index.scss';
import classNames from 'classnames';
import React, { FC, useState } from 'react';
import { Text } from '../../app/app.modules';
import translate from '../../i18n/translate';

interface IProps {
  data: any;
  countSymbols: number;
}

interface IState {
  expanded: boolean;
}

const ToggleText: FC<IProps> = ({ data, countSymbols }) => {
  const [state, setState] = useState<IState>({
    expanded: false,
  });

  const { expanded } = state;

  const classesContainer = classNames('event-single__data', {
    'event-single__data--active': expanded,
  });

  const classesDescription = classNames('event-single__description', {
    'event-single__description-active': expanded,
  });

  const classesToggle = classNames('event-single__toggle', {
    'event-single__toggle-active': expanded,
  });

  const toggleDescription = () => {
    const { expanded } = state;

    setState({ ...state, expanded: !expanded });
  };

  const renderShorDescription = (desc) => {
    return (
      <>
        <span className={classesDescription}>{expanded ? desc : desc.substring(0, countSymbols) + '...'}</span>
        <span onClick={() => toggleDescription()} className={classesToggle}>
          {!expanded ? translate('g.read.more') : translate('g.collapse')}
        </span>
      </>
    );
  };

  return (
    <div className={classesContainer}>
      <Text>
        {data.length > countSymbols && renderShorDescription(data)}
        {data.length < countSymbols && data}
      </Text>
    </div>
  );
};

export default ToggleText;
