import './index.scss';
import React, { FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useHover } from '../../hooks';
import useBoundingClient from '../../hooks/useBoundingClient';
import Portal from '../Portal';
import Text from '../Text';

interface IProps {
  label: string | ReactNode;
  children: any;
  move?: Array<string>;
}

interface IState {
  top: undefined | number;
  left: undefined | number;
  width: undefined | number;
  height: undefined | number;
}

const Hint: FC<IProps> = ({ children, label, move = [] }): ReactElement => {
  const [ref, isHovered] = useHover();

  const innerRef = useRef(null);

  const [state, setState] = useState<IState>({
    top: undefined,
    left: undefined,
    width: undefined,
    height: undefined,
  });

  const { top, left, width, height } = useBoundingClient(ref);

  const innerWidth = useBoundingClient(innerRef)?.width;

  const innerHeight = useBoundingClient(innerRef)?.height;

  const styleMoveHint = () => {
    const calculateMoveHintX = {
      left: left - innerWidth,
      right: left + width,
    };

    const calculateMoveHintY = {
      top: top - innerHeight,
      bottom: top + height,
    };

    let styles = {};

    for (const key of move) {
      const isX = calculateMoveHintX[key];

      const isY = calculateMoveHintY[key];

      styles = {
        ...styles,
        ...(isX && { left: calculateMoveHintX[key] }),
        ...(isY && { top: calculateMoveHintY[key] }),
      };
    }

    return styles;
  };

  const renderHintContent = () => {
    const styling = {
      left: state.left,
      top: state.top,
    };

    return (
      isHovered && (
        <Portal>
          <div className="hint--primary" style={styling} ref={innerRef}>
            <Text variant="sm">{label}</Text>
          </div>
        </Portal>
      )
    );
  };

  useEffect(() => {
    setState((state) => ({
      ...state,
      top: top - innerHeight / 2 + 16 / 2,
      left: left + width,
      ...styleMoveHint(),
    }));
  }, [top, left, width, height, move, innerHeight, innerWidth, innerRef]);

  return (
    <>
      {children(ref)}

      {renderHintContent()}
    </>
  );
};

export default Hint;
