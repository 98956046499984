import { FC, ReactElement, ReactNode, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { $body } from '../env';

interface IProps {
  children: ReactNode;
  close?(element: Element): void;
}

const Portal: FC<IProps> = ({ children }): ReactElement => {
  const element = document.createElement('div');

  const $selector = 'portal';

  useEffect(() => {
    $body.appendChild(element);
    element.className = $selector;

    return function cleanUp() {
      $body.removeChild(element);
    };
  });

  return createPortal(children, element);
};

export default Portal;
