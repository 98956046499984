import './index.scss';
import React, { FC, ReactElement } from 'react';
import { Icon } from '../../app/app.modules';
import { useToState } from '../../hooks';
import Prompt from '../Prompt';
import Text from '../Text';
import Toggle from '../Toggle';

interface IProps {
  data: any;
  onChange: any;
}

const ContextMenu2Option: FC<IProps> = function ({ data, onChange }): ReactElement {
  const [state, setState] = useToState({
    checked: data?.checked ?? false,
  });

  const onChangeOption = () => {
    onChange && onChange(data, !state.checked);

    setState({
      checked: !state.checked,
    });
  };

  const renderContent = () => {
    const content = (
      <Toggle
        replace
        variant="success"
        className="content-menu-2__option-switch"
        checked={state.checked}
        onChange={onChangeOption}
      >
        <Icon name="CheckSuccess" />
        <div className="content-menu-2__option-label">{data.title}</div>
      </Toggle>
    );

    if (data?.prompt) {
      return (
        <Prompt label={data?.prompt} view>
          {content}
        </Prompt>
      );
    }

    return content;
  };

  return <Text className="content-menu-2__option">{renderContent()}</Text>;
};

export default ContextMenu2Option;
