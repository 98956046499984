import React from 'react';

export default function TypeChoice() {
  return (
    <i>
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none" viewBox="0 0 24 22">
        <path
          fill="var(--el-fonts--icon-color)"
          d="M2.311 22h19.645C23.11 22 24 20.778 24 19.556V2.473C24 1.223 23.111 0 21.956 0H2.31C1.156 0 0 1.222 0 2.464v17.118C0 20.778 1.156 22 2.311 22zM3.534 4.678c.648 0 1.178.577 1.178 1.282 0 .705-.53 1.282-1.178 1.282-.648 0-1.178-.577-1.178-1.282 0-.705.53-1.282 1.178-1.282zm16.49 0c.59 0 .59 2.564 0 2.564H7.067c-.588 0-.588-2.564 0-2.564h12.957zM3.535 9.806c.648 0 1.178.577 1.178 1.282 0 .705-.53 1.282-1.178 1.282-.648 0-1.178-.577-1.178-1.282 0-.705.53-1.282 1.178-1.282zm16.49 0c.59 0 .59 2.564 0 2.564H7.067c-.588 0-.588-2.564 0-2.564h12.957zm-16.49 5.128c.648 0 1.178.576 1.178 1.281 0 .706-.53 1.282-1.178 1.282-.648 0-1.178-.576-1.178-1.282 0-.705.53-1.281 1.178-1.281zm16.49 0c.59 0 .59 2.563 0 2.563H7.067c-.588 0-.588-2.563 0-2.563h12.957z"
        />
      </svg>
    </i>
  );
}
