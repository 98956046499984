import React from 'react';

interface IProps {
  className?: string;
}

export default function AddPrimary({ className }: IProps) {
  const params = { className };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13" {...params}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="3"
        d="M6.658 1.88v9.555m4.777-4.778H1.88"
      />
    </svg>
  );
} //TODO [Deprecated]: Will be remove
