import './index.scss';
import React, { FC } from 'react';
import { Square, Text } from '../../../app.modules';

interface IProps {
  number: number;
  name: string;
}

const CreateHeading: FC<IProps> = ({ number, name }) => {
  const parseName = (value) => {
    return value
      ?.replace(/<\/?[^>]+>/g, '')
      .substr(0, 100)
      .replaceAll('&nbsp;', ' ')
      .replaceAll(/(<iframe.*?>.*?<\/iframe>)/g, ' ');
  };

  return (
    <div className="heading">
      <Square size="sm" auto>
        <Text variant="md" className="heading__text">
          <strong>{number}</strong>
          <span dangerouslySetInnerHTML={{ __html: parseName(name) }} />
        </Text>
      </Square>
    </div>
  );
};

export default CreateHeading;
