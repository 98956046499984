export default {
  DE: {
    'homework.usr.awr.name': 'Meine Antwort',
    'homework.usr.send.tlt': 'Prüfen Sie, ob Sie alles getan haben, bevor Sie die Antwort abschicken sollen',
    'homework.usr.awr.lbl':
      'Der Antwort wird  aktuell vom Unterrichter geprüft. Nach der Prüfung kommt er zurück zur Nachbesserung, abgelehnt oder akzeptiert mit einer Note.',
    'homework.status.pending': 'Wird geprüft',
    'homework.status.rework': 'Zue Verbesserung',
    'homework.status.result': 'Zu den Resultaten',
    'homework.status.accepted': 'Zu der Aufgabe',
    'homework.status.success': 'Akzeptiert',
    'homework.status.success.lbl':
      'Ihre Antwort wurde vom Unterrichter akzeptiert. Sie dürfen diese Entscheidung mit dem Unterrichter abdiskutieren um',
    'homework.status.success.lbl.2': 'zu der Aufgabe.',
    'homework.status.cancel.lbl':
      'Ihre Antwort wurde vom Unterrichter abgelehnt. Sie dürfen diese Entscheidung mit dem Unterrichter abdiskutieren um',
    'homework.status.cancel.lbl.2': 'zu der Aufgabe.',
    'homework.history.tlt': 'Prüfgeschichte',
    'homework.send.accept': 'Gesendet für Prüfung',
    'homework.send.repeat': 'Neubeantwortet',
    'homework.send.rework': 'Gesendet zur Nachbesserung',
    'homework.awr.usr.tlt': 'Antwort von dem Studenten',
    'homework.change.scr': 'Punkte ändern',
    'homework.adm.cancel.lbl': 'Lassen Sie einen Kommentar f. den Studenten, warum die Aufgabe abgelehnt wurde.',
    'homework.adm.rework.lbl': 'Lassen Sie einen Kommentar f. den studenten, was er nachbessern soll.',
    'homework.adm.cmt': 'Kommentar des Unterrichters',
    'homework.adm.cmt.pls': 'Geben Sie einen Kommentar zur getroffenen Entscheidung ein',
    'homework.tlt': 'Hausaufgabe',
  },
};
