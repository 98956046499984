import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useToState } from '../../hooks';
import { LOCAL } from '../../i18n';
import Text from '../Text';

interface IProps {
  lang: string;
  className?: string;
  defaultValue?: string;
  onChange?(data: string): void;
}

const Alphabet: FC<IProps> = ({ lang, className, defaultValue, onChange }) => {
  const [state, setState] = useToState({
    valueName: defaultValue ?? '',
  });

  const onChangeName = (valueName) => () => {
    const formationValue = defaultValue === valueName ? '' : valueName;

    setState({
      valueName: formationValue,
    });

    onChange && onChange(formationValue);
  };

  const renderLibraryLang = () => {
    const types = {
      [LOCAL.EN]: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
      ],
      [LOCAL.RU]: [
        'А',
        'Б',
        'В',
        'Г',
        'Д',
        'Е',
        'Ё',
        'Ж',
        'З',
        'И',
        'Й',
        'К',
        'Л',
        'М',
        'Н',
        'О',
        'П',
        'Р',
        'С',
        'Т',
        'У',
        'Ф',
        'Х',
        'Ц',
        'Ч',
        'Ш',
        'Щ',
        'Э',
        'Ю',
        'Я',
      ],
      [LOCAL.DE]: [
        'A',
        'B',
        'C',
        'D',
        'E',
        'F',
        'G',
        'H',
        'I',
        'J',
        'K',
        'L',
        'M',
        'N',
        'O',
        'P',
        'Q',
        'R',
        'S',
        'T',
        'U',
        'V',
        'W',
        'X',
        'Y',
        'Z',
        'Ä',
        'Ö',
        'Ü',
        'ß',
      ],
    };

    const data = types[lang ?? LOCAL.RU];

    return data.map((el) => {
      const classesAlphabetName = classNames('alphabet__name', {
        'alphabet__name--active': el === state.valueName,
      });

      return (
        <Text variant="md" key={el} className={classesAlphabetName} onClick={onChangeName(el)}>
          {el}
        </Text>
      );
    });
  };

  const classesPrompt = classNames('alphabet', className, {});

  return <div className={classesPrompt}>{renderLibraryLang()}</div>;
};

export default Alphabet;
