import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELQuestionFile(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="27" fill="none" viewBox="0 0 24 27" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M8.001 1a6.033 6.033 0 00-6.046 6.046v12.907A6.033 6.033 0 008 26h8.838a6.033 6.033 0 006.046-6.047v-4.709c0-3.388-.075-4.842-2.326-7.093l-2.325-2.384-2.326-2.383C14.064 1.539 13.382 1 10.792 1h-2.79z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M12.306 20.291a1.165 1.165 0 110-2.33 1.165 1.165 0 010 2.33zm1.92-6.308c-.552.425-.856.66-.856 1.336v.246c0 .534-.473.972-1.056.972-.585 0-1.06-.438-1.06-.972v-.246c0-1.581.969-2.328 1.608-2.822.553-.427.856-.66.856-1.335 0-.762-.63-1.385-1.405-1.385-.774 0-1.404.621-1.404 1.385 0 .537-.475.97-1.059.97-.582 0-1.059-.433-1.059-.97 0-1.834 1.581-3.326 3.522-3.326 1.94 0 3.52 1.49 3.52 3.326 0 1.58-.966 2.325-1.607 2.82z"
        clipRule="evenodd"
      />
    </svg>
  );
}
