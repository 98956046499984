import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELTestsLibrary(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="30" fill="none" viewBox="0 0 35 30" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M12.245 8.583a1 1 0 00-1.414 0l-1.54 1.54-.584-.582a1 1 0 00-1.414 1.414l.936.936a1.5 1.5 0 002.122 0l1.894-1.894a1 1 0 000-1.414zm3.757.917a1 1 0 100 2h10.5a1 1 0 100-2h-10.5zm0 5a1 1 0 100 2h3.5a1 1 0 100-2h-3.5zm-6.502 2a1 1 0 100-2 1 1 0 000 2zm16.573 8.122a3.548 3.548 0 113.549-3.548 3.554 3.554 0 01-3.549 3.548zm0 2a5.548 5.548 0 114.56-2.389c.039.03.076.061.112.097l1.981 1.981.99.991a1 1 0 01-1.413 1.414l-.991-.99-1.982-1.982a1.006 1.006 0 01-.096-.112 5.528 5.528 0 01-3.16.99z"
        clipRule="evenodd"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2.012"
        d="M17.5 24H7.836A5.823 5.823 0 012 18.164V7.836A5.823 5.823 0 017.836 2h18.328A5.823 5.823 0 0132 7.836V14"
      />
    </svg>
  );
}
