import React from 'react';

export default function StorageArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" fill="none" viewBox="0 0 21 16">
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        d="M8.45 2L2.264 8.186l6.186 6.186M19 8.196H5.333"
      />
    </svg>
  );
}
