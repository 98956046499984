import './index.scss';
import React, { FC, ReactElement } from 'react';
import { uuid } from '../../../utils';
import ModalQuestionAudio from './ModalQuestionAudio';
import ModalQuestionVideo from './ModalQuestionVideo';

interface IProps {
  data: {
    params: any;
    setAlert?(data: any): void;
  };
  onDestroy: any;
  ButtonClose?: any;
}

const ModalQuestion: FC<IProps> = function ({ data, onDestroy, ButtonClose }): ReactElement {
  const { params } = data;

  const loading = params?.loading;

  const onChangeEndCode = () => {
    if (!loading?.endCode || !data?.setAlert) return null;

    data.setAlert({
      code: loading.endCode,
      uuid: uuid(),
      params: {},
    });
  };

  const onConfirm = async (file, key) => {
    await data.params?.onConfirm(file, key);

    onChangeEndCode();

    onDestroy();
  };

  const renderTypes = () => {
    const types = {
      AttachVideo: [() => <ModalQuestionVideo data={data} onConfirm={onConfirm} onDestroy={onDestroy} />],
      AttachAudio: [() => <ModalQuestionAudio data={data} onConfirm={onConfirm} onDestroy={onDestroy} />],
    };

    const [component] = types[data.params?.type];

    return component();
  };

  return (
    <div className="modal-question">
      <ButtonClose />
      {renderTypes()}
    </div>
  );
};

export default ModalQuestion;
