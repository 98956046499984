import api from './builder.questions.api';
import {
  IBuilderTestsGetQuestion,
  IBuilderTestsQuestionAudio,
  IBuilderTestsQuestionsFrom,
  IBuilderTestsQuestionsPosition,
  IBuilderTestsQuestionTo,
  IBuilderTestsQuestionVideo,
} from './builder.questions.resources';

export function questionCreate(id: number, data: IBuilderTestsQuestionTo): Promise<IBuilderTestsGetQuestion> {
  const request = api.init('questionCreate');

  return request({
    path: id,
    params: data,
  });
}

export function getQuestions(id: number): Promise<IBuilderTestsQuestionsFrom> {
  const request = api.init('getQuestions');

  return request({
    path: id,
  });
}

export function questionUpdate(id: number, data: IBuilderTestsQuestionTo): Promise<IBuilderTestsGetQuestion> {
  const request = api.init('questionUpdate');

  return request({
    path: id,
    params: data,
  });
}

export function questionUpdateAudio(id: number, data: IBuilderTestsQuestionAudio): Promise<IBuilderTestsGetQuestion> {
  const request = api.init('questionUpdateAudio');

  return request({
    path: id,
    params: data,
  });
}

export function questionUpdateVideo(id: number, data: IBuilderTestsQuestionVideo): Promise<IBuilderTestsGetQuestion> {
  const request = api.init('questionUpdateVideo');

  return request({
    path: id,
    params: data,
  });
}

export function questionDelete(id: number): Promise<IBuilderTestsGetQuestion> {
  const request = api.init('questionDelete');

  return request({
    path: id,
  });
}

export function questionCopy(questionId: number): Promise<any> {
  const request = api.init('questionCopy');

  return request({
    path: questionId,
  });
}

export function questionPosition(id: number, data: IBuilderTestsQuestionsPosition): Promise<any> {
  const request = api.init('questionPosition');

  return request({
    path: id,
    params: data,
  });
}

export function questionCreateAnswer(questionId: number): Promise<any> {
  const request = api.init('questionCreateAnswer');

  return request({
    path: questionId,
  });
}

export function questionDeleteAnswer(questionIdAnswerId: number): Promise<any> {
  const request = api.init('questionDeleteAnswer');

  return request({
    path: questionIdAnswerId,
  });
}
