import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELFilter(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="none" viewBox="0 0 19 21" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M11.5 3.25H18m-17 0h6.5M8.25 1v4.5M9.5 17.25H18m-17 0h4.5M6.25 15v4.5m3.25-9.25H1m17 0h-4.5M12.75 8v4.5"
      />
    </svg>
  );
}
