import React from 'react';

export default function Flag() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" fill="none" viewBox="0 0 14 18">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M2 1a1 1 0 00-2 0v16a1 1 0 102 0v-5h11a1 1 0 00.707-1.707L10.414 7l3.293-3.293A1 1 0 0013 2H2V1z"
        clipRule="evenodd"
      />
    </svg>
  );
}
