import './index.scss';
import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useEvent, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { URI } from '../../../routes/uri';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { AudioPlayer, Icon } from '../../app.modules';
import { setModal } from '../../Modal/modal.actions';
import { MODALS_TRIGGER } from '../../Modal/modal.types';
import { typesAccept } from '../../Storage/storage.types';
import { questionUpdateAudio } from '../builder.questions.services';
import Bridge from '../BuilderQuestionsCreate/questions.create.pipes';

interface IProps {
  data?: any;
  setModalAction?: any;
  questionUpdateAudioAction?: any;
  setAlertAction?: any;
  onChange: any;
  onClose: any;
}

const QuestionAudio: FC<IProps> = function ({
  data,
  setModalAction,
  questionUpdateAudioAction,
  setAlertAction,
  onChange,
  onClose,
}) {
  const [state, setState] = useToState({
    audio: data?.params?.audio,
  });

  const onDeleteAudio = () => {
    setModalAction({
      code: MODALS_TRIGGER.CONDITION,
      params: {
        title: 'Удалить аудио?',
        prevBtnTitle: translate('g.btn.cancel'),
        nextBtnTitle: translate('g.btn.delete'),
        async onConfirm() {
          const requestData = Bridge.onRequestData(data.questionData);

          questionUpdateAudioAction(requestData.id, {
            audioId: null,
          })
            .then(() => {
              onChange({
                audio: null,
                audioId: null,
              });
              setAlertAction({
                code: '080202',
                params: {},
              });
            })
            .catch(() => {
              setAlertAction({
                code: '089999',
              });
            });
        },
      },
    });
  };

  const onOpenStorage = (typesAccept) => () => {
    setModalAction({
      code: MODALS_TRIGGER.STORAGE.OPEN,
      uuid: uuid(),
      params: {
        accept: [typesAccept],
        onConfirm(audio) {
          setState({
            audio,
          });

          const requestData = Bridge.onRequestData(data.questionData);

          questionUpdateAudioAction(requestData.id, {
            ...requestData,
            audioId: audio?.id,
          })
            .then(() => {
              setAlertAction({
                code: '080202',
              });
            })
            .catch(() => {
              setAlertAction({
                code: '089999',
              });
            });

          onChange({
            audio,
            audioId: audio?.id,
          });
        },
      },
    });
  };

  const onCloseMount = (e) => {
    if (!e.target.closest('.question-audio') && !e.target.closest('.modal') && !e.target.closest('.modal__close')) {
      onClose();
    }
  };

  useEvent('click', onCloseMount);

  return (
    <div className="question-audio">
      <div className="question-audio__player">
        <AudioPlayer url={URI.STORAGE.FILE(state.audio?.id)}>
          {({ isPlaying, togglePlayPause, progressBarRef, changeRange, changeRangeMove }) => (
            <>
              <div className="question-audio__play" onClick={togglePlayPause}>
                <Icon name={isPlaying ? 'ELPause' : 'ELPlay'} className="question-audio__play-icon" />
              </div>
              <input
                type="range"
                className="audio-player__progress-bar"
                defaultValue="0"
                ref={progressBarRef}
                onMouseUp={changeRange}
                onMouseDown={changeRangeMove}
              />
            </>
          )}
        </AudioPlayer>
      </div>
      <div className="question-audio__controls">
        <Icon name="ELPencil" className="question-audio__control" onClick={onOpenStorage(typesAccept.AUDIO)} />
        <Icon name="Trash" className="question-audio__control" onClick={onDeleteAudio} />
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setModalAction: bindActionCreators(setModal, dispatch),
  questionUpdateAudioAction: bindActionCreators(questionUpdateAudio, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(QuestionAudio);
