import translate from '../i18n/translate';

interface IRouters {
  [key: string]: string;
} //TODO [Deprecated]: Will be remove

export const TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n

export const PAGE: IRouters = {
  HOME: '/',
  ADMIN_PANEL: '/panel',
  ADMIN_HOST_PANEL: '/admin',
  DASHBOARD: '/-',
  BUILDER_PAGE: '/build/page',
  BUILDER_TEST: '/build/test',
  BUILDER_QUESTIONS: '/build/test/-',
  VIEW_TEST: '/test',
  LEARNING: '/in',
  DEV: '/dev',
  ERROR: '/404',
  ANY: '*',
  ACCEPTS_INVITES: '/accept-invite',
  ACCEPTS_VERIFY: '/verify',
}; //TODO [Deprecated]: Will be remove

export const APP = {
  ID: 'ROOT',
  PATHNAME: '/',
  CHILDREN: {
    AUTH_LOGIN: {
      ID: 'AL',
      PATHNAME: '/login',
    },
    AUTH_SIGNUP: {
      ID: 'AS',
      PATHNAME: '/signup',
    },
    AUTH_FORGOT: {
      ID: 'AF',
      PATHNAME: '/forgot',
    },
    AUTH_FORGOT_SUCCESS: {
      ID: 'AFS',
      PATHNAME: '/forgot/send',
    },
    AUTH_RESET_PASSWORD: {
      ID: 'ARP',
      PATHNAME: '/reset-password',
    },
    ADMIN_PANEL: {
      ID: 'AP',
      PATHNAME: PAGE.ADMIN_PANEL,
      CHILDREN: {
        EVENTS: {
          ID: 'AP/E',
          PATHNAME: `${PAGE.ADMIN_PANEL}/events`,
          MODE: `${PAGE.ADMIN_PANEL}/events/:type`,
          TITLE: translate('events.name'),
          CHILDREN: {
            EVENT: {
              ID: 'AP/E/-/',
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/:type`,
              SIGNATURES: [':id'],
              BACK: true,
            },
            CURRENT: {
              ID: 'AP/E/C',
              TITLE: translate('events.overview'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/events/overview`,
            },
            UPCOMING: {
              ID: 'AP/E/U',
              TITLE: translate('events.active'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/events/active`,
            },
            COMPLETED: {
              ID: 'AP/E/CD',
              TITLE: translate('events.completed'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/events/stale`,
            },
            ARCHIVE: {
              ID: 'AP/E/A',
              TITLE: translate('events.archive'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/events/archive`,
            },
            SETTINGS: {
              ID: 'AP/E/-/S',
              TITLE: translate('g.settings'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/settings`,
              SIGNATURES: [':id'],
            },
            STRUCTURE: {
              ID: 'AP/E/-/ST',
              TITLE: translate('g.content'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/struct`,
              SIGNATURES: [':id'],
            },
            USERS: {
              ID: 'AP/E/-/U',
              TITLE: translate('g.mmb'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/users`,
              SIGNATURES: [':id'],
            },
            PARTICIPANT: {
              ID: 'AP/E/-/P',
              TITLE: translate('g.usr.s'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/members`,
              SIGNATURES: [':id'],
              CHILDREN: {
                USER: {
                  ID: 'AP/E/-/P/U',
                  TITLE: translate('g.see'),
                  PATHNAME: (id, userId) => `${PAGE.ADMIN_PANEL}/events/-/${id}/members/${userId}`,
                  SIGNATURES: [':id', ':userId'],
                  BACK: true,
                  CHILDREN: {
                    HOMEWORK: {
                      ID: 'AP/E/-/P/U/H',
                      TITLE: `ДЗ`,
                      PATHNAME: (id, userId, elementId) =>
                        `${PAGE.ADMIN_PANEL}/events/-/${id}/members/${userId}/homework/${elementId}`,
                      SIGNATURES: [':id', ':userId', ':elementId'],
                    },
                    STATISTIC: {
                      ID: 'AP/E/-/P/U/S',
                      TITLE: translate('g.statistic'),
                      PATHNAME: (id, userId, elementId) =>
                        `${PAGE.ADMIN_PANEL}/events/-/${id}/members/${userId}/stats/${elementId}`,
                      SIGNATURES: [':id', ':userId', ':elementId'],
                      BACK: true,
                      CHILDREN: {
                        ATTEMPT: {
                          ID: 'AP/E/-/P/U/S/A',
                          TITLE: translate('g.attempt'),
                          PATHNAME: (id, userId, elementId, attemptId) =>
                            `${PAGE.ADMIN_PANEL}/events/-/${id}/members/${userId}/stats/${elementId}/${attemptId}`,
                          SIGNATURES: [':id', ':userId', ':elementId', ':attemptId'],
                        },
                      },
                    },
                  },
                },
              },
            },
            SITE: {
              ID: 'AP/E/-/SC',
              TITLE: translate('g.site'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/events/-/${id}/site`,
              SIGNATURES: [':id'],
            },
          },
        },
        TESTS: {
          ID: 'AP/T',
          PATHNAME: `${PAGE.ADMIN_PANEL}/tests`,
          TITLE: translate('ts.library'),
          CHILDREN: {
            TEST: {
              ID: 'AP/T/-/',
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/tests/-/${id}/:type`,
              SIGNATURES: [':id'],
              BACK: true,
            },
            SETTINGS: {
              ID: 'AP/T/-/S',
              TITLE: translate('ts.overview'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/tests/-/${id}/settings`,
              SIGNATURES: [':id'],
            },
            FORMATION: {
              ID: 'AP/T/-/F',
              TITLE: translate('ts.formation'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/tests/-/${id}/formation`,
              SIGNATURES: [':id'],
            },
            RESULTS: {
              ID: 'AP/T/-/R',
              TITLE: translate('ts.results'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/tests/-/${id}/results`,
              SIGNATURES: [':id'],
            },
          },
        },
        AGENCY: {
          ID: 'AP/A',
          PATHNAME: `${PAGE.ADMIN_PANEL}/agency`,
          MODE: `${PAGE.ADMIN_PANEL}/agency/:type`,
          TITLE: translate('g.mmb'),
          CHILDREN: {
            USERS: {
              ID: 'AP/A/U',
              TITLE: translate('agency.staff'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/agency/users`,
            },
            INVITATIONS: {
              ID: 'AP/A/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/agency/invitations`,
            },
            ROLES: {
              ID: 'AP/A/R',
              TITLE: translate('g.roles'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/agency/roles`,
            },
            PROFILE: {
              ID: 'AP/A/-/P',
              PATHNAME: `${PAGE.ADMIN_PANEL}/agency/-/profile`,
              TITLE: translate('agency.profile'),
            },
            SWITCH: {
              ID: 'AP/A/-/S',
              PATHNAME: `${PAGE.ADMIN_PANEL}/agency/-/switch`,
              TITLE: translate('agency.switch'),
            },
          },
        },
        PROFILE: {
          ID: 'AP/P',
          PATHNAME: `${PAGE.ADMIN_PANEL}/me`,
          TITLE: translate('g.my.profile'),
        },
        AGENCY_PROFILE: {
          ID: 'AP/PA',
          PATHNAME: `${PAGE.ADMIN_PANEL}/profile/agency`,
          TITLE: translate('agency.profile'),
        },
        GROUPS: {
          ID: 'AP/G',
          PATHNAME: `${PAGE.ADMIN_PANEL}/groups`,
          TITLE: 'Группы',
          CHILDREN: {
            GROUP: {
              ID: 'AP/G/-/',
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/groups/-/${id}/:type`,
              SIGNATURES: [':id'],
              BACK: true,
            },
            SETTINGS: {
              ID: 'AP/G/-/S',
              TITLE: `Настройки`,
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/groups/-/${id}/settings`,
              SIGNATURES: [':id'],
            },
            INVITATIONS: {
              ID: 'AP/G/-/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/groups/-/${id}/invitations`,
              SIGNATURES: [':id'],
            },
            PARTICIPANTS: {
              ID: 'AP/G/-/P',
              TITLE: `Участники`,
              PATHNAME: (id) => `${PAGE.ADMIN_PANEL}/groups/-/${id}/participants`,
              SIGNATURES: [':id'],
            },
          },
        },
        EXPAND: {
          ID: 'AP/EX',
          PATHNAME: `${PAGE.ADMIN_PANEL}/expand`,
          MODE: `${PAGE.ADMIN_PANEL}/expand/:type`,
          TITLE: translate('expand.opportunities'),
          CHILDREN: {
            PLAN: {
              ID: 'AP/EX/T',
              TITLE: translate('expand.rate'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/expand/plan`,
            },
            PLAN_HISTORY: {
              ID: 'AP/EX/H',
              TITLE: translate('expand.payment.history'),
              PATHNAME: `${PAGE.ADMIN_PANEL}/expand/history`,
            },
          },
        },
        STORAGE: {
          ID: 'AP/S',
          PATHNAME: `${PAGE.ADMIN_PANEL}/storage`,
          TITLE: translate('storage.tlt'),
        },
        NOTIFICATIONS: {
          ID: 'AP/N',
          PATHNAME: `${PAGE.ADMIN_PANEL}/notifications`,
          TITLE: translate('notice.name'),
        },
      },
    },
    ADMIN_HOST_PANEL: {
      ID: 'AHP',
      PATHNAME: PAGE.ADMIN_HOST_PANEL,
      CHILDREN: {
        USERS: {
          ID: 'AHP/U',
          PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/users`,
          CHILDREN: {
            CREATE_USER: {
              ID: 'AHP/U/C',
              PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/users/create`,
            },
            UPDATE_USER: {
              ID: 'AHP/U/U',
              PATHNAME: (userId) => `${PAGE.ADMIN_HOST_PANEL}/users/${userId}`,
            },
          },
        },
        AGENCY: {
          ID: 'AHP/A',
          PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/agency`,
          CHILDREN: {
            CREATE_AGENT: {
              ID: 'AHP/A/C',
              PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/agency/create`,
            },
            UPDATE_AGENCY: {
              ID: 'AHP/A/U',
              PATHNAME: (agencyId) => `${PAGE.ADMIN_HOST_PANEL}/agency/${agencyId}`,
            },
          },
        },
        AGENCY_USERS: {
          ID: 'AHP/A/UL',
          PATHNAME: (agencyId) => `${PAGE.ADMIN_HOST_PANEL}/agency/${agencyId}/users`,
          SIGNATURES: [':agencyId'],
        },
        AGENCY_REGISTER_REQUEST: {
          ID: 'AHP/A/RQ',
          PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/agency/register-request`,
        },
        POSTS: {
          ID: 'AHP/P',
          PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/posts`,
          MODE: `${PAGE.ADMIN_HOST_PANEL}/posts/:type`,
          CHILDREN: {
            NEWS: {
              ID: 'AHP/P/N',
              PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/posts/news`,
            },
            INSTRUCTION: {
              ID: 'AHP/P/I',
              PATHNAME: `${PAGE.ADMIN_HOST_PANEL}/posts/instruction`,
            },
            POST: {
              ID: 'AHP/P/S',
              PATHNAME: (agencyId) => `${PAGE.ADMIN_HOST_PANEL}/posts/settings/${agencyId}`,
            },
          },
        },
      },
    },
    DASHBOARD: {
      ID: 'DP',
      PATHNAME: PAGE.DASHBOARD,
      CHILDREN: {
        EVENTS: {
          ID: 'DP/E',
          PATHNAME: `${PAGE.DASHBOARD}/events`,
          MODE: `${PAGE.DASHBOARD}/events/:type`,
          TITLE: translate('events.name'),
          CHILDREN: {
            CURRENT: {
              ID: 'DP/E/C',
              TITLE: translate('events.current'),
              PATHNAME: `${PAGE.DASHBOARD}/events/current`,
            },
            UPCOMING: {
              ID: 'DP/E/U',
              TITLE: translate('events.upcoming'),
              PATHNAME: `${PAGE.DASHBOARD}/events/upcoming`,
            },
            COMPLETED: {
              ID: 'DP/E/CD',
              TITLE: translate('events.completed'),
              PATHNAME: `${PAGE.DASHBOARD}/events/completed`,
            },
            INVITATIONS: {
              ID: 'DP/E/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: `${PAGE.DASHBOARD}/events/invitations`,
            },
            REQUESTS: {
              ID: 'DP/E/RS',
              TITLE: translate('events.request'),
              PATHNAME: `${PAGE.DASHBOARD}/events/requests`,
            },
            PASSING: {
              ID: 'DP/E/-/',
              TITLE: translate('events.passing'),
              PATHNAME: (id) => `${PAGE.DASHBOARD}/events/-/${id}`,
              SIGNATURES: [':id'],
              BACK: true,
              CHILDREN: {
                STATISTIC: {
                  ID: 'DP/E/-/-/ST',
                  TITLE: translate('g.statistic'),
                  PATHNAME: (id, elementId) => `${PAGE.DASHBOARD}/events/-/${id}/${elementId}/stats`,
                  SIGNATURES: [':id', ':elementId'],
                },
                ATTEMPT: {
                  ID: 'DP/E/-/-/ST/A',
                  TITLE: translate('g.attempt'),
                  PATHNAME: (id, elementId, attemptId) =>
                    `${PAGE.DASHBOARD}/events/-/${id}/${elementId}/stats/${attemptId}`,
                  SIGNATURES: [':id', ':elementId'],
                },
                HOMEWORK: {
                  ID: 'DP/E/-/-/H',
                  TITLE: translate('homework.tlt'),
                  PATHNAME: (id, elementId) => `${PAGE.DASHBOARD}/events/-/${id}/${elementId}/homework`,
                  SIGNATURES: [':id', ':elementId'],
                },
                STREAM: {
                  ID: 'DP/E/-/-/STR',
                  TITLE: translate('g.broadcast'),
                  PATHNAME: (id, elementId) => `${PAGE.DASHBOARD}/events/-/${id}/${elementId}/stream`,
                  SIGNATURES: [':id', ':elementId'],
                },
                WEBINAR: {
                  ID: 'DP/E/-/-/WEB',
                  TITLE: translate('g.webinar'),
                  PATHNAME: (id, elementId) => `${PAGE.DASHBOARD}/events/-/${id}/${elementId}/webinar`,
                  SIGNATURES: [':id', ':elementId'],
                },
              },
            },
          },
        },
        // NOTIFICATIONS: `${PAGE.DASHBOARD}/n`,
        PROFILE: {
          ID: 'DP/P',
          PATHNAME: `${PAGE.DASHBOARD}/me`,
          TITLE: translate('g.my.profile'),
        },
        NOTIFICATIONS: {
          ID: 'DP/N',
          PATHNAME: `${PAGE.DASHBOARD}/notifications`,
          TITLE: translate('notice.name'),
        },
        GROUPS: {
          ID: 'DP/G',
          PATHNAME: `${PAGE.DASHBOARD}/groups`,
          MODE: `${PAGE.DASHBOARD}/groups/:type`,
          TITLE: 'Группы',
          CHILDREN: {
            OVERVIEW: {
              ID: 'DP/G/O',
              TITLE: `Мои группы`,
              PATHNAME: `${PAGE.DASHBOARD}/groups/overview`,
            },
            INVITATIONS: {
              ID: 'DP/G/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: `${PAGE.DASHBOARD}/groups/invitations`,
            },
            GROUP: {
              ID: 'DP/G/-/',
              TITLE: 'Группа',
              PATHNAME: (id) => `${PAGE.DASHBOARD}/groups/-/${id}`,
              SIGNATURES: [':id'],
              BACK: true,
            },
          },
        },
        PAYMENTS: {
          ID: 'DP/PMT',
          PATHNAME: `${PAGE.DASHBOARD}/payments`,
          TITLE: translate('pay.name'),
        },
      },
    },
    LEARNING: {
      ID: 'L',
      PATHNAME: PAGE.LEARNING,
      CHILDREN: {
        PREVIEW: {
          ID: 'L/--/',
          PATHNAME: (eventId, elementId) => `${PAGE.LEARNING}/-/${eventId}-${elementId}`,
          SIGNATURES: [':eventId', ':elementId'],
        },
        PASSING: {
          ID: 'L/--/--/',
          PATHNAME: (eventId, elementId, attemptId, questionId) =>
            `${PAGE.LEARNING}/-/${eventId}-${elementId}/${attemptId}-${questionId}`,
          SIGNATURES: [':eventId', ':elementId', ':attemptId', ':questionId'],
        },
        RESULT: {
          ID: 'L/-/-/-/',
          PATHNAME: (eventId, attemptId, elementId) => `${PAGE.LEARNING}/-/${eventId}/${attemptId}/${elementId}`,
          SIGNATURES: [':eventId', ':attemptId', ':elementId'],
        },
      },
    },
    BUILDER_QUESTIONS: {
      ID: 'BQ',
      PATHNAME: PAGE.BUILDER_QUESTIONS,
      CHILDREN: {
        CREATE: {
          ID: 'BQ/-/',
          PATHNAME: (testId) => `${PAGE.BUILDER_QUESTIONS}/${testId}`,
          SIGNATURES: [':id'],
        },
      },
    },
    SITE: {
      ID: 'S',
      PATHNAME: '/site',
      CHILDREN: {
        CREATE: {
          ID: 'S/C',
          PATHNAME: '/site/course',
          SIGNATURES: [':id'],
        },
      },
    },
    CONFERENCE: {
      ID: 'C',
      PATHNAME: '/',
      CHILDREN: {
        CREATE: {
          ID: 'C/R',
          PATHNAME: (roomName) => `/${roomName}`,
          SIGNATURES: [':id'],
        },
      },
    },
    ACCEPT: {
      ID: 'M',
      PATHNAME: '/',
      CHILDREN: {
        ACCEPTS_INVITES: {
          ID: 'MC/I',
          PATHNAME: (acceptId) => `${PAGE.ACCEPTS_INVITES}/${acceptId}`,
          SIGNATURES: [':id'],
        },
        ACCEPTS_VERIFY: {
          ID: 'MV/I',
          PATHNAME: (acceptId) => `${PAGE.ACCEPTS_VERIFY}/${acceptId}`,
          SIGNATURES: [':id'],
        },
      },
    },
  },
};

export const getRoute = (routeId, key) => {
  let searchSave = {};

  const moving = (object) => {
    const sample = Object.keys(object);

    for (const key of sample) {
      const item = object[key];

      const CHILDREN = item?.CHILDREN;

      const ID = item?.ID;

      if (ID === routeId) {
        searchSave = item;
        return null;
      }

      if (CHILDREN) {
        moving(CHILDREN);
      }
    }
  };

  moving(APP.CHILDREN);

  const typesPath = () => {
    const types = {
      path: 'PATHNAME',
      title: 'TITLE',
      mode: 'MODE',
    };

    const search = types[key];

    return search ? searchSave[search] : searchSave;
  };

  return typesPath();
};

export const GENERAL = {
  AUTH: {
    ADMIN: {
      LOGIN: '/admin',
      SIGN_UP: '/admin/signup',
      REDIRECT: `${PAGE.ADMIN_PANEL}/events`,
    },
    USER: {
      LOGIN: '/login',
      SIGN_UP: '/signup',
      REDIRECT: PAGE.DASHBOARD,
    },
  },
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n

export const HOME: IRouters = {
  ADMIN_LOGIN: `${GENERAL.AUTH.ADMIN.LOGIN}`,
  ADMIN_SIGN_UP: `${GENERAL.AUTH.ADMIN.SIGN_UP}`,
  USER_LOGIN: `${GENERAL.AUTH.USER.LOGIN}`,
  USER_SIGN_UP: `${GENERAL.AUTH.USER.SIGN_UP}`,
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n
