import React from 'react';

interface IProps {
  innerRef: any;
  className?: string;
}

export default function ELHint(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M9.999 19A9 9 0 1119 9.999 9.011 9.011 0 019.999 19z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M9.828 15.02a.938.938 0 110-1.874.938.938 0 010 1.875zm1.544-5.075c-.443.342-.688.53-.688 1.076v.197c0 .43-.38.782-.85.782s-.852-.352-.852-.782v-.197c0-1.273.779-1.873 1.293-2.271.445-.343.688-.53.688-1.074 0-.614-.507-1.114-1.13-1.114-.623 0-1.129.5-1.129 1.114 0 .432-.382.78-.852.78-.469 0-.852-.348-.852-.78C7 6.2 8.272 5 9.834 5c1.56 0 2.832 1.2 2.832 2.676 0 1.27-.778 1.87-1.294 2.27z"
        clipRule="evenodd"
      />
    </svg>
  );
}
