import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Icon, LocationDate, Text } from '../../app/app.modules';
import { useRouter, useToState } from '../../hooks';
import { getRoute } from '../../routes/client';

interface IProps {
  data: any;
}

const CardAttach: FC<IProps> = ({ data }): ReactElement | null => {
  const { history } = useRouter();

  const attachData = data?.data;

  const [state, setState] = useToState({
    showContent: false,
  });

  const onToggleShowContent = (e) => {
    e.stopPropagation();

    setState({
      showContent: !state.showContent,
    });
  };

  const onToEvent = (attach) => (e) => {
    e.stopPropagation();

    attach?.eventId && history.push(getRoute('AP/E/-/S', 'path')(attach.eventId));
  };

  const renderAttachContent = () => {
    return (
      <div className="card__attach-content">
        {attachData.map((item, i) => (
          <Text variant="md" className="card__attach-unit" key={i} onClick={onToEvent(item)}>
            {item.event_name}
            <LocationDate startDate={item?.eventStartDate} endDate={item?.eventEndDate}>
              {({ getStartDate, getEndDate, withInfinity }) => (
                <span className="card__attach-unit__date">
                  ( {withInfinity(getStartDate('dd.MM.yyyy'), getEndDate('dd.MM.yyyy'), ' - ')} )
                </span>
              )}
            </LocationDate>
          </Text>
        ))}
      </div>
    );
  };

  const classesTitle = classNames('card__attach-title', {
    'card__attach-title--active': state.showContent,
  });

  const classesIcon = classNames('card__attach-icon', {
    'card__attach-icon--active': state.showContent,
  });

  return attachData.length > 0 ? (
    <div className="card__attach" onClick={onToggleShowContent}>
      <Text variant="md" className={classesTitle}>
        {data?.title}
        <Icon className={classesIcon} name="ArrowUse" />
      </Text>
      {state.showContent && renderAttachContent()}
    </div>
  ) : null;
};

export default CardAttach;
