import React, { FC, useEffect, useRef, useState } from 'react';

interface IProps {
  url: string;
  children: any;
}

const AudioPlayer: FC<IProps> = ({ url, children }) => {
  // state
  const [isPlaying, setIsPlaying] = useState(false);

  const [duration, setDuration] = useState(0);

  const [currentTime, setCurrentTime] = useState(0);

  const [currentVolume, setCurrentVolume] = useState(100);

  // references
  const audioPlayer: any = useRef(null); // reference our audio component

  const progressBar: any = useRef(null); // reference our progress bar

  const progressVolumeBar: any = useRef(null); // reference our progress bar

  const animationRef: any = useRef(null); // reference the animation

  useEffect(() => {
    return () => {
      setIsPlaying(false);
      setDuration(0);
      setCurrentTime(0);
      setCurrentVolume(100);
      cancelAnimationFrame(animationRef.current);
    };
  }, []);

  useEffect(() => {
    onReset();
  }, [url]);

  const calculateDuration = (e) => {
    const { duration } = e.target;

    const seconds = Math.floor(duration);

    if (!isNaN(seconds)) {
      setDuration(seconds);
      progressBar.current.max = seconds;
    }
  };

  const calculateTime = (secs) => {
    const minutes = Math.floor(secs / 60);

    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const seconds = Math.floor(secs % 60);

    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${returnedMinutes}:${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;

    setIsPlaying(!prevValue);
    if (!prevValue) {
      audioPlayer.current.play();
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    if (progressBar.current) {
      progressBar.current.value = audioPlayer.current.currentTime;
      changePlayerCurrentTime();
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();

    if (isPlaying) {
      animationRef.current = requestAnimationFrame(whilePlaying);
    }
  };

  const onReset = () => {
    setIsPlaying(false);
    audioPlayer.current.currentTime = 0;
    progressBar.current.value = 0;
    audioPlayer.current.pause();
    cancelAnimationFrame(animationRef.current);
  };

  useEffect(() => {
    if (+progressBar.current.value === duration) {
      setIsPlaying(false);
      // audioPlayer.current.currentTime = 0;
      // progressBar.current.value = 0;
      audioPlayer.current.pause();
      cancelAnimationFrame(animationRef.current);
    }
  }, [progressBar.current?.value]);

  const changeVolumeRange = () => {
    audioPlayer.current.volume = progressVolumeBar.current.value / 100;
    changePlayerCurrentVolume();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty('--width', `${(progressBar.current.value / duration) * 100}%`);
    setCurrentTime(progressBar.current.value);
  };

  const changePlayerCurrentVolume = () => {
    progressBar.current.style.setProperty('--width', `${(progressVolumeBar.current.value / duration) * 100}%`);
    setCurrentVolume(progressVolumeBar.current.value);
  };

  const backThirty = (value = 30) => {
    progressBar.current.value = Number(+progressBar.current.value - value);
    changeRange();
  };

  const forwardThirty = (value = 30) => {
    progressBar.current.value = Number(+progressBar.current.value + value);
    changeRange();
  };

  const changeRangeMove = () => {
    cancelAnimationFrame(animationRef.current);
  };

  return (
    <>
      <audio
        className="audio-player__source"
        ref={audioPlayer}
        src={url}
        onLoadedMetadata={calculateDuration}
        preload="metadata"
      />
      {children({
        isPlaying,
        togglePlayPause,
        progressBarRef: progressBar,
        changeRange,
        changeRangeMove,
        backThirty,
        forwardThirty,
        duration,
        currentTimeFrom: calculateTime(currentTime),
        currentTimeTo: calculateTime(duration),
        currentVolume,
        progressVolumeBarRef: progressVolumeBar,
        changeVolumeRange,
      })}
    </>
  );
};

export default AudioPlayer;
