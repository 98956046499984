import ModalCompleteTest from './ModalCompleteTest';
import ModalCondition from './ModalCondition';
import ModalCreate from './ModalCreate';
import ModalInvitations from './ModalInvitations';
import ModalMeet from './ModalMeet';
import ModalQuestion from './ModalQuestion';
import ModalSignIn from './ModalSignIn';
import ModalStorage from './ModalStorage';
import ModalSupport from './ModalSupport';
import CreateGroup from './storage/CreateGroup';
import CreateTest from './storage/CreateTest';
import EventAttachHomework from './storage/EventAttachHomework';
import EventAttachPage from './storage/EventAttachPage';
import EventAttachVideStream from './storage/EventAttachVideoStream';
import EventAttachWebinar from './storage/EventAttachWebinar';
import InviteGroup from './storage/InviteGroup';
import LibraryTests from './storage/LibraryTests';
import AttachTests from './storage/LibraryTests/TestsAttach';
import TestsSettings from './storage/LibraryTests/TestsSettings';
import TestsSettingsUpdate from './storage/LibraryTests/TestsSettings/TestSettingsUpdate';
import SendInviteAgency from './storage/SendInviteAgency';
import Theme from './storage/Theme';

export const MODALS_TRIGGER = {
  STORAGE: {
    OPEN: '020001',
  },
  CONDITION: '020003',
  TESTS: {
    CREATE: `tc.1e5d046b`,
    DELETE: `td.ab999817`,
  },
  EVENT_PASSING: {
    COMPLETE_TEST: '020005',
  },
  AGENCY: {
    SEND_INVITE: `as.2cdf1781`,
  },
  THEME: 't.9775e708',
  STRUCTURE: {
    TESTS: 't.d272ba51',
    TESTS_ATTACH: 't.07b0dcd3',
    TESTS_SETTINGS: 't.50cd0503',
    TESTS_SETTINGS_UPDATE: 't.2cf723e7',
    PAGE: 'p.140ab620',
    HOMEWORK: 'h.76da75fe',
    DATA: 'd.676bf65f',
    VIDEO_STREAM: 'v.020002',
    WEBINAR: 'v.020323',
  },
  GROUPS: {
    CREATE: 'gc.d0cc2704',
    INVITE: 'gi.0e4468c5',
  },
  QUESTIONS: {
    ATTACH: '020004',
  },
  EVENT_MEMBERS_INVITE: '020006',
  SUPPORT: '020007',
  SING_IN: '020008',
  SING_UP: '020009',
  CREATE: '020009',
  MEET: '020010',
};

export const MODAL_SIGNATURE_TYPE = {
  FILL: '02',
};

export default {
  [MODALS_TRIGGER.TESTS.CREATE]: CreateTest,
  [MODALS_TRIGGER.GROUPS.CREATE]: CreateGroup,
  [MODALS_TRIGGER.GROUPS.INVITE]: InviteGroup,
  // [MODALS_TRIGGER.EVENTS.CREATE]: CreateEvent,
  [MODALS_TRIGGER.AGENCY.SEND_INVITE]: SendInviteAgency,
  [MODALS_TRIGGER.THEME]: Theme,
  [MODALS_TRIGGER.STRUCTURE.TESTS]: LibraryTests,
  [MODALS_TRIGGER.STRUCTURE.TESTS_ATTACH]: AttachTests,
  [MODALS_TRIGGER.STRUCTURE.TESTS_SETTINGS]: TestsSettings,
  [MODALS_TRIGGER.STRUCTURE.TESTS_SETTINGS_UPDATE]: TestsSettingsUpdate,
  [MODALS_TRIGGER.STRUCTURE.PAGE]: EventAttachPage,
  [MODALS_TRIGGER.STRUCTURE.HOMEWORK]: EventAttachHomework,
  [MODALS_TRIGGER.STRUCTURE.DATA]: MODALS_TRIGGER.STRUCTURE.DATA,
  //
  [MODALS_TRIGGER.STORAGE.OPEN]: ModalStorage,
  [MODALS_TRIGGER.CONDITION]: ModalCondition,
  [MODALS_TRIGGER.STRUCTURE.VIDEO_STREAM]: EventAttachVideStream,
  [MODALS_TRIGGER.QUESTIONS.ATTACH]: ModalQuestion,
  [MODALS_TRIGGER.EVENT_PASSING.COMPLETE_TEST]: ModalCompleteTest,
  [MODALS_TRIGGER.EVENT_MEMBERS_INVITE]: ModalInvitations,
  [MODALS_TRIGGER.SUPPORT]: ModalSupport,
  [MODALS_TRIGGER.SING_IN]: ModalSignIn,
  [MODALS_TRIGGER.CREATE]: ModalCreate,
  [MODALS_TRIGGER.STRUCTURE.WEBINAR]: EventAttachWebinar,
  [MODALS_TRIGGER.MEET]: ModalMeet,
};

export const types = {
  DATA: 'DATA',
  REGISTER: 'REGISTER',
};
