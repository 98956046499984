import './index.scss';
import React, { Component, FC, ReactElement, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  className?: string;
  swipe?: boolean;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 't--question',
  head: 't--question__head',
  body: 't--question__body',
  foot: 't--question__foot',
  choice: 't--question__choice',
  free: 't--question__free',
};

const layoutPart: FC<IParts> | Function = (children, className): ReactElement | Function => (
  <div className={className}>{children}</div>
);

const Head: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.head);

const Body: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.body);

const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

const Choice: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.choice);

const Free: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.free);

class TQuestion extends Component<IProps> {
  public static Head: Function = Head;

  public static Body: Function = Body;

  public static Foot: Function = Foot;

  public static Choice: Function = Choice;

  public static Free: Function = Free;

  render(): JSX.Element {
    const { children, className } = this.props;

    return <div className={`${field.app} ${className}`}>{children}</div>;
  }
}

export default TQuestion;
