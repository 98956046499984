import React from 'react';

interface IProps {
  className?: string;
}

export default function ELEvents(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" fill="none" viewBox="0 0 27 27" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M14.57 14.639l.232.429.481.081 2.273.384a.3.3 0 01.163.508l-1.588 1.595-.353.355.073.496.33 2.239a.3.3 0 01-.427.314l-2.121-1.018-.433-.208-.433.208-2.12 1.018a.3.3 0 01-.428-.314l.33-2.24.073-.495-.353-.355L8.68 16.04a.3.3 0 01.163-.508l2.273-.384.481-.081.232-.43 1.106-2.046a.3.3 0 01.528 0l.88-.476-.88.476 1.106 2.046z"
      />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M1.5 8.4h23" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M18.963 4.593V1.5M7.383 4.593V1.5m6 3.093V1.5"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 7.3a4 4 0 014-4h16.346a4 4 0 014 4v13.95a4 4 0 01-4 4H5a4 4 0 01-4-4V7.3z"
      />
    </svg>
  );
}
