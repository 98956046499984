import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowDownBold(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="11" fill="none" viewBox="0 0 18 11" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.6"
        d="M2.6 2.265L8.964 8.63l6.364-6.364"
      />
    </svg>
  );
}
