export default {
  RU: {
    'stats.start': 'Начало',
    'stats.end': 'Завершение',
    'stats.qs.all': 'Всего вопросов',
    'stats.att.best.time': 'Время лучшей попытки',
    'stats.att.scr': 'Баллы лучшей попытки',
    'stats.test.sts': 'Статус теста',
    'stats.att.worst': 'худшая попытка',
    'stats.att.best': 'лучшая попытка',
    'stats.awr.know.not': 'Ответов “Я не знаю”',
    'stats.awr.correct': 'Верных ответов',
    'stats.tbl.awr.correct': '- верный ответ',
    'stats.tbl.awr.correct.no': '- неверный ответ',
    'stats.tbl.awr.know.not': '- ответ “Я не знаю”',
    'stats.tbl.awr.skip': '- пропуск ответа',
    'stats.tbl.time': 'Время, сек',
    'stats.dtl.tlt': 'Содержание вопроса и ответ ученика',
    'stats.dtl.scr': 'Время и баллы',
    'stats.view.correct': 'Посмотреть правильный ответ',
    'stats.view.more': 'Смотреть подробно',
    'stats.att.best.usr': 'Самая сильная попытка',
    'stats.att.worst.usr': 'Самая слабая попытка',
  },
};
