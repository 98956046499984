function* generatorSplitParts(num, count) {
  let sumParts = 0 as any;

  const parts = num / count;

  for (let i = 0; i < parts - 1; i++) {
    sumParts += count;
    yield sumParts;
  }

  yield num - sumParts;
}

export default generatorSplitParts;
