import classNames from 'classnames';
import React, { FC } from 'react';
import { useToState } from '../../../../../hooks';
import translate from '../../../../../i18n/translate';
import { Handle, Icon, Square, Textarea, Toggle } from '../../../../app.modules';
import CreateScores from '../../CreateScores';
import Bridge from '../../questions.create.pipes';

interface IProps {
  data: any;
  question: any;
  handle?: any;
  onChange(e, data: any): void;
  onDelete(data: any): void;
  isDelete: number;
}

const ChoiceAnswer: FC<IProps> = ({ data, handle, onChange, onDelete, isDelete, question }) => {
  const [state, setState] = useToState({
    isComment: false,
    valueAnswer: data.text || '',
    valueComment: data.comment || '',
    valueScore: data.score || 0,
  });

  const onToggleComment = () => {
    setState((state) => ({
      ...state,
      isComment: !state.isComment,
    }));
  };

  const onChangeTextarea = (e) => {
    const { value } = e?.target ?? { value: e };

    setState({
      valueAnswer: value,
    });

    onChange(data, { text: value });
  };

  const onChangeTextComment = (e) => {
    const { value } = e?.target ?? { value: e };

    setState({
      valueComment: value,
    });

    value.length < 300 && onChange(data, { comment: value });
  };

  const onChangeTextScore = (e) => {
    const { value } = e.target;

    const isValid = value.replace(/[^\d.]/g, '');

    setState({
      valueScore: isValid,
    });

    onChange(data, { score: isValid });
  };

  const onChangeToggle = (e) => {
    const { checked } = e.target;

    onChange(data, { correct: checked });
  };

  const onDeleteChange = () => {
    onDelete(data);
  };

  const classesIconChat = classNames({
    'comment--active': state.isComment,
  });

  const classesComments = classNames('answer-choice__comment', {
    'answer-choice__comment--active': data.comment !== '',
  });

  const isErrorCorrect = Bridge.parseFieldsError(question);

  const isErrorAnswerText = !state.valueAnswer && Bridge.parseAnswerTextError(question);

  const isErrorScores = !+state.valueScore && !+data.score && data.correct && Bridge.parseScoresError(question);

  const isAmountScore = question?.typeFields?.multipleAnswers && !question?.typeFields?.countIncomplete;

  return (
    <div className="answer-choice">
      <div className="answer-choice__entry">
        {handle && <Handle handle={handle} />}
        <Toggle
          error={isErrorCorrect}
          replace
          variant="success"
          defaultChecked={data.correct}
          onChange={onChangeToggle}
        >
          <Icon name="CheckSuccess" />
        </Toggle>
        <Textarea
          error={isErrorAnswerText}
          variant="answer"
          value={state.valueAnswer}
          onChange={onChangeTextarea}
          placeholderNode={translate('qs.awr.pls')}
          errorText={translate('qs.err.awr.txt')}
        />
        {!isAmountScore && (
          <CreateScores
            errorText={translate('qs.err.scr.txt')}
            error={isErrorScores}
            data={state.valueScore}
            onChange={onChangeTextScore}
          />
        )}
        <Square size="lg" className={classesIconChat} revert onClick={onToggleComment}>
          {data.comment !== '' ? <Icon name="Chat" /> : <Icon name="ChatEmpty" />}
        </Square>
        {isDelete > 2 && <Icon name="Trash" className="answer-choice__entry-trash" onClick={onDeleteChange} />}
      </div>
      {state.isComment && (
        <div className={classesComments}>
          <Textarea
            variant="comment"
            value={state.valueComment}
            onChange={onChangeTextComment}
            placeholderNode={translate('qs.cmt.pls')}
          />
        </div>
      )}
    </div>
  );
};

export default ChoiceAnswer;
