import './index.scss';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useErrorFields, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import Button from '../../../Button';
import Input from '../../../Input';
import Textarea from '../../../Textarea';

interface IProps {
  onConfirm(data: { name: string; description: string }): void;
  onClose(): void;
  params?: any;
}

const CreateEvent: FC<IProps> = ({ onConfirm, onClose, params }) => {
  const [state, setState] = useToState({
    name: '',
    description: '',
  });

  const [test, setTest] = useState(null);

  const elemRef: any = useRef(null);

  useEffect(() => {
    setTest(test);
  }, [elemRef]);

  useEffect(() => {
    setTest(elemRef);
    elemRef?.current?.focus();
  }, [test]); //TODO [это пиздец]: переделать (почемуто непроисходит обновления компонента - приходиться обновлять принудительно)

  const [error, setError] = useErrorFields(state, (value, key) => {
    switch (key) {
      case 'name':
        return value?.length;
      default:
        return true;
    }
  });

  const onChangeField = (key) => (e) => {
    const value = e?.target?.value ?? e;

    setError(key, value);

    setState({
      [key]: value,
    });
  };

  const onConfirmData = () => {
    setError(state).then(() => {
      onConfirm(state);
    });
  };

  const title = params?.title ?? 'Новое мероприятие';

  const placeholderInput = params?.placeholderInput ?? 'Введите название мероприятия';

  const placeholderTextarea = params?.placeholderTextarea ?? 'Введите описание мероприятия';

  return (
    <div className="create-event">
      <h1 className="create-event__head">{title}</h1>
      <div className="create-event__row">
        <Input
          innerRef={elemRef}
          placeholder={placeholderInput}
          label={translate('g.name')}
          size="md"
          value={state.name}
          onChange={onChangeField('name')}
          error={!error.name}
        />
      </div>
      <div className="create-event__row">
        <Textarea
          placeholder={placeholderTextarea}
          label="Описание"
          value={state.description}
          onChange={onChangeField('description')}
        />
      </div>
      <div className="create-event__foot">
        <Button size="md" variant="outlined" monochrome onClick={onClose}>
          {translate('g.btn.cancel')}
        </Button>
        <Button off={!error.name} size="md" onClick={onConfirmData}>
          {translate('g.btn.create')}
        </Button>
      </div>
    </div>
  );
};

export default CreateEvent;
