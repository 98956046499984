import './index.scss';
import React, { FC, ReactElement } from 'react';
import translate from '../../../i18n/translate';
import { Button, Icon } from '../../app.modules';

interface IProps {
  close(): void;
  confirm: any;
  copy: any;
  text: string;
}

const DuplicateFile: FC<IProps> = function ({ close, confirm, text, copy }): ReactElement {
  return (
    <div className="confirm">
      <div className="confirm__close" onClick={close}>
        <Icon name="ELClose" />
      </div>
      <h1>{text}</h1>
      <Button size="md" onClick={confirm}>
        Перезаписать
      </Button>
      <Button size="md" onClick={copy}>
        {translate('g.btn.copy')}
      </Button>
      <Button size="md" onClick={close}>
        {translate('g.btn.cancel')}
      </Button>
    </div>
  );
};

export default DuplicateFile;
