import { IExpandAction, IExpandState } from './expand.resources';
import types from './expand.types';

const initialState: IExpandState = {
  activityPlan: null,
  pendingPlan: null,
  nextPlan: null,
  requestPlan: null,
  paymentHistory: null,
};

export default function expandReducer(state: IExpandState = initialState, action: IExpandAction): IExpandState {
  const actionType = {
    [types.ON_ACTIVITY_PLAN]: { ...state, activityPlan: action.activityPlan },
    [types.ON_PENDING_PLAN]: { ...state, pendingPlan: action.pendingPlan },
    [types.ON_NEXT_PLAN]: { ...state, nextPlan: action.nextPlan },
    [types.REQUEST_PLAN]: { ...state, requestPlan: action.requestPlan },
    [types.ON_PAYMENT_HISTORY]: { ...state, paymentHistory: action.paymentHistory },
  };

  return actionType[action.type] ?? state;
}
