import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';

interface IProps {
  children: any;
  type: 'inProgress' | 'completed' | 'notYet';
  signature?: 'status' | null;
}

const Contour: FC<IProps> = ({ type, signature = null, children }) => {
  const classesPrompt = classNames('contour', {
    [`contour--${type}`]: type,
    [`contour--s-${signature}`]: signature,
  });

  return <div className={classesPrompt}>{children}</div>;
};

export default Contour;
