import React from 'react';

interface IProps {
  className?: string;
}

function ELPencil(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M13.283 4.001l2.653 2.653" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M16.341 1.304l2.292 2.292a1 1 0 010 1.414L6.318 17.325a2 2 0 01-.964.535l-4.26.983.983-4.26a2 2 0 01.535-.964L14.927 1.304a1 1 0 011.414 0z"
      />
    </svg>
  );
}

export default ELPencil;
