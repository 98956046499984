import { IStorageAction, IStorageState } from './storage.resources';

const initialState: IStorageState = {};

export default function storageReducer(state: IStorageState = initialState, action: IStorageAction): IStorageState {
  switch (action.type) {
    default:
      return state;
  }
}
