import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useHover } from '../../hooks';
import DivTarget from '../DivTarget';
import Hint from '../Hint';
import Icon from '../Icon';

interface IProps {
  children: any;
  position?: 'right' | 'left';
  label: string | ReactNode;
  view?: boolean;
  size?: null | 'xs' | 'sm' | 'md' | 'lg';
}

const Prompt: FC<IProps> = ({ position, children, label, view = false, size = null }) => {
  const [ref, isHovered] = useHover();

  const classesPrompt = classNames('prompt', {
    [`prompt--${position}`]: position,
    'prompt--view': view,
    'prompt--size': size,
  });

  const classesPolygon = classNames('prompt__polygon', {
    [`prompt__polygon--${size}`]: size,
  });

  return (
    <div className={classesPrompt} ref={ref}>
      {children}
      {isHovered && !view && (
        <div className={classesPolygon}>
          <Hint label={label} move={['right', 'bottom']}>
            {(ref) => (
              <DivTarget innerRef={ref} className="prompt__icon">
                <Icon name="ELHint" />
              </DivTarget>
            )}
          </Hint>
        </div>
      )}
      {size && view && (
        <div className={classesPolygon}>
          <Hint label={label} move={['right', 'bottom']}>
            {(ref) => (
              <DivTarget innerRef={ref} className="prompt__icon">
                <Icon name="ELHint" />
              </DivTarget>
            )}
          </Hint>
        </div>
      )}
      {view && !size && (
        <div className="prompt__polygon">
          <Hint label={label} move={['right', 'bottom']}>
            {(ref) => (
              <DivTarget innerRef={ref} className="prompt__icon">
                <Icon name="ELHint" />
              </DivTarget>
            )}
          </Hint>
        </div>
      )}
    </div>
  );
};

export default Prompt;
