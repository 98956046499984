import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ElStorageSearch(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" fill="none" viewBox="0 0 30 20" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M25.858 9.506H8.306a3 3 0 00-2.35 1.135l-2.093 2.636c-1.561 1.966-.16 4.866 2.35 4.866h15.481a3 3 0 002.35-1.135l3.38-4.258c1.041-1.31.108-3.244-1.566-3.244z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M3.572 4a2 2 0 012-2h3.337a2 2 0 011.406.578l1.65 1.63a4 4 0 002.812 1.155h5.366a2 2 0 012 2v2.143h2V7.363a4 4 0 00-4-4h-5.366a2 2 0 01-1.406-.577l-1.65-1.631A4 4 0 008.909 0H5.572a4 4 0 00-4 4v11.143a4 4 0 004 4h14.571c1.212 0 2.298-.54 3.032-1.39a3 3 0 01-1.48.39H6.212c-2.33 0-3.704-2.497-2.641-4.428V4z"
        clipRule="evenodd"
      />
    </svg>
  );
}
