import './index.scss';
import React, { FC, ReactNode, useRef, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useEvent, useMobile } from '../../hooks';
import { offset } from '../../utils';

interface IProps {
  children: ReactNode;
  onScroll?(data: object): void;
  onLast?: any;
  forceVisible?: string;
  direction?: string;
  forceMobile?: boolean;
}

const Scroll: FC<IProps> = (props) => {
  const { children, onScroll, onLast, direction }: IProps = props;

  const forceMobile = props?.forceMobile ?? false;

  const ref: any = useRef();

  const [startLoad, setStartLoad] = useState<boolean>(false);

  const [isMobile] = useMobile();

  const isStart = (bool: boolean) => {
    setStartLoad(bool);
  };

  const loadCallback = (): null | boolean | undefined => {
    if (!onLast) {
      return null;
    }
    const item = onLast.current;

    if (item === null) {
      return null;
    }

    const wrapperList = item.parentNode.parentNode.parentNode.parentNode;

    const wrapperOffsetTop = offset(wrapperList)?.top;

    const wrapperHeight = wrapperList.clientHeight;

    const itemOffsetTop = offset(item)?.top;

    const itemHeight = item.clientHeight;

    if (wrapperOffsetTop + wrapperHeight >= itemOffsetTop + itemHeight) {
      if (!startLoad) {
        isStart(true);
        return true;
      }
    } else {
      isStart(false);
    }
  };

  const renderScrolling = (data) => {
    const render = loadCallback();

    if (render) {
      onScroll && onScroll(data);
    }
  };

  const onScrollHandler = (data) => {
    onLast && onScroll && renderScrolling(data);
  };

  const defaultOptions: object = {
    scrollableNodeProps: { ref: ref },
    style: {
      maxHeight: '100%',
      height: '100%',
      width: '100%',
    },
    ...(direction && { direction }),
  };

  onScroll && useEvent('scroll', onScrollHandler, ref.current);

  if (isMobile && forceMobile) {
    return <div>{children}</div>;
  }

  return <SimpleBar {...defaultOptions}>{children}</SimpleBar>;
};

export default Scroll;
