export default {
  RU: {
    'profile.info': 'Основные сведения',
    'profile.last.name': 'Фамилия*',
    'profile.last.name.pls': 'Введите фамилию',
    'profile.first.name': 'Имя*',
    'profile.first.name.pls': 'Введите имя',
    'profile.middle.name': 'Отчество',
    'profile.middle.name.pls': 'Введите отчество при наличии',
    'profile.birthdate': 'Дата рождения',
    'profile.gender': 'Пол',
    'profile.email': 'Email',
    'profile.phone': 'Телефон',
    'profile.pass.change': 'Изменить пароль',
    'profile.pass.old': 'Старый пароль',
    'profile.pass.new': 'Новый пароль',
    'profile.pass.btn': 'Изменить пароль',
    'profile.gender.female': 'Женский',
    'profile.gender.male': 'Мужской',
    'profile.gender.other': 'Другое',
    'profile.timezone': 'Часовой пояс',
  },
};
