import React from 'react';

interface IProps {
  className?: string;
}

export default function Sync({ className }: IProps) {
  const params = { className };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none" viewBox="0 0 12 20" {...params}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M6 18.1c-2.052 0-3.72-1.39-3.72-3.1v-3.125c0-1-2.28-1-2.28 0V15c0 2.76 2.688 5 6 5s6-2.24 6-5v-3.125c0-1-2.28-1-2.28 0V15c0 1.71-1.668 3.1-3.72 3.1z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M4.666 6.875v7.5c0 1.25 2.667 1.25 2.667 0v-7.5c0-1.25-2.667-1.25-2.667 0z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M0 5v3.125c0 1 2.28 1 2.28 0V4.999c0-1.71 1.668-3.1 3.72-3.1 2.052 0 3.72 1.39 3.72 3.1v3.126c0 1 2.28 1 2.28 0V4.999c0-2.76-2.688-5-6-5S0 2.24 0 5z"
      />
    </svg>
  );
}
