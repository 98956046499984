import api from './storage.api';
import {
  IStorageCreateFolderFrom,
  IStorageCreateFolderTo,
  IStorageDeleteFolderFrom,
  IStorageDeleteFolderTo,
  IStorageFileFrom,
  IStorageFileTo,
  IStorageFrom,
  IStorageRootsFrom,
  IStorageTo,
} from './storage.resources';

export function setStorage(data: IStorageFileTo, progressCallback, cancelToken): Promise<IStorageFileFrom> {
  const sideEffect = () =>
    api.setStorage({
      params: data,
      progressCallback,
      options: {
        cancelToken,
      },
    });

  return sideEffect as any;
}

export function getRootStorage(): Promise<IStorageRootsFrom> {
  const request = api.init('getRootStorage');

  return request(null);
}

export function getStorage(data: IStorageTo): Promise<IStorageFrom> {
  const request = api.init('getStorage');

  return request({
    params: data,
  });
}

export function getStorageRequest(data: any): Promise<any> {
  const request = api.init('getStorageRequest');

  return request({
    params: data,
  });
}

export function getStoragePartUploadAbort(uploadId: any): Promise<any> {
  const request = api.init('getStoragePartUploadAbort');

  return request({
    path: uploadId,
  });
}

export function getStoragePartUpload(data: any, progressCallback, cancelToken): Promise<any> {
  const sideEffect = () =>
    api.getStoragePartUpload({
      params: data,
      progressCallback,
      options: {
        cancelToken,
      },
    });

  return sideEffect as any;
}

export function createFolderStorage(data: IStorageCreateFolderTo): Promise<IStorageCreateFolderFrom> {
  const request = api.init('createFolderStorage');

  return request({
    params: data,
  });
}

export function deleteStorage(data: IStorageDeleteFolderTo): Promise<IStorageDeleteFolderFrom> {
  const request = api.init('deleteStorage');

  return request({
    params: data,
  });
}

export function deleteMassStorage(data: IStorageDeleteFolderTo): Promise<IStorageDeleteFolderFrom> {
  const request = api.init('deleteMassStorage');

  return request({
    params: data,
  });
}
