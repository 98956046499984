import './index.scss';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { EditorText, Textarea } from '../../app/app.modules';
import { ITestMark } from '../../app/Tests/tests.resources';
import { useErrorFields, useToState } from '../../hooks';
import translate from '../../i18n/translate';
import Icon from '../Icon';
import Input from '../Input';
import Prompt from '../Prompt';
import Square from '../Square';
import Text from '../Text';

interface IProps {
  data: ITestMark;
  showDescription?: boolean;
  onDelete(data: ITestMark): void;
  onChange(markId: number, data: ITestMark): void;
  viewLabel?: boolean;
}

const Mark: FC<IProps> = ({ data, showDescription, onDelete, viewLabel, onChange }) => {
  const [state, setState] = useToState({
    fields: {
      title: data.title,
      description: data.description,
      minScore: data.minScore,
    },
    showDescription: showDescription ?? false,
    isFocus: data.description !== '' ?? false,
    isShowEditorChild: data.description !== '' ?? false,
  });

  const [error, setError] = useErrorFields(state.fields, (value, key) => {
    switch (key) {
      case 'minScore':
        return value > 0;
      default:
        return true;
    }
  });

  const onUpdateMarkBlur = (fields = {}) => () => {
    if (error.minScore && onChange && data.id) {
      onChange(data.id, {
        ...state.fields,
        ...fields,
      });
    }
  };

  const onDeleteMark = () => {
    onDelete && onDelete(data);
  };

  const onChangeRating = (e) => {
    const { value } = e.target;

    setState({
      fields: {
        ...state.fields,
        title: value,
      },
    });
  };

  const onBlurRating = (e) => {
    const { value } = e.target;

    if (data.title !== value) {
      onUpdateMarkBlur()();
    }
  };

  const onChangeScores = (key) => (e) => {
    const { value } = e.target;

    const isValid = value.replace(/[^\d.]/g, '');

    setError(key, +isValid);

    setState({
      fields: {
        ...state.fields,
        [key]: +isValid,
      },
    });
  };

  const onBlurMinScores = () => {
    onUpdateMarkBlur()();
  };

  const onChangeDesc = (description) => {
    setState({
      fields: {
        ...state.fields,
        description,
      },
    });
  };

  const onBlurDesc = (value) => {
    if (data.description !== value) {
      onUpdateMarkBlur()();
    }
    if (!value) {
      setState({
        isFocus: false,
      });
    }
  };

  const onToggleShowDesc = () => {
    setState({
      showDescription: !state.showDescription,
    });
  };

  const onFocusChangeDesc = () => {
    setState({
      isFocus: !state.isFocus,
    });
  };

  const onRenderEditorChild = () => {
    setState({
      isShowEditorChild: state.isFocus,
    });
  };

  useEffect(onRenderEditorChild, [state.isFocus]);

  const renderArrow = () => {
    const classesArrow = classNames('mark__arrow', {
      'mark__arrow--active': state.showDescription,
    });

    return (
      <Square variant="lg" className={classesArrow} onClick={onToggleShowDesc}>
        <Icon name="ArrowStruct" />
      </Square>
    );
  };

  const renderPoints = () => {
    const { minScore } = state.fields;

    const classesInputScoresMin = classNames('mark__points-input', {
      'mark__points-input--empty': +minScore === 0,
    });

    return (
      <div className="mark__points">
        <Text variant="md" className="mark__points__label">
          {translate('g.from')}
        </Text>
        <Input
          className={classesInputScoresMin}
          variant="inline"
          maxLength={3}
          error={!error.minScore}
          value={state.fields.minScore}
          onBlur={onBlurMinScores}
          onChange={onChangeScores('minScore')}
        />
        <Text variant="sm" className="mark__points__sub">
          {translate('ts.scores')}
        </Text>
      </div>
    );
  };

  const renderDescription = () => {
    return state.isShowEditorChild ? (
      <EditorText
        data={state.fields.description ?? ''}
        onChange={onChangeDesc}
        minHeight={80}
        className="mark__description-input"
        onBlur={onBlurDesc}
      />
    ) : (
      <Textarea
        className="mark__description-input"
        variant="answer"
        placeholderNode={translate('ts.score.desc.plc')}
        value={state.fields.description}
        onFocus={onFocusChangeDesc}
      />
    );
  };

  const classesMark = classNames('mark', {
    'mark--viewer': viewLabel,
  });

  return (
    <div className={classesMark}>
      {viewLabel && (
        <div className="mark__head">
          <Text variant="md" className="mark__head-label">
            {translate('ts.grade')}:
          </Text>
          <Text variant="md" className="mark__head-pint">
            {translate('g.point.a')}:
          </Text>
        </div>
      )}
      <Input
        className="mark__input"
        size="md"
        value={state.fields.title}
        onChange={onChangeRating}
        onBlur={onBlurRating}
        placeholderNode={translate('ts.enter.rating')}
      />
      {renderPoints()}
      {renderArrow()}
      {state.showDescription && (
        <div className="mark__description">
          <Text variant="md" className="mark__description-label">
            {translate('ts.score.desc')}
          </Text>
          <Prompt label={translate('ts.desc.hint')}>{renderDescription()}</Prompt>
        </div>
      )}
      <Icon name="Trash" className="mark__trash" onClick={onDeleteMark} />
    </div>
  );
};

export default Mark;
