import React from 'react';

interface IProps {
  className?: string;
}

export default function TimeOld(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="37" fill="none" viewBox="0 0 28 37" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M1.585 36.308c-.363-.116-.8-.587-.91-.982a1.485 1.485 0 01.732-1.734c.312-.16.446-.182 1.282-.204l.934-.024v-2.939c0-2.59.016-3.033.137-3.736.323-1.889 1.105-3.552 2.386-5.078.877-1.044 2.013-1.899 3.61-2.715.492-.251.894-.473.894-.492 0-.02-.402-.24-.894-.492-1.597-.816-2.733-1.67-3.61-2.715-1.281-1.525-2.063-3.19-2.386-5.077-.12-.704-.137-1.147-.137-3.744V3.431h-.79c-.89-.001-1.236-.072-1.615-.33C.91 2.892.6 2.32.6 1.966c0-.648.456-1.301 1.028-1.472.42-.126 24.518-.126 24.94 0 .57.171 1.027.824 1.027 1.472 0 .354-.312.926-.618 1.135-.38.258-.726.329-1.614.33h-.791v2.945c0 2.597-.016 3.04-.137 3.744a10.316 10.316 0 01-1.52 3.907c-1.113 1.712-2.43 2.855-4.485 3.894-.488.246-.887.464-.887.483 0 .02.399.237.887.484 1.56.788 2.74 1.676 3.62 2.723 1.28 1.526 2.062 3.19 2.385 5.078.12.703.137 1.146.137 3.736v2.939l.933.024c.836.022.97.044 1.283.204.622.32.926 1.041.732 1.734-.117.416-.552.868-.953.988-.396.118-24.61.112-24.982-.006zm20.034-5.464c0-1.393-.034-2.837-.075-3.207-.184-1.65-.851-3.095-1.976-4.281-.816-.86-1.275-1.16-3.449-2.252L14.1 20.09l-1.916.958c-2.199 1.1-2.708 1.427-3.496 2.248-1.16 1.208-1.83 2.618-2.034 4.282-.043.356-.077 1.764-.077 3.216v2.584H21.62v-2.534zm-5.131-1.023c-.534-.231-.795-.645-.904-1.427-.084-.607-.264-.91-.715-1.202-.83-.537-1.115-1.27-.781-2.005.392-.862 1.404-1.123 2.303-.591.517.306 1.258 1.045 1.56 1.556.496.84.756 2.057.58 2.71-.098.362-.424.75-.786.935-.324.165-.907.176-1.257.024zm-.476-14.061c2.125-1.063 2.54-1.318 3.243-1.99.862-.826 1.386-1.605 1.823-2.712.465-1.178.486-1.363.522-4.657l.032-2.97H6.575v2.583c0 1.452.035 2.86.078 3.216.203 1.651.87 3.065 2.01 4.26.792.829 1.223 1.108 3.425 2.222 1.086.55 1.982 1 1.992 1.002.01.002.88-.428 1.932-.954zm-3.992-3.435c-1.396-.7-2.42-2.352-2.42-3.903-.001-.864.65-1.547 1.474-1.547.89 0 1.393.504 1.537 1.54.084.607.264.91.715 1.201.627.406.913.853.91 1.422-.005 1.1-1.204 1.795-2.216 1.287z"
      />
    </svg>
  );
}
