import AgencyRoles from './storage/AgencyRoles';
import CreateEvent from './storage/CreateEvent';
import DeleteTest from './storage/DeleteTest';
import EventAttachFile from './storage/EventAttachFile';
import HomeworkConfirm from './storage/HomeworkConfirm';
import SendInviteAgency from './storage/SendInviteAgency';
import SendInviteUsers from './storage/SendInviteUsers';
import Tariff from './storage/Tariff';

export const EVENTS = {
  CREATE: 'create_event',
  SEND_INVITE: 'send_invite',
  ATTACH_FILE: 'FileEventPart',
};

export const TESTS = {
  CREATE: 'test_event',
  DELETE: 'test_delete',
};

export const AGENCY = {
  ROLES: 'roles',
  SEND_INVITE: 'send_invite_agency',
  TARIFF_CHANGE: 'plan_change',
};

export const MODAL_HOMEWORK = {
  CONFIRM: 'homework_confirm',
};

export const SHARED = {
  STORAGE: 'storage',
};

export default {
  [EVENTS.ATTACH_FILE]: EventAttachFile,
  [EVENTS.CREATE]: CreateEvent,
  [EVENTS.SEND_INVITE]: SendInviteUsers,
  [TESTS.CREATE]: CreateEvent,
  [TESTS.DELETE]: DeleteTest,
  [MODAL_HOMEWORK.CONFIRM]: HomeworkConfirm,
  [AGENCY.ROLES]: AgencyRoles,
  [AGENCY.SEND_INVITE]: SendInviteAgency,
  [AGENCY.TARIFF_CHANGE]: Tariff,
};
