import { IAdminAction, IAdminState } from './admin.resources';
import types from './admin.types';

const initialState: IAdminState = {
  users: null,
  agencies: null,
  news: null,
  instruction: null,
};

export default function adminReducer(state: IAdminState = initialState, action: IAdminAction): IAdminState {
  const actionType = {
    [types.USERS]: {
      ...state,
      users: action.users,
    },
    [types.AGENCIES]: {
      ...state,
      agencies: action.agencies,
    },
    [types.ADMIN_NEWS]: {
      ...state,
      news: action.news,
    },
    [types.ADMIN_INSTRUCTION]: {
      ...state,
      instruction: action.instruction,
    },
  };

  return actionType[action.type] ?? state;
}
