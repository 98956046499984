import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAlphabet(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" fill="none" viewBox="0 0 14 20" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 18.4L6.826 2.129a.2.2 0 01.377 0L13 18.401M2.57 14.679h8.888"
      />
    </svg>
  );
}
