import React from 'react';

interface IProps {
  className?: string;
}

function CoverEventNew(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="198" height="177" fill="none" viewBox="0 0 198 177" {...props}>
      <path
        fill="url(#paint0_linear)"
        d="M164.892 171.14L15.493 154.584c-7.183-.795-12.36-7.263-11.565-14.445L17.66 16.261c.796-7.182 7.265-12.36 14.448-11.563l149.399 16.555c7.183.797 12.361 7.264 11.565 14.446L179.34 159.577c-.796 7.182-7.264 12.359-14.448 11.563z"
      />
      <path
        fill="url(#paint1_linear)"
        d="M193.097 35.869l-5.415 48.877-12.037-63.98 5.887.652c7.183.795 12.361 7.264 11.565 14.451z"
      />
      <path
        fill="url(#paint2_linear)"
        d="M186.328 148.245l-147.763 27.75c-7.105 1.334-13.948-3.334-15.284-10.426L.228 43.236c-1.337-7.092 3.34-13.924 10.444-15.258L158.435.228c7.105-1.335 13.948 3.333 15.284 10.425l23.053 122.334c1.337 7.092-3.339 13.924-10.444 15.258z"
      />
      <path
        fill="url(#paint3_linear)"
        d="M176.875 27.291v122.835l9.454-1.779c7.105-1.336 11.781-8.179 10.444-15.284L176.875 27.291z"
      />
      <path
        fill="url(#paint4_linear)"
        d="M22.578 161.962l.704 3.739c1.336 7.104 8.179 11.78 15.283 10.444l75.391-14.183H22.578z"
      />
      <path
        fill="url(#paint5_linear)"
        d="M163.785 162.727H13.429c-7.229 0-13.09-5.86-13.09-13.09V24.946c0-7.23 5.861-13.09 13.09-13.09h150.356c7.229 0 13.089 5.86 13.089 13.09v124.691c0 7.23-5.86 13.09-13.089 13.09z"
      />
      <path
        fill="url(#paint6_linear)"
        d="M151.527 150.427H25.686c-6.05 0-10.956-4.905-10.956-10.955V35.11c0-6.05 4.905-10.955 10.956-10.955h125.841c6.05 0 10.955 4.905 10.955 10.955v104.362c0 6.05-4.905 10.955-10.955 10.955z"
      />
      <path
        fill="url(#paint7_linear)"
        d="M97.365 150.426h-71.68c-6.051 0-10.958-4.904-10.958-10.955v-17.108l18.186-22.105c6.002-7.302 17.174-7.302 23.18 0l11.324 13.766 29.948 36.402z"
      />
      <path
        fill="url(#paint8_linear)"
        d="M162.482 111.962v27.509c0 6.052-4.907 10.955-10.959 10.955H37.469l29.948-36.402L104.208 69.3c6.002-7.297 17.179-7.297 23.18 0l35.094 42.662z"
      />
      <path
        fill="url(#paint9_linear)"
        d="M53.008 66.146c8.58 0 15.536-6.955 15.536-15.535s-6.956-15.536-15.536-15.536c-8.58 0-15.535 6.956-15.535 15.536 0 8.58 6.955 15.535 15.535 15.535z"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="25.115"
          x2="92.758"
          y1="93.206"
          y2="169.499"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7C5FD" />
          <stop offset="1" stopColor="#7FAEF4" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="177.614"
          x2="190.869"
          y1="54.786"
          y2="58.391"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A7C5FD" />
          <stop offset="1" stopColor="#7FAEF4" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="34.855"
          x2="181.492"
          y1="44.7"
          y2="145.056"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDEC7A" />
          <stop offset="0.216" stopColor="#B0E995" />
          <stop offset="0.561" stopColor="#87E4BB" />
          <stop offset="0.835" stopColor="#6EE1D2" />
          <stop offset="1" stopColor="#65E0DB" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="189.926"
          x2="174.071"
          y1="88.709"
          y2="88.709"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDEC7A" stopOpacity="0" />
          <stop offset="0.235" stopColor="#9AD57D" stopOpacity="0.235" />
          <stop offset="0.604" stopColor="#51B482" stopOpacity="0.604" />
          <stop offset="0.868" stopColor="#239F85" stopOpacity="0.868" />
          <stop offset="1" stopColor="#119786" />
        </linearGradient>
        <linearGradient
          id="paint4_linear"
          x1="68.267"
          x2="68.267"
          y1="169.969"
          y2="160.577"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDEC7A" stopOpacity="0" />
          <stop offset="0.235" stopColor="#9AD57D" stopOpacity="0.235" />
          <stop offset="0.604" stopColor="#51B482" stopOpacity="0.604" />
          <stop offset="0.868" stopColor="#239F85" stopOpacity="0.868" />
          <stop offset="1" stopColor="#119786" />
        </linearGradient>
        <linearGradient
          id="paint5_linear"
          x1="34.094"
          x2="159.776"
          y1="32.779"
          y2="158.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8F6FB" />
          <stop offset="1" stopColor="#EFDCFB" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="43.559"
          x2="166.141"
          y1="28.685"
          y2="188.164"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18CEFB" />
          <stop offset="0.297" stopColor="#2BB9F9" />
          <stop offset="0.735" stopColor="#42A0F7" />
          <stop offset="1" stopColor="#4A97F6" />
        </linearGradient>
        <linearGradient
          id="paint7_linear"
          x1="29.195"
          x2="82.898"
          y1="111.877"
          y2="181.745"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDEC7A" />
          <stop offset="0.215" stopColor="#B0E995" stopOpacity="0.784" />
          <stop offset="0.56" stopColor="#87E4BB" stopOpacity="0.439" />
          <stop offset="0.833" stopColor="#6EE1D2" stopOpacity="0.165" />
          <stop offset="0.999" stopColor="#65E0DB" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint8_linear"
          x1="76.798"
          x2="141.567"
          y1="101.04"
          y2="185.305"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDEC7A" />
          <stop offset="0.216" stopColor="#B0E995" />
          <stop offset="0.561" stopColor="#87E4BB" />
          <stop offset="0.835" stopColor="#6EE1D2" />
          <stop offset="1" stopColor="#65E0DB" />
        </linearGradient>
        <linearGradient
          id="paint9_linear"
          x1="45.289"
          x2="66.293"
          y1="40.569"
          y2="67.895"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFD945" />
          <stop offset="0.304" stopColor="#FFCD3E" />
          <stop offset="0.856" stopColor="#FFAD2B" />
          <stop offset="1" stopColor="#FFA325" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default CoverEventNew;
