export default function offset(element: Element) {
  if (!element) return;
  const el = element;

  const box = el.getBoundingClientRect();

  const body = document.body;

  const clientTop = el.clientTop || body.clientTop || 0;

  const clientLeft = el.clientLeft || body.clientLeft || 0;

  const scrollTop = el.scrollTop;

  const scrollLeft = el.scrollLeft;

  return {
    top: box.top + scrollTop - clientTop,
    left: box.left + scrollLeft - clientLeft,
  };
} //TODO deprecated (will be rework on use hook)
