export default {
  EN: {
    'support.tlt': 'Contact technical support',
    'support.desc': 'Found a bug in our client? Please help us to eliminate it.',
    'support.label.1':
      'To do it, please describe steps to repro the problem precisely and attach link to the client page and a screenshot.',
    'support.label.2': 'We will get your message and introduce alterations shortly.',
    'support.problem.pls.2': 'Describe the problem',
    'support.problem.link': 'Insert link to the client page',
  },
};
