export const typesView = {
  COMPACT: 'COMPACT',
  MOBILE: 'MOBILE',
  DEFAULT: 'DEFAULT',
};

export default {
  ALERT: 'ALERT',
  VIEW: 'VIEW',
  BREADCRUMBS: 'BREADCRUMBS',
};
