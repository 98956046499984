import React from 'react';
import translate from '../../../i18n/translate';
import { typesAccept } from '../storage.types';
import StorageFolder from './file-types/StorageFolder';
import StorageImage from './file-types/StorageImage';
import StorageText from './file-types/StorageText';

interface IProps {
  types: object;
  accept: object;
  acceptLabel: object;
}

class Bridge implements IProps {
  types: object;

  accept: object;

  acceptLabel: object;

  constructor() {
    this.types = {
      file: {
        image: [(data) => <StorageImage key={data.index} data={data} />],
        text: [(data) => <StorageText key={data.index} data={data} />],
      },
      dir: {
        folder: [(data) => <StorageFolder key={data.index} data={data} />],
      },
    };

    this.accept = {
      File: [
        '.doc',
        '.docx',
        '.xml',
        'application/msword',
        'application/json',
        'application/xml',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      ],
      Image: ['image/jpeg', 'image/png'],
      Book: ['application/pdf'],
      Video: [
        'video/*',
        'video/mp4',
        'video/*,.mkv',
        'video/mpeg',
        'video/ogg',
        'video/quicktime',
        'video/webm',
        'video/x-ms-wmv',
        'video/x-flv',
        'video/x-msvideo',
        'video/3gpp',
        'video/3gpp2',
      ],
      Audio: ['audio/*, audio/mpeg'],
      [typesAccept.ALL]: ['*'],
    };

    this.acceptLabel = {
      [typesAccept.ALL]: translate('storage.file.type.all'),
      File: translate('storage.file.s'),
      Image: translate('storage.image.s'),
      Book: translate('storage.book.s'),
      Video: translate('storage.video.s'),
      Audio: translate('storage.audio.s'),
    };
  }

  public parseType(data) {
    switch (data) {
      case 'image/jpeg':
      case 'image/png':
        return 'image';
      case 'application/json':
        return 'text';
      case 'dir':
        return 'folder';
      default:
        return 'text';
    }
  }

  public getAcceptType(type) {
    const searchTypeData = type ? type.map((key) => this.accept[key]) : [];

    return searchTypeData?.join(',');
  }

  public getAcceptTypeChange(type, join = false) {
    return type
      ? type.map((key) =>
          join ? (this.accept[key] ? this.accept[key] : this.accept[typesAccept.ALL]).join(', ') : this.accept[key]
        )
      : [];
  }

  public getAcceptTypesSelect(type) {
    const findAll = type.find((key) => key === typesAccept.ALL);

    const onBlockAll = () => {
      return Object.keys(this.acceptLabel).map((key) => ({
        title: this.acceptLabel[key],
        active: !!findAll,
        all: key === typesAccept.ALL,
        value: this.accept[key].join(','),
      }));
    };

    const onBlockInject = () => {
      const formation = type.map((key) => ({
        title: this.acceptLabel[key],
        active: type[0] === key,
        all: false,
        value: this.accept[key].join(','),
      }));

      return [
        {
          title: this.acceptLabel[typesAccept.ALL],
          active: false,
          all: true,
          value: this.accept[typesAccept.ALL].join(','),
        },
        ...formation,
      ];
    };

    return findAll ? onBlockAll() : onBlockInject();
  }

  public acceptType(data, type) {
    const searchTypeData = this.accept[type];

    return searchTypeData?.some((el) => data.mimetype === el) ?? true;
  }

  public getType(data) {
    const { file } = data;

    const type = this.types[file.type];

    const mimetype = this.parseType(file?.mimetype ?? file.type);

    const [component] = type[mimetype] ? type[mimetype] : [];

    return component && component(data); //TODO [Rework]: Is not dir
  }
}

export default new Bridge();
