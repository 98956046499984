export default function dataParseBndReverse(dataRaw: any) {
  const { columnOrder, columns } = dataRaw;

  const concatColumnOrderData = columnOrder.reduce((total, key) => [...total, ...columns[key].data], []);

  return columnOrder.map((key) => {
    const field = columns[key];

    if (field) {
      const { itemIds, uuid, title, params, disabled, hidden, id } = field;

      const filtered = concatColumnOrderData.filter((el) => itemIds.indexOf(el.uuid) !== -1);

      const sortable = filtered.sort((a, b) => itemIds.indexOf(a.uuid) - itemIds.indexOf(b.uuid));

      return {
        ...field,
        uuid,
        id,
        title,
        params,
        disabled,
        hidden,
        data: sortable,
      };
    }
  });
}
