import React from 'react';
import ContentLoader from 'react-content-loader';

const Portals = (props) => (
  <ContentLoader
    speed={1}
    width={380}
    height={226}
    viewBox="0 0 380 226"
    backgroundColor="var(--el-fonts--sheet-color)"
    foregroundColor="#fcfcfc"
    {...props}
  >
    <rect x="0" y="0" rx="6" ry="6" width="380" height="189" />
    <rect x="97" y="420" rx="0" ry="0" width="368" height="27" />
    <rect x="2" y="198" rx="0" ry="0" width="377" height="19" />
  </ContentLoader>
);

export default Portals;
