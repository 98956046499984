import React from 'react';

interface IProps {
  className?: string;
}

export default function Timer({ className }: IProps) {
  const props = {
    ...(className && { className }),
  };

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      fill="var(--el-fonts--icon-color)"
      viewBox="0 0 13 15"
    >
      <path
        fill="var(--el-fonts--icon-color)"
        d="M10.328 3.32l1.05-1.037c.659-.653 1.945.652 1.295 1.304-.65.652-1.049 1.038-1.049 1.038 1.037 1.284 1.49 2.845 1.352 4.482a6.404 6.404 0 01-2.144 4.256 6.542 6.542 0 01-4.514 1.635 6.534 6.534 0 01-4.414-1.88A6.396 6.396 0 01.003 8.75a6.388 6.388 0 011.652-4.465 6.525 6.525 0 014.302-2.12c1.655-.137 3.074.13 4.371 1.155zM5.777 5v3.478c0 .715 1.444.715 1.444 0V5c0-.714-1.444-.714-1.444 0zM3.61 0h5.777c.723 0 .723 1.63 0 1.63H3.61c-.72 0-.72-1.63 0-1.63z"
      />
    </svg>
  );
}
