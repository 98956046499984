import './index.scss';
import React, { FC } from 'react';
import { useToState } from '../../../../hooks';
import { typesAccept } from '../../../Storage/storage.types';
import AttachControl from './AttachControl';

interface IProps {
  data: any;
  onChangeFieldAudio: any;
  onChangeField: any;
}

const CreateAttach: FC<IProps> = ({ data, onChangeFieldAudio, onChangeField }) => {
  const [state, setState] = useToState({
    activeIndex: -1,
  });

  const formationData = [
    {
      id: data?.video?.fileId || data?.video?.url || data?.video?.code,
      type: typesAccept.VIDEO,
      title: 'Видео:',
      icon: 'ELVideo',
      params: data?.video,
      label: data?.video?.file?.originalName || data?.video?.url || data?.video?.code,
      questionData: data,
      controls: [
        {
          title: 'Автовоспроизведение',
          key: 'autoPlay',
          field: 'video',
          checked: data?.video?.autoPlay,
        },
      ],
      onChangeField,
    },
    // {
    //   id: data?.image?.fileId || data?.image?.url,
    //   type: typesAccept.IMAGE,
    //   title: 'Изображение:',
    //   icon: 'ELImage',
    //   questionData: data,
    //   params: data?.image,
    // },
    {
      id: data?.audioId,
      type: typesAccept.AUDIO,
      title: 'Аудио:',
      icon: 'ELAudio',
      label: data?.audio?.originalName,
      questionData: data,
      controls: [
        {
          title: 'Управление плеером',
          key: 'audioControl',
          field: 'audio',
          prompt:
            'Рекомендуем включить управление плеером, чтобы ученик мог управлять воспроизведением звуковой дорожки',
          checked: data?.audioControl,
        },
      ],
      params: {
        audioAutoPlay: data?.audioAutoPlay,
        audioControl: data?.audioControl,
        audioId: data?.audioId,
        audio: data?.audio,
      },
      onChangeFieldAudio,
    },
  ];

  const filtered = formationData.filter((el) => el.id);

  const onChangeActiveAttachContent = (activeIndex) => () => {
    setState((state) => ({
      activeIndex: state.activeIndex === activeIndex ? -1 : activeIndex,
    }));
  };

  const renderContent = () => {
    return filtered.map((item) => (
      <AttachControl
        data={item}
        key={item.type}
        activeIndex={state.activeIndex === item.type}
        onChange={onChangeActiveAttachContent(item.type)}
      />
    ));
  };

  return filtered?.length ? <div className="question-attach">{renderContent()}</div> : null;
};

export default CreateAttach;
