import './index.scss';
import React, { FC, ReactNode } from 'react';
import Clipboard from '../Clipboard';
import Icon from '../Icon';
import Text from '../Text';

interface IProps {
  label: string | ReactNode;
  copyText: string;
  iconName?: string;
  onChange?(data: string): void;
}

const CopyClipboard: FC<IProps> = ({ label, copyText, iconName, onChange }) => {
  return (
    <div className="copy-clipboard">
      <Text className="copy-clipboard__text">{copyText}</Text>
      <Clipboard copyText={copyText} onChange={onChange}>
        <Icon name={iconName ?? 'ELCopy'} className="copy-clipboard__icon" />
      </Clipboard>
      {label && <label className="copy-clipboard__label">{label}</label>}
    </div>
  );
};

export default CopyClipboard;
