export default {
  RU: {
    'homework.usr.awr.name': 'Мой ответ',
    'homework.usr.send.tlt': 'Проверьте, все ли вы выполнили, прежде чем отправлять ответ',
    'homework.usr.awr.lbl':
      'Ответ находится на проверке у преподавателя. После проверки он будет отправлен на доработку, отклонен или принят с оценкой.',
    'homework.status.pending': 'На проверке',
    'homework.status.rework': 'На доработку',
    'homework.status.result': 'К результатам',
    'homework.status.accepted': 'К заданию',
    'homework.status.success': 'Принят',
    'homework.status.success.lbl': 'Ваш ответ принят преподавателем. Вы можете обсудить это решение с преподавателем в',
    'homework.status.success.lbl.2': 'к заданию.',
    'homework.status.cancel.lbl':
      'Ваш ответ отклонен преподавателем. Вы можете обсудить это решение с преподавателем в',
    'homework.status.cancel.lbl.2': 'к заданию.',
    'homework.history.tlt': 'История проверки',
    'homework.send.accept': 'Отправлено на проверку',
    'homework.send.repeat': 'Переотвеченно',
    'homework.send.rework': 'Отправлено на доработку',
    'homework.awr.usr.tlt': 'Ответ ученика',
    'homework.change.scr': 'Изменить баллы',
    'homework.adm.cancel.lbl': 'Оставьте ученику пояснение о том, почему задание отклонено.',
    'homework.adm.rework.lbl': 'Оставьте ученику пояснение о том, что необходимо доработать.',
    'homework.adm.cmt': 'Пояснение преподавателя',
    'homework.adm.cmt.pls': 'Введите пояснение принятого решения',
    'homework.tlt': 'ДЗ',
  },
};
