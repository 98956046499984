import api from './webinar.api';

export function getWebinarJWT(eventId: number, elementId: number): Promise<any> {
  const request = api.init('getWebinarJWT');

  return request({
    path: [eventId, elementId],
  });
}

export function getWebinarAdminJWT(eventId: number, elementId: number): Promise<any> {
  const request = api.init('getWebinarAdminJWT');

  return request({
    path: [eventId, elementId],
  });
}
