import './index.scss';
import React, { FC, ReactElement } from 'react';
import translate from '../../../../i18n/translate';
import Button from '../../../Button';
import Text from '../../../Text';

interface IProps {
  close(): void;
  onConfirm: any;
  text: string;
  onClose: any;
}

const HomeworkConfirm: FC<IProps> = function ({ onConfirm, onClose }): ReactElement {
  return (
    <div className="homework-confirm">
      <Text>
        <strong>{translate('homework.usr.send.tlt')}</strong>
      </Text>
      <div className="homework-confirm__buttons">
        <Button variant="outlined" size="sm" onClick={onConfirm}>
          {translate('g.btn.send')}
        </Button>
        <Button size="sm" onClick={onClose}>
          {translate('g.btn.verify')}
        </Button>
      </div>
    </div>
  );
};

export default HomeworkConfirm;
