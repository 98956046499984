import React from 'react';

interface IProps {
  className?: string;
}

export default function ELUser(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="none" viewBox="0 0 18 20" {...props}>
      <circle cx="8.511" cy="5.957" r="4.957" stroke="var(--el-fonts--icon-color)" strokeWidth="2" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 18.085a3.894 3.894 0 013.894-3.894h7.234a3.894 3.894 0 013.893 3.894c0 .505-.41.915-.915.915H1.915A.915.915 0 011 18.085z"
      />
    </svg>
  );
}
