import './index.scss';
import classNames from 'classnames';
import React, { Component, FC, ReactElement, ReactNode } from 'react';
import Loader from '../Loader';
import Text from '../Text';

interface IProps {
  children?: ReactNode;
  className?: string;
  size?: string;
  off?: boolean;
  loading?: boolean;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 't--settings',
  head: 't--settings__head',
  foot: 't--settings__foot',
  body: 't--settings__body',
  part: 't--settings__part',
  screen: 't--settings__screen',
  content: 't--settings__body-content',
  overflow: 't--settings__body-overflow',
  grid: 't--settings__body-grid',
  cell: 't--settings__body-cell',
  headerPart: 't--settings__header-part',
  fixed: 't--settings__fixed',
  row: 't--settings__row',
  panel: 't--settings__panel',
  label: 't--settings__label',
  button: 't--settings__button',
  options: 't--settings__options',
};

const layoutPart: FC<IParts> | Function = (children, className): ReactElement | Function => (
  <div className={className}>{children}</div>
);

const Head: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.head);

const Body: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.body);

const Foot: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.foot);

const Content: Function = ({ children, loading }: IProps): ReactNode => {
  return loading ? <Loader /> : layoutPart(children, field.content); //TODO [Test]: Testing loader injecting component
};

const Grid: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.grid);

const Cell: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.cell);

const Part: Function = ({ children }: IProps): ReactNode => <section className={field.part}>{children}</section>;

const Screen: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.screen);

const Overflow: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.overflow);

const HeaderPart: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.headerPart);

const Fixed: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.fixed);

const Panel: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.panel);

const Label: Function = ({ children }: IProps): ReactNode => <Text className={field.label}>{children}</Text>;

const Button: Function = ({ children }: IProps): ReactNode => layoutPart(children, field.button);

const Options: Function = ({ children, className, size = 'lg' }: IProps): ReactNode => {
  const classesOptions = classNames(field.options, className, {
    [`${field.options}--${size}`]: size,
  });

  return <div className={classesOptions}>{children}</div>;
};

const Row: Function = ({ children, className, size, off }: IProps): ReactNode => {
  const classesRow = classNames(field.row, className, {
    [`${field.row}--${size}`]: size,
    [`${field.row}--off`]: off,
  });

  return <div className={classesRow}>{children}</div>;
};

class TSettings extends Component<IProps> {
  public static Head: Function = Head;

  public static Body: Function = Body;

  public static Foot: Function = Foot;

  public static Content: Function = Content;

  public static Grid: Function = Grid;

  public static Cell: Function = Cell;

  public static Part: Function = Part;

  public static Screen: Function = Screen;

  public static Overflow: Function = Overflow;

  public static HeaderPart: Function = HeaderPart;

  public static Fixed: Function = Fixed;

  public static Row: Function = Row;

  public static Panel: Function = Panel;

  public static Label: Function = Label;

  public static Button: Function = Button;

  public static Options: Function = Options;

  render(): JSX.Element {
    const { children } = this.props;

    return <div className={field.app}>{children}</div>;
  }
}

export default TSettings;
