import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELWebinarFile(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M6 21h10m-5-4v4"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 5a4 4 0 014-4h12a4 4 0 014 4v8a4 4 0 01-4 4H5a4 4 0 01-4-4V5z"
      />
      <path fill="var(--el-fonts--icon-color)" d="M11.127 8.15a.85.85 0 100 1.7.85.85 0 000-1.7z" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="1.7"
        d="M9.3 6.5S7.8 6.907 7.8 9s1.5 2.5 1.5 2.5M6.3 5s-2 .684-2 4.172c0 3.488 2 3.828 2 3.828m6.5-6.5s1.5.407 1.5 2.5-1.5 2.5-1.5 2.5m3-6.5s2 .684 2 4.172c0 3.488-2 3.828-2 3.828"
      />
    </svg>
  );
}
