import React from 'react';

interface IProps {
  className?: string;
}

export default function CloseMobile(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="37" height="37" fill="none" viewBox="0 0 37 37" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="5.4"
        d="M34.203 3.152l-30.6 30.6M3.6 3.151l30.6 30.6"
      />
    </svg>
  );
}
