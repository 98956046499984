export default {
  EN: {
    'qs.stt.tlt': 'Questions settings',
    'qs.stt.awr': 'Answers settings',
    'qs.awr.mix': 'Mix answers',
    'qs.cmt.view': 'View comments',
    'qs.cmt.view.all': 'To all answers',
    'qs.cmt.view.correct': 'View only right answers',
    'qs.cmt.view.usr': 'Only to student answer',
    'qs.view.not': 'Do not view',
    'qs.stt.cmt.view': 'Entered comments will be only viewed after the student has answered all questions',
    'qs.up.view': 'Collapse question',
    'qs.down.view': 'Expand question',
    'qs.rqd.up': 'All questions are obligatory. Make a question unobliging',
    'qs.rqd.down': 'This question can be skipped. Click to make it obligatory to pass.',
    'qs.once': 'Single answer',
    'qs.more': 'Several answers',
    'qs.more.up': 'Accept incomplete answers',
    'qs.awr.pls': 'Enter answer variant',
    'qs.cmt.pls': 'Enter short comment to the answer',
    'qs.add.awr': 'Add answer',
    'qs.save': 'Save question',
    'qs.audio.tlt': 'Audio material',
    'qs.audio.add': 'Add video',
    'qs.video.tlt': 'Video material',
    'qs.video.upload.lbl': 'Upload new video or attach a link to an already uploaded video.',
    'qs.video.add': 'Add video',
    'qs.video.link': 'Input video link',
    'qs.video.storage': 'Add from cloud storage',
    'qs.video.cover': 'Choose video cover',
    'qs.crt': 'Create a question',
    'qs.type.choose': 'Choose answer',
    'qs.type.free': 'Free answer',
    'qs.awr.txt': 'Text answer',
    'qs.awr.manual': 'Manual check',
    'qs.awr.number': 'Numerical answer',
    'qs.delete': 'Delete question',
    'qs.name': 'Question',
    'qs.err.scr.txt': 'Input score',
    'qs.err.awr.txt': 'Input answer text',
  },
};
