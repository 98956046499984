import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
// import { HexColorPicker } from 'react-colorful';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
// import { useToState } from '../../../../hooks';
import { IReduxStore } from '../../../../store/serviceReducer';
import { setAlert } from '../../../app.actions';
import { Icon, Text, Toggle } from '../../../app.modules';
import { middlewareSetUser } from '../../../Middleware/middleware.actions';
import { profileUserUpdate } from '../../../Profile/profile.services';
import { setDataModal } from '../../modal.actions';

interface IProps {
  data: any;
  profileUserUpdateAction: any;
  user: any;
  setMeAction: any;
}

const Theme: FC<IProps> = function ({ profileUserUpdateAction, user, setMeAction }): ReactElement {
  const dataColor = ['Blue', 'Edbee', 'Pink', 'Purple', 'Orange', 'Green'];

  // const [state] = useToState({
  //   coloringCustom: '',
  //   activeHexBuilder: false,
  // });

  const html = document.querySelector('html');

  const dataLayoutColor = [
    { title: 'Светлая', value: 'light' },
    { title: 'Темная', value: 'dark' },
    { title: 'Черная', value: 'darked', lock: true },
  ];

  const onUpdateTheme = ({ coloring = user?.theme?.coloring, layout = user?.theme?.layout }) => {
    const theme = {
      coloring,
      layout,
    };

    return profileUserUpdateAction({
      theme,
    });
  };

  const onChangeThemeColor = (name) => () => {
    onUpdateTheme({
      coloring: name,
    }).then((response) => {
      html?.setAttribute('data-coloring', response?.theme?.coloring);
      setMeAction(response);
    });
  };

  const onChangeThemeLayoutColor = (name) => () => {
    onUpdateTheme({
      layout: name,
    }).then((response) => {
      html?.setAttribute('data-layout', response?.theme?.layout);
      setMeAction(response);
    });
  };

  // const onChangeColoringCustom = (name) => {
  //   // console.log(name);
  // };

  // const renderHex = () => {
  //   const { activeHexBuilder } = state;
  //
  //   return <HexColorPicker color={state.coloringCustom} onChange={onChangeColoringCustom} />;
  // };

  const renderTypesColor = (name) => {
    const types = {
      // Custom: (
      //   <PopoverTarget target={renderHex}>
      //     <DivTarget className={`theme__data-color--${name}`}>
      //       <span className={`theme__data-color--${name}`} />
      //     </DivTarget>
      //   </PopoverTarget>
      // ),
      Default: <span className={`theme__data-color--${name}`} />,
    };

    return types[name] ?? types['Default'];
  };

  const renderColor = () => {
    return dataColor.map((item) => {
      const isActive = item === user?.theme?.coloring;

      return (
        <div className="theme__data-color" key={item}>
          <Toggle
            type="radio"
            name="theme-color"
            variant="theme"
            defaultChecked={isActive}
            replace
            onChange={onChangeThemeColor(item)}
          >
            {renderTypesColor(item)}
          </Toggle>
        </div>
      );
    });
  };

  const renderLayoutColor = () => {
    return dataLayoutColor.map((item) => {
      const isActive = item?.value === user?.theme?.layout;

      return (
        <div
          className={classNames('theme__data-layout', {
            'theme__data-layout--none': item?.lock,
          })}
          key={item.value}
        >
          <Toggle
            type="radio"
            name="theme-layout-color"
            variant="success"
            className="theme__data-check"
            replace
            defaultChecked={isActive}
            onChange={onChangeThemeLayoutColor(item.value)}
          >
            <>
              <Icon name="CheckSuccess" />
              <Text variant="md" className={`theme__data-layout--${item.value}`}>
                <strong>{item.title}</strong>
              </Text>
            </>
          </Toggle>
        </div>
      );
    });
  };

  return (
    <div className="theme">
      <h1>Настроить внешний вид</h1>
      <div className="theme__data">
        <div className="theme__data-colors">{renderColor()}</div>
        <div className="theme__data-layouts">{renderLayoutColor()}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  data: store.modal.data!,
  user: store.middleware.user!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  profileUserUpdateAction: bindActionCreators(profileUserUpdate, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
  setMeAction: bindActionCreators(middlewareSetUser, dispatch),
  setDataModalAction: bindActionCreators(setDataModal, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Theme);
