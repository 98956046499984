import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { useGetParams, useRouter } from '../../hooks';
import Icon from '../Icon';
import Portal from '../Portal';
import types from './types';

interface IProps {
  children?: ReactNode;
  close?(element: Element): void;
  onClose?(): void;
  notEvent?: Array<string>;
  overlay?: string;
  overlayClose?: boolean;
  data?: any;
  className?: string;
  onConfirm?: any;
  params?: any;
}

const Modal: FC<IProps> = ({
  overlay = 'outlined',
  onClose,
  data,
  className,
  onConfirm,
  overlayClose = false,
  params,
}) => {
  const { history } = useRouter();

  const modalName = useGetParams('win') as any;

  const classesModal = classNames('modal', {
    [`modal--ovl-${overlay}`]: overlay,
  });

  const onStopDefaultEvent = (e) => {
    e.stopPropagation();
  };

  const onCloseModal = (e) => {
    const { action, push, goBack } = history;

    e && onStopDefaultEvent(e);

    const typesActions = {
      POP: () =>
        push({
          search: '',
        }),
      PUSH: goBack,
    };

    typesActions[action]();

    onClose && onClose();
  };

  const renderButtonClose = () => {
    return (
      onClose && (
        <div className="modal__close" onClick={onCloseModal}>
          <Icon name="CloseBold" />
        </div>
      )
    );
  };

  const onCloseOverlay = (e) => {
    overlayClose && onCloseModal(e);
  };

  const onConfirmModal = (data) => {
    onConfirm && onConfirm({ data, onCloseModal });
  };

  const renderPortalModal = () => {
    const props = {
      data,
      className,
      onConfirm: onConfirmModal,
      onClose: onCloseModal,
      params,
    };

    const Component: any = types[modalName] ?? null;

    return modalName ? (
      <Portal>
        <div className={classesModal} onClick={onCloseOverlay}>
          <div className="modal__container" onClick={onStopDefaultEvent}>
            {renderButtonClose()}
            <Component {...props} />
          </div>
        </div>
      </Portal>
    ) : null;
  };

  // return useMemo(renderPortalModal, [data, location.search]);//TODO [Deprecated]: Will be remove
  return renderPortalModal();
};

export default Modal;
