export const cardFields = {
  MINI_COVER: 'miniCoverFileId',
  NAME: 'name',
  DESCRIPTION: 'description',
  START_DATE: 'startDate',
  AUTHOR: 'author',
  PARTICIPANTS_COUNT: 'participantCount',
  DATE_UPDATE: 'updatedAt',
  TEST_INFO: 'testInfo',
  QUESTIONS: 'questions',
  MAX_SCORE: 'maxScore',
  AGENCY: 'agency',
};

export const BUTTON_TEMPLATE = {
  DEFAULT: 'DEFAULT',
  PAYMENT: 'PAYMENT',
};

export default {
  ADMIN_CARD: 'ADMIN_CARD',
  USER_CARD: 'USER_CARD',
  MOBILE_CARD: 'MOBILE_CARD',
};
