import React from 'react';

export default function Check() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" fill="none" viewBox="0 0 12 16">
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M10.469 5.457l-4.97 4.97a.5.5 0 01-.708 0L2.09 7.725"
      />
    </svg>
  );
}
