import React from 'react';

interface IProps {
  onClick?(e: any): void;
}

function Trash(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.302 8.472l.415 9.547m5.396-9.547l-.415 9.547M1 4.32h22"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M20.717 4.684l-.104 1.193-.207 2.387-.415 4.774-.433 4.98a5 5 0 01-4.982 4.567H9.631a5 5 0 01-4.98-4.567l-.434-4.98-.415-4.774-.208-2.387-.103-1.193m4.981-.364h6.641c0-1.302-1.103-3.344-3.391-3.32-2.398.026-3.25 1.922-3.25 3.32z"
      />
    </svg>
  );
}

export default Trash;
