import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useState } from 'react';
import { Icon, Input, Text } from '../../../app.modules';

interface IProps {
  variant?: string;
  data?: Array<string>;
  slug?: string;
  label?: string | ReactNode;
  defaultData?: any;
  onChange?(data: Array<string>): void;
  error?: boolean;
}

const Tags: FC<IProps> = function ({
  variant = 'primary',
  onChange,
  slug = '',
  defaultData,
  label,
  error,
}): ReactElement {
  const [state, setState] = useState({
    tags: defaultData ?? [],
    value: slug,
  });

  const onDeleteTag = (data) => {
    const filtered = state.tags.filter((el) => el !== data);

    setState((state) => ({
      ...state,
      tags: filtered,
    }));

    onChange && onChange(filtered);
  };

  const renderTags = () => {
    const filtered = state.tags.map((el) => ({
      title: `#${el}`,
      value: el,
    }));

    return filtered.map(({ title, value }) => (
      <Text variant="md" key={value}>
        {title}
        <i onClick={() => onDeleteTag(value)}>
          <Icon name="Delete" />
        </i>
      </Text>
    ));
  };

  const onChangeInput = (e) => {
    const { value } = e.target;

    setState((state) => ({
      ...state,
      value,
    }));
  };

  const onCreateNewTags = () => {
    const isWithSlug = state.value[0] !== slug ? slug : '';

    const isEmpty = state.value.trim() && state.value.trim() !== slug;

    const isFiltered =
      isEmpty && !state.tags.includes(state.value) ? [...state.tags, `${isWithSlug}${state.value}`] : state.tags;

    const validationData = isFiltered
      .map((el) => {
        const createArray = el.split('#');

        if (createArray.length > 1) {
          return createArray.filter((el) => el !== '#').join('');
        } else {
          return el;
        }
      })
      .filter((el) => el);

    setState((state) => ({
      ...state,
      tags: validationData,
      value: slug,
    }));

    onChange && onChange(isFiltered);
  };

  const onKeyPress = (e) => {
    const { keyCode, charCode } = e;

    switch (keyCode || charCode) {
      case 13:
        return onCreateNewTags();
    }
  };

  const onClearTags = () => {
    setState((state) => ({
      ...state,
      tags: [],
      value: slug,
    }));

    onChange && onChange([]);
  };

  const classesTags = classNames('tags', {
    [`tags--${variant}`]: variant,
    'tags--error': error,
  });

  return (
    <div className={classesTags}>
      <div className="tags__polygon">
        {renderTags()}
        <Input variant="inline" value={state.value} onChange={onChangeInput} onKeyPress={onKeyPress} />
        <div className={`tags--${variant}__clear`} onClick={onClearTags}>
          <Text variant="md">
            Удалить все
            <Icon name="Delete" />
          </Text>
        </div>
      </div>
      {label && <label className="tags__label">{label}</label>}
    </div>
  );
};

export default Tags;
