import Provider from './Provider';

export interface IRequestQueryParams {
  [key: string]: string | number;
}

export interface IRequestConstructor {
  host: string;
}

export interface IRequestProvider extends IRequestConstructor {
  token?: string;
}

export interface IRequestDataParse {
  path?: string;
  params?: object;
  formData?: FormData;
  query?: object;
  success?(response: Response): void;
  error?(error: Error): void;
  headers?: object;
  method?: string;
}

export interface IRequestDataParsed {
  path: string;
  params: object;
  formData?: FormData;
  query: string;
  success(response: Response): void;
  error(error: Error): void;
  headers: object;
}

export interface ISocketClient extends IRequestConstructor {
  options?: {
    path?: string;
    withCredentials?: boolean;
  };
  host: any;
  openConnect?(nameConnect: string, callback: Function): void;
  closeConnect?(nameChannelEvent: string | undefined, Function: Function | undefined): void;
}

export default Provider;
