import './index.scss';
import { JitsiMeeting } from '@jitsi/react-sdk';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { ILocationName } from '../../app/Location/location.resources';
import { IReduxStore } from '../../store/serviceReducer';

interface IProps {
  className?: string;
  room: string;
  jwt: string;
  onDisconnect?(): void;
  langName: ILocationName;
}

const Meet: FC<IProps> = ({ className, room, jwt, onDisconnect, langName }): ReactElement => {
  const classesMeet = classNames('meet', className);

  return (
    <div className={classesMeet}>
      <JitsiMeeting
        domain="meet.edbee.ru"
        roomName={room}
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: false,
          // startScreenSharing: true,
          enableEmailInStats: false,
          defaultLanguage: langName.toLowerCase(),
        }}
        jwt={jwt}
        onReadyToClose={() => onDisconnect && onDisconnect()}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
          LANG_DETECTION: false,
        }}
        onApiReady={() => {
          //externalApi
          // here you can attach custom event listeners to the Jitsi Meet External API
          // you can also store it locally to execute commands
        }}
        getIFrameRef={(iframe) => {
          iframe.style.height = '1000px';
        }}
      />
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  langName: store.location.langName!,
});

export default connect(mapStateToProps)(Meet);
