import React, { FC, ReactNode } from 'react';

interface IProps {
  params: { [key: string]: any };
  children: ReactNode;
}

const RefTarget: FC<IProps> = React.forwardRef(({ params, children }, ref) => {
  let elements = React.Children.toArray(children) as any;

  if (elements.length > 1) {
    throw Error("Can't have more than one child");
  }

  elements = React.cloneElement(elements[0], {
    ref,
    ...params,
  });

  return elements;
});

export default RefTarget;
