import '../index.scss';
import React, { FC } from 'react';
import { CONFIG } from '../../../env';
import translate from '../../../i18n/translate';
import { URI_GENERAL } from '../../../routes/uri';
import { Text } from '../../app.modules';
import { IStorageFileFrom } from '../storage.resources';

interface IProps {
  action: {
    onFileDelete(data: IStorageFileFrom): void;
    onChangeFile(data: IStorageFileFrom): void;
    onChangeFolder(data: IStorageFileFrom): void;
    onFilesDelete(): void;
  };
  contextData: IStorageFileFrom;
  service: any;
}

const StorageContext: FC<IProps> = ({ action, contextData, service }): any => {
  const isFocusinOneFile = service.focusinFiles.length <= 1;

  const onFileDelete = () => {
    isFocusinOneFile ? action.onFileDelete(contextData) : action.onFilesDelete();
  };

  const onChangeFile = () => {
    action.onChangeFile(contextData);
  };

  const onChangeFolder = () => {
    action.onChangeFolder(contextData);
  };

  const style = {
    top: contextData?.top ?? 0,
    left: contextData?.left ?? 0,
  };

  const renderTypesMenuAddition = () => {
    const { type, id } = contextData;

    const params = {
      contentDisposition: 'attachment',
    };

    const createUri = URI_GENERAL.download(id, params, CONFIG.REACT_APP_URI_URL);

    const types = {
      file: (
        <>
          <div className="storage-context__item" onClick={onChangeFile}>
            <Text variant="md">{translate('g.choose.s')}</Text>
          </div>
          <div className="storage-context__item">
            <a href={createUri} download>
              <Text variant="md">{translate('g.download')}</Text>
            </a>
          </div>
        </>
      ),
      dir: (
        <div className="storage-context__item" onClick={onChangeFolder}>
          <Text variant="md">{translate('g.btn.open')}</Text>
        </div>
      ),
    };

    return types[type];
  };

  return (
    <div className="storage-context" style={style}>
      {isFocusinOneFile && renderTypesMenuAddition()}
      <div className="storage-context__item" onClick={onFileDelete}>
        <Text variant="md">{translate('g.btn.delete')}</Text>
      </div>
    </div>
  );
};

export default StorageContext;
