import React from 'react';

interface IProps {
  className?: string;
}

export default function Scores2(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M8.829 19.927A10.03 10.03 0 01.05 10.97c-.26-2.523.503-5.11 2.12-7.172.369-.47 1.186-1.292 1.632-1.64C5.86.555 8.45-.206 10.98.049c3.926.398 7.292 3.094 8.511 6.816a9.907 9.907 0 010 6.271c-1.467 4.48-6.002 7.369-10.662 6.792zm2.256-1.53a8.477 8.477 0 006.025-3.803 8.436 8.436 0 000-9.188c-1.149-1.769-2.954-3.082-4.948-3.6C6.793.41 1.531 4.468 1.531 10c0 5.086 4.518 9.058 9.553 8.397zm-5.237-4.615a.94.94 0 01-.237-.243c-.117-.192-1.53-5.206-1.529-5.431 0-.362.356-.71.726-.711.104 0 .692.137 1.307.306.615.168 1.151.306 1.192.306.04 0 .549-.616 1.13-1.369C9.531 5.222 9.656 5.1 10.003 5.1c.345 0 .47.122 1.564 1.54.59.764 1.093 1.369 1.138 1.369.044 0 .583-.138 1.197-.307.615-.168 1.201-.305 1.302-.305.365 0 .72.352.72.714 0 .184-1.392 5.134-1.507 5.36-.036.07-.127.185-.202.255l-.137.127-4.041.014c-3.91.012-4.046.01-4.189-.086zm7.342-1.48c.306-1.04.846-2.987.835-3.013-.008-.02-.325.052-.704.16-.806.23-1.134.258-1.347.117-.08-.053-.548-.615-1.039-1.248-.49-.633-.91-1.151-.932-1.151-.023 0-.446.522-.94 1.16-.495.639-.96 1.2-1.034 1.25-.201.131-.559.097-1.344-.13-.379-.11-.696-.18-.705-.157-.012.03.707 2.662.83 3.037.014.04.936.056 3.187.057 2.868 0 3.17-.007 3.193-.082z"
      />
    </svg>
  );
}
