import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELStatsAccept(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="10" fill="none" viewBox="0 0 11 10" {...props}>
      <path
        stroke="var(--el-fonts--icon-color"
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.002 3.17L4.272 7.9a.5.5 0 01-.707 0L1.002 5.335"
      />
    </svg>
  );
}
