import './index.scss';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import EditorPage from '../../../../components/EditorPage';
import { useErrorFields, useGetParams, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { IReduxStore } from '../../../../store/serviceReducer';
import { Button, Input, Select, Text, Textarea, TimeFrame } from '../../../app.modules';
import { MODALS_TRIGGER } from '../../modal.types';

interface IProps {
  data?: any;
}

const EventAttachHomework: FC<IProps> = function ({ data }): ReactElement {
  const part: any = useGetParams('part');

  const elIndex: any = useGetParams('el');

  const modal = data[MODALS_TRIGGER.STRUCTURE.DATA];

  const element = modal?.data[+part]?.data[elIndex];

  const [state, setState] = useToState({
    handlerType: elIndex !== null ? 'Update' : 'Create',
    params: {
      title: '',
      text: '',
      maxScore: '',
      minScore: '',
      description: '',
      passingScore: '',
      startDate: null,
      endDate: null,
    },
    accessDates: !!element?.startDate || !!element?.endDate,
    activeEditor: false,
  });

  useEffect(() => {
    setState({
      params: {
        title: element?.title || '',
        text: element?.params?.text || '',
        maxScore: element?.params?.maxScore || '',
        minScore: element?.params?.minScore || '',
        passingScore: element?.params?.passingScore || '',
        description: element?.description || '',
        startDate: element?.startDate ?? null,
        endDate: element?.endDate ?? null,
      },
      accessDates: !!element?.startDate || !!element?.endDate,
      activeEditor: true,
    });
  }, [modal?.data]);

  const [error, setError] = useErrorFields(state.params, (value, key) => {
    switch (key) {
      case 'maxScore':
      case 'minScore':
      case 'passingScore':
        return value !== '' && +value !== 0;
      case 'title':
        return value.length;
      case 'text':
        return value;
      default:
        return true;
    }
  });

  const onChangeTextField = (e, key) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState((state) => ({
      params: {
        ...state.params,
        [key]: value,
      },
    }));
  };

  const onChangeTextFieldNumber = (e, key) => {
    const { value } = e?.target;

    const isValid = value.replace(/\D/g, '');

    setError(key, isValid);

    setState((state) => ({
      params: {
        ...state.params,
        [key]: +isValid,
      },
    }));
  };

  const onChangeParamsField = (key) => (e) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState((state) => ({
      params: {
        ...state.params,
        [key]: value,
      },
    }));
  };

  const onUpload = () => {
    const modal = data[MODALS_TRIGGER.STRUCTURE.DATA];

    const params = {
      type: 'Homework',
      parentId: part && modal.data[+part].id,
      position: part && modal.data[+part].data.length,
      params: state.params,
    };

    setError(state.params).then(() => {
      modal.onConfirm(
        {
          ...data,
          indexUp: part && +part,
          ...(elIndex && {
            indexDown: +elIndex,
          }),
        },
        params,
        state.handlerType
      );
    });
  };

  const renderDateFrame = () => {
    const { startDate, endDate } = state.params;

    const { accessDates } = state;

    return (
      accessDates && (
        <>
          <div className="homework__data-item">
            <TimeFrame
              size="md"
              label={translate('events.str.ath.start.pbl')}
              placeholder="Выберите дату и время"
              onChange={onChangeParamsField('startDate')}
              value={startDate ? new Date(startDate) : null}
              startDate={startDate ? new Date(startDate) : null}
              endDate={endDate ? new Date(endDate) : null}
              selectsStart
            />
          </div>
          <div className="homework__data-item">
            <TimeFrame
              size="md"
              label={translate('events.str.ath.end.pbl')}
              placeholder="Выберите дату и время"
              onChange={onChangeParamsField('endDate')}
              value={endDate ? new Date(endDate) : null}
              startDate={startDate ? new Date(startDate) : null}
              endDate={endDate ? new Date(endDate) : null}
              minDate={startDate ? new Date(startDate) : null}
              selectsEnd
            />
          </div>
        </>
      )
    );
  };

  const onChangeSelectDate = (data) => {
    const { params } = data;

    setState({
      params: {
        ...state.params,
        startDate: params.startDate,
        endDate: params.endDate,
      },
      accessDates: params.showDate,
    });
  };

  const dataSelectDate = [
    {
      title: translate('events.str.ath.acc.sett.opt.event'),
      active: !state.accessDates,
      params: {
        showDate: false,
        startDate: modal?.event?.startDate,
        endDate: modal?.event?.endDate,
      },
    },
    {
      title: translate('events.str.ath.acc.sett.opt.set'),
      active: state.accessDates,
      params: {
        showDate: true,
        startDate: null,
        endDate: null,
      },
    },
  ];

  return (
    <div className="homework">
      <h1>{translate('g.homework')}</h1>
      <div className="homework__data">
        <div className="homework__data-item">
          <Input
            onChange={(e) => onChangeTextField(e, 'title')}
            value={state.params.title}
            label={translate('events.str.ath.tlt')}
            size="md"
            placeholderNode={translate('events.str.ath.tlt.pls')}
            error={!error.title}
          />
        </div>
        <div className="homework__data-item">
          <Textarea
            onChange={(e) => onChangeTextField(e, 'description')}
            value={state.params.description}
            label={translate('g.description')}
            placeholderNode={translate('events.ath.homework.desc.pls')}
            error={!error.description}
          />
        </div>
        <div className="homework__data-item">
          {state.activeEditor && (
            <EditorPage
              error={!error.text}
              label={translate('g.content')}
              placeholder="Добавьте содержание задания"
              data={state.params.text}
              onChange={(api, data) => onChangeTextField(data, 'text')}
            />
          )}
        </div>
        <div className="homework__data-item">
          <div className="homework__score">
            <Text className="homework__score-label">{translate('events.str.homework.scr')}</Text>
            <div className="homework__score-items">
              <Input
                className="homework__score__item"
                onChange={(e) => onChangeTextFieldNumber(e, 'minScore')}
                value={state.params.minScore}
                label="min"
                size="md"
                placeholder="0"
                error={!error.minScore}
                maxLength={4}
              />
              <Input
                className="homework__score__item"
                onChange={(e) => onChangeTextFieldNumber(e, 'maxScore')}
                value={state.params.maxScore}
                label="max"
                size="md"
                maxLength={4}
                placeholder="0"
                error={!error.maxScore}
              />
            </div>
          </div>
        </div>
        <div className="homework__data-item">
          <div className="homework__score">
            <Text className="homework__score-label">{translate('events.str.homework.scr.psg')}</Text>
            <div className="homework__score-items">
              <Input
                className="homework__score__item"
                maxLength={4}
                onChange={(e) => onChangeTextFieldNumber(e, 'passingScore')}
                value={state.params.passingScore}
                size="md"
                placeholder="0"
                error={!error.passingScore}
              />
            </div>
          </div>
        </div>
        <div className="homework__data-item">
          <Select data={dataSelectDate} onChange={onChangeSelectDate} label={translate('g.access')} />
        </div>
        {renderDateFrame()}
        <Button size="md" onClick={onUpload}>
          {translate('g.btn.add')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  data: store.modal.data!,
});

export default connect(mapStateToProps)(EventAttachHomework);
