import React from 'react';

interface IProps {
  className?: string;
}

export default function ELQuestionSmall(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" fill="none" viewBox="0 0 13 12" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M5.173 5.604S9.95 1.027 10.787.219c.837-.809 2.118.841 1.5 1.438-.619.597-1 .96-1 .96s.628.596 1.5 1.438c.871.841-1.144 3.02-2.144 2.054-1-.965-1.5-1.438-1.5-1.438l-2.47 2.372a3.27 3.27 0 01.306 2.335 3.387 3.387 0 01-1.352 1.964c-.68.48-1.517.71-2.359.648a3.605 3.605 0 01-2.23-.98A3.333 3.333 0 01.01 8.87a3.293 3.293 0 01.675-2.266 3.549 3.549 0 012.05-1.298 3.67 3.67 0 012.438.298zm-.636 3.967a1.37 1.37 0 00.317-.44 1.31 1.31 0 00-.3-1.495 1.42 1.42 0 00-.464-.297 1.467 1.467 0 00-1.094.01 1.417 1.417 0 00-.459.303c-.257.256-.4.599-.397.955.004.355.152.696.414.947.263.252.617.394.988.397.371.003.728-.133.995-.38z"
      />
    </svg>
  );
}
