import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELPause(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="18" fill="none" viewBox="0 0 15 18" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="3" d="M2 2v13.75M13 2v13.75" />
    </svg>
  );
}
