import { useEffect, useState } from 'react';

interface IState {
  isRole: any;
}

const usePermission: any = (roleIAm): any => {
  const [state, setState] = useState<IState>({
    isRole: {},
  });

  useEffect(() => {
    if (roleIAm?.agencyRoles?.length === 0 || !roleIAm?.agencyRoles) return;
    const [isRole] = roleIAm.agencyRoles;

    setState({
      ...state,
      isRole,
    });
  }, [roleIAm]);

  const onEntity__EVENTS = (data, reject) => () => {
    const isAllow = Object.keys(data)?.find((key) => roleIAm.eventsRoles.some((el) => el.role === key));

    if (!isAllow) return reject();

    if (isAllow && data?.eventId) {
      const isHandler = roleIAm.eventsRoles.find((el) => el.eventId === +data?.eventId)?.role;

      return isHandler ? data[isHandler]() : reject();
    }

    const defaultHandler = data[isAllow];

    if (!defaultHandler) return reject();

    defaultHandler();
  };

  const onTestAllowedRoles = (reject, allowedRoles, permission) => {
    if (!allowedRoles || !roleIAm?.eventsRoles) return reject();

    const entity = permission[1];

    const typesEntity = {
      events: onEntity__EVENTS(allowedRoles, reject),
    };

    if (!typesEntity[entity]) return reject();

    typesEntity[entity]();
  };

  const withPermissions = (permission, data) => {
    const permissionsFn = data[0];

    const allowedRoles = data[1];

    const [resolve, reject] = permissionsFn;

    if (state.isRole.slug === 'owner') return resolve();

    if (!state?.isRole?.scopes?.length) return onTestAllowedRoles(reject, allowedRoles, permission);

    const scopes = [...state.isRole.scopes];

    const isHavePermissions = scopes.find(
      (el) => `${el.privilege} ${el.resource}` === (Array.isArray(permission) ? permission.join(' ') : permission)
    );

    return isHavePermissions ? resolve() : reject();
  };

  const filteredPermissions = (permission, data) => {
    if (state.isRole.slug === 'owner') return true;

    const allowedRoles = data[1];

    if (!state?.isRole?.scopes?.length) {
      if (!allowedRoles || !roleIAm?.eventsRoles) return false;

      const entity = permission[1];

      const typesEntity = {
        events: () => {
          const isAllow = allowedRoles?.find((key) => roleIAm.eventsRoles.some((el) => el.role === key));

          if (!isAllow) return false;

          if (isAllow && data[2]?.eventId) {
            return roleIAm.eventsRoles.find((el) => el.eventId === +data[2]?.eventId)?.role === isAllow;
          }

          const defaultHandler = allowedRoles[isAllow];

          if (!defaultHandler) return false;

          return true;
        },
      };

      if (!typesEntity[entity]) return false;

      return typesEntity[entity]();
    }

    const scopes = [...state.isRole.scopes];

    const isHavePermissions = scopes.find(
      (el) => `${el.privilege} ${el.resource}` === (Array.isArray(permission) ? permission.join(' ') : permission)
    );

    return isHavePermissions ? true : false; //TODO [=)]
  };

  const filteredTabs = (data) => {
    return data.filter((el) => {
      if (el?.permissions) {
        return filteredPermissions(el?.permissions, el.allowedRoles);
      }

      return el;
    });
  };

  return [withPermissions, filteredTabs];
};

export default usePermission;
