import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import Icon from '../Icon';
import Text from '../Text';

interface IProps {
  className?: string;
  children: ReactNode;
  name: string;
  size?: string;
  iconName?: string;
  bordered?: boolean;
  user?: boolean;
}

const StatusView: FC<IProps> = function ({
  children,
  name,
  size,
  iconName,
  bordered = false,
  user = false,
}): ReactElement {
  const classesStatus = classNames('status', {
    [`status--bordered`]: bordered,
    [`status--user`]: user,
    [`status--${size}`]: size,
    'status--accepted': name === 'accepted',
    'status--pending': name === 'pending',
    'status--rejected': name === 'rejected' || name === 'canceled',
  });

  return (
    <Text className={classesStatus}>
      {iconName && <Icon name={iconName} />}
      {children}
    </Text>
  );
};

export default StatusView;
