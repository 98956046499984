import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect, useMemo } from 'react';
import { Icon, Pagination, Search, Square, Text } from '../../app/app.modules';
import { useGetParams, useToState } from '../../hooks';
import translate from '../../i18n/translate';
import Alphabet from '../Alphabet';
import Button from '../Button';
import ContextMenu2 from '../ContextMenu2';
import Permission from '../Permission';
import PopoverTarget from '../PopoverTarget';
import Scroll from '../Scroll';
import Select from '../Select';
import Toggle from '../Toggle';
import TableRow from './TableRow';

interface IProps {
  data: Array<{
    [key: string]:
      | any //TODO [Rework]
      | Array<
          | any
          | {
              sort: boolean;
              check: boolean;
            }
        >;
  }>;
  dataPanel?: Array<{
    title: string | ReactNode;
    icon?: string;
    disabled?: boolean;
    onChange?(data: any): void;
  }>;
  empty?: null | {
    text: string | ReactNode;
  };
  register?: Array<
    | {
        key?: string;
        title?: string;
        hidden?: boolean;
        checked?: boolean;
        view?: boolean;
      }
    | string
  > | null;
  dataControls?: {
    startPanel?: {
      captionName?: string | ReactNode;
      search?: {
        placeholder?: string | ReactNode;
        onChange?(data: string): void;
        onClear?(): void;
        defaultValue?: string;
      };
      alphabet?: {
        defaultValue?: string;
        lang: string;
        onChange?(data: string): void;
      };
      register?: {
        onChange?(data: any, checked: boolean): void;
        data?: Array<{
          key?: string;
          title?: string;
          hidden?: boolean;
          checked?: boolean;
          view?: boolean;
        }>;
        title?: string | ReactNode;
        placeholder?: string | ReactNode;
      };
      filter?: {
        status?: {
          data: Array<{
            title: string;
            params: {
              key: string;
            };
          }>;
          onChange(data: any): void;
        };
      };
      content?: ReactNode;
      exportEXEL?: {
        label: string | ReactNode;
        link: string;
      };
    };
    endPanel?: {
      content?: ReactNode;
      pagination?: {
        alwaysOn?: boolean;
        data: {
          page: number;
          perPage: number;
          total: number;
        };
        perPageLabel?: string | ReactNode;
        onChange?(page: number, perPage: number): void;
        onMore?(page: number, perPage: number): void;
      };
    };
    middlePanel?: {
      withScroll?: boolean;
    };
  };
  defaultSortBy?: string;
  singleRows?: null | Array<any>;
}

const Table: FC<IProps> = ({
  data,
  dataPanel,
  empty = null,
  register = null,
  dataControls,
  defaultSortBy = '',
  singleRows = null,
}): ReactElement | null => {
  const [state, setState] = useToState({
    tHead: [],
    tBody: [],
    sample: [],
    sampleAll: false,
    activeSortType: defaultSortBy,
    register: register ?? [
      'name',
      'email',
      'roles',
      'status',
      'lock',
      'phoneNumber',
      'createdAt',
      'subdomain',
      'address',
      'lastLogin',
      'plan',
    ],
    alphabetControl: false,
    alphabetValue: dataControls?.startPanel?.alphabet?.defaultValue ?? '',
    registerControl: false,
  });

  const paramsRoute = useGetParams('data');

  const dataTableField = state.register;

  useEffect(() => {
    register &&
      setState({
        register,
      });
  }, [register]);

  const onToggleActiveSortType = (data) => () => {
    const askDesk = state.activeSortType === data.field;

    const replaceField = data?.params?.replaceField;

    setState({
      activeSortType: askDesk ? '' : data.field,
    });

    data?.sort &&
      data.sort({
        sortBy: replaceField ?? data.field,
        sortOrder: askDesk ? 'DESC' : 'ASC',
      });
  };

  const onToggleSampleAll = (e) => {
    const { checked } = e.target;

    const { tBody } = state;

    const sample = checked ? [...tBody] : [];

    setState({
      sample,
    });
  };

  useEffect(() => {
    const { sample, tBody } = state;

    const contains = tBody.length > 0 && sample.length === tBody.length;

    setState({
      sampleAll: contains,
    });
  }, [state.sample]);

  useEffect(() => {
    const routeParse = JSON.parse(`${paramsRoute}`) ?? [];

    routeParse.length > 0 &&
      setState({
        sample: routeParse.map((el) => [{ id: el }]),
      });
  }, [paramsRoute]);

  const onChangeSample = (checked, data) => {
    const { sample } = state;

    const cloneSample = [...sample];

    const searchIndex = sample.findIndex((el) => el.id === data.id);

    checked ? cloneSample.push(data) : cloneSample.splice(searchIndex, 1);

    setState({
      sample: cloneSample,
    });
  };

  const onChangeFieldPanel = (field) => async () => {
    if (field?.onChange) {
      await field.onChange({
        sample: state.sample.map((el) => el[0].id),
        table: data,
      });

      setState({
        sample: [],
      });
    }
  };

  const toLocked = (data) => {
    if (data?.agencyMeta?.blocked) {
      return { blocked: data.agencyMeta.blocked };
    }
  };

  // const parserInjectData = (data, key) => {
  //   if (typeof data[key] !== 'function') return null;
  //
  //   const types = {
  //     lock: '',
  //     default: {
  //       ...data[key](),
  //       field: key,
  //       id: data.id,
  //     }
  //   }
  //
  //   return types[key] ?? types['default']
  // }

  const sampleInjectFunction = (data, ignoreLocked = false) => {
    const locked = 'lock' in data && data.lock();

    if (!ignoreLocked && locked.view) {
      const arr = ['lock', ...locked.concat];

      return arr
        .map((el) =>
          typeof data[el] === 'function'
            ? {
                ...data[el](),
                field: el,
                id: data.id,
                ...toLocked(data),
              }
            : null
        )
        .filter((el) => el)
        .sort((e, b) => e.position - b.position);
    }

    return dataTableField
      .filter((col) => !col?.hidden)
      .map((el) =>
        typeof data[el?.key ?? el] === 'function' && (el?.key ?? el) !== 'lock'
          ? {
              ...data[el?.key ?? el](),
              field: el?.key ?? el,
              id: data.id,
              ...toLocked(data),
            }
          : null
      )
      .filter((el) => el)
      .sort((e, b) => e.position - b.position);
  };

  const THEAD_PARSER = (data) => {
    if (!data?.length) return [];

    const injectElement = data[0]; //.find(el => !el.deletedAt) ?? []; //TODO [!!!]

    return sampleInjectFunction(injectElement, true);
  };

  const TBODY_PARSER = (data) => {
    return data.map((el) => sampleInjectFunction(el));
  };

  const onLoadDefault = () => {
    setState({
      tHead: THEAD_PARSER(data),
      tBody: TBODY_PARSER(data),
      //
      // tFoot
    });
  };

  useEffect(onLoadDefault, [data, state.register]);

  const renderPanel = () => {
    const { sample } = state;

    return (
      sample.length > 0 &&
      dataPanel && (
        <div className="table__panel">
          <div className="table__panel-count">
            <Toggle replace variant="success" checked={state.sampleAll} onChange={onToggleSampleAll}>
              <Icon name="CheckSuccess" />
            </Toggle>
            <Text variant="md">
              {sample.length} {translate('g.choose')}
            </Text>
          </div>
          {dataPanel.map((item, i) => (
            <Text
              variant="md"
              key={i}
              className="table__panel-field"
              disabled={item?.disabled}
              onClick={onChangeFieldPanel(item)}
            >
              {item?.icon && <Icon name={item.icon} className="table__panel-icon" />}
              {item.title}
            </Text>
          ))}
        </div>
      )
    );
  };

  const renderCheckTemplate = (data, checked, onChange) => {
    const isPermission = data?.permissions;

    const renderWithPermissions = (children) => <Permission is={isPermission}>{children}</Permission>;

    const content = (
      <Toggle replace variant="success" checked={checked} onChange={onChange} className="table__switch">
        <Icon name="CheckSuccess" />
      </Toggle>
    );

    return isPermission ? renderWithPermissions(content) : content;
  };

  const renderHead = () => {
    return (
      <div className="table__thead">
        {renderPanel()}
        {state.tHead.map((item, i) => (
          <Text key={i} className={`table__thead-th table__col--${item.field}`}>
            {item.check && renderCheckTemplate(item, state.sampleAll, onToggleSampleAll)}
            <strong>{item.title}</strong>
            {item.sort && (
              <Square
                active={state.activeSortType === item.field}
                className="table__thead-sort"
                size="sm"
                variant="outlined"
                onClick={onToggleActiveSortType(item)}
              >
                <Icon name="ArrowSmall" />
              </Square>
            )}
          </Text>
        ))}
      </div>
    );
  };

  const renderSingleRows = () => {
    if (!singleRows) return null;

    const dataParse = TBODY_PARSER(singleRows);

    return dataParse.map((item) => (
      <TableRow
        key={item[0]?.id ?? item[0]?.fieldId}
        data={item}
        type="single"
        active={{
          update: false,
          view: false,
        }}
      />
    ));
  };

  const renderBody = () => {
    return (
      <div className="table__tbody">
        {state.tBody.map((item) => (
          <TableRow
            key={item[0].id}
            data={item}
            active={{
              update: state.sample.length === 0 || state.sample.length === state.tBody.length,
              view: state.sampleAll,
            }}
            onChangeSample={onChangeSample}
            renderCheckTemplate={renderCheckTemplate}
          />
        ))}
        {renderSingleRows()}
      </div>
    );
  };

  const renderEmpty = () => {
    const text = empty?.text;

    return <Text className="table__empty">{text ? text : translate('g.not.found')}</Text>;
  };

  const renderPagination = (pagination) => {
    const { tBody } = state;

    const paginationData = pagination?.data;

    const perPageLabel = pagination?.perPageLabel;

    const onChange = pagination?.onChange;

    const onMore = pagination?.onMore;

    const isShow = !!paginationData.alwaysOn || Math.ceil(paginationData.total / paginationData.perPage) > 1;
    // const isParams = {
    //   ...(withScroll && {
    //     scrollIntoElement: scrollRef?.current,
    //   }),
    // };

    return isShow && tBody.length > 0 ? ( //TODO [Thunk]: Why tBody
      <>
        {onMore && (
          <Button className="table__end-more" variant="outlined" onClick={() => onMore(paginationData.page)}>
            {translate('g.btn.view.more')}
          </Button>
        )}
        <Pagination
          alwaysOn={isShow}
          onChange={onChange}
          perPage={paginationData.perPage}
          page={paginationData.page}
          total={paginationData.total}
          realTimeCount={tBody.length}
          perPageLabel={perPageLabel}
          scrollIntoElement={document.querySelector('.table')} //TODO [Test]: Test and Rework on useRef
          //{...isParams}
        />
      </>
    ) : null;
  };

  const renderEndPanel = () => {
    const endPanel = dataControls?.endPanel;

    const pagination = endPanel?.pagination;

    return endPanel && <div className="table__end-panel">{pagination && renderPagination(pagination)}</div>;
  };

  const renderSearch = (search) => {
    return (
      <Search
        onSearch={search?.onChange}
        onClear={search?.onClear}
        defaultValue={search?.defaultValue}
        onEnter={search?.onChange}
        // placeholder={search.placeholder ?? 'Введите текст'}
        placeholderNode={search.placeholder ?? translate('g.search.pls')}
        size="md"
        type="square"
        width="none"
      />
    );
  };

  const onToggleControls = (key) => () => {
    setState({
      [key]: !state[key],
    });
  };

  const onChangeAlphabet = (alphabet) => (alphabetValue) => {
    setState({
      alphabetValue,
    });

    alphabet?.onChange(alphabetValue);
  };

  const renderAlphabet = (alphabet) => {
    return (
      <PopoverTarget
        onClick={onToggleControls('alphabetControl')}
        onClose={onToggleControls('alphabetControl')}
        target={() =>
          alphabet && (
            <Alphabet
              lang={alphabet.lang}
              onChange={onChangeAlphabet(alphabet)}
              defaultValue={alphabet?.defaultValue}
            />
          )
        }
        mode="left"
      >
        <Square size="lg" play={state.alphabetControl} className="dsa">
          {state.alphabetValue ? <Text>{state.alphabetValue}</Text> : <Icon name="ELAlphabet" />}
        </Square>
      </PopoverTarget>
    );
  };

  const onChangeRegister = (onChange) => (dataSelect, checked) => {
    const { register } = state;

    const cloneRegister = [...register];

    const findIndex = cloneRegister.findIndex((el) => el.key === dataSelect.key);

    cloneRegister[findIndex].checked = checked;

    cloneRegister[findIndex].hidden = !checked;

    onChange && onChange(dataSelect, checked);

    setState({ register: cloneRegister });
  };

  const onFilteredRegister = (data, defaultData) => {
    const { register } = state;

    if (!defaultData) return register;

    return register.filter((el) => defaultData.some((some) => some.key === el.key));
  };

  const renderRegister = useMemo(() => {
    const startPanel = dataControls?.startPanel;

    const register = startPanel?.register;

    const defaultRegister = register?.data;

    const search = {
      label: register?.title,
      placeholderNode: register?.placeholder,
    };

    const withScroll = register?.title !== undefined;

    const classesRegisterMenu = classNames('table__register-menu', {
      'table__register-menu--without': !withScroll,
    });

    return (
      <PopoverTarget
        onClick={onToggleControls('registerControl')}
        onClose={onToggleControls('registerControl')}
        target={() =>
          register && (
            <ContextMenu2
              search={search}
              withScroll={withScroll}
              className={classesRegisterMenu}
              data={onFilteredRegister(state.register, defaultRegister)}
              onChange={onChangeRegister(register.onChange)}
            />
          )
        }
        mode="left"
      >
        <Square size="lg" play={state.registerControl}>
          <Icon name="ELSettings" />
        </Square>
      </PopoverTarget>
    );
  }, [state.registerControl]);

  const renderFilterStatus = (status) => {
    const data = status?.data;

    const onChange = status?.onChange;

    return <Select update={false} data={data} onChange={onChange} />;
  };

  const renderFilter = () => {
    const startPanel = dataControls?.startPanel;

    const filter = startPanel?.filter;

    const status = filter?.status;

    return <div className="table__filter">{status && renderFilterStatus(status)}</div>;
  };

  const renderExportEXEL = (data) => {
    return (
      <a download href={data.link}>
        <Square auto size="lg">
          <Text className="table__export">
            <Icon name="ELDownload" />
            {data.label}
          </Text>
        </Square>
      </a>
    );
  };

  const renderStartPanel = () => {
    const startPanel = dataControls?.startPanel;

    const captionName = startPanel?.captionName;

    const search = startPanel?.search;

    const register = startPanel?.register;

    const filter = startPanel?.filter;

    const content = startPanel?.content;

    const exportEXEL = startPanel?.exportEXEL;

    const alphabet = startPanel?.alphabet;

    return (
      startPanel && (
        <>
          <div className="table__start-panel">
            {captionName && <h1 className="table__caption-name">{captionName}</h1>}
            {exportEXEL && renderExportEXEL(exportEXEL)}
            {search && renderSearch(search)}
            {alphabet && renderAlphabet(alphabet)}
            {register && renderRegister}
            {filter && renderFilter()}
          </div>
          {content}
        </>
      )
    );
  };

  const renderContent = () => {
    return (
      <>
        {renderHead()}
        {renderBody()}
      </>
    );
  };

  const renderTable = () => {
    const withScroll = dataControls?.middlePanel?.withScroll;

    const content = renderContent();

    if (!withScroll) return content;

    return (
      <Scroll>
        <div className="table__scroll">{content}</div>
      </Scroll>
    );
  };

  return useMemo(
    () => (
      <div className="table">
        {renderStartPanel()}
        {data?.length > 0 ? renderTable() : renderEmpty()}
        {renderEndPanel()}
      </div>
    ),
    [data, state]
  );
};

export default Table;
