import './index.scss';
import PaginationCore from 'rc-pagination';
import React, { FC, ReactNode, useEffect } from 'react';
import { $root } from '../../env';
import { useToState } from '../../hooks';
import translate from '../../i18n/translate';
import Icon from '../Icon';
import Input from '../Input';
import Select from '../Select';
import Text from '../Text';

interface IProps {
  alwaysOn?: boolean;
  onChange?: any;
  total?: number;
  page?: number;
  perPage?: number;
  scrollIntoElement?: any;
  realTimeCount?: number;
  perPageLabel?: string | ReactNode;
}

const Pagination: FC<IProps> = ({
  alwaysOn,
  onChange,
  total = 10,
  page = 1,
  perPage = 1,
  scrollIntoElement = null,
  realTimeCount = 5,
  perPageLabel,
}): any => {
  const [state, setState] = useToState({
    value: '',
    current: page,
    perPageValue: perPage,
    jump: perPage,
  });

  const onUpdateRealTime = () => {
    if (total > realTimeCount && perPage > realTimeCount) {
      onChangeAction(state.current);
    }
  };

  useEffect(onUpdateRealTime, [realTimeCount, total]);

  const onChangeScrollEffect = () => {
    if (scrollIntoElement === null) return null;

    (scrollIntoElement ? scrollIntoElement : $root)?.scrollIntoView({
      block: 'start',
      behavior: 'auto',
    });
  };

  const onChangeAction = (page, perPageValue = perPage, scrollEffect = true) => {
    onChange &&
      onChange(page, perPageValue)?.then(() => {
        scrollEffect && onChangeScrollEffect();
      });
  };

  const onChangeSlide = (page) => {
    setState({
      current: page,
    });
    onChangeAction(page);
  };

  const onChangeInputPage = (e) => {
    const { value } = e.target;

    const isValidNumber = value.replace(/\D/g, '');

    const difference = Math.ceil(total / perPage);

    +isValidNumber <= difference &&
      setState({
        value: isValidNumber,
      });
  };

  const onChangePerPage = (e) => {
    const value = e.title;

    const difference = Math.ceil(total / perPage);

    +value <= difference &&
      setState({
        perPageValue: value,
      });

    onChangeAction(+page, value, false);
  };

  const onNextSlide = () => {
    const { value } = state;

    setState({
      current: +value,
    });

    onChangeAction(+value);
  };

  const renderSlider = () => {
    const { current } = state;

    return (
      <div className="pagination__slider">
        <PaginationCore
          itemRender={itemRender}
          showTitle={false}
          current={current}
          total={total}
          pageSize={perPage}
          onChange={onChangeSlide}
        />
      </div>
    );
  };

  const itemRender = (current, type, element) => {
    const types = {
      page: (
        <Text className="pagination__slide" variant="md">
          {current}
        </Text>
      ),
      prev: <Icon name="ArrowXS" className="pagination__slider-prev" />,
      next: <Icon name="ArrowXS" className="pagination__slider-next" />,
      'jump-next': (
        <Text className="pagination__slide" variant="md">
          ...
        </Text>
      ),
      'jump-prev': (
        <Text className="pagination__slide" variant="md">
          ...
        </Text>
      ),
    };

    return types[type] ?? element;
  };

  const isShow = !!alwaysOn || Math.ceil(total / perPage) > 1;

  const renderPerPage = () => {
    if (!perPageLabel) return null;

    const { jump } = state;

    const difference = Math.ceil(total / jump);

    const longFormation = [...Array(difference).keys()];

    if (!longFormation.length) return null;

    const formationDataSelect = [
      { title: 10, active: 10 === perPage },
      { title: 50, active: 50 === perPage },
      { title: 100, active: 100 === perPage },
    ];

    return (
      <div className="pagination__perp">
        <Text variant="md" className="pagination__perp-label">
          {perPageLabel}
        </Text>
        <div className="pagination__input">
          <Select data={formationDataSelect} className="pagination__input-select" auto onChange={onChangePerPage} />
        </div>
      </div>
    );
  };

  return (
    isShow && (
      <div className="pagination">
        {renderPerPage()}
        <div className="pagination__controls">
          {renderSlider()}
          <div className="pagination__input">
            <Text className="pagination__input-label" variant="md">
              {translate('g.pgn.lbl')}
            </Text>
            <Input
              maxLength={4}
              className="pagination__input-field"
              variant="inline"
              value={state.value}
              onChange={onChangeInputPage}
            />
            <Icon name="Arrow" className="pagination__input-btn" onClick={onNextSlide} />
          </div>
        </div>
      </div>
    )
  );
};

export default Pagination;
