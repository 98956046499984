import React from 'react';

export default function Photo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" fill="none" viewBox="0 0 18 16">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M16.04 3.6h-2.886l-.478-1.437A1.758 1.758 0 0011.006.96H6.994a1.758 1.758 0 00-1.67 1.204L4.846 3.6H1.96A1.762 1.762 0 00.2 5.36v7.92a1.762 1.762 0 001.76 1.76h14.08a1.762 1.762 0 001.76-1.76V5.36a1.762 1.762 0 00-1.76-1.76zM9 12.4a3.52 3.52 0 110-7.04 3.52 3.52 0 010 7.04z"
      />
    </svg>
  );
}
