import './index.scss';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Icon, Text } from '../../../app/app.modules';
import { useMobile } from '../../../hooks';
import translate from '../../../i18n/translate';

interface IProps {
  status?: string;
}

const CardStatus: FC<IProps> = ({ status }) => {
  if (!status) return null;

  const [isMobile] = useMobile();

  const types = {
    Stopped: (
      <>
        <Icon className="card-status__icon" name="ELCloseCircle" />
        <Text className="card-status__txt" variant="md">
          {translate('g.active.not')}
        </Text>
      </>
    ),
    Working: (
      <>
        <Icon className="card-status__icon" name="ELCheckCircle" />
        <Text className="card-status__txt" variant="md">
          {translate('g.active')}
        </Text>
      </>
    ),
  };

  const classesCardStatus = classNames('card-status', `card-status--${status.toLowerCase()}`);

  return useMemo(() => <div className={classesCardStatus}>{!isMobile && types[status]}</div>, [status, isMobile]);
};

export default CardStatus;
