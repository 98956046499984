import React from 'react';

export default function AddLight() {
  return (
    <i>
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="none" viewBox="0 0 13 13">
        <path
          stroke="var(--el-fonts--icon-color)"
          strokeLinecap="round"
          strokeWidth="2"
          d="M6.657 1.88v9.555m4.778-4.778H1.88"
        />
      </svg>
    </i>
  );
}
