export default {
  EN: {
    'ts.results': 'Results',
    'ts.formation': 'Test formation',
    'ts.overview': 'Basic information',
    'ts.library': 'Tests library',
    'ts.create.qs': 'Create questions',
    'ts.add.rating': 'Mark added',
    'ts.delete.rating': 'Mark deleted',
    'ts.change.rating': 'Mark has been changed',
    'ts.change.qs': 'Edit questions',
    'ts.save.success': 'Test saved succesfully',
    'ts.allowed.after.test': 'Allowed after test pass`',
    'ts.allowed.period.test': 'Allowed after expiration of test period',
    'ts.add.event': 'Added to events',
    'ts.stt.tlt': 'Test settings',
    'ts.desc': 'Test description',
    'ts.remove': 'Remove test',
    'ts.desc.plc': 'Input test name',
    'ts.cover': 'Test cover',
    'ts.cover.mini': 'Test minicover',
    'ts.passing': 'Passing parameters',
    'ts.order.qs': 'Questions sequence',
    'ts.move.qs': 'Cross-over between questions',
    'ts.change.aws': 'Change answer',
    'ts.change.nkw': 'Answer "I dont know"',
    'ts.see.results': 'See results',
    'ts.see.correct': 'See correct answers',
    'ts.atm': 'Parameters of the attempt',
    'ts.count.atm': 'Attempts amount',
    'ts.time.atm': 'Time for one attempt',
    'ts.interval.atm': 'Interval between two attempts',
    'ts.fix.atm': 'Register an attemp',
    'ts.qs.atm': 'Number of questions in one attempt',
    'ts.order.qs.hint':
      'Test questions will follow one another with a sequence you defined in questions designer.Pick "accidentialy" to shuffle questions automatically.',
    'ts.fix.atm.hint': 'Choose "yes" in order to always repeat  questions formed by first attempt and their sequence.',
    'ts.move.qs.hint': 'Choose "allowed" in order that the student could cross over to any question while testing.',
    'ts.change.aws.hint':
      'Choose "allowed", in order that the student could return to any question and change his answer while testing.',
    'ts.change.nkw.hint':
      'The answer “I dont know” will be added for objective estimation of real knowledges in cases when student knows no answer but can guess it by accidentially choosing the answer .',
    'ts.see.results.hint': 'Set up an option to view the attempt results by the participant',
    'ts.see.correct.hint': 'Set up an option to view correct answers by the participant',
    'ts.count.atm.hint':
      'You can change attempt amounts for passing test by clicking the cross icon at the input field and putting in the value you need. If you leave this field empty or input zero value - attempts amount will not be limited. By such value of the parameter a student has unlimited test attempts, until it remains available for pass.',
    'ts.interval.atm.hint':
      'You can change the time interval between each attempt by clicken cross icon at the input field und write in the value you need. If you leave this field empty or input zero value - the student can make attempts as often as he wants.',
    'ts.amount.rts': 'Summing-up parameters',
    'ts.rate': 'Rate',
    'ts.rate.grate': 'Grade for testing will be calculated based on summ of gained score or completion percentage',
    'ts.grade': 'Grade',
    'ts.prc': 'Percent',
    'ts.scores': 'points',
    'ts.score.desc': 'Grade description',
    'ts.score.desc.plc': 'Input description to the grade',
    'ts.score.add': 'Add grade',
    'ts.rts.desc': 'General description to the test result',
    'ts.rts.desc.text': 'You may attach general description, image and other elements to the test results.',
    'ts.enter.desc': 'Enter description',
    'ts.desc.hint': 'You may attach general description, image and other elements to the final grade.',
    'ts.enter.rating': 'Enter grade',
    'ts.crt.tlt': 'New test',
    'ts.crt.name.pls': 'Input test name',
    'ts.crt.desc.pls': 'Input test description',
    'ts.mini.cover.field': 'Test minicover',
    'ts.field.know.no': ' "I dont know" answer',
  },
};
