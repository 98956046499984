import React, { FC, ReactElement } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface IProps {
  onChange?(data: string): void;
  copyText: string;
  children: any;
}

const Clipboard: FC<IProps> = ({ copyText, onChange, children }): ReactElement => {
  return (
    <CopyToClipboard text={copyText} onCopy={onChange}>
      {children}
    </CopyToClipboard>
  );
};

export default Clipboard;
