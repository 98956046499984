import React from 'react';

interface IProps {
  className?: string;
}

export default function Dust({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 12 13" {...params}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M4.255 7.457l5.51 1.171c1.008.215 1.672 1.081 1.49 1.943l-.006.024c-.183.862-1.143 1.383-2.151 1.169l-5.51-1.171c-1.009-.215-1.673-1.081-1.49-1.943l.005-.024c.183-.862 1.143-1.383 2.152-1.169z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M2.296 3.702l3.296.705c.603.129 1.008.611.907 1.082l-.003.013c-.1.47-.667.746-1.27.617L1.93 5.414c-.603-.13-1.008-.612-.907-1.083l.002-.013c.101-.47.668-.745 1.271-.616z"
        opacity="0.6"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M1.082.839L3.4 1.33c.424.09.696.491.61.9l-.003.011c-.087.408-.498.664-.923.574L.766 2.324a.768.768 0 01-.609-.9l.003-.011a.765.765 0 01.922-.574z"
        opacity="0.4"
      />
    </svg>
  );
}
