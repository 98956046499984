import React from 'react';

export default function Delete() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" fill="none" viewBox="0 0 8 9">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M5.196 4.5l2.546-2.546A.846.846 0 106.546.758L4 3.304 1.454.758A.846.846 0 10.258 1.954L2.804 4.5.258 7.046a.846.846 0 101.196 1.196L4 5.696l2.546 2.546a.846.846 0 101.196-1.196L5.196 4.5z"
      />
    </svg>
  );
}
