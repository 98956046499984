import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect, useRef } from 'react';
import { useEvent, useToState } from '../../hooks';
import addOrRemoveFromArray from '../../utils/addOrRemoveFromArray';
import Hint from '../Hint';
import Icon from '../Icon';
import Portal from '../Portal';
import Scroll from '../Scroll';
import Toggle from '../Toggle';

interface IProps {
  variant?: string;
  data?: Array<{
    title: string | ReactNode;
    active?: boolean;
    params?: any;
  }>;
  label?: string | ReactNode;
  onChange?(data, e): any;
  multiple?: boolean;
  placeholder?: string | ReactNode;
  auto?: boolean;
  children?: any;
  hint?: string;
  className?: string;
  update?: boolean;
  error?: boolean;
  defaultHeightSelection?: number;
}

interface IState {
  toggle?: boolean;
  selected?: string;
  multiples?: Array<string>;
  rectStyle?: object;
  move?: boolean;
}

const Select: FC<IProps> = ({
  data,
  onChange,
  variant = 'primary',
  multiple = false,
  placeholder,
  label,
  auto,
  hint,
  children,
  className,
  update = true,
  error,
  defaultHeightSelection,
}): ReactElement => {
  const renderActive = (): any => {
    return data?.find((el) => el?.active)?.title || null;
  };

  const [state, setState] = useToState<IState>({
    toggle: false,
    selected: renderActive() || placeholder || '-',
    multiples: [],
    rectStyle: {},
    move: false,
  });

  update &&
    useEffect(() => {
      setState({
        selected: renderActive() || placeholder || '-',
      });
    }, [data]); //TODO [Test]: Testing new Effect

  const wrapper = useRef(null);

  const wrapperSelection = useRef(null);

  const wrapperSelected = useRef(null);

  const target: HTMLDivElement = wrapper.current!;

  const targetSelected: HTMLDivElement = wrapperSelected.current!;

  const closeSelect: EventListener = (e) => {
    const search = e.target as Node;

    if (target instanceof HTMLDivElement) {
      if (!target.contains(search)) {
        setState((state) => ({ ...state, toggle: false }));
      }
    }
  };

  const toggleSelect = () => {
    setState({ ...state, toggle: !state.toggle });
  };

  const onMultiples = (e, title, data) => {
    setState((state) => ({
      ...state,
      multiples: addOrRemoveFromArray(state?.multiples, title),
    }));

    onChange && onChange(data, e);
  };

  const onChaneSelected = (data) => {
    if (multiple) return null;

    setState((state) => ({
      ...state,
      toggle: multiple,
      ...(!multiple && {
        selected: data?.title,
      }),
    }));

    onChange && onChange(data, null);
  };

  useEvent('click', closeSelect);

  const renderMenuItem: Function = (): ReactNode => {
    return (
      <div
        className={`select--${variant}__selection ${state.move ? 'move' : ''}`}
        {...state.rectStyle}
        ref={wrapperSelection}
      >
        <Scroll>
          {data?.map((item, i) => {
            const { title } = item;

            const isActive = title === state.selected ? 'active' : '';

            return (
              <div className={`select--${variant}__option ${isActive}`} onClick={() => onChaneSelected(item)} key={i}>
                <span>{title}</span>
                {multiple && (
                  <Toggle
                    onChange={(e) => onMultiples(e, title, item)}
                    defaultChecked={state?.multiples!.indexOf(title) !== -1}
                  />
                )}
              </div>
            );
          })}
        </Scroll>
      </div>
    );
  };

  const renderSelected = () => {
    const filtered = state?.multiples!?.map((el, i) => <span key={i}>{el}</span>);

    return !multiple ? state.selected : filtered.length > 0 ? filtered : state.selected;
  };

  const top = targetSelected?.getBoundingClientRect().top;

  const left = targetSelected?.getBoundingClientRect().left;

  const widthWrapper = targetSelected?.getBoundingClientRect().width;

  const height = targetSelected?.getBoundingClientRect().height;

  useEffect(() => {
    if (target) {
      const targetSelection: HTMLDivElement = wrapperSelection.current!;

      const heightSelection = targetSelection?.getBoundingClientRect().height;

      const coefficient = defaultHeightSelection ?? heightSelection + (data?.reduce((total) => total + 44, 0) || 0); //total + 32//TODO [Thunk]

      const isMove = coefficient + top + height > window.innerHeight - height;

      setState((state) => ({
        ...state,
        move: isMove,
        rectStyle: {
          style: {
            ...(isMove ? { top: top - coefficient + 1 } : { top: top + height - 1 }),
            // top: top + height - 1,
            left,
            width: widthWrapper,
            ...(defaultHeightSelection && {
              height: defaultHeightSelection,
            }),
          },
        },
      }));
    }
  }, [target, top, left, state.toggle]); //TODO [Rework] : Rework on useCallback

  const renderHint = () => {
    return (
      hint && (
        <Hint label={hint} move={['right', 'bottom']}>
          {(ref) => <Icon name="ELHint" innerRef={ref} />}
        </Hint>
      )
    );
  };

  const classesSelect = classNames('select--eject', className, {
    [`select--${variant}`]: variant,
    'select--hint': hint,
    'select--auto': auto,
    multiple: multiple,
    'select--error': error,
  });

  return (
    <div className={classesSelect} ref={wrapper}>
      <div
        className={`select--${variant}__selected ${state.toggle ? 'active' : ''} ${state.move ? 'move' : ''}`}
        onClick={toggleSelect}
        ref={wrapperSelected}
      >
        {renderSelected()}
        <Icon name="ArrowUse" />
      </div>
      {state.toggle && <Portal>{renderMenuItem()}</Portal>}
      {children}
      {renderHint()}
      {label && <label>{label}</label>}
    </div>
  );
};

export default Select; //TODO [Deprecated]: Rework with PopoverTarget and DivTarget
