import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useToState } from '../../../hooks';
import { parserName } from '../../../utils';
import { ContextMenu, Search } from '../../app.modules';
import { IMiddlewareIntraSystem } from '../middleware.resources';
import { middlewareIntraSystemSearch } from '../middleware.services';

interface IProps {
  label?: string | ReactNode;
  className?: string;
  value?: string;
  onChange?(data: any): void;
  onOption?(data: any): void;
  error?: boolean;
  placeholder?: string | ReactNode;
  errorNode?: string | ReactNode;
  middlewareIntraSystemSearchAction(data: IMiddlewareIntraSystem): Promise<any>;
}

const MiddlewareIntraSystemSearch: FC<IProps> = ({
  middlewareIntraSystemSearchAction,
  label,
  placeholder,
  className,
  error,
  value,
  onChange,
  onOption,
  errorNode,
}) => {
  const [state, setState] = useToState({
    data: [],
  });

  const onSearchChange = (data) => {
    data && onChange && onChange({ target: { value: data } });
  };

  const onChangeOption = (data) => () => {
    setState({
      data: [],
    });
    data && onOption && onOption(data);
  };

  const onChangeSearch = (email) => {
    middlewareIntraSystemSearchAction({ email }).then((data) => {
      setState({
        data: data.map((el) => ({
          ...el,
          title: (
            <div className="intrasystem-search__menu-option">
              <span className="intrasystem-search__menu-email">{el.email}</span>
              <span className="intrasystem-search__menu-name">
                {parserName(el.firstName, el.lastName, el.patronymic)}
              </span>
            </div>
          ),
          onClick: onChangeOption({
            email: el.email,
            firstName: el.firstName,
            lastName: el.lastName,
            patronymic: el.patronymic,
          }),
        })),
      });
    });
  };

  const renderOptions = () => {
    const { data } = state;

    return data.length > 0 && <ContextMenu className="intrasystem-search__menu" data={data} />;
  };

  const classesIntraSystemSearch = classNames('intrasystem-search', className);

  return (
    <div className={classesIntraSystemSearch}>
      <Search
        size="md"
        error={error}
        errorNode={errorNode}
        withoutIcon
        value={value}
        label={label}
        onSearch={onChangeSearch}
        onChange={onSearchChange}
        placeholderNode={placeholder}
      />
      {renderOptions()}
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  middlewareIntraSystemSearchAction: bindActionCreators(middlewareIntraSystemSearch, dispatch),
});

export default connect(null, mapDispatchToProps)(MiddlewareIntraSystemSearch);
