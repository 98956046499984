import React from 'react';

export default function ChatEmpty() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none" viewBox="0 0 26 26">
      <g opacity="0.5">
        <path
          fill="var(--el-fonts--icon-color)"
          d="M24.419 11.367l-.007-.063V11.3l-.001-.003-.028-.236-.02-.163-.006-.049-.012.002c-.299-1.698-1.119-3.31-2.39-4.685-1.263-1.37-2.906-2.45-4.75-3.127a14.414 14.414 0 00-4.98-.873c-2.329 0-4.591.548-6.542 1.584-3.76 2-5.979 5.673-5.651 9.36.17 1.908.917 3.724 2.161 5.252 1.171 1.438 2.756 2.598 4.582 3.356 1.122.466 2.232.685 3.408.917l.137.028c.324.064.411.152.433.187.042.064.02.192.004.262l-.047.194c-.127.515-.257 1.047-.155 1.634.118.674.54 1.061 1.156 1.061.663 0 1.418-.445 1.918-.739l.066-.04c1.192-.699 2.314-1.488 3.16-2.098 1.848-1.334 3.943-2.845 5.514-4.801 1.582-1.97 2.328-4.501 2.05-6.955z"
        />
        <circle cx="21" cy="5" r="5" fill="#fff" />
        <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M21 2.5v5M18.5 5h5" />
        <circle r="1.375" fill="#fff" transform="matrix(-1 0 0 1 5.973 12.609)" />
        <circle r="1.375" fill="#fff" transform="matrix(-1 0 0 1 12.416 12.609)" />
        <circle r="1.375" fill="#fff" transform="matrix(-1 0 0 1 18.545 12.736)" />
      </g>
    </svg>
  );
}
