import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { SUPPORT } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...SUPPORT };
  }

  public sendSupportData(data: IRequestDataParse): Promise<any> {
    const { SEND_INFO } = this.ROUTE;

    return this.upload(SEND_INFO, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
