import React from 'react';

interface IProps {
  className?: string;
}

export default function ELRocket(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="61" height="85" fill="none" viewBox="0 0 61 85" {...props}>
      <g
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3.618"
      >
        <path d="M26.342 57.087l-10.811-5.725s4.163-15.737 10.928-26.452c4.414-6.991 9.82-13.615 16.283-18.003 3.272-2.223 7.845-6.12 12.274-4.122" />
        <path d="M35.726 39.064a7.63 7.63 0 107.165-13.474 7.63 7.63 0 00-7.165 13.474zM42.703 7.64s.194 3.673 6.11 6.811c5.842 3.1 9.063 1.125 9.063 1.125" />
        <path d="M26.03 56.92l10.792 5.763s10.72-12.25 15.821-23.85c3.328-7.569 6.292-16.038 6.318-23.848.014-3.966.234-10.1-3.944-12.2M30.471 66.449c-2.534-1.353-10.463-5.637-13.776-7.362-3.313-1.725-1.166-7.724-1.166-7.724l21.292 11.322s-3.796 5.127-6.35 3.764z" />
        <path d="M3.845 47.729c-.515 1.127-3.207 8.68-1.29 12.323 1.494 2.839 11.928-5.34 12.775-8.04.847-2.7 5.588-16.2 5.588-16.2S7.604 39.501 3.845 47.728zm42.849 2.158s4.383 13.103-.336 20.82c-.646 1.058-5.404 7.513-9.496 7.96-3.188.348-2.242-12.874-.477-15.086 1.765-2.212 10.31-13.694 10.31-13.694zM20.22 67.847l-7.96 14.97m2.39-18.107l-4.357 8.194m15.455-2.142l-4.384 8.244" />
      </g>
    </svg>
  );
}
