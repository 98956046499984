import { IMiddlewareAction, IMiddlewareState } from './middleware.resources';
import types from './middleware.types';

const initialState: IMiddlewareState = {
  session: null,
  user: null,
  scopes: null,
  roleIAm: {},
  roles: null,
  socketStream: null,
  forceUpdate: null,
};

export default function modalReducer(
  state: IMiddlewareState = initialState,
  action: IMiddlewareAction
): IMiddlewareState {
  const actionType = {
    [types.SESSION]: {
      ...state,
      session: action.session,
    },
    [types.USER]: {
      ...state,
      user: action.user,
    },
    [types.SCOPES]: {
      ...state,
      scopes: action.scopes,
    },
    [types.ROLE_IAM]: {
      ...state,
      roleIAm: action.roleIAm,
    },
    [types.ROLES]: {
      ...state,
      roles: action.roles,
    },
    [types.SOCKET_STREAM]: {
      ...state,
      socketStream: action.socketStream,
    },
    [types.FORCE_UPDATE]: {
      ...state,
      forceUpdate: action.forceUpdate,
    },
  };

  return actionType[action.type] ?? state;
}
