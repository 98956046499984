import React from 'react';

interface IProps {
  className?: string;
}

export default function ELHourglass(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="20" fill="none" viewBox="0 0 15 20" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M.547 19.949c-.201-.064-.444-.326-.506-.546a.825.825 0 01.407-.963c.174-.09.248-.1.713-.113l.518-.014v-1.632c0-1.44.01-1.686.076-2.076.18-1.05.614-1.973 1.326-2.82.487-.581 1.118-1.056 2.006-1.51.273-.139.497-.262.497-.273 0-.01-.224-.133-.497-.273-.888-.453-1.519-.928-2.006-1.508-.712-.848-1.146-1.772-1.326-2.821-.067-.39-.076-.637-.076-2.08V1.684H1.24c-.494 0-.686-.04-.897-.184C.173 1.385 0 1.067 0 .87 0 .51.253.147.57.052c.235-.07 13.622-.07 13.856 0 .318.095.57.458.57.818 0 .197-.172.515-.342.63-.211.144-.403.183-.897.184h-.44V3.32c0 1.443-.008 1.69-.075 2.08a5.733 5.733 0 01-.845 2.17c-.618.952-1.35 1.587-2.492 2.164-.27.137-.493.258-.493.268 0 .011.222.132.493.27.867.437 1.523.93 2.011 1.512.712.848 1.146 1.772 1.326 2.82.067.392.076.638.076 2.077v1.632l.518.014c.465.012.54.024.713.113a.825.825 0 01.407.963c-.065.232-.307.483-.53.55-.22.065-13.672.062-13.879-.004zm11.13-3.036c0-.774-.018-1.575-.041-1.781a4.037 4.037 0 00-1.098-2.379c-.453-.478-.709-.645-1.916-1.25L7.5 10.938l-1.065.532c-1.222.61-1.504.793-1.942 1.249a4.04 4.04 0 00-1.13 2.379c-.024.198-.043.98-.043 1.787v1.435h8.358v-1.408zm-2.85-.568c-.297-.128-.442-.358-.503-.793-.046-.337-.146-.505-.397-.667-.46-.299-.62-.706-.434-1.114.218-.48.78-.624 1.28-.329.287.17.699.58.866.864.276.467.42 1.144.323 1.506a.905.905 0 01-.437.52.955.955 0 01-.698.013zm-.265-7.812c1.18-.59 1.411-.732 1.802-1.106a3.92 3.92 0 001.013-1.506c.258-.654.27-.757.29-2.587l.018-1.65H3.32v1.435c0 .807.019 1.59.043 1.787A4.046 4.046 0 004.48 7.272c.44.46.68.616 1.902 1.235.603.305 1.101.555 1.107.556.006.001.488-.237 1.073-.53zM6.344 6.625C5.57 6.236 5 5.319 5 4.457c0-.48.362-.86.82-.86.494 0 .774.28.854.855.046.338.146.506.397.668.348.226.507.474.506.79-.003.61-.67.997-1.232.715z"
      />
    </svg>
  );
}
