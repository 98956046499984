import React from 'react';

export default function StorageToGrid() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="18" fill="none" viewBox="0 0 24 18">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M21.75 3h-9L10.189.45A1.569 1.569 0 009.057 0H2.25A2.25 2.25 0 000 2.25v13.5A2.25 2.25 0 002.25 18h19.5A2.25 2.25 0 0024 15.75V5.25A2.25 2.25 0 0021.75 3zm-4.898 7.875a.75.75 0 01-.75.75h-3v3a.75.75 0 01-.75.75h-.75a.75.75 0 01-.75-.75v-3h-3a.75.75 0 01-.75-.75v-.75a.75.75 0 01.75-.75h3v-3a.75.75 0 01.75-.75h.75a.75.75 0 01.75.75v3h3a.75.75 0 01.75.75v.75z"
      />
    </svg>
  );
}
