import '../index.scss';
import axios from 'axios';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useEvent, useGetParams, useToState } from '../../../hooks';
import { generatorSplitParts, getMaxOfArray, uuid } from '../../../utils';
import {
  IStorageCreateFolderFrom,
  IStorageCreateFolderTo,
  IStorageFile,
  IStorageFileFrom,
  IStorageFileTo,
  IStorageFrom,
  IStorageOptions,
  IStorageRootsFrom,
  IStorageTo,
} from '../storage.resources';
import {
  createFolderStorage,
  deleteMassStorage,
  getRootStorage,
  getStorage,
  getStoragePartUpload,
  getStoragePartUploadAbort,
  getStorageRequest,
  setStorage,
} from '../storage.services';
import { typesAccept } from '../storage.types';
import Bridge from './Bridge';

interface IProps {
  children: any;
  accept?: Array<string>;

  setStorageAction(data: IStorageFileTo, percent: Function, cancelToken: any): Promise<IStorageFileFrom>;

  getRootStorageAction(): Promise<IStorageRootsFrom>;

  getStorageAction(data: IStorageTo): Promise<IStorageFrom>;

  deleteMassStorageAction: any;

  createFolderStorageAction(data: IStorageCreateFolderTo): Promise<IStorageCreateFolderFrom>;
  getStorageRequestAction: any;
  getStoragePartUploadAction: any;
  onChange?(data: IStorageFileFrom): void;
  getStoragePartUploadAbortAction: any;
}

interface IState {
  contextActivate: boolean;
  copperActivate: boolean;
  createFolderActivate: boolean;
  data: null | IStorageFrom;
  contextData: null | IStorageFileFrom;
  copperFile: null | IStorageFile;
  activeIndex: number;
  typeView: boolean;
  loading: boolean;
  loadingLocale: boolean;
  searchPath: Array<{
    localPath: string;
    uuid: string;
  }>;
  saveData: null | IStorageFrom;
  roots: IStorageRootsFrom;
  dynamicPath: string;
  inputPath: string;
  processType: string; //TODO [Rework on screenplay]
  indexPath: {
    localPath?: string;
    uuid?: string;
  };
  defaultPath: {
    localPath: string;
    uuid: string;
  };
  options: IStorageOptions;
  preloader: number;
  isDuplicate: boolean;
  confirmDelete: boolean;
  isDuplicateFolder: boolean;
  isFileUploaded: boolean;
  uploadProcessStart: boolean;
  file: null | FormData;
  currentUploadedFile: any;
  isCancelUpload: boolean;
  focusinFiles: Array<any>;
  changeCtrl: boolean;
  saveChunkLoadData: number;
}

let cancelTokenSource = axios.CancelToken.source();

const StorageService: FC<IProps> = ({
  createFolderStorageAction,
  children,
  onChange,
  setStorageAction,
  getRootStorageAction,
  getStorageAction,
  deleteMassStorageAction,
  accept,
  getStorageRequestAction,
  getStoragePartUploadAction,
  getStoragePartUploadAbortAction,
}): any => {
  const [state, setState] = useToState<IState>({
    contextActivate: false,
    copperActivate: false,
    createFolderActivate: false,
    data: null,
    saveData: null,
    contextData: null,
    copperFile: null,
    activeIndex: -1,
    typeView: true,
    loading: true,
    loadingLocale: true,
    searchPath: [],
    roots: [],
    dynamicPath: '/',
    inputPath: '/',
    processType: 'upload',
    indexPath: {},
    defaultPath: {
      uuid: uuid(),
      localPath: '/',
    },
    options: {
      path: '/',
      folder_name: '',
      root_folder: {
        type: 'agency',
      },
    },
    preloader: 0,
    isDuplicate: false,
    confirmDelete: false,
    isDuplicateFolder: false,
    isFileUploaded: false,
    uploadProcessStart: false,
    file: null,
    currentUploadedFile: {},
    isCancelUpload: false,
    focusinFiles: [],
    changeCtrl: false,
    saveChunkLoadData: 0,
  });

  const queryAccept = useGetParams('accept');

  const onState = (data) => {
    setState((state) => ({
      ...state,
      ...data,
    }));
  };

  const onUploadCopperClose = () => {
    setState((state) => ({
      ...state,
      copperActivate: false,
    }));
  };

  const onToggleDuplicateModal = () => {
    setState((state) => ({
      isDuplicate: !state.isDuplicate,
    }));
  };

  const onToggleUploadProgressModal = () => {
    cancelTokenSource.cancel();

    cancelTokenSource = axios.CancelToken.source();

    onToggleCancelUploadModal();

    setState((state) => ({
      uploadProcessStart: !state.uploadProcessStart,
      preloader: 0,
      saveChunkLoadData: 0,
    }));
  };

  const onToggleCancelUploadModal = () => {
    state.isCancelUpload && onRetryUploadFile();

    setState((state) => ({
      isCancelUpload: !state.isCancelUpload,
    }));
  };

  const onCancelUploadFile = () => {
    setState((state) => ({
      isCancelUpload: !state.isCancelUpload,
    }));
  };

  const onRetryUploadFile = (e?) => {
    const { currentUploadedFile } = state;

    checkDuplicateFile(e, currentUploadedFile);
  };

  const onToggleDuplicateFolderModal = () => {
    setState((state) => ({
      isDuplicateFolder: !state.isDuplicateFolder,
    }));
  };

  const onCopyDuplicateFile = () => {
    const { file } = state;

    onUploadFile(file);
    onToggleDuplicateModal();
  };

  const onCopyDuplicateFolder = () => {
    onCreateFolder();
    onToggleDuplicateFolderModal();
  };

  const checkDuplicateFile = (e, previousFile) => {
    let file;

    if (e?.target?.files) {
      [file] = e.target.files;
    } else {
      file = previousFile;
    }
    const { data } = state;

    let copy;

    const result = { isDuplicate: false };

    const elements: Array<string> = [];

    for (const el of data) {
      const regExp = new RegExp(`Копия[(0-9)]+-${file?.name}`, 'g');

      copy =
        el.originalName?.match(regExp) ?? (el.originalName?.trim() === file?.name.trim() && [`Копия(0)-${file.name}`]);

      if (copy) {
        elements.push(copy[0]);
        result.isDuplicate = true;
      }
    }

    if (result?.isDuplicate) {
      let lastCopy: any = getLastCopyInt(elements);

      lastCopy++;

      const name = `Копия(${lastCopy})-${file.name}`;

      elements.push(name);

      const fileInfo = { ...file, name, basename: name };

      setState({ file, copperFile: fileInfo });
      onToggleDuplicateModal();
    } else {
      onUploadFile(file, file.name);
    }
  };

  const checkDuplicateFolder = (folder) => {
    const cleanFolderName = folder.trim();

    const { data } = state;

    let copy;

    const result: any = { isDuplicateFolder: false };

    const elements: Array<string> = [];

    for (const el of data) {
      const regExp = new RegExp(`Копия[(0-9)]+-${cleanFolderName}`, 'g');

      copy = el.filename?.match(regExp) ?? (el.filename === cleanFolderName && [`Копия(0)-${cleanFolderName}`]);

      if (copy) {
        elements.push(copy[0]);
        result.folder = el;
        result.isDuplicateFolder = true;
      }
    }

    if (result.isDuplicateFolder) {
      let lastCopy: any = getLastCopyInt(elements);

      lastCopy++;

      const name = `Копия(${lastCopy})-${cleanFolderName}`;

      elements.push(name);

      setState((state) => ({
        options: {
          ...state.options,
          folder_name: name,
        },
      }));

      onToggleDuplicateFolderModal();
    } else {
      onSaveFolder(cleanFolderName);
    }
  };

  const getLastCopyInt = (copies): Number => {
    const copiesIterations: any = [];

    if (copies.length > 0) {
      for (const copy of copies) {
        const countRegExp = new RegExp('([(\\d^)]+)');

        const [copyCount] = copy.match(countRegExp);

        const copyInt = +copyCount?.replace(/[( )]/g, '');

        copiesIterations.push(copyInt);
      }
    } else {
      copiesIterations.push(0);
    }

    return getMaxOfArray(copiesIterations);
  };

  const onToggleConfirmDeleteModal = () => {
    setState((state) => ({
      confirmDelete: !state.confirmDelete,
    }));
  };

  const onFileDelete = () => {
    onToggleConfirmDeleteModal();
  };

  const deleteFile = () => {
    const { contextData } = state;

    const { root_folder } = state.options;

    deleteMassStorageAction({
      ids: [contextData.id],
      root_folder,
    }).then(() => {
      const id = get(state.contextData, 'filename');

      const cloneData = [...state.data];

      const cloneDataSome = [...state.some];

      const findIndex = cloneData.findIndex((el) => el?.filename === id);

      const findIndexSome = cloneDataSome.findIndex((el) => el?.filename === id);

      cloneData.splice(findIndex, 1);

      cloneDataSome.splice(findIndexSome, 1);

      onToggleConfirmDeleteModal();

      onState({
        data: cloneData,
        some: cloneDataSome,
        contextActivate: false,
      });
    });
  };

  const onContextMenu = (data, index) => {
    setState((state) => ({
      ...state,
      contextActivate: true,
      contextData: data,
      activeIndex: index,
      options: {
        ...state.options,
        folder_name: data.filename,
      },
    }));
  };

  const clearContextMenu = (e) => {
    e.preventDefault();

    setState((state) => ({
      ...state,
      contextActivate: false,
      contextData: null,
      processType: 'upload',
      activeIndex: -1,
      focusinFiles: [],
    }));
  };

  const onCreateFolder = () => {
    setState((state) => ({
      ...state,
      createFolderActivate: !state.createFolderActivate,
    }));
  };

  const onSaveFolder = (value?) => {
    const { options, data } = state;

    const params = {
      ...options,
      folder_name: value || options.folder_name,
    };

    createFolderStorageAction(params).then((response: any) => {
      const cloneObject = {
        filename: params.folder_name,
        id: response.id,
        baseName: response.baseName,
        localPath: `${params.path === '/' ? '' : params.path}/${params.folder_name}`,
        type: 'dir',
      };

      state.isDuplicateFolder && onToggleDuplicateFolderModal();

      const cloneArray = [...data, cloneObject];

      const sortFiles = sortBy(cloneArray, (el) => el.type);

      setState((state) => ({
        ...state,
        data: sortFiles,
        createFolderActivate: false,
      }));
    });
  };

  const onFormationSearch = (searchPath) => {
    const [locale] = searchPath.length ? searchPath.slice(-1) : [{ localPath: '/' }];

    return locale.localPath;
  };

  const onChangeFolder = (data) => {
    const { searchPath, indexPath } = state;

    const isOneElement = data?.localPath[0] !== '/';

    const cloneSearchPath = [...searchPath];

    const lastWord = data?.localPath.slice(-1);

    const formationLocalPath = lastWord === '/' ? data?.localPath.slice(0, -1) : data?.localPath;

    const formationNewPath = {
      uuid: uuid(),
      localPath: isOneElement ? `/${formationLocalPath}` : formationLocalPath,
    };

    const searchIndex = searchPath.findIndex((el) => el.uuid === indexPath?.uuid); // ?? -1

    const transform = searchIndex !== -1 ? cloneSearchPath.slice(0, searchIndex + 1) : cloneSearchPath;

    transform.push(formationNewPath);

    const formationSearch = onFormationSearch(transform);

    setState((state) => ({
      ...state,
      dynamicPath: formationSearch,
      searchPath: transform,
      indexPath: formationNewPath,
      contextActivate: false,
    }));
  };

  const onPrevThreeFolder = () => {
    const { searchPath, indexPath } = state;

    const searchIndex = searchPath.findIndex((el) => el.uuid === indexPath?.uuid); // ?? -1

    const dynamicPath = searchIndex !== -1 && searchIndex !== 0;

    const onPrevSearchPathIndex = dynamicPath ? searchIndex - 1 : 0;

    const findPrev = searchPath[onPrevSearchPathIndex];

    setState((state) => ({
      ...state,
      dynamicPath: findPrev.localPath,
      indexPath: findPrev,
    }));
  };

  const onNextThreeFolder = () => {
    const { searchPath, indexPath } = state;

    const searchIndex = searchPath.findIndex((el) => el.uuid === indexPath?.uuid); // ?? -1

    const dynamicPath = searchIndex !== -1 && searchPath.length - 1 > searchIndex;

    const onPrevSearchPathIndex = dynamicPath ? searchIndex + 1 : searchPath.length - 1;

    const findNext = searchPath[onPrevSearchPathIndex];

    setState((state) => ({
      ...state,
      dynamicPath: findNext.localPath,
      indexPath: findNext,
    }));
  };

  const onChangeNameFolder = (e) => {
    const { value } = e.target;

    const { options } = state;

    onState({
      options: {
        ...options,
        folder_name: value,
      },
    });
  };

  const onChangeRootFolder = (data) => {
    const { root_folder } = data;

    setState((state) => ({
      ...state,
      contextActivate: false,
      createFolderActivate: false,
      dynamicPath: '/',
      searchPath: [state.defaultPath],
      indexPath: {},
      options: {
        ...state.options,
        path: '/',
        root_folder,
      },
    }));
  };

  const onLoadRoots = () => {
    getRootStorageAction().then((roots) => {
      setState((state) => ({
        ...state,
        roots,
        searchPath: [state.defaultPath],
      }));
    });
  };

  const onProcessLoad = (percents: number | undefined) => {
    setState({ preloader: percents === 100 ? 99 : percents });
  };

  const onProcessLoadChunk = (count) => (percents: any) => {
    setState((state) => {
      const fullSize = percents / count;

      // console.log(fullSize,'fullSize')
      const formationFullAmount = percents !== undefined && fullSize;

      const saveChunkLoadData = state.saveChunkLoadData === 100 ? 100 : state.saveChunkLoadData + formationFullAmount;

      return {
        preloader: state.preloader >= 90 ? 99 : saveChunkLoadData,
        // preloader: state.saveChunkLoadData === 100 ? 100 : state.saveChunkLoadData + formationFullAmount,
        saveChunkLoadData: percents === 100 ? state.saveChunkLoadData + formationFullAmount : state.saveChunkLoadData,
      };
    });
  };

  const onUploadStorage = (formData) => {
    setStorageAction(formData, onProcessLoad, cancelTokenSource)
      .then((response) => {
        const { data, some } = state;

        const cloneObject = response && { ...response };

        const cloneArray = [...data, cloneObject];

        const cloneArraySome = [...some, cloneObject];

        setState((state) => ({
          ...state,
          data: cloneArray,
          some: cloneArraySome,
          copperActivate: false,
          preloader: 100,
          uploadProcessStart: false,
        }));
      })
      .catch((error) => {
        if (error) {
          onProcessLoad(undefined);
        }
      });
  };

  const onUploadStoragePart = (file, name) => {
    const { root_folder, path } = state.options;

    getStorageRequestAction({
      root_folder: {
        type: root_folder.type,
      },
      name,
      path,
    }).then((response) => {
      const rootId = response?.uploadId;

      let chunks = [...generatorSplitParts(file.size, 10000000)];

      const smallChunk = chunks.find((el) => el < 10000000);

      if (smallChunk) {
        chunks = chunks.filter((el) => el >= 10000000);

        chunks = chunks.map((el, index) => (index === chunks.length - 1 ? el + smallChunk : el));
      }

      const uploadPartChunk = (chunk, index, last) => {
        return new Promise((resolve, reject) => {
          const formData = new FormData();

          formData.append('uploadId', rootId);

          const searchChunk = chunks[index - 1] ?? 0;

          const isLastChunk = index === chunks.length - 1;

          const lastValue = isLastChunk && chunks[index - 1] + chunk;

          formData.append('file', file.slice(searchChunk, lastValue ? lastValue : chunk));

          formData.append('partNumber', `${index + 1}`);

          last && formData.append('last', 'true');

          getStoragePartUploadAction(formData, onProcessLoadChunk(chunks.length), cancelTokenSource)
            .then((response) => {
              if (last) {
                const { data } = state;

                const cloneObject = response && { ...response };

                const cloneArray = [...data, cloneObject];

                setState((state) => ({
                  ...state,
                  data: cloneArray,
                  copperActivate: false,
                  preloader: 0,
                  saveChunkLoadData: 0,
                  uploadProcessStart: false,
                }));
              }
              resolve(response);
            })
            .catch((error) => {
              if (error) {
                onProcessLoad(undefined);
                getStoragePartUploadAbortAction(rootId);
              }
              reject(error);
            });
        });
      };

      let some = false;

      chunks.reduce(
        async (result, current, index) =>
          result
            .then(() => {
              if (!some) {
                return uploadPartChunk(current, index, chunks.length - 1 === index);
              }
              return false;
            })
            .catch((error) => {
              if (error) {
                some = true;
                onProcessLoad(undefined);
              }
            }),
        Promise.resolve()
      );
    });
  };

  const onUploadFile = (file, prevName = null) => {
    const { root_folder, path } = state.options;

    const formData = new FormData();

    setState((state) => ({
      ...state,
      currentUploadedFile: file,
      uploadProcessStart: true,
      preloader: 0,
      saveChunkLoadData: 0,
    }));

    const maxFileSizeBite = 100000000;

    const name = prevName ?? state.copperFile?.name ?? file?.name;

    formData.append('file', file, name); //TODO [Error]: FormData undefined 2's params
    formData.append('root_folder[type]', root_folder.type);
    root_folder?.id && formData.append('root_folder[id]', root_folder.id);
    formData.append('path', path);

    maxFileSizeBite > file.size ? onUploadStorage(formData) : onUploadStoragePart(file, name);
  };

  const onChangeViewFolder = () => {
    setState((state) => ({
      ...state,
      typeView: !state.typeView,
    }));
  };

  const onFocusFile = (e, data, index) => {
    e.preventDefault();
    e.stopPropagation();
    const { changeCtrl, focusinFiles } = state;

    if (changeCtrl) {
      const cloneFiles = [...focusinFiles];

      const searchFileIndex = cloneFiles.findIndex((el) => el.id === data.id);

      searchFileIndex === -1 ? cloneFiles.push(data) : cloneFiles.splice(searchFileIndex, 1);

      setState({
        focusinFiles: cloneFiles,
        processType: 'upload',
      });
    }

    if (!changeCtrl) {
      const processType = {
        dir: 'open',
        file: 'choose',
      };

      setState((state) => ({
        ...state,
        activeIndex: index,
        contextData: data,
        processType: processType[data.type],
        contextActivate: false,
        options: {
          ...state.options,
          folder_name: data.filename,
        },
        focusinFiles: [data],
      }));
    }
  };

  const onChangeInputPath = (e) => {
    const { value } = e.target;

    setState((state) => ({
      ...state,
      inputPath: value,
    }));
  };

  const onContextMenuChange = (e, data, index) => {
    e.preventDefault();
    e.stopPropagation();

    const origin = {
      left: e.pageX,
      top: e.pageY,
    };

    onContextMenu(
      {
        ...data,
        ...origin,
      },
      index
    );
  };

  const onChangeFile = (data) => {
    onChange && onChange(data);
  };

  const onProcessStorage = () => {
    const { processType, contextData } = state;

    const types = {
      upload: checkDuplicateFile,
      open: () => onChangeFolder(contextData),
      choose: () => onChangeFile(contextData),
      // file: onUploadCopper,
    };

    return types[processType];
  };

  const onSearch = () => {
    const { searchPath, inputPath } = state;

    const isOneElement = inputPath[0] !== '/';

    const cloneSearchPath = [...searchPath];

    const formationNewPath = {
      uuid: uuid(),
      localPath: isOneElement ? `/${inputPath}` : inputPath,
    };

    cloneSearchPath.push(formationNewPath);

    const formationSearch = onFormationSearch(cloneSearchPath);

    setState((state) => ({
      ...state,
      dynamicPath: formationSearch,
      searchPath: cloneSearchPath,
      indexPath: formationNewPath,
    }));
  };

  // const onSortFile = (data) => {
  //   const filtered = data.filter((el) => el.type === 'file');
  //
  //   const dirPart = data.filter((el) => el.type === 'dir');
  //
  //   const filePart = filtered.filter((el) => Bridge.acceptType(el, accept));
  //
  //   return [...dirPart, ...filePart];
  // };

  const onChangeFileType = (data) => {
    const dataClone = [...state.some];

    const filteredData = dataClone.filter((el) => {
      return (data.value.includes(el.mimetype) && el.type !== 'dir' && !data.all) || el.type === 'dir';
    });

    setState({
      data: data.all ? state.some : filteredData,
      saveData: dataClone,
    });
  };

  useEffect(onLoadRoots, []);

  useEffect(() => {
    const { dynamicPath } = state;

    dynamicPath &&
      setState((state) => ({
        ...state,
        options: {
          ...state.options,
          path: dynamicPath,
        },
      }));
  }, [state.dynamicPath]);

  useEffect(() => {
    const { loadingLocale } = state;

    !loadingLocale &&
      setState((state) => ({
        ...state,
        inputPath: state.dynamicPath,
      }));
  }, [state.loadingLocale]);

  useEffect(() => {
    const { path, root_folder } = state.options;

    setState((state) => ({
      ...state,
      loadingLocale: true,
    }));

    getStorageAction({
      path,
      root_folder,
    }).then((response) => {
      const sortFiles = sortBy(response, (el) => el.type);

      const searchType = accept ?? [queryAccept ?? typesAccept.ALL];

      const [target] = searchType;

      const acceptTypes = Bridge.getAcceptTypeChange(searchType);

      const sortedFile = sortFiles.filter(
        (el) =>
          acceptTypes.find((item) => item && item.join('').includes(el.mimetype)) ||
          target === typesAccept.ALL ||
          el.type === 'dir'
      );

      setState((state) => ({
        ...state,
        data: sortedFile ?? [],
        // data: sortFiles,
        // dataSave: sortedFile,
        some: sortFiles,
        loading: false,
        processType: 'upload',
        contextData: null,
        activeIndex: -1,
        loadingLocale: false,
      }));
    });
  }, [state.options.root_folder, state.options.path]);

  const ctrlKey = 17;

  const cmdKey = 91;

  const onCombineKeyEventsChange = (value) => (e) => {
    if (e.keyCode == ctrlKey || e.keyCode == cmdKey) {
      // const { contextData, focusinFiles } = state;
      // const cloneFiles = [...focusinFiles];

      // if (contextData !== null) {
      //
      //   const searchFileIndex = cloneFiles.findIndex(el => el.id === contextData.id)
      //
      //   searchFileIndex === -1 ? cloneFiles.push(contextData) : cloneFiles.splice(searchFileIndex, 1);
      // }

      setState({
        changeCtrl: value,
        contextActivate: false,
        // focusinFiles: cloneFiles
      });
      // cloneFiles.push(data)
      // console.log(contextData, 'contextData')
    }
  };

  useEvent('keydown', onCombineKeyEventsChange(true), document);

  useEvent('keyup', onCombineKeyEventsChange(false), document);

  const onFilesDelete = () => {
    const { focusinFiles } = state;

    const { root_folder } = state.options;

    const idsFiles = focusinFiles.map((el) => el.id);

    deleteMassStorageAction({
      ids: idsFiles,
      root_folder,
    }).then(() => {
      const cloneData = [...state.data];

      const cloneDataSome = [...state.some];

      const filteredFiles = cloneData.filter((el) => !idsFiles.includes(el.id));

      const filteredFilesSome = cloneDataSome.filter((el) => !idsFiles.includes(el.id));

      onState({
        data: filteredFiles,
        some: filteredFilesSome,
        contextActivate: false,
      });
    });
  };

  return children({
    service: { ...state },
    action: {
      onUploadCopperClose,
      onUploadFile,
      onContextMenu,
      onFileDelete,
      clearContextMenu,
      onCreateFolder,
      onSaveFolder,
      onChangeNameFolder,
      onChangeRootFolder,
      onChangeViewFolder,
      onChangeFolder,
      onPrevThreeFolder,
      onNextThreeFolder,
      onChangeInputPath,
      onFocusFile,
      onContextMenuChange,
      onProcessStorage,
      onChangeFile,
      onSearch,
      onToggleDuplicateModal,
      onCopyDuplicateFile,
      onToggleConfirmDeleteModal,
      onToggleDuplicateFolderModal,
      onCopyDuplicateFolder,
      checkDuplicateFolder,
      onToggleUploadProgressModal,
      onRetryUploadFile,
      onToggleCancelUploadModal,
      deleteFile,
      onCancelUploadFile,
      onChangeFileType,
      onFilesDelete,
    },
  });
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setStorageAction: bindActionCreators(setStorage, dispatch),
  getRootStorageAction: bindActionCreators(getRootStorage, dispatch),
  getStorageAction: bindActionCreators(getStorage, dispatch),
  createFolderStorageAction: bindActionCreators(createFolderStorage, dispatch),
  deleteMassStorageAction: bindActionCreators(deleteMassStorage, dispatch),
  getStorageRequestAction: bindActionCreators(getStorageRequest, dispatch),
  getStoragePartUploadAction: bindActionCreators(getStoragePartUpload, dispatch),
  getStoragePartUploadAbortAction: bindActionCreators(getStoragePartUploadAbort, dispatch),
});

export default connect(null, mapDispatchToProps)(StorageService);
