import api from './mail.api';
import { IMailMethod } from './mail.resources';

export function emailSendInvite(data: any): Promise<void> {
  const request = api.init('emailSendInvite');

  return request({
    params: data,
  });
}

export function getEventInvitation(token: string): Promise<void> {
  const request = api.init('getEventInvitation');

  return request({
    path: token,
  });
}

export function setEventInvitation(token: string, data: IMailMethod): Promise<void> {
  const request = api.init('setEventInvitation');

  return request({
    path: token,
    params: data,
  });
}

export function getEventInvitationMembers(token: string): Promise<void> {
  const request = api.init('getEventInvitationMembers');

  return request({
    path: token,
  });
}

export function setEventInvitationMembers(token: string, data: IMailMethod): Promise<void> {
  const request = api.init('setEventInvitationMembers');

  return request({
    path: token,
    params: data,
  });
}

export function getAgencyInvitation(token): Promise<any> {
  const request = api.init('getAgencyInvitation');

  return request({
    path: token,
  });
}

export function setAgencyInvitation(token, data: IMailMethod): Promise<any> {
  const request = api.init('setAgencyInvitation');

  return request({
    path: token,
    params: data,
  });
}
