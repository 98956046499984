import * as Sentry from '@sentry/react';
// import { Integrations } from '@sentry/tracing';
import { CONFIG } from './config';

export default () => {
  return (
    CONFIG.REACT_APP_SENTRY_ACTIVATE &&
    Sentry.init({
      dsn: CONFIG.REACT_APP_SENTRY_DNS,
      // integrations: [new Integrations.BrowserTracing()],
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      // tracesSampleRate: 1.0,
      // dsn: config.global.REACT_APP_SENTRY_DSN,
      attachStacktrace: true,
    })
  );
};
