import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELStatsReject(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" fill="none" viewBox="0 0 9 9" {...props}>
      <path stroke="var(--el-fonts--icon-color" strokeLinecap="round" strokeWidth="2" d="M7.564 1.564l-6 6m6 0l-6-6" />
    </svg>
  );
}
