import React from 'react';

export default function StarSmall() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M.33 6.441l2.718 2.562-.755 3.57c-.075.465.076.93.453 1.164a.845.845 0 00.604.232c.152 0 .303 0 .454-.077 0 0 .075 0 .075-.078l3.097-1.785 3.096 1.785s.076 0 .076.078c.378.155.83.155 1.133-.078.378-.233.529-.698.453-1.164l-.755-3.57c.302-.233.755-.698 1.208-1.164l1.435-1.32.076-.077c.302-.31.377-.776.226-1.164-.15-.388-.453-.699-.906-.776h-.075l-3.55-.388L7.958.854s0-.078-.076-.078C7.807.233 7.43 0 7.052 0c-.378 0-.756.233-.982.62 0 0 0 .078-.076.078L4.559 4.036l-3.55.388H.934a1.31 1.31 0 00-.907.776c-.075.465 0 .931.302 1.241z"
      />
    </svg>
  );
}
