export const typesView = {
  DISABLED: 'Disabled',
  ENABLED: 'Enabled',
  ENABLE_DAFTER_COMPLETION: 'EnabledAfterCompletion',
  ENABLED_AFTER_END: 'EnabledAfterTheEnd',
};

export const typesTests = {
  COMPLETE: 'Complete',
  UN_COMPLETE: 'Uncompleted',
};

export default {
  RESOURCES_TESTS: 'RESOURCES_TESTS',
};
