export default {
  EN: {
    'expand.rate': 'Tariff plan',
    'expand.payment.history': 'Payment history',
    'expand.opportunities': 'Expand opportunities',
    'expand.type.status.plan.active': 'Current tariff plan',
    'expand.type.status.plan.pending': 'Tariff plan upgraden',
    'expand.type.status.plan.next': 'Next tariff plan',
    'expand.type.status.plan.request': 'Individual tariff plan request',
    'expand.request.sent': 'Request has been sent',
    'expand.request.canceled': 'Request has been canceled',
    'expand.request.payment': 'Waiting for payment',
    'expand.time.left': 'Time left',
    'expand.unlimited': 'Unlimited',
    'expand.about.collapse': 'Collapse',
    'expand.about.tariff': 'More about tariff plan',
    'expand.participants': 'participants',
    'expand.change.tariff': 'Change tariff plan',
    'expand.more': 'More',
    'expand.plan': 'Upgrade tariff plan',
    'expand.per.month': 'per month',
    'expand.period.year': '*annually payment',
    'expand.period': 'Period',
    'expand.expire': 'Choose tariff plan expiration time ',
    'expand.month': 'month',
    'expand.year': 'year',
    'expand.payable': 'Total amount due:',
    'expand.yu.money': 'For payment you will be redirected to UMoney System server.',
    'expand.pay.notfound': 'No payments found',
    'expand.type.Базовый': 'Base',
    'expand.type.Стандарт': 'Standard',
    'expand.type.PRO': 'PRO',
    'expand.cancel.pay': 'Cancel payment?',
    'expand.cancel.send': 'Cancel request?',
    'expand.canceled.pay': 'Payment cancelled',
    'expand.id.quote': 'Get individual quotation',
    'expand.contact': 'Our client manager will contact you soon!',
    'expand.up': 'Upgrade tariff',
    'expand.up.text': 'New prolongation conditions will go into effect after the expiry of the current tariff ',
    'expand.next': 'Prolong  tariff plan',
    'expand.next.text':
      'If you change the tariff parameter, Edbee will recalculate the rest of payed days according to new tariff conditions automatically',
    'expand.event': 'Events creation',
    'expand.test': 'Building up of test tasks',
    'expand.roles': 'Roles designer',
    'expand.delegation': 'Delegation of duties to employees',
    'expand.subdomain': 'Details to creation of unique sub-domain',
    'expand.groups': 'Building up and managing study groups',
    'expand.storage': 'Cloud storage for files and multimedia ',
    'expand.homework': ' "Homework" module',
    'expand.support': 'Technical support',
  },
};
