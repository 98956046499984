import React from 'react';

interface IProps {
  className?: string;
}

export default function ELUserExpand(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" fill="none" viewBox="0 0 23 28" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2.7"
        d="M2.315 26.65h18.37l.016-.001a1.45 1.45 0 00.538-.145.681.681 0 00.273-.23c.055-.083.138-.256.138-.607 0-.85-.463-2.874-1.981-4.67-1.463-1.732-3.958-3.314-8.169-3.314s-6.706 1.582-8.169 3.313c-1.518 1.797-1.981 3.822-1.981 4.67 0 .352.083.525.138.608.06.091.147.166.273.23a1.45 1.45 0 00.538.145l.016.001zm5.267-15.648A5.5 5.5 0 0011.5 12.65a5.5 5.5 0 003.918-1.648A5.702 5.702 0 0017.05 7c0-1.505-.59-2.944-1.632-4.002A5.5 5.5 0 0011.5 1.35a5.5 5.5 0 00-3.918 1.648A5.702 5.702 0 005.95 7c0 1.505.59 2.944 1.632 4.002z"
      />
    </svg>
  );
}
