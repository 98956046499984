import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { useGetParams, useRouter, useSetParams, useToState } from '../../../../../hooks';
import translate from '../../../../../i18n/translate';
import { IReduxStore } from '../../../../../store/serviceReducer';
import { Button, Icon, Text } from '../../../../app.modules';
import { MODALS_TRIGGER } from '../../../modal.types';

const AttachTests: FC = function (): ReactElement {
  const { history } = useRouter();

  const part = useGetParams('part');

  const testId = useGetParams('testId');

  const sync = useGetParams('sync');

  const [state, setState] = useToState({
    sync: sync && sync !== undefined ? (sync === 'false' ? false : true) : null,
  });

  const onBack = () => {
    history.push(
      useSetParams({
        m: MODALS_TRIGGER.STRUCTURE.TESTS,
        part,
        testId,
        ...(state.sync !== null && {
          sync: `${state.sync}`,
        }),
      })
    );
  };

  const onConfirm = () => {
    history.push(
      useSetParams({
        m: MODALS_TRIGGER.STRUCTURE.TESTS_SETTINGS,
        part,
        testId,
        sync: `${state.sync}`,
      })
    );
  };

  const onChange = (sync) => () => {
    setState({
      sync,
    });
  };

  const classesAttachBlock = (value) =>
    classNames('attach-tests__block', {
      'attach-tests__block--active': value,
    });

  return (
    <div className="attach-tests">
      <h1 className="attach-tests__title">{translate('events.str.test.add')}</h1>
      <Text className="attach-tests__label">{translate('events.str.test.add.lbl')}</Text>
      <div className={classesAttachBlock(state.sync !== null ? !state.sync : null)} onClick={onChange(false)}>
        <Text className="attach-tests__block-name">
          <Icon name="ELCopy" className="attach-tests__block-icon" />
          <strong>{translate('events.str.test.add.copy')}</strong>
        </Text>
        <Text className="attach-tests__desc" variant="md">
          {translate('events.str.test.add.copy.lbl')}
        </Text>
      </div>
      <div className={classesAttachBlock(state.sync !== null ? state.sync : null)} onClick={onChange(true)}>
        <Text className="attach-tests__block-name">
          <Icon name="Sync" className="attach-tests__block-icon" />
          <strong>{translate('events.str.test.add.ath')}</strong>
        </Text>
        <Text className="attach-tests__desc" variant="md">
          {translate('events.str.test.add.ath.lbl')}
        </Text>
      </div>
      <div className="attach-tests__panel">
        <Button variant="outlined" size="md" monochrome onClick={onBack}>
          {translate('g.btn.back')}
        </Button>
        <Button size="md" onClick={onConfirm} off={state.sync === null}>
          {translate('g.btn.add')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  data: store.modal.data!,
});

export default connect(mapStateToProps)(AttachTests);
