import React from 'react';

function ELUsers() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" fill="none" viewBox="0 0 25 19">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M14.803 9.287c-.443.53-.98.977-1.587 1.316a5.306 5.306 0 101.221-8.969c.49.485.892 1.058 1.18 1.693a3.306 3.306 0 11-.814 5.96zM10.675 19h11.773c.942 0 1.706-.764 1.706-1.705a4.358 4.358 0 00-4.359-4.359h-4a4.667 4.667 0 012.173 2h1.827c1.203 0 2.195.9 2.34 2.064h-3.593l.003.18a1.82 1.82 0 01-1.82 1.82h-6.05z"
        clipRule="evenodd"
      />
      <circle cx="8.084" cy="5.66" r="4.66" stroke="var(--el-fonts--icon-color)" strokeWidth="2" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 17.18a3.649 3.649 0 013.649-3.648h6.872a3.649 3.649 0 013.65 3.649.82.82 0 01-.82.819H1.819A.82.82 0 011 17.18z"
      />
    </svg>
  );
}

export default ELUsers;
