import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELCopy(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" fill="none" viewBox="0 0 24 26" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.705 20.03V5.864A4.854 4.854 0 016.569 1h4.73"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M10.456 4.886a4.854 4.854 0 00-4.864 4.865v10.384A4.854 4.854 0 0010.456 25h7.11a4.854 4.854 0 004.865-4.865v-3.789c0-2.726-.06-3.896-1.87-5.706l-1.872-1.918-1.871-1.918c-1.484-1.484-2.033-1.918-4.116-1.918h-2.246z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M13.333 4.903v6.395a2.349 2.349 0 002.348 2.348h6.611c0-1.078-.659-1.926-1.73-2.997l-1.884-1.915-1.854-1.916c-1.322-1.322-1.895-1.946-3.49-1.915z"
      />
    </svg>
  );
}
