export default {
  DE: {
    'events.name': 'Maßnahmen',
    'events.overview': 'Übersicht',
    'events.active': 'Aktiv',
    'events.completed': 'Abgeschlossen',
    'events.archive': 'Archive',
    'events.srh.name': 'Geben Sie einen Namen der Maßnahme ein',
    'events.not.found': 'Es wurde keine Maßnahme gefunden',
    'events.desc': 'Geben sie eine Bezeichnung für die Maßnahme ein',
    'events.create': 'Neue Maßnahme',
    'events.cover': 'Titelblatt der Maßnahme',
    'events.cover.mini': 'Minititelbild der Maßnahme',
    'events.author': 'Ersteller der Maßnahme',
    'events.author.pls': 'Geben Sie einen Ersteller ein',
    'events.stt.pass': 'Einstellungen der Maßnahmenablauf',
    'events.mrl': 'Materialien zum Abschluß der Maßnahme',
    'events.dates': 'Kalenderdaten der Maßnahme',
    'events.start': 'Beginn der Maßnahme',
    'events.end': 'Abschluß der Maßnahme',
    'events.site': 'Webseite der Maßnahme',
    'events.site.start': 'Webseite der Maßnahme starten',
    'events.site.start.lbl':
      'Erstellen und starten Sie eine eigenartige Webseite von der Maßnahme, die nutzliche Info und Anweisungen zur Anmeldung für die Teilnehmer beinhaltet.',
    'events.site.start.lnk': 'Link zur Webseite',
    'events.reg.usr': 'Teilnehmeranmeldung',
    'events.reg.usr.on': 'Anmeldung für die Maßnahme freigeben',
    'events.reg.usr.lbl': 'Selbständige Teilnehmeranmeldung f. die Maßnahme',
    'events.reg.usr.cnt': 'Teilnehmeranzahl',
    'events.reg.usr.cnt.hint': 'Maximal erlaubte Anzahl der Teilnehmer zur Anmeldung für eine Maßnahme',
    'events.reg.usr.pay': 'Bezahlung der Teilnahme',
    'events.reg.usr.confirm': 'Teilnahme bestätigen',
    'events.reg.usr.confirm.hint': 'Antrag für jeden Teilnehmer manuell bestätigen',
    'events.reg.usr.payment': 'Teilnahmekosten',
    'events.reg.usr.payment.pls': 'Teilnahmekosten eingeben',
    'events.site.start.hint':
      'Verknüpfung führt zur eine separate Seite, wo man sich f. eine Maßnahme anmelden und bzw. falls nötig bezahlen kann. Wenn Sie was testen möchten - Öffnen Sie den Link in einem anderen Browser, oder in einem privaten Fenster',
    'events.reg.usr.table': 'Angemeldet für die Maßnahme',
    'events.reg.time': 'Anmeldezeit',
    'events.reg.status': 'Bestätigung',
    'events.sts.rqs': 'Anmeldestatus:',
    'events.sts.wait': 'Wird geprüft',
    'events.ended': 'Maßnahme abgeschlossen',
    'events.mmb.i.s': 'Abgeschickt',
    'events.role.Reviewer': 'Gutachter',
    'events.role.Author': 'Ersteller',
    'events.role.Editor': 'Redakteur',
    'events.ivt.lbl': 'Die Eingeladenen zur Maßnahme',
    'events.ivt.not.found': 'Es wurden keine Einladungen gefunden',
    'events.ivt.is.cancel': 'Möchten Sie die Einladung zur Maßnahme canceln? ',
    'events.ivt.is.repeat': 'Möchten Sie erneut einladen?',
    'events.ivt': 'Zur Maßnahme einladen',
    'events.str.prt.tlt': 'Abschnitt erstellen',
    'events.str.prt.desc': 'Geben Sie eine Beschreibung ein',
    'events.str.file.tlt': 'Infodatei',
    'events.str.ath.tlt': 'Titel',
    'events.str.ath.tlt.pls': 'Tippen Sie den Titel ein',
    'events.str.ath.desc': 'Beschreibung',
    'events.str.ath.desc.pls': 'Beschreibung hinfügen',
    'events.str.ath.acc.sett': 'Zugriffseinstellungen',
    'events.str.ath.acc.sett.opt.event': 'Maßnahmeeinstellungen sind definiert worden ',
    'events.str.ath.acc.sett.opt.set': 'Individuel definiert',
    'events.str.ath.start.pbl': 'Start der Veröffentlichung',
    'events.str.ath.end.pbl': 'Ende der Veröffentlichung',
    'events.str.page.tlt': 'Infoseite',
    'events.str.ath.filling': 'Füllung',
    'events.str.video.tlt': 'Videomaterial',
    'events.str.test.lbl': 'Zur Testerstellung rübergehen ',
    'events.str.test.add': 'Test hinzufügen',
    'events.str.test.add.lbl': 'Art der Hinzufügung f. den Test auswählen:',
    'events.str.test.add.copy': 'Neue Kopie',
    'events.str.test.add.copy.lbl': 'Es wird eine individuelle Kopie vom Test erstellt.',
    'events.str.test.add.ath': 'Verknüpfter Test',
    'events.str.test.add.ath.lbl': 'Änderungen im Test in der Datenbank führen zu Änderungen in dem verknüpften Test.',
    'events.str.test.stt.lbl':
      'Beim Hinfügen eines verknüpften Tests haben Sie eine Möglichkeit Kalendardaten für Testfreigabe und Priorität von jeden Test festlegen.',
    'events.str.test.stt.lbl.2': 'Andere Einstellungen können ausschlieslich in der Test-Datenbank geändert werden.',
    'events.str.homework.tlt': 'Hausaufgabe',
    'events.str.homework.scr': 'Punkte für die Aufgabe',
    'events.str.homework.scr.psg': 'Punkteminimum f.Ablegung',
    'events.str.brd.add': 'Live-Videoübertragung hinzufügen',
    'events.str.brd.add.hint':
      'Fügen Sie einen Link zur Live-Videoübertragung in dem Eingabefeld hinzu(YouTube,  Twitch) oder integrieren Sie einen Code',
    'events.str.part.del': 'Möchten die das Bereich löschen?',
    'events.str.el.del': 'Möchten Sie das Element löschen?',
    'events.current': 'Aktuelle',
    'events.upcoming': 'Kommenden',
    'events.request': 'Anträge',
    'events.usr.sort.completed': 'Erfüllten',
    'events.usr.sort.completed.not': 'Nicht erfüllten',
    'events.usr.sort.all': 'Alle',
    'events.usr.btn.tst.result': 'Resultat anschauen',
    'events.usr.btn.homework.view': 'Aufgabe anschauen',
    'events.request.pay.tlt': 'Bezahlen Sie Teilnahme an der Maßnahme',
    'events.request.amount': 'Preis',
    'events.request.pay.pts': 'Teilnahme bezahlen',
    'events.delete': 'Möchten Sie die Maßnahme unwiederruflich löschen?',
    'events.ath.homework.desc.pls': 'Fügen Sie eine Aufgabenbeschreibung hinzu.',
    'events.ivt.from.in': 'Gesendet an',
    'events.ivt.reg.lbl': 'Loggen Sie sich am Platform ein um an der Maßnahme teilnehmen zu können.',
    'events.ivt.add.lbl': 'Die Maßnahme wird für den Profil hinzugefügt',
    'events.ivt.confirm': 'Einladung zur Maßnahme akzeptieren?',
    'events.ivt.cancel': 'Einladung zur Maßnahme ablehnen?',
    'events.passing': 'Ablegung',
  },
};
