import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELFolder(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="22" fill="none" viewBox="0 0 26 22" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M22 4.924h-6.966a3 3 0 01-2.109-.866l-2.217-2.192A3 3 0 008.599 1H4a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V7.924a3 3 0 00-3-3z"
      />
    </svg>
  );
}
