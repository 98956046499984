export default {
  RU: {
    'ts.results': 'Результаты',
    'ts.formation': 'Формирование теста',
    'ts.overview': 'Основная информация',
    'ts.library': 'Библиотека тестов',
    'ts.create.qs': 'Создать вопросы',
    'ts.add.rating': 'Добавлена оценка',
    'ts.delete.rating': 'Оценка удалена',
    'ts.change.rating': 'Оценка была изменена',
    'ts.change.qs': 'Редактировать вопросы',
    'ts.save.success': 'Тест успешно сохранен',
    'ts.allowed.after.test': 'Разрешено после прохождения теста',
    'ts.allowed.period.test': 'Разрешен после окончания срока тестирования',
    'ts.add.event': 'Добавлен в мероприятия',
    'ts.stt.tlt': 'Настройки теста',
    'ts.desc': 'Описание теста',
    'ts.remove': 'Удаление теста',
    'ts.desc.plc': 'Введите название тестирования',
    'ts.cover': 'Обложка теста',
    'ts.cover.mini': 'Миниатюра теста',
    'ts.passing': 'Параметры прохождения',
    'ts.order.qs': 'Порядок вопросов',
    'ts.move.qs': 'Переход между вопросами',
    'ts.change.aws': 'Изменение ответа',
    'ts.change.nkw': 'Ответить "Я не знаю"',
    'ts.see.results': 'Просмотр результатов',
    'ts.see.correct': 'Просмотр верных ответов',
    'ts.atm': 'Параметры попытки',
    'ts.count.atm': 'Количество попыток',
    'ts.time.atm': 'Время на попытку',
    'ts.interval.atm': 'Интервал между попытками',
    'ts.fix.atm': 'Зафиксировать попытку',
    'ts.qs.atm': 'Вопросов в попытке',
    'ts.order.qs.hint':
      'Вопросы теста будут следовать друг за другом с последовательностью, заданной Вами в конструкторе вопросов. Выберите “случайно”, чтобы вопросы автоматически чередовались в случайном порядке.',
    'ts.fix.atm.hint':
      'Выберите "да", чтобы вопросы, сформированные в первой попытке (включая их очередность), повторялись во всех последующих.',
    'ts.move.qs.hint': 'Выберите "разрешено", чтобы ученик во время прохождения теста мог перейти к любому  вопросу.',
    'ts.change.aws.hint':
      'Выберите "разрешено", чтобы ученик во время прохождения теста мог вернуться к любому вопросу и изменить свой ответ.',
    'ts.change.nkw.hint':
      'Ответ “Я не знаю” добавляется для объективной оценки знаний, в случаях, когда ученик не зная ответ на вопрос может угадать его, выбрав ответ случайным образом.',
    'ts.see.results.hint': 'Настройте возможность просмотра результатов попытки участником',
    'ts.see.correct.hint': 'Настройте возможность просмотра верных ответов участником',
    'ts.count.atm.hint':
      'Можно изменить количество попыток прохождения теста, нажав на иконку крестика в поле ввода и вписав нужное вам число. Если вы оставите поле незаполненным или введете значение, равное нулю - количество попыток будет неограниченным. При таком значении параметра ученик может проходить тест неограниченное количество раз, пока он доступен для прохождения.',
    'ts.interval.atm.hint':
      'Можно изменить промежуток времени между каждой попыткой, нажав на иконку крестика в поле ввода и вписав нужное вам значение. Если вы оставите поле незаполненным или введете значение, равное нулю - ученик сможет проходить попытку с любой частотой.',
    'ts.amount.rts': 'Параметры подведения итогов',
    'ts.rate': 'Поставить оценку',
    'ts.rate.grate': 'Оценка за тестирование высчитывается исходя из суммы набранного балла или процента прохождения',
    'ts.grade': 'Оценка',
    'ts.prc': 'процентов',
    'ts.scores': 'баллов',
    'ts.score.desc': 'Описание к оценке',
    'ts.score.desc.plc': 'Введите описание к оценке',
    'ts.score.add': 'Добавить оценку',
    'ts.rts.desc': 'Общее описание к результату теста',
    'ts.rts.desc.text': 'К результату тестирования можно добавить общее описание, изображение и другие элементы.',
    'ts.enter.desc': 'Введите описание',
    'ts.desc.hint': 'К итоговой оценке за тестирование можно добавить общее описание, изображение и другие элементы.',
    'ts.enter.rating': 'Введите оценку',
    'ts.crt.tlt': 'Новый Тест',
    'ts.crt.name.pls': 'Введите название теста',
    'ts.crt.desc.pls': 'Введите описание теста',
    'ts.mini.cover.field': 'Миниатюра теста',
    'ts.field.know.no': 'Ответ "Я не знаю"',
  },
};
