export default {
  RU: {
    'learning.cmp.test': 'Поздравляем! Тест завершен.',
    'learning.repeat': 'Пройти заново',
    'learning.cmp.attempt': 'Данная попытка завершена',
    'learning.rating': 'Твоя оценка',
    'learning.answered': 'Отвечено',
    'learning.back': 'В кабинет',
    'learning.back.event': 'Назад в мероприятие',
    'learning.alw.tlt': 'В тесте разрешено',
    'learning.alw.change.awr': 'Изменять свои ответы на вопросы.',
    'learning.alw.qs.awr': 'Отвечать на вопросы в любой последовательности.',
    'learning.alw.know.not': 'Отвечать “я не знаю” для оценки своих реальных знаний.',
    'learning.test.start': 'Начать тест',
    'learning.progress': '% пройдено',
    'learning.chs.once.awr': 'Выберите один вариант ответа',
    'learning.chs.more.awr': 'Выберите несколько вариантов ответа',
    'learning.know.no': 'Я не знаю',
    'learning.qs.next': 'Следующий вопрос',
    'learning.test.end': 'Хотите завершить тест ?',
    'learning.inp.txt': 'Впишите ответ в текстовое поле',
    'learning.inp.txt.pls': 'Введите вариант ответа',
    'learning.att.warning.tlt': 'Вы заканчиваете тестирование не завершив все попытки.',
    'learning.result.now': 'Ваш текущий результат:',
    'learning.att.close.arg': 'Я подтверждаю, что хочу завершить тестирование досрочно',
  },
};
