import React, { FC, ReactElement, ReactNode, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal } from '../app/app.modules';
import { useRouter, useSetParams } from '../hooks';
import { IReduxStore } from '../store/serviceReducer';
import { AGENCY } from './Modal/types';

interface IProps {
  children: ReactNode;
  limited: Array<string | number | boolean>;
  replace: any;
  user: any;
  updateFollows?: Array<string | number | boolean>;
}

const Restrictions: FC<IProps> = ({ children, limited, replace, user, updateFollows = [] }): ReactElement => {
  const [key, valueKey, valueServe] = limited;

  const plan = user?.agency?.plan;

  const PLAN__Dynamic = () => ({
    events: {
      count: plan?.maxEvents,
      activate: plan?.maxActiveEvents ?? 2,
      members: plan?.maxParticipants ?? 15,
      alert(keyValue) {
        const type = {
          count: 'Первышено максимальное возможное создание мероприятий',
          activate: 'Превышено максимальное количество активированых мероприятий',
          members: 'Превышено максимальное количество приглашенных людей в акстивное мероприятие',
        };

        return type[keyValue];
      },
    },
    tests: {
      count: plan?.maxTests,
      // activate: 2,
      alert(keyValue) {
        const type = {
          count: 'Превышено максимально возможное количество тестов',
          // activate: 'Превышено максимальное количество активированых мероприятий',
        };

        return type[keyValue];
      },
    },
    homework: {
      lock: !plan.hasHomework,
      alert(keyValue) {
        const type = {
          lock: 'Доступно в тарифе "Стандарт"',
        };

        return type[keyValue];
      },
    },
    groups: plan.hasGroups,
  });

  const { history } = useRouter();

  const openModal = () => {
    history.push(
      useSetParams({
        win: AGENCY.TARIFF_CHANGE,
      })
    );
  };

  const valuePlanParse = (searchKey) => {
    if (typeof valueServe === 'boolean' || valueKey === null) {
      return valueServe;
    }

    const valuePlan = searchKey[valueKey as string];

    return typeof valuePlan === 'boolean' ? valuePlan : valuePlan === valueServe;
  };

  const renderContent = () => {
    const searchKey = key && PLAN__Dynamic()[key as string];

    const alert = searchKey?.alert(valueKey);

    const isPlan = valuePlanParse(searchKey);

    return isPlan ? (
      typeof replace === 'function' ? (
        <>
          <Modal onConfirm={() => 213} onClose={() => 132} />
          {replace({
            openModal,
            alert,
          })}
        </>
      ) : (
        replace
      )
    ) : (
      children
    );
  };

  return useMemo(renderContent, [user, ...updateFollows]);
};

const mapStateToProps = (store: IReduxStore) => ({
  user: store.middleware.user!,
});

export default connect(mapStateToProps)(Restrictions);
