import Article from './Article';
import EditorText from './EditorText';
import EventCard from './EventCard';
import Mount from './Mount';
import Portals from './Portals';

export default {
  Mount,
  Article,
  EditorText,
  EventCard,
  Portals,
};
