import './index.scss';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import isEmail from 'validator/lib/isEmail';
import { useErrorFields, useEvent, useGetParams, useLocalStorage, useRouter, useToState } from '../../../hooks';
import { getRoute } from '../../../routes/client';
import { IReduxStore } from '../../../store/serviceReducer';
import { uuid } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Button, Icon, Input, Text, Toggle } from '../../app.modules';
import { middlewareSetUser } from '../middleware.actions';
import {
  middlewareUserForgotEmail,
  middlewareUserLogin,
  middlewareUserResetPassword,
  middlewareUserSignUp,
} from '../middleware.services';

interface IProps {
  authUserLoginAction: (data: any) => Promise<any>;
  authUserSignUpAction: (data: any) => Promise<any>;
  middlewareUserResetPasswordAction: (data: any) => Promise<any>;
  setAlertAction: (data: object) => any;
  setMeAction: any;
  redirect?: any;
  type?: string;
  to?: string | null;
  user: any;
  onUpdate?: any;
  middlewareUserForgotEmailAction: any;
}

interface IState {
  saveFields: boolean;
  showPassword: boolean;
  type: string;
  fields: {
    email: string;
    password: string;
  };
  signUp: {
    firstName: string;
    lastName: string;
    patronymic: string;
    email: string;
    password: string;
  };
  forgotEmailFields: {
    email: string;
  };
  resetPassword: {
    password: string;
    repeatPassword: string;
    typeRender: string;
  };
}

const MiddlewareGuard: FC<IProps> = function ({
  authUserLoginAction,
  authUserSignUpAction,
  middlewareUserForgotEmailAction,
  middlewareUserResetPasswordAction,
  setAlertAction,
  onUpdate,
  redirect = true,
  to = null,
  type = 'Login',
}): ReactElement {
  const { history } = useRouter();

  const [localEssence, setLocalEssence] = useLocalStorage('saveIn', null);

  const [state, setState] = useToState<IState>({
    saveFields: !!localEssence,
    showPassword: false,
    type: type,
    fields: localEssence
      ? localEssence
      : {
          email: '',
          password: '',
        },
    signUp: {
      firstName: '',
      lastName: '',
      patronymic: '',
      email: '',
      password: '',
    },
    forgotEmailFields: {
      email: '',
    },
    resetPassword: {
      password: '',
      repeatPassword: '',
      typeRender: 'Reset',
    },
  });

  const [errorLogin, setErrorLogin] = useErrorFields(state.fields, (value, key) => {
    switch (key) {
      case 'email':
        return isEmail(value);
      default:
        return value !== '' && value;
    }
  });

  const [errorForgotEmail, setErrorForgotEmail] = useErrorFields(state.forgotEmailFields, (value, key) => {
    switch (key) {
      case 'email':
        return isEmail(value);
      default:
        return value !== '' && value;
    }
  });

  const [errorResetPassword, setErrorResetPassword] = useErrorFields(state.resetPassword, (value, key) => {
    switch (key) {
      case 'password':
        return value && value.length > 6;
      case 'repeatPassword':
        return value && value.length > 6 && state.resetPassword.password === value;
      default:
        return value !== '' && value;
    }
  });

  const [errorSignUp, setErrorSignUp] = useErrorFields(state.signUp, (value, key) => {
    switch (key) {
      case 'firstName':
      case 'lastName':
        return value !== '' && value;
      case 'email':
        return isEmail(value);
      case 'password':
        return value !== '' && value.length > 5;
      default:
        return true;
    }
  });

  const onChangeTypeAuth = (type) => {
    setState({
      type,
      showPassword: false,
    });
  };

  useEffect(() => {
    type !== state.type && onChangeTypeAuth(type);
  }, [type]);

  const onFieldChange = (e: any, key: string, type = 'fields', define = setErrorLogin) => {
    const { value } = e.target;

    const newEssence = {
      ...state,
      [type]: {
        ...state[type],
        [key]: value,
      },
    };

    define(key, value);

    setState(newEssence);

    type === 'fields' && setLocalEssence(state.saveFields ? newEssence[type] : null);
  };

  const saveData = () => {
    setState({
      ...state,
      saveFields: !state.saveFields,
    });
    setLocalEssence(!state.saveFields ? state.fields : null);
  };

  const onShowPassword = () => {
    setState({
      ...state,
      showPassword: !state.showPassword,
    });
  };

  const onSubmit = (data, define, action) => {
    define(data).then((resolve) => {
      action(resolve)
        .then(() => {
          // authSessionAction({
          //   connect: true,
          //   type: TYPES.USER,
          // });// TODO [Thunk]: Target user dist

          redirect ? history.push('/') : onUpdate();
        })
        .catch((error) => {
          setAlertAction({
            code: '08' + (error?.code ?? '9999'),
            uuid: uuid(),
            params: {},
          });
        });
    });
  };

  const submitLogin = () => {
    onSubmit({ ...state.fields, rememberMe: state.saveFields }, setErrorLogin, authUserLoginAction);
  };

  const submitSignUp = () => {
    onSubmit(state.signUp, setErrorSignUp, authUserSignUpAction);
  };

  const submitForgotEmail = () => {
    setErrorForgotEmail(state.forgotEmailFields).then((resolve) => {
      middlewareUserForgotEmailAction(resolve)
        .then(() => {
          history.push({
            pathname: to,
            search: `email=${state.forgotEmailFields.email}`,
          });
        })
        .catch((error) => {
          setAlertAction({
            code: '08' + (error?.code ?? '9999'),
            uuid: uuid(),
            params: {},
          });
        });
    });
  };

  const submitResetPassword = (token) => () => {
    setErrorResetPassword(state.resetPassword).then((resolve) => {
      middlewareUserResetPasswordAction({
        password: resolve.password,
        token,
      })
        .then(() => {
          history.push(to);
          setAlertAction({
            code: '080902',
            uuid: uuid(),
            params: {},
          });
        })
        .catch((error) => {
          setState({
            resetPassword: {
              ...state.resetPassword,
              typeRender: 'Leave',
            },
          });
          setAlertAction({
            code: '08' + (error?.code ?? '9999'),
            uuid: uuid(),
            params: {},
          });
        });
    });
  };

  const keyboardSubmitLogin = (e) => {
    if (e.keyCode === 13) {
      const types = {
        Login: submitLogin,
        SignUp: submitSignUp,
        Forgot: submitForgotEmail,
        ForgotSuccess: submitForgotSuccess,
        ResetPassword: submitResetPassword,
      };

      types[type]();
    }
  };

  const onToSignUp = () => {
    to ? history.push(to) : onChangeTypeAuth('SignUp');
  };

  const onToLogin = () => {
    to ? history.push(to) : onChangeTypeAuth('Login');
  };

  useEvent('keydown', keyboardSubmitLogin);

  const renderContentLogin = () => {
    return (
      <>
        <div className="login__data">
          <h1 className="login__data-title t--center">Вход</h1>
          <Input
            placeholder="E-mail*"
            type="email"
            value={state.fields['email']}
            error={!errorLogin['email']}
            // errorText="Поле email некорректно"
            onChange={(e) => onFieldChange(e, 'email')}
          />
          <Input
            placeholder="Пароль"
            type={state.showPassword ? 'text' : 'password'}
            value={state.fields['password']}
            error={!errorLogin['password']}
            // errorText="Введите пароль"
            onChange={(e) => onFieldChange(e, 'password')}
          >
            <Icon className="login__icon" name={state.showPassword ? 'ELEyeHide' : 'ELEye'} onClick={onShowPassword} />
          </Input>
        </div>
        <div className="login__source">
          <Toggle defaultChecked={state.saveFields} replace variant="success" onChange={saveData}>
            <Icon name="CheckSuccess" />
            <Text variant="lg">Запомнить пароль</Text>
          </Toggle>
          <Link to={getRoute('AF', 'path')} className="login__source-forgot">
            <Text variant="lg">Забыли пароль?</Text>
          </Link>
          <Text variant="xs">При входе вы даете согласие на обработку персональных данных.</Text>
          <Button auto onClick={submitLogin} className="login__btn">
            Войти
          </Button>
          <Button variant="text" className="login__btn" onClick={onToSignUp}>
            <a>Зарегистрироваться</a>
          </Button>
        </div>
      </>
    );
  };

  const renderSignUp = () => {
    return (
      <>
        <div className="login__data">
          <h1 className="login__data-title t--center">Регистрация</h1>
          <Input
            placeholder="Фамилия"
            value={state.signUp['lastName']}
            error={!errorSignUp['lastName']}
            onChange={(e) => onFieldChange(e, 'lastName', 'signUp', setErrorSignUp)}
          />
          <Input
            placeholder="Имя"
            value={state.signUp['firstName']}
            error={!errorSignUp['firstName']}
            onChange={(e) => onFieldChange(e, 'firstName', 'signUp', setErrorSignUp)}
          />
          <Input
            placeholder="Отчество"
            value={state.signUp['patronymic']}
            error={!errorSignUp['patronymic']}
            onChange={(e) => onFieldChange(e, 'patronymic', 'signUp', setErrorSignUp)}
          />
          <Input
            placeholder="E-mail*"
            type="email"
            value={state.signUp['email']}
            error={!errorSignUp['email']}
            onChange={(e) => onFieldChange(e, 'email', 'signUp', setErrorSignUp)}
          />
          <Input
            placeholder="Пароль"
            value={state.signUp['password']}
            error={!errorSignUp['password']}
            type={state.showPassword ? 'text' : 'password'}
            onChange={(e) => onFieldChange(e, 'password', 'signUp', setErrorSignUp)}
          >
            <Icon className="login__icon" name={state.showPassword ? 'ELEyeHide' : 'ELEye'} onClick={onShowPassword} />
          </Input>
        </div>
        <Text className="login__source-desc" variant="xs">
          Регистрируясь, вы даете согласие на обработку персональных данных.
        </Text>
        <div className="login__source">
          <Button auto onClick={submitSignUp} className="login__btn">
            Зарегистрироваться
          </Button>
          <Button variant="text" className="login__btn" onClick={onToLogin}>
            <a>У меня уже есть аккаунт</a>
          </Button>
        </div>
      </>
    );
  };

  const renderForgot = () => {
    return (
      <>
        <div className="login__data">
          <Text className="t--center">
            <strong>Введите ваш E-mail, и мы вышлем ссылку на восстановление пароля</strong>
          </Text>
          <Input
            placeholder="E-mail*"
            type="email"
            value={state.forgotEmailFields['email']}
            error={!errorForgotEmail['email']}
            // errorText="Поле email некорректно"
            onChange={(e) => onFieldChange(e, 'email', 'forgotEmailFields', setErrorForgotEmail)}
          />
        </div>
        <div className="login__source">
          <Button auto onClick={submitForgotEmail} className="login__btn">
            Восстановить пароль
          </Button>
          <Link to={getRoute('AL', 'path')} className="login__forgot">
            <Text variant="md">Я вспомнил пароль</Text>
          </Link>
        </div>
      </>
    );
  };

  const submitForgotSuccess = () => {
    history.push(getRoute('AL', 'path'));
  };

  const renderForgotSuccess = () => {
    const email = useGetParams('email');

    return (
      <>
        <div className="login__data t--center">
          <Text>
            <strong>
              Вам отправлена ссылка <br />
              для восстановления пароля
            </strong>
          </Text>
          <Text>
            Проверьте почту
            <a className="login__data-link" href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
              {email}
            </a>
            ,<br />
            ссылка для восстановления уже там
          </Text>
        </div>
        <div className="login__source">
          <Button auto onClick={submitForgotSuccess} className="login__btn">
            Спасибо, посмотрю!
          </Button>
        </div>
      </>
    );
  };

  const onLeaveResetPassword = () => {
    history.push(to);
  };

  const renderResetPassword = () => {
    const { typeRender } = state.resetPassword;

    const token = useGetParams('token');

    const types = {
      Leave: (
        <div className="login__source">
          <Button auto onClick={onLeaveResetPassword} className="login__btn">
            Покинуть страницу
          </Button>
        </div>
      ),
      Reset: (
        <>
          <div className="login__data">
            <Text className="t--center">
              <strong>Введите новый пароль</strong>
            </Text>
            <Input
              placeholder="Введите пароль"
              type="password"
              value={state.resetPassword['password']}
              error={!errorResetPassword['password']}
              // errorText="Поле email некорректно"
              onChange={(e) => onFieldChange(e, 'password', 'resetPassword', setErrorResetPassword)}
            />
            <Input
              placeholder="Повторите пароль"
              type="password"
              value={state.resetPassword['repeatPassword']}
              error={!errorResetPassword['repeatPassword']}
              // errorText="Поле email некорректно"
              onChange={(e) => onFieldChange(e, 'repeatPassword', 'resetPassword', setErrorResetPassword)}
            />
          </div>
          <div className="login__source">
            <Button auto onClick={submitResetPassword(token)} className="login__btn">
              Установить новый пароль
            </Button>
          </div>
        </>
      ),
    };

    return types[typeRender];
  };

  const renderTypesLogin = () => {
    const { type } = state;

    const types = {
      Login: renderContentLogin,
      SignUp: renderSignUp,
      Forgot: renderForgot,
      ForgotSuccess: renderForgotSuccess,
      ResetPassword: renderResetPassword,
    };

    return types[type ?? 'Login']();
  };

  return (
    <div className="login">
      <div className="login__form">{renderTypesLogin()}</div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  user: store.middleware.user!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  authUserLoginAction: bindActionCreators(middlewareUserLogin, dispatch),
  authUserSignUpAction: bindActionCreators(middlewareUserSignUp, dispatch),
  middlewareUserForgotEmailAction: bindActionCreators(middlewareUserForgotEmail, dispatch),
  middlewareUserResetPasswordAction: bindActionCreators(middlewareUserResetPassword, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
  setMeAction: bindActionCreators(middlewareSetUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MiddlewareGuard);
