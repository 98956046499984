import { useState } from 'react';

export default (fields, onCase) => {
  const callbacksState = (data, callback) => {
    let newObject = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];

        newObject = {
          ...newObject,
          [key]: callback ? callback(value, key) : value,
        };
      }
    }

    return newObject;
  };

  const [error, setError] = useState(callbacksState(fields, () => true)) as any;

  const onValidate = (value, key) => {
    return onCase(value, key);
  };

  const onChangeField = (key, value, object) => {
    setError({
      ...object,
      [key]: onValidate(value, key),
    });
  };

  const onChangeFields = (object) => {
    let newArray = { ...object };

    Object.keys(fields).map((key) => {
      newArray = {
        ...newArray,
        [key]: onValidate(fields[key], key),
      };

      return key;
    });

    setError(newArray);

    return newArray;
  };

  const onChangeFieldsAsync = (define) => {
    const errors = onChangeFields(error);

    return new Promise((resolve, reject) => {
      const testError = Object.values(errors).every((el) => el);
      // const testValues = Object.values(define).every((el) => el !== '');
      // if (testError && testValues) {

      testError ? resolve(define) : reject({ define, errors }); //TODO [Tests]: Test reject
    });
  };

  const onChangeFieldsAsyncFunction = (define) => {
    setError(define());
  };

  const onChangeError = (define, values) => {
    switch (typeof define) {
      case 'string':
        return onChangeField(define, values, error);
      case 'object':
        return onChangeFieldsAsync(define);
      case 'function':
        return onChangeFieldsAsyncFunction(define);
      default:
        return onChangeFields(error);
    }
  };

  return [error, onChangeError];
};
