import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELVideo(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" fill="none" viewBox="0 0 21 15" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M2.647 2.996h9.203c.358 0 .647.29.647.647v8.182c0 .357-.29.647-.647.647H2.647A.647.647 0 012 11.825V3.643c0-.357.29-.647.647-.647zM0 3.643A2.647 2.647 0 012.647.996h9.203a2.647 2.647 0 012.647 2.647v.045l.335-.223c.62-.411 1.397-.914 1.951-1.232.851-.487 1.736-.412 2.389.1a2.35 2.35 0 01.873 1.816v6.813c0 .803-.305 1.556-.873 2.031-.599.5-1.461.643-2.226.165a606.09 606.09 0 01-2.449-1.544v.211a2.647 2.647 0 01-2.647 2.647H2.647A2.647 2.647 0 010 11.825V3.643zm17.777.326a.556.556 0 01.159-.063h.002a.36.36 0 01.107.242v6.815c0 .24-.067.383-.116.451-.386-.241-1.126-.708-1.79-1.128l-.866-.547V5.577c.183-.124.413-.278.664-.445a38.422 38.422 0 011.84-1.163z"
        clipRule="evenodd"
      />
    </svg>
  );
}
