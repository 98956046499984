import React from 'react';

interface IProps {
  className?: string;
}

export default function Required(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="30" fill="none" viewBox="0 0 28 30" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M1 0v26.874a2 2 0 002.838 1.816l9.324-4.303a2 2 0 011.676 0l9.324 4.303A2 2 0 0027 26.874V0"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.5"
        d="M10.95 17.191l-.056.033h-.03l-.037-.04-.08-.048c-.003-.002-.016-.01-.03-.047a.381.381 0 01-.013-.179l.585-2.764a1.25 1.25 0 00-.366-1.168l-.438.464.438-.464-2.109-1.986c-.028-.034-.076-.128-.062-.317a.407.407 0 01.157-.134l.037-.004 2.757-.301a1.25 1.25 0 001.007-.737l-.686-.303.686.303 1.158-2.619a.457.457 0 01.094-.11c.05.05.115.14.173.266l1.112 2.585c.178.413.565.7 1.012.748l2.753.301.04.005h.022c.029.01.05.024.068.04a.38.38 0 01.094.147.2.2 0 01-.032.203l-.05.051-1.215 1.117-.016.014-.014.015c-.288.295-.553.568-.767.764l.507.553-.507-.553a1.3 1.3 0 00-.4 1.22l.578 2.73c.01.08 0 .142-.014.18-.013.036-.027.044-.03.046l-.034.02-.025.02a.16.16 0 01-.045.008c-.018 0-.036 0-.057-.002a.805.805 0 00-.181-.1l-2.33-1.343a1.25 1.25 0 00-1.249 0L10.95 17.19zM14 6.75h-.002H14zm-.079-.015a.014.014 0 01-.003-.001l.003.001z"
      />
    </svg>
  );
}
