import './index.scss';
import React, { FC, ReactElement, ReactNode } from 'react';
import { Button, Text } from '../../../app.modules';

interface IProps {
  onConfirm: any;
  text?: string;
  onClose: any;
  title: string | ReactNode;
  negativeButtonTitle: string | ReactNode;
  positiveButtonTitle: string | ReactNode;
}

const Cancel: FC<IProps> = function ({
  onConfirm,
  onClose,
  title,
  negativeButtonTitle,
  positiveButtonTitle,
}): ReactElement {
  return (
    <div className="cancel">
      <Text variant="lg">
        <strong>{title}</strong>
      </Text>
      <Button size="sm" variant="outlined" bold onClick={onClose}>
        {negativeButtonTitle}
      </Button>
      <Button size="sm" onClick={onConfirm}>
        {positiveButtonTitle}
      </Button>
    </div>
  );
};

export default Cancel;
