import React from 'react';

interface IProps {
  className?: string;
}

const Infinity = (props: IProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="11" fill="none" viewBox="0 0 20 11" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M5.424 11C2.432 11 0 8.533 0 5.5 0 2.469 2.433.002 5.424.002c.836.004 1.66.2 2.406.571a5.37 5.37 0 011.893 1.572L8.503 3.32a3.709 3.709 0 00-1.334-1.19 3.768 3.768 0 00-1.745-.436c-2.07 0-3.752 1.709-3.752 3.808 0 2.099 1.684 3.807 3.752 3.807 1.09 0 2.66-2.388 3.925-4.306C11.043 2.428 12.641 0 14.589 0 17.572 0 20 2.46 20 5.483c0 3.024-2.427 5.484-5.412 5.484a5.388 5.388 0 01-4.286-2.139l1.21-1.191c.337.5.794.912 1.33 1.198a3.763 3.763 0 001.746.44c2.062 0 3.74-1.701 3.74-3.792 0-2.09-1.678-3.791-3.74-3.791-1.05 0-2.602 2.355-3.848 4.248C9.026 8.543 7.408 11 5.424 11z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default Infinity;
