import './index.scss';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import EditorPage from '../../../../components/EditorPage';
import { useErrorFields, useGetParams, useRouter, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { IReduxStore } from '../../../../store/serviceReducer';
import { Button, Input, Select, Textarea, TimeFrame } from '../../../app.modules';
import { MODALS_TRIGGER } from '../../modal.types';

interface IProps {
  data: any;
}

const EventAttachPage: FC<IProps> = function ({ data }): ReactElement {
  const { history } = useRouter();

  const part: any = useGetParams('part');

  const elIndex: any = useGetParams('el');

  const modal = data[MODALS_TRIGGER.STRUCTURE.DATA];

  const element = modal?.data[+part]?.data[elIndex];

  const [state, setState] = useToState({
    handler: elIndex !== null ? 'Update' : 'Create',
    activate: false,
    params: {
      title: '',
      description: '',
      structure: '',
      startDate: null,
      endDate: null,
    },
    accessDates: !!element?.startDate || !!element?.endDate,
  });

  useEffect(() => {
    setState({
      ...(elIndex !== null && {
        activate: !!element?.params?.structure,
      }),
      params: {
        title: element?.title ?? '',
        description: element?.description ?? '',
        structure: element?.params?.structure ?? '',
        startDate: element?.startDate ?? null,
        endDate: element?.endDate ?? null,
      },
      accessDates: !!element?.startDate || !!element?.endDate,
    });
  }, [modal?.data]);

  useEffect(() => {
    if (elIndex === null) {
      setState({
        activate: true,
      });
    }
  }, [elIndex]);

  const [error, setError] = useErrorFields(state.params, (value, key) => {
    switch (key) {
      case 'title':
        return value.length;
      default:
        return true;
    }
  });

  const onChangeTextField = (key) => (e) => {
    const { value } = e?.target ?? { value: e };

    setError(key, value);

    setState((state) => ({
      params: {
        ...state.params,
        [key]: value,
      },
    }));
  };

  const onBack = () => {
    history.push({
      search: '',
    });
  };

  const onConfirm = () => {
    const modal = data[MODALS_TRIGGER.STRUCTURE.DATA];

    const params = {
      type: 'Page',
      parentId: part && modal.data[+part].id,
      position: part && modal.data[+part].data.length,
      params: state.params,
    };

    modal.onConfirm(
      {
        ...data,
        indexUp: part && +part,
        ...(elIndex && {
          indexDown: +elIndex,
        }),
      },
      params,
      state.handler
    );
  };

  const renderDateFrame = () => {
    const { startDate, endDate } = state.params;

    const { accessDates } = state;

    return (
      accessDates && (
        <>
          <div className="event-attach-page__field">
            <TimeFrame
              size="md"
              label={translate('events.str.ath.start.pbl')}
              placeholder="Выберите дату и время"
              onChange={onChangeTextField('startDate')}
              value={startDate ? new Date(startDate) : null}
              startDate={startDate ? new Date(startDate) : null}
              endDate={endDate ? new Date(endDate) : null}
              selectsStart
            />
          </div>
          <div className="event-attach-page__field">
            <TimeFrame
              size="md"
              label={translate('events.str.ath.end.pbl')}
              placeholder="Выберите дату и время"
              onChange={onChangeTextField('endDate')}
              value={endDate ? new Date(endDate) : null}
              startDate={startDate ? new Date(startDate) : null}
              endDate={endDate ? new Date(endDate) : null}
              minDate={startDate ? new Date(startDate) : null}
              selectsEnd
            />
          </div>
        </>
      )
    );
  };

  const onChangeSelectDate = (data) => {
    const { params } = data;

    setState({
      params: {
        ...state.params,
        startDate: params.startDate,
        endDate: params.endDate,
      },
      accessDates: params.showDate,
    });
  };

  const dataSelectDate = [
    {
      title: translate('events.str.ath.acc.sett.opt.event'),
      active: !state.accessDates,
      params: {
        showDate: false,
        startDate: modal?.event?.startDate,
        endDate: modal?.event?.endDate,
      },
    },
    {
      title: translate('events.str.ath.acc.sett.opt.set'),
      active: state.accessDates,
      params: {
        showDate: true,
        startDate: null,
        endDate: null,
      },
    },
  ];

  const renderButtonPanel = () => {
    const { handler } = state;

    const types = {
      Update: (
        <>
          <Button variant="outlined" size="md" monochrome onClick={onBack}>
            {translate('g.btn.cancel')}
          </Button>
          <Button size="md" onClick={onConfirm} off={state.params.title === ''}>
            {translate('g.btn.change')}
          </Button>
        </>
      ),
      Create: (
        <>
          <Button variant="outlined" size="md" monochrome onClick={onBack}>
            {translate('g.btn.cancel')}
          </Button>
          <Button size="md" onClick={onConfirm} off={state.params.title === ''}>
            {translate('g.btn.add')}
          </Button>
        </>
      ),
    };

    return types[handler];
  };

  return (
    <div className="event-attach-page">
      <h1 className="event-attach-page__title">{translate('events.str.page.tlt')}</h1>
      <div className="event-attach-page__field">
        <Input
          error={!error.title}
          onChange={onChangeTextField('title')}
          value={state.params.title}
          label={translate('events.str.ath.tlt')}
          size="md"
          placeholderNode={translate('events.str.ath.tlt.pls')}
        />
      </div>
      <div className="event-attach-page__field">
        <Textarea
          onChange={onChangeTextField('description')}
          value={state.params.description}
          label={translate('events.str.ath.desc')}
          placeholderNode={translate('events.str.ath.desc')}
        />
      </div>
      <div className="event-attach-page__field">
        {state.activate && (
          <EditorPage
            label={translate('events.str.ath.filling')}
            placeholder="Наполните страницу текстом, таблицами или другими элементами"
            data={state.params.structure}
            onChange={(api, data) => onChangeTextField('structure')(data)}
          />
        )}
      </div>
      <div className="event-attach-page__field">
        <Select data={dataSelectDate} onChange={onChangeSelectDate} label={translate('events.str.ath.acc.sett')} />
      </div>
      {renderDateFrame()}
      <div className="event-attach-page__panel">{renderButtonPanel()}</div>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  data: store.modal.data!,
});

export default connect(mapStateToProps)(EventAttachPage);
