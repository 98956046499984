import React from 'react';

function ELWarning() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none" viewBox="0 0 24 22">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M21.592 17.042L13.211 2.525a1.683 1.683 0 00-2.916 0L1.914 17.042a1.683 1.683 0 001.457 2.525h16.764a1.683 1.683 0 001.457-2.525zM14.668 1.683c-1.295-2.244-4.535-2.244-5.83 0L.456 16.201C-.84 18.445.78 21.25 3.371 21.25h16.764c2.59 0 4.21-2.805 2.915-5.05L14.668 1.684z"
        clipRule="evenodd"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M11.753 6.5v6.124m0 3.366v.253"
      />
    </svg>
  );
}

export default ELWarning;
