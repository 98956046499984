import React from 'react';

export default function Chat() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M21.949 8.515l-.007-.056v-.005l-.026-.212c-.005-.043-.01-.092-.018-.147l-.005-.044-.01.001c-.27-1.526-1.006-2.974-2.148-4.21-1.136-1.232-2.613-2.203-4.27-2.811A12.956 12.956 0 0010.99.246c-2.094 0-4.127.493-5.88 1.424C1.728 3.466-.266 6.768.029 10.082a8.68 8.68 0 001.942 4.72c1.053 1.292 2.477 2.335 4.118 3.016 1.01.419 2.007.616 3.064.825l.123.025c.291.057.37.136.39.168.037.058.017.172.002.236l-.042.174c-.113.463-.23.941-.138 1.468.106.607.484.954 1.038.955.597 0 1.275-.4 1.724-.665l.06-.035c1.071-.63 2.08-1.339 2.84-1.887 1.661-1.198 3.545-2.557 4.957-4.315 1.421-1.77 2.091-4.046 1.842-6.252z"
      />
      <circle r="1.236" fill="#fff" transform="matrix(-1 0 0 1 5.369 9.632)" />
      <circle r="1.236" fill="#fff" transform="matrix(-1 0 0 1 11.16 9.632)" />
      <circle r="1.236" fill="#fff" transform="matrix(-1 0 0 1 16.669 9.747)" />
    </svg>
  );
}
