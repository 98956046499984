import api from './notifications.api';

export function getNotifications(): Promise<any> {
  const request = api.init('getNotifications');

  return request(null);
}

export function deleteNotification(data): Promise<any> {
  const request = api.init('deleteNotification');

  return request({
    path: [data?.data.eventId, data?.data.elementId, data?.data.participantId],
  });
}
