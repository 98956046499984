import React from 'react';

export default function BellActive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="21" fill="none" viewBox="0 0 19 21">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M9.002 21c1.63 0 2.993-1.13 3.306-2.625H5.696C6.008 19.87 7.372 21 9.002 21z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M9.9 1.009V.875A.888.888 0 009 0c-.497 0-.9.391-.9.875v.945c-3.048.427-5.4 2.976-5.4 6.055v2.44c0 1.731-.78 3.366-2.15 4.49-.35.292-.55.716-.55 1.164 0 .844.706 1.531 1.575 1.531h14.85c.869 0 1.575-.687 1.575-1.531 0-.448-.2-.872-.559-1.17a5.796 5.796 0 01-2.141-4.484v-.406c-.1.005-.2.008-.3.008-3.314 0-6-2.612-6-5.834 0-1.128.33-2.181.9-3.074z"
        clipRule="evenodd"
      />
      <ellipse cx="15" cy="4.277" fill="#E45454" rx="4" ry="4.074" />
    </svg>
  );
}
