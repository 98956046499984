import './index.scss';
import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  ContextMenu,
  Hint,
  Icon,
  LocationDate,
  Permission,
  PopoverTarget,
  Square,
  Text,
} from '../../../app/app.modules';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { clearHTML } from '../../../utils';
import CardAttach from '../CardAttach';
import CardStatus from '../CardStatus';

interface IProps {
  data: any;
  options?: any;
  optionsPermissions?: any;
}

const CardMobile: FC<IProps> = ({ data, options, optionsPermissions }) => {
  const [state, setState] = useToState({
    activeContent: false,
    data: data.reduce(
      (total, current) => ({
        ...total,
        [current.field]: current,
      }),
      {}
    ),
  });

  const dataRow = data[0]; //TODO [Thunk]: May be rework on fields

  const cardId = dataRow?.id;

  const status = dataRow?.status;

  const onClick = dataRow?._onClick ?? dataRow?.onClick;

  const onToggleActiveContent = () => {
    setState({
      activeContent: !state.activeContent,
    });
  };

  const renderTestInfo = () => {
    const { testInfo } = state.data;

    const questions = testInfo?.value?.questions;

    const attemptTime = testInfo?.value?.attemptTime;

    const maxScore = testInfo?.value?.maxScore;

    const onClick = testInfo?.value?.onClick;

    const paramsSquare = {
      ...(onClick && {
        onClick: (e) => {
          e.stopPropagation();
          onClick(cardId);
        },
      }),
    };

    return (
      <div className="card-mobile__body-row">
        <Text variant="md" className="card-mobile__body-col card-mobile__label card-mobile__box">
          {translate('g.questions.s')}
          <Square variant="sm" className="card-mobile__eye" {...paramsSquare}>
            <Icon name="ELEye" />
          </Square>
        </Text>
        <Text variant="md" className="card-mobile__body-col">
          <div className="card-mobile__questions-list">
            {questions && (
              <div className="card-mobile__box">
                {translate('g.questions')}: {questions}
              </div>
            )}
            {attemptTime > 0 && (
              <div className="card-mobile__box">
                {translate('g.time')}:{' '}
                <LocationDate secondTime={attemptTime * 60}>
                  {({ getSecondToFormat }) => getSecondToFormat()}
                </LocationDate>
              </div>
            )}
            {maxScore > 0 && (
              <div className="card-mobile__box">
                {translate('g.points')}: {maxScore}
              </div>
            )}
          </div>
        </Text>
      </div>
    );
  };

  const renderAttach = () => {
    const attach = dataRow?.attach;

    return attach && <CardAttach data={attach} />;
  };

  const renderContentMenu = () => {
    const formationDataOptions = options.map((el) => ({
      ...el,
      cardId,
      ...(el?.hidden && {
        hidden: el.hidden(dataRow),
      }),
    }));

    return <ContextMenu className="card-mobile__options" data={formationDataOptions} />;
  };

  const renderOptions = () => {
    return (
      <Permission is={optionsPermissions}>
        <PopoverTarget target={renderContentMenu} mode="right">
          <Square className="card-mobile__dots" size="sm">
            <Icon name="Dots" />
          </Square>
        </PopoverTarget>
      </Permission>
    );
  };

  const renderStatus = () => {
    return <CardStatus status={status} />;
  };

  const onClickFillCard = () => {
    onClick && onClick(dataRow);
  };

  const renderHead = () => {
    return (
      <div className="card-mobile__head">
        <div className="card-mobile__thumb" onClick={onClickFillCard}>
          {state.data?.miniCoverFileId?.value ? (
            <img className="card-mobile__thumb-image" src={state.data?.miniCoverFileId?.value} alt="" />
          ) : (
            <Icon name="CoverEventSmall" className="card-mobile__thumb-image" />
          )}
        </div>
        <Text className="card-mobile__title" onClick={onClickFillCard}>
          <strong>{state?.data?.name?.value?.name?.substr(0, 100)}</strong>
        </Text>
        {options && renderOptions()}
        <Square
          className="card-mobile__arrow"
          size="sm"
          play={state.activeContent}
          collapse={state.activeContent}
          onClick={onToggleActiveContent}
        >
          <Icon name="ArrowStruct" />
        </Square>
      </div>
    );
  };

  const renderDate = () => {
    const { data } = state;

    const date = data?.startDate ?? data?.updatedAt;

    return (
      date && (
        <div className="card-mobile__body-row">
          <Text variant="md" className="card-mobile__body-col card-mobile__label">
            {date.title}
          </Text>
          <div className="card-mobile__body-col">
            <LocationDate startDate={date.value?.startDate} endDate={date.value?.endDate}>
              {({ getStartDate, getEndDate, withInfinity }) => (
                <Text variant="md">{withInfinity(getStartDate('dd.MM.yyyy'), getEndDate('dd.MM.yyyy'), <br />)}</Text>
              )}
            </LocationDate>
          </div>
        </div>
      )
    );
  };

  const renderAuthor = () => {
    const { data } = state;

    const author = data?.author;

    return (
      author && (
        <div className="card-mobile__body-row">
          <Text variant="md" className="card-mobile__body-col card-mobile__label">
            {author.title}
          </Text>
          <Text className="card-mobile__body-col">{author.value}</Text>
        </div>
      )
    );
  };

  const renderParticipantsCount = () => {
    const { data } = state;

    const participantCount = data?.participantCount;

    const isLink = participantCount?.value?.href;

    return (
      participantCount && (
        <div className="card-mobile__body-row">
          <Text variant="md" className="card-mobile__body-col card-mobile__label">
            {participantCount.title}
          </Text>
          <Text className="card-mobile__body-col">
            {isLink ? (
              <Link to={isLink} onClick={(e) => e.stopPropagation()} className="card__block-link">
                {participantCount?.value?.text}
              </Link>
            ) : (
              participantCount?.value
            )}
            {}
          </Text>
        </div>
      )
    );
  };

  const renderQuestions = () => {
    const { data } = state;

    const questions = data?.questions;

    if (!questions) return null;

    const countQuestions = questions?.value?.countQuestions;

    const hint = questions?.value?.hint;

    const onClick = questions?.value?.onClick;

    const paramsSquare = {
      ...(onClick && {
        onClick: (e) => {
          e.stopPropagation();
          onClick(dataRow?.element);
        },
      }),
    };

    const renderIcon = (ref) => (
      <Square variant="xs" innerRef={ref} className="card-mobile__eye" {...paramsSquare}>
        <Icon name="ELPencil" />
      </Square>
    );

    const emptyTest = (
      <Text variant="md" className="card-mobile__link" {...paramsSquare}>
        <Icon className="card-mobile__eye" name="ELPlusMini" />
        {translate('g.btn.create')}
      </Text>
    );

    const completedTest = (
      <Text variant="md" className="card-mobile__box">
        {hint ? (
          <Hint label={hint} move={['right', 'bottom']}>
            {(ref) => renderIcon(ref)}
          </Hint>
        ) : (
          renderIcon(null)
        )}
        <div className="card-mobile__box">{countQuestions}</div>
      </Text>
    );

    return (
      <div className="card-mobile__body-row">
        <Text variant="md" className="card-mobile__body-col card-mobile__label">
          {questions.title}
        </Text>
        <Text className="card-mobile__body-col">{countQuestions > 0 ? completedTest : emptyTest}</Text>
      </div>
    );
  };

  const renderMaxScore = () => {
    return null;
  };

  const renderBody = () => {
    const { activeContent, data } = state;

    const description = data?.name?.value?.description;

    return (
      activeContent && (
        <div className="card-mobile__body">
          {description && (
            <Text variant="md" className="card-mobile__desc">
              <div dangerouslySetInnerHTML={{ __html: clearHTML(description?.substr(0, 300)) }} />
            </Text>
          )}
          <div className="card-mobile__hr" />
          {renderQuestions()}
          {renderMaxScore()}
          {renderTestInfo()}
          {renderDate()}
          {renderAuthor()}
          {renderParticipantsCount()}
        </div>
      )
    );
  };

  const classesTableRow = classNames('card-mobile', {
    // 'card-mobile--wrap': data?.attach?.data.length > 0,
    // card__block: !data?.attach || data?.attach?.data.length === 0,
  });

  return (
    <div className={classesTableRow}>
      {renderStatus()}
      <div className="card-mobile__wrapper">
        {renderHead()}
        {renderBody()}
        {renderAttach()}
      </div>
    </div>
  );
};

export default CardMobile;
