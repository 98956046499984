import { ILearningAction, ILearningState } from './learning.resources';

const initialState: ILearningState = {};

export default function viewTestsReducer(
  state: ILearningState = initialState,
  action: ILearningAction
): ILearningState {
  switch (action.type) {
    default:
      return state;
  }
}
