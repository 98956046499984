import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { dataParseBnd } from '../../utils';

interface IProps {
  data?: any;
  onChange?: any;
  variant?: string;
  children?: any;
  partTemplate?(data: any, handler: any, html: any): any;
  identify?: string;
  className?: string;
}

const BDN: FC<IProps> = ({
  data,
  onChange,
  variant = 'primary',
  children,
  identify = 'all-scope',
  partTemplate,
  className,
}): ReactElement | null => {
  const [state, setState] = useState<any>(dataParseBnd(data));

  useEffect(() => {
    setState(dataParseBnd(data));
  }, [data]);

  const onDragStart = (start) => {
    const homeIndex = state.columnOrder.indexOf(start.source.droppableId);

    setState({
      ...state,
      homeIndex,
    });
  };

  const onDragUpdate = () => {
    // console.log(e);
  };

  const onDragEnd = (result) => {
    setState({
      ...state,
      homeIndex: null,
    });

    const { destination, source, draggableId, type } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    if (type === 'scope') {
      const newColumnOrder = Array.from(state.columnOrder);

      newColumnOrder.splice(source.index, 1);
      newColumnOrder.splice(destination.index, 0, draggableId);
      const newState = {
        ...state,
        columnOrder: newColumnOrder,
      };

      setState(newState);
      onChange && onChange(newState);
      return;
    }

    const start = state.columns[source.droppableId];

    const finish = state.columns[destination.droppableId];

    if (start === finish) {
      const newTaskIds = Array.from(start.itemIds);

      newTaskIds.splice(source.index, 1);
      newTaskIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...start,
        itemIds: newTaskIds,
      };

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.uuid]: newColumn,
        },
      };

      setState(newState);

      onChange && onChange(newState, newColumn);
      return;
    }

    //Moving from one list to another
    const startTaskIds = Array.from(start.itemIds);

    startTaskIds.splice(source.index, 1);
    const newStart = {
      ...start,
      itemIds: startTaskIds,
    };

    const finishTaskIds = Array.from(finish.itemIds);

    finishTaskIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      itemIds: finishTaskIds,
    };

    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.uuid]: newStart,
        [newFinish.uuid]: newFinish,
      },
    };

    setState(newState);

    onChange && onChange(newState, newStart, newFinish);
  };

  const classesBDN = classNames(className, {
    [`bdn--${variant}`]: variant,
  });

  return (
    state && (
      <div className={classesBDN}>
        <DragDropContext onDragStart={onDragStart} onDragUpdate={onDragUpdate} onDragEnd={onDragEnd}>
          <Droppable droppableId={identify} type="scope">
            {(providedWrapper) => {
              return (
                <div {...providedWrapper.droppableProps} ref={providedWrapper.innerRef}>
                  {state.columnOrder.map((item, indexUp) => {
                    const column = state.columns[item];

                    const tasks = column.itemIds.map((taskId) => state.items[taskId]);

                    // console.log(column);
                    return (
                      <Draggable key={column.uuid} draggableId={column.uuid} index={indexUp}>
                        {(providedWrapperInner, snapshotWrapperInner: any) => {
                          return (
                            <div
                              {...providedWrapperInner.draggableProps}
                              ref={providedWrapperInner.innerRef}
                              key={column.uuid}
                              className={`bdn--${variant}__area ${column?.className ? column?.className : ''} ${
                                snapshotWrapperInner.isDragging ? 'active' : ''
                              }`}
                            >
                              <Droppable
                                droppableId={column.uuid}
                                type="task"
                                // type={column.id === 'column-1' ? 'done' : 'active'}
                                // isDropDisabled={column.disabled}
                                // isCombineEnabled={true}
                                // ignoreContainerClipping={true}
                              >
                                {(provided, snapshot) => {
                                  column.indexUp = indexUp;
                                  const template = [
                                    tasks.map((field, index) => {
                                      field.indexUp = indexUp;
                                      field.indexDown = index;

                                      return (
                                        <Draggable
                                          draggableId={field.uuid}
                                          index={index}
                                          isDragDisabled={field.disabled}
                                          key={field.uuid}
                                        >
                                          {(provided, snapshot) => {
                                            const params = {
                                              ...provided.draggableProps,
                                              ref: provided.innerRef,
                                              className: `bdn--${variant}__item ${snapshot.isDragging ? 'active' : ''}`,
                                            };

                                            return (
                                              <div key={field.uuid} {...params}>
                                                {children(field, provided.dragHandleProps)}
                                              </div>
                                            );
                                          }}
                                        </Draggable>
                                      );
                                    }),
                                    provided.placeholder,
                                    provided.droppableProps,
                                    provided.innerRef,
                                  ];

                                  const isWrapperParams = {
                                    ...((!partTemplate || column?.disabled) && {
                                      ref: provided.innerRef,
                                      ...provided.droppableProps,
                                    }),
                                  };

                                  return (
                                    <div
                                      className={`bdn--${variant}__area-outlined ${
                                        snapshot.isDraggingOver ? 'active' : ''
                                      }`}
                                      {...isWrapperParams}
                                    >
                                      <span {...(!partTemplate && { ...providedWrapperInner.dragHandleProps })} />
                                      {partTemplate
                                        ? partTemplate(
                                            column,
                                            providedWrapperInner.dragHandleProps,
                                            !column?.disabled ? template : template
                                          )
                                        : !column?.disabled && template[0]}
                                      {/*<div {...providedWrapperInner.dragHandleProps}>{column.part.title}</div>*/}
                                      {/*{!column?.disabled &&*/}
                                      {!partTemplate && provided.placeholder}
                                    </div>
                                  );
                                }}
                              </Droppable>
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {providedWrapper.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>
      </div>
    )
  );
};

export default BDN;
