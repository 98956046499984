import React from 'react';

interface IProps {
  onClick?(): void;
}

export default function ELPlus(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" {...props}>
      <circle cx="16" cy="16" r="15" stroke="var(--el-fonts--icon-color)" strokeWidth="2" />
      <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M9.5 16h13M16 9.25v13.5" />
    </svg>
  );
}
