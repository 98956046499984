import React from 'react';

export default function Upload() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="none" viewBox="0 0 16 16">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M8.045 11.2a.589.589 0 01-.429-.185l-4.151-4.4c-.36-.381-.093-1.015.428-1.015h2.175V1c0-.551.443-1 .988-1h1.977c.545 0 .988.449.988 1v4.6h2.175c.52 0 .788.634.428 1.014l-4.15 4.4a.589.589 0 01-.43.186zm6.571 4.8H1.384A1.393 1.393 0 010 14.6v-.4c0-.772.62-1.4 1.384-1.4h13.232c.763 0 1.384.628 1.384 1.4v.4c0 .772-.62 1.4-1.384 1.4z"
      />
    </svg>
  );
}
