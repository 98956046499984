export default function addOrRemoveFromArray(array, predicate) {
  const clonedArray = array.slice();

  const index = array.indexOf(predicate);

  if (index >= 0) {
    clonedArray.splice(index, 1);
  } else {
    clonedArray.push(predicate);
  }

  return clonedArray;
}
