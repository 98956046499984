import React from 'react';

function ELGroups() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" fill="none" viewBox="0 0 30 28">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M21.493 9.412a5.094 5.094 0 01-1.853 1.309 4.766 4.766 0 101.393-8.55A5.098 5.098 0 0122.377 4a2.766 2.766 0 11-.884 5.411zm-13.872 0a5.094 5.094 0 001.853 1.309 4.766 4.766 0 11-1.393-8.55A5.098 5.098 0 006.737 4a2.766 2.766 0 10.884 5.411zM17.003 18h10.575c.846 0 1.532-.686 1.532-1.532a3.915 3.915 0 00-3.915-3.915H22.5a4.194 4.194 0 012.057 2h.638c.896 0 1.649.616 1.858 1.447h-2.1c.011.12.017.242.017.366 0 .902-.732 1.634-1.634 1.634h-6.333zm-4.896 0H1.532A1.532 1.532 0 010 16.468a3.915 3.915 0 013.915-3.915h2.694a4.193 4.193 0 00-2.057 2h-.637c-.896 0-1.648.616-1.857 1.447h2.097a4.23 4.23 0 00-.016.366c0 .902.731 1.634 1.634 1.634h6.334z"
        clipRule="evenodd"
      />
      <circle cx="14.724" cy="5.66" r="4.66" stroke="var(--el-fonts--icon-color)" strokeWidth="2" />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M7.64 17.18a3.649 3.649 0 013.649-3.648h6.872a3.649 3.649 0 013.65 3.649.82.82 0 01-.82.819H8.46a.82.82 0 01-.819-.82z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M17.64 24.165h-5.671M14.804 27v-5.67M28.14 22.5c-1.5.5-3 1-5.5 1.5m-21.2-1.5c1.5.5 3 1 5.5 1.5"
      />
    </svg>
  );
}

export default ELGroups;
