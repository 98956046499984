import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect } from 'react';
// import { NAME_ROLES } from '../../../../../app/Agency/agency.types';
import { useGetParams, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { parserName } from '../../../../utils';
import Button from '../../../Button';
import Text from '../../../Text';

interface IProps {
  close?(): void;
  onConfirm(data: any): void;
  data: any;
}

const AgencyRoles: FC<IProps> = function ({ data, onConfirm }): ReactElement {
  const [state, setState] = useToState({
    data: [],
    idRole: -1,
  });

  const paramsRoute = useGetParams('data');

  useEffect(() => {
    const routeParse = JSON.parse(`${paramsRoute}`);

    setState({
      data: data.data.filter((el) => routeParse.indexOf(el.id) !== -1),
    });
  }, [data, paramsRoute]);

  const onChangeRole = (data) => () => {
    setState({
      idRole: data.id,
    });
  };

  // const onKeydownConfirm = (e) => {
  //   const { keyCode } = e;
  //
  //   keyCode === 13 && onAddUsers();
  // };
  //
  // useEvent('keydown', onKeydownConfirm);

  // const dataSelect = (roles) => {
  //   const nameRoles = Object.keys(NAME_ROLES);
  //
  //   return nameRoles.map((key) => ({
  //     title: NAME_ROLES[key],
  //     active: roles.findIndex((el) => el.name === key) !== -1,
  //     params: {
  //       type: key,
  //     },
  //   }));
  // };

  const onChangeSubmit = () => {
    onConfirm && onConfirm(state);
  };

  const renderRoles = () => {
    return data?.roles?.map((item) => (
      <Text
        key={item.id}
        className={classNames('agency-role', {
          'agency-role--active': state.idRole === item.id,
        })}
        onClick={onChangeRole(item)}
      >
        {item?.name}
      </Text>
    ));
  };

  const renderContent = () => {
    const { data } = state;

    return (
      <div className="agency-roles__data">
        <Text className="agency-roles__data-label agency-roles__user">
          <strong>Пользователь</strong>
          {data.map((item) => (
            <div key={item.id} className="agency-roles__data-row">
              <Text className="agency-roles__data-name">
                {parserName(item.firstName, item.lastName, item.patronymic)}
              </Text>
              {/*<Select data={dataSelect(item.agencyMeta.roles)} onChange={onChangeSelect}/>*/}
            </div>
          ))}
        </Text>
        <Text className="agency-roles__data-label agency-roles__role">
          <strong>Роль</strong>
          <div className="agency-roles__role--scroll">{renderRoles()}</div>
        </Text>
        <Button className="agency-roles__btn" size="md" onClick={onChangeSubmit}>
          {translate('g.btn.change')}
        </Button>
      </div>
    );
  };

  return (
    <div className="agency-roles">
      <h1 className="agency-roles__title">Изменение роли</h1>
      {renderContent()}
    </div>
  );
};

export default AgencyRoles;
