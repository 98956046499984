import React from 'react';

export default function StorageSearchButton() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="var(--el-fonts--icon-color)"
        d="M8.191 16.395a8.156 8.156 0 004.545-1.382l4.706 4.71c.37.37.98.37 1.366 0l.915-.916a.98.98 0 000-1.367l-4.722-4.693a8.172 8.172 0 001.381-4.55C16.382 3.682 12.704 0 8.192 0 3.677 0 0 3.68 0 8.198c0 4.516 3.662 8.197 8.191 8.197zm0-13.18a4.985 4.985 0 014.98 4.983 4.985 4.985 0 01-4.98 4.983 4.985 4.985 0 01-4.979-4.983 4.985 4.985 0 014.98-4.983z"
      />
    </svg>
  );
}
