import React from 'react';

interface IProps {
  className?: string;
}

export default function ELWarnCircle(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M11.501 21.002a9.501 9.501 0 100-19.002 9.501 9.501 0 000 19.002zm0 2c6.352 0 11.501-5.149 11.501-11.5C23.002 5.148 17.853 0 11.502 0 5.148 0 0 5.15 0 11.501c0 6.352 5.15 11.501 11.501 11.501z"
        clipRule="evenodd"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M11.567 16.667c.59 0 1.067-.466 1.067-1.04 0-.575-.478-1.041-1.067-1.041s-1.066.466-1.066 1.04c0 .575.477 1.041 1.066 1.041zM10.5 6.78c0-1.04 2.133-1.04 2.133 0v6.244c0 1.041-2.133 1.04-2.133 0V6.78z"
      />
    </svg>
  );
}
