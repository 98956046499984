import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowSlideDown(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" fill="none" viewBox="0 0 12 7" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 6l5-5 5 5"
      />
    </svg>
  );
}
