import React from 'react';

interface IProps {
  onClick?(): void;
}

export default function Key(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" fill="none" viewBox="0 0 34 34" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M13.528 15.61S26.022 3.024 28.212.8c2.19-2.222 5.54 2.315 3.922 3.957a492.31 492.31 0 01-2.615 2.637s1.643 1.642 3.923 3.957c2.28 2.314-2.99 8.303-5.606 5.65a659.822 659.822 0 00-3.922-3.957l-6.462 6.523a9.401 9.401 0 01.801 6.422 9.336 9.336 0 01-3.535 5.402 9.194 9.194 0 01-12.005-.914 9.384 9.384 0 01-.922-12.117 9.241 9.241 0 015.364-3.568 9.176 9.176 0 016.373.819zm-1.662 10.91a3.731 3.731 0 001.129-2.651 3.759 3.759 0 00-1.083-2.67 3.695 3.695 0 00-2.648-1.093 3.672 3.672 0 00-2.628 1.139 3.748 3.748 0 00-1.038 2.624 3.746 3.746 0 001.083 2.605 3.683 3.683 0 002.583 1.093 3.682 3.682 0 002.602-1.047z"
      />
    </svg>
  );
}
