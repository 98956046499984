import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELHomework(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="27" fill="none" viewBox="0 0 23 27" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M6.841 1A5.829 5.829 0 001 6.841V20.16A5.829 5.829 0 006.841 26h8.538a5.828 5.828 0 005.841-5.841V14.76c0-3.273-.072-4.678-2.246-6.852l-2.247-2.303-2.247-2.303C12.698 1.52 12.04 1 9.537 1H6.841z"
      />
      <path
        fill="var(--el-fonts--icon-color)"
        d="M11.2 6.881a3.876 3.876 0 013.854 3.854l-.002 7.71c.002 2.058-1.634 3.736-3.67 3.833-.06.005-.12.022-.186.022-2.113-.002-3.846-1.735-3.854-3.854l.004-5.4c-.004-.998.647-1.846 1.538-2.17a2.29 2.29 0 012.405.538c.434.434.677 1.022.676 1.636l.002 5.394a.773.773 0 01-1.315.547.767.767 0 01-.225-.544l-.002-5.395a.767.767 0 10-1.534-.002l-.004 5.4a2.308 2.308 0 001.542 2.164c.24.087.496.147.77.146.273 0 .53-.06.77-.148a2.302 2.302 0 001.543-2.165l.002-7.71a2.331 2.331 0 00-2.316-2.316 2.34 2.34 0 00-1.563.625.826.826 0 01-.607.312.796.796 0 01-.779-.8.82.82 0 01.154-.46.83.83 0 01.23-.208A3.82 3.82 0 0111.2 6.881z"
      />
    </svg>
  );
}
