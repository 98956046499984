import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { PROFILE } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: string;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...PROFILE };
  }

  protected profileUserUpdate(data: IRequestDataParse): void {
    const { ROUTINE } = this.ROUTE;

    this.patch(ROUTINE, data);
  }

  protected profileUserPasswordUpdate(data: IRequestDataParse): void {
    const { UPDATE_PASSWORD } = this.ROUTE;

    this.patch(UPDATE_PASSWORD, data);
  }

  protected getTimeZones(data: IRequestDataParse): void {
    const { TIMEZONES } = this.ROUTE;

    this.through(TIMEZONES, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
