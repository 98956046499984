import React, { FC, ReactElement } from 'react';

interface IProps {
  children?: any;
  innerRef?: any;
  className?: string;
  onClick?(e): void;
}

const DivTarget: FC<IProps> = ({ innerRef, children, className, onClick }): ReactElement => {
  const params = {
    ...(className && { className }),
    ...(onClick && { onClick }),
    ...(innerRef && { ref: innerRef }),
  };

  return <div {...params}>{children}</div>;
};

export default DivTarget;
