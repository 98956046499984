import React from 'react';

export default function StarQuestion() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M4.355 12.86L.47 9.203C.039 8.76-.07 8.093.039 7.428c.216-.554.755-.998 1.295-1.109h.108l5.07-.554L8.672.887C8.994.333 9.46 0 10 0s1.053.526 1.368 1.22l2.05 4.767 5.071.554h.108c.648.111 1.08.555 1.295 1.109a1.58 1.58 0 01-.324 1.663l-.108.11-2.05 1.886c-.647.665-1.294 1.33-1.726 1.663l1.08 5.1c.107.665-.109 1.33-.648 1.663-.432.332-1.08.332-1.619.11 0-.11-.108-.11-.108-.11l-4.423-2.55-4.424 2.55c0 .11-.108.11-.108.11-.216.111-.432.111-.648.111-.323 0-.647-.11-.863-.332-.54-.333-.755-.998-.647-1.663l1.079-5.1zm4.98-1.718c-.129.229-.193.518-.193.868s1.267.354 1.267 0c0-.326.04-.389.122-.513.007-.01.014-.02.02-.032.096-.155.247-.344.454-.566a6.72 6.72 0 00.42-.495 1.94 1.94 0 00.276-.535c.079-.209.118-.451.118-.727 0-.586-.196-1.044-.588-1.374-.386-.33-.9-.495-1.543-.495-.549 0-1.024.118-1.427.353a2.558 2.558 0 00-.948 1c-.235.431.644 1.138 1.015.718.14-.158.218-.256.272-.323a.733.733 0 01.24-.223 1.27 1.27 0 01.705-.202c.274 0 .492.074.654.222.168.142.252.337.252.586a.87.87 0 01-.134.475 3.735 3.735 0 01-.403.505 4.662 4.662 0 00-.579.758zm-.134 3.141c.156.175.35.263.579.263.23 0 .42-.088.57-.263a.973.973 0 00.235-.656.917.917 0 00-.226-.637.73.73 0 00-.58-.263.73.73 0 00-.578.263.917.917 0 00-.227.636c0 .256.076.475.227.657z"
        clipRule="evenodd"
      />
    </svg>
  );
}
