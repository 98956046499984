import { IEventsAction, IEventsState } from './events.resources';
import types from './events.types';

const initialMap = {
  data: [],
  total: 0,
  loading: true,
}; //TODO [Move]: May be move to shared ?

const initialState: IEventsState = {
  resourcesDP: {
    current: initialMap,
    upcoming: initialMap,
    completed: initialMap,
  },
  structureDP: {},
  singles: {},
};

export default function eventsReducer(state: IEventsState = initialState, action: IEventsAction): IEventsState {
  const actionType = {
    [types.RESOURCES_DP]: {
      ...state,
      resourcesDP: action.resourcesDP,
    },
    [types.STRUCTURE_DP]: {
      ...state,
      structureDP: action.structureDP,
    },
    [types.SINGLES]: {
      ...state,
      singles: action.singles,
    },
  };

  return actionType[action.type] ?? state;
}
