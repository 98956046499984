import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { STORAGE } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: any;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...STORAGE };
  }

  public getRootStorage(data: IRequestDataParse): void {
    const { GET_ROOTS } = this.ROUTE;

    this.get(GET_ROOTS, data);
  }

  public getStorage(data: IRequestDataParse): void {
    const { GET_ITEMS } = this.ROUTE;

    this.post(GET_ITEMS, data);
  }

  public createFolderStorage(data: IRequestDataParse): void {
    const { FOLDER } = this.ROUTE;

    this.post(FOLDER, data);
  }

  public getStorageRequest(data: IRequestDataParse): void {
    const { REQUEST } = this.ROUTE;

    this.post(REQUEST, data);
  }

  public getStoragePartUploadAbort(data: IRequestDataParse): void {
    const { ABORT } = this.ROUTE;

    this.post(ABORT, data);
  }

  public getStoragePartUpload(data: any): any {
    const { PART_UPLOAD } = this.ROUTE;

    return this.upload(PART_UPLOAD, data);
  }

  public deleteStorage(data: IRequestDataParse): void {
    const { ENDPOINT } = this.ROUTE;

    this.delete(ENDPOINT, data);
  }

  public deleteMassStorage(data: IRequestDataParse): void {
    const { DELETE } = this.ROUTE;

    this.delete(DELETE, data);
  }

  public setStorage(data: any): Promise<any> {
    const { ENDPOINT } = this.ROUTE;

    return this.upload(ENDPOINT, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
