import React from 'react';

interface IProps {
  className?: string;
  onClick?(): void;
}

export default function ELWebinar(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="28" fill="none" viewBox="0 0 35 28" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.778"
        d="M34.111 6.222A5.333 5.333 0 0028.778.89H18.206a5.333 5.333 0 00-5.333 5.333v4.901a5.333 5.333 0 005.333 5.333h5.4a2.88 2.88 0 012.242 1.074l1.713 2.126c.324.401.967.086.848-.416a2.262 2.262 0 012.201-2.784h.08a3.42 3.42 0 003.421-3.42V6.222z"
      />
      <path
        fill="#fff"
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="1.778"
        d="M6.222 5.282H18.5a5.333 5.333 0 015.333 5.333v6.532A5.333 5.333 0 0118.5 22.48h-4.33c-.926 0-1.801.424-2.375 1.15l-1.8 2.275c-.398.503-1.2.116-1.055-.508a2.378 2.378 0 00-2.316-2.917H5.519a4.63 4.63 0 01-4.63-4.63v-7.235a5.333 5.333 0 015.333-5.333z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M15.68 13.446a1 1 0 010 1.732l-4.07 2.35a1 1 0 01-1.5-.867v-4.699a1 1 0 011.5-.866l4.07 2.35z"
      />
    </svg>
  );
}
