import React from 'react';

interface IPops {
  className?: string;
}

export default function ELBell(props: IPops) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" fill="none" viewBox="0 0 24 22" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M11.794 1C7.846 1 4.826 4.267 4.826 7.154c0 1.73.134 2.732-.243 4.018-.571 1.811-3.19 3.372-2.454 5.128.288.685 1.141.941 2.56.941h14.622c1.418 0 2.271-.256 2.558-.94.737-1.757-1.882-3.318-2.453-5.13-.377-1.285-.243-2.288-.243-4.017 0-2.887-3.02-6.154-6.968-6.154-.068 0-.133.01-.2.01-.072-.001-.14-.01-.212-.01z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeWidth="2"
        d="M8.202 17.194h7.596c0 1.49-1.261 3.826-3.879 3.798-2.742-.03-3.717-2.198-3.717-3.798z"
      />
    </svg>
  );
}
