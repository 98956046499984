import { Dispatch } from 'redux';
import { IEventsStructureDP, IEventsStructurePage } from './events.resources';
import types from './events.types';

export function setEventsDP(resourcesDP, type) {
  return (dispatch: Dispatch, getStore) => {
    const data = getStore().events.resourcesDP;

    dispatch({
      type: types.RESOURCES_DP,
      resourcesDP: {
        ...data,
        [type]: {
          ...resourcesDP,
          loading: false,
        },
      },
    });
  };
}

export function eventAddDP(data: object, type) {
  return (dispatch: Dispatch, getStore) => {
    const resourcesDP = getStore().events.resourcesDP;

    dispatch({
      type: types.RESOURCES_DP,
      resourcesDP: {
        ...resourcesDP,
        [type]: {
          total: resourcesDP[type].total + 1,
          data: [data, ...resourcesDP[type].data],
        },
      },
    });
  };
}

export function setEventStructureDP(id: number, data: IEventsStructureDP) {
  return (dispatch: Dispatch, getStore) => {
    const structureDP = getStore().events.structureDP;

    const findPage = structureDP[id];

    dispatch({
      type: types.STRUCTURE_DP,
      structureDP: {
        ...structureDP,
        ...(findPage
          ? {
              [id]: {
                page: {
                  ...findPage.page,
                  ...data[id].page,
                },
                elements: data[id].elements,
                ...findPage,
              },
            }
          : data),
      },
    });
  };
}

export function setEvent(eventId: number, event: IEventsStructurePage) {
  return (dispatch: Dispatch, getStore) => {
    const singles = getStore().events.singles;

    const status = singles[eventId];

    dispatch({
      type: types.SINGLES,
      singles: {
        ...singles,
        [eventId]: {
          ...(status
            ? {
                ...status,
                ...event,
              }
            : event),
        },
      },
    });
  };
}

export function setLastOpenElementId(eventId: number, elementId: number | null) {
  return (dispatch: Dispatch, getStore) => {
    const structureDP = getStore().events.structureDP;

    const event = structureDP[eventId];

    dispatch({
      type: types.STRUCTURE_DP,
      structureDP: {
        ...structureDP,
        ...(event
          ? {
              [eventId]: {
                lastOpenElementId: elementId,
                ...structureDP[eventId],
              },
            }
          : undefined),
      },
    });
  };
}

// export function setEventOpenSection(eventId: number, sectionIds: Set<number>) {
//   return (dispatch: Dispatch, getStore) => {
//     const structureDP = getStore().events.structureDP;

//     const findPage = structureDP[eventId];

//     dispatch({
//       type: types.STRUCTURE_DP,
//       structureDP: {
//         ...structureDP,
//         ...(findPage
//           ? {
//             [eventId]: {
//               ...findPage,
//               openSectionId: sectionIds,
//             },
//           }
//           : undefined),
//       },
//     });
//   };
// }
