import React from 'react';

interface IProps {
  className?: string;
}

export default function EW({ className }: IProps) {
  const params = { className };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512" {...params}>
      <path
        fill="#ffdf40"
        d="M301 512h-90v-35.79c-28.037-5.742-54.902-16.89-78.838-32.725l-24.668 24.639-63.633-63.633L68.5 379.838C52.68 355.902 41.518 329.037 35.805 301H0v-90h35.805c5.713-28.037 16.875-54.902 32.695-78.838l-24.639-24.653 63.633-63.633 24.668 24.639C156.098 52.68 182.963 41.532 211 35.79V0h90v35.79c28.037 5.742 54.902 16.89 78.838 32.725l24.668-24.639 63.633 63.633-24.639 24.653c15.82 23.936 26.982 50.801 32.695 78.838H512v90h-35.805c-5.713 28.037-16.875 54.902-32.695 78.838l24.639 24.653-63.633 63.633-24.668-24.639C355.902 459.32 329.037 470.468 301 476.21z"
      />
      <path
        fill="#ffbe40"
        d="M443.5 132.162l24.639-24.653-63.633-63.633-24.668 24.639C355.902 52.68 329.037 41.532 301 35.79V0h-45v512h45v-35.79c28.037-5.742 54.902-16.89 78.838-32.725l24.668 24.639 63.633-63.633-24.639-24.653c15.82-23.936 26.982-50.801 32.695-78.838H512v-90h-35.805c-5.713-28.037-16.875-54.902-32.695-78.838z"
      />
      <circle cx="256" cy="256" r="150" fill="#ecf2ff" />
      <path fill="#d9e5ff" d="M406 256c0-82.705-67.295-150-150-150v300c82.705 0 150-67.295 150-150z" />
      <path
        fill="#5a7380"
        d="M256 91c-90.967 0-165 74.019-165 165s74.033 165 165 165 165-74.019 165-165S346.967 91 256 91zm15 298.484V361h-30v28.484C178.675 382.473 129.525 333.325 122.516 271H151v-30h-28.484c7.009-62.325 56.158-111.473 118.484-118.484V151h30v-28.484c62.325 7.011 111.475 56.158 118.484 118.484H361v30h28.484C382.475 333.325 333.325 382.473 271 389.484z"
      />
      <path fill="#5a7380" d="M331 271h-90v-90h30v60h60z" />
      <g fill="#475e66">
        <path d="M271 151v-28.484c62.325 7.011 111.475 56.158 118.484 118.484H361v30h28.484C382.475 333.325 333.325 382.473 271 389.484V361h-15v60c90.967 0 165-74.019 165-165S346.967 91 256 91v60z" />
        <path d="M271 181h-15v90h75v-30h-60z" />
      </g>
    </svg>
  );
}
