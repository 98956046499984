export default (object) => {
  let params = '?';

  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      const field = object[key];

      params += field && field !== '' ? `${key}=${field}&` : '';
    }
  }
  return params.substring(-1, params.length - 1);
};
