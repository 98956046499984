import './index.scss';
import React, { FC, ReactElement, ReactNode } from 'react';
import { useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { Button, Text } from '../../app.modules';

interface IProps {
  data: {
    params: {
      loading?: {
        title?: string | ReactNode;
        endCode?: string;
        onChange?(): void;
      };
      prevBtnTitle?: string | ReactNode;
      nextBtnTitle?: string | ReactNode;
      title?: string | ReactNode;
      onConfirm(): Promise<any>;
    };
    setAlert?(data: any): void;
  };
  onDestroy: any;
}

const ModalCondition: FC<IProps> = function ({ data, onDestroy }): ReactElement {
  const { params } = data;

  const loading = params?.loading;

  const [state, setState] = useToState({
    loading: false,
  });

  const onChangeEndCode = () => {
    if (!loading?.endCode || !data?.setAlert) return null;

    data.setAlert({
      code: loading.endCode,
      uuid: uuid(),
      params: {},
    });
  };

  const onConfirm = async () => {
    setState({
      loading: true,
    });

    await data.params?.onConfirm();

    onChangeEndCode();

    onDestroy();
  };

  const loadingTemplate = () => {
    return (
      <Text className="t--center">
        <strong>{loading?.title ?? 'Удаление...'}</strong>
      </Text>
    );
  };

  const mountTemplate = () => {
    return (
      <>
        <Text className="modal-condition__title">
          <strong>{params?.title ?? translate('g.btn.delete')}</strong>
        </Text>
        <div className="modal-condition__panel">
          <Button size="sm" onClick={onDestroy} variant="outlined" monochrome className="modal-condition__panel-btn">
            {params?.prevBtnTitle ?? translate('g.btn.cancel')}
          </Button>
          <Button
            size="sm"
            onClick={onConfirm}
            loading={state.loading}
            off={state.loading}
            className="modal-condition__panel-btn"
          >
            {params?.nextBtnTitle ?? translate('g.btn.delete')}
          </Button>
        </div>
      </>
    );
  };

  return <div className="modal-condition">{state.loading && loading ? loadingTemplate() : mountTemplate()}</div>;
};

export default ModalCondition;
