import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, useEffect } from 'react';
import { Text } from '../../app/app.modules';
import { useGetParams, useToState } from '../../hooks';

interface IProps {
  data: any;
  active: {
    update: boolean;
    view: boolean;
  };
  type?: undefined | 'single';
  onChangeSample?(checked: boolean, data: any): void;
  renderCheckTemplate?(data: any, checked: any, onChange: any): any;
}

const TableRow: FC<IProps> = ({ data, onChangeSample, active, renderCheckTemplate, type }): ReactElement => {
  const [state, setState] = useToState({
    active: false,
  });

  const paramsRoute = useGetParams('data');

  useEffect(() => {
    const { update, view } = active;

    update &&
      setState({
        active: view,
      });
  }, [active]);

  useEffect(() => {
    const parseRoute = JSON.parse(`${paramsRoute}`) ?? [];

    const findIndex = parseRoute?.findIndex((el) => el === data[0].id);

    findIndex !== -1 &&
      setState({
        active: true,
      });
  }, [paramsRoute]);

  const onToggleActive = (value = null) => () => {
    setState({
      active: value ? value : !state.active,
    });

    onChangeSample && onChangeSample(!state.active, data);
  };

  const onValidationClasses = (item) => {
    return classNames(`table__row-td table__col--${item.field}`, {
      [`table__row-td--${item?.params?.status}`]: item?.params?.status,
    });
  };

  const renderContent = () => {
    return data.map((item, i) => (
      <Text key={item?.field && item?.id ? `${item?.field}-${item?.id}` : i} className={onValidationClasses(item)}>
        {item.check && renderCheckTemplate && renderCheckTemplate(item, state.active, onToggleActive())}
        {item.value}
      </Text>
    ));
  };

  const classesTableRow = classNames('table__row', {
    'table__row--lock': data[0].blocked,
    'table__row--single': type === 'single',
  });

  return <div className={classesTableRow}>{renderContent()}</div>;
};

export default TableRow;
