import { INotificationAction, INotificationState } from './notifications.resources';
import types from './notifications.types';

const initialState: INotificationState = {
  resources: {
    data: [],
    total: 0,
  },
  loading: true,
};

export default function notificationReducer(
  state: INotificationState = initialState,
  action: INotificationAction
): INotificationState {
  const actionType = {
    [types.RESOURCES]: {
      ...state,
      resources: action.resources,
    },
    [types.LOADING]: {
      ...state,
      loading: action.loading,
    },
  };

  return actionType[action.type] ?? state;
}
