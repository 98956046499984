import './index.scss';
import classNames from 'classnames';
import intervalToDuration from 'date-fns/intervalToDuration';
import isAfter from 'date-fns/isAfter';
import parseISO from 'date-fns/parseISO';
import React, { FC, useEffect } from 'react';
import { useToState } from '../../hooks';

interface IProps {
  dataTime?: string;
  startDate?: number | string;
  endDate?: string;
  onTimeEnd?(): void;
  className?: string;
}

interface IState {
  years?: number | string;
  days?: number | string;
  hours: number | string;
  minutes: number | string;
  months?: number | string;
  seconds: number | string;
}

const Timer: FC<IProps> = function ({ dataTime, endDate, onTimeEnd, className }): any {
  const [state, setState] = useToState<IState>({
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  useEffect(() => {
    const timer = setInterval(() => {
      if (endDate && isAfter(Date.now(), parseISO(endDate))) {
        clearInterval(timer);

        return onTimeEnd && onTimeEnd();
      }

      const result =
        dataTime &&
        (intervalToDuration({
          start: endDate ? Date.now() : parseISO(dataTime),
          end: endDate ? parseISO(endDate) : Date.now(),
        }) as any);

      result &&
        setState({
          ...result,
          hours: result.hours < 10 ? `0${result.hours}` : result.hours,
          minutes: result.minutes < 10 ? `0${result.minutes}` : result.minutes,
          seconds: result.seconds < 10 ? `0${result.seconds}` : result.seconds,
        });
    }, 1000);

    return function cleanUp() {
      clearInterval(timer);
      setState({
        hours: '00',
        minutes: '00',
        seconds: '00',
      });
    };
  }, [dataTime]);

  const classesTimer = classNames('timer-primary', className);

  return (
    <div className={classesTimer}>
      {state?.hours}:{state?.minutes}:{state?.seconds}
    </div>
  );
};

export default Timer;
