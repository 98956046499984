import React from 'react';

interface IProps {
  className?: string;
}

export default function ArrowStruct({ className }: IProps) {
  const params = {
    className,
  };

  return (
    <svg {...params} xmlns="http://www.w3.org/2000/svg" width="13" height="7" fill="none" viewBox="0 0 13 7">
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1.535 1.768l4.768 3.42 4.768-3.42"
      />
    </svg>
  );
}
