export default {
  DE: {
    'profile.info': 'Basic info',
    'profile.last.name': 'Last name*',
    'profile.last.name.pls': 'Input last name',
    'profile.first.name': 'First name*',
    'profile.first.name.pls': 'Input name',
    'profile.middle.name': 'Patronym',
    'profile.middle.name.pls': 'Input patronym if any',
    'profile.birthdate': 'Birth date',
    'profile.gender': 'Gender',
    'profile.email': 'email',
    'profile.phone': 'Phone',
    'profile.pass.change': 'Change password',
    'profile.pass.old': 'Old password',
    'profile.pass.new': 'New password',
    'profile.pass.btn': 'Change password',
    'profile.gender.female': 'Female',
    'profile.gender.male': 'Male',
    'profile.gender.other': 'Other',
    'profile.timezone': 'Time zone',
  },
};
