import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Icon, LocationDate, Text } from '../../app/app.modules';
import translate from '../../i18n/translate';
import Button from '../Button';
import StatusView from '../StatusView';
import { BUTTON_TEMPLATE } from './card.types';

interface IProps {
  data: any;
}

const CardUser: FC<IProps> = ({ data }): ReactElement => {
  const onClick = data?.params?.onClick;

  const uri = data?.params?.uri;

  const template = data?.params?.template ?? BUTTON_TEMPLATE.DEFAULT;

  const renderThumb = () => {
    return data?.miniCoverFileId ? (
      <img className="card__image" src={uri} alt="" />
    ) : (
      <Icon name="CoverEventSmall" className="card__user-thumb__icon" />
    );
  };

  const parseName = (value) => {
    return (
      value &&
      value
        .replace(/<\/?[^>]+>/g, '')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('&mdash;', ' ')
    );
  };

  const renderSendDate = () => {
    return (
      data?.created && (
        <LocationDate date={data.created}>
          {({ getStartDate }) => (
            <Text variant="md" className="card__user-created">
              {translate('events.ivt.from.in')} {getStartDate('dd.MM.yyyy в HH:mm')}
            </Text>
          )}
        </LocationDate>
      )
    );
  };

  const renderDefaultButtons = () => {
    return (
      <div className="card__user-agencies">
        {renderSendDate()}
        <div className="card__user-buttons">
          {data.buttons?.onCancel && (
            <Button
              variant="outlined"
              size="sm"
              width="sm"
              className="card__user-button"
              onClick={data.buttons?.onCancel}
            >
              {translate('g.btn.canceled')}
            </Button>
          )}
          <Button className="card__user-button" size="sm" width="sm" onClick={data.buttons?.onConfirm}>
            {data?.buttons?.onConfirmLabel ?? translate('g.btn.accept')}
          </Button>
        </div>
      </div>
    );
  };

  const renderPaymentButtons = () => {
    const types = {
      payment: data.payment && data.status === 'pending' && (
        <>
          <div className="card__payment-info">
            <Text className="card__payment-label">{translate('events.request.pay.tlt')}</Text>
            <Text className="card__payment-pay">
              <strong>{translate('events.request.amount')}:</strong> {data.payment?.payment?.amount?.value}{' '}
              {translate('g.money.ru')}
            </Text>
          </div>
          <a href={data.payment?.payment?.confirmation?.confirmation_url} className="card__payment-link">
            <Button user size="md" className="card__payment-button">
              {translate('events.request.pay.pts')}
            </Button>
          </a>
        </>
      ),
      paymentCanceled: (data.status === 'payment_error' || data.status === 'canceled') && (
        <>
          <Text className="card__payment-label">
            {translate('events.sts.rqs')}
            <StatusView name="canceled" size="lgx" iconName="ELCloseCircle" bordered>
              {translate('g.sts.rejected.1')}
            </StatusView>
          </Text>
        </>
      ),
      rejected: data.status === 'rejected' && (
        <>
          <Text className="card__payment-label">
            {translate('events.sts.rqs')}
            <StatusView name="canceled" size="lgx" iconName="ELCloseCircle" bordered>
              {translate('g.sts.rejected.1')}
            </StatusView>
          </Text>
        </>
      ),
      pending: !data.payment && data.status === 'pending' && (
        <>
          <Text className="card__payment-label">
            {translate('events.sts.rqs')}
            <StatusView name="pending" size="lgx" iconName="ELTimerSmall" bordered>
              {translate('events.sts.wait')}
            </StatusView>
          </Text>
        </>
      ),
    };

    const searchStatus = Object.values(types).find((el) => el);

    return <div className="card__user-payment">{searchStatus}</div>;
  };

  const renderTemplateButtonsPanel = () => {
    const types = {
      [BUTTON_TEMPLATE.DEFAULT]: renderDefaultButtons,
      [BUTTON_TEMPLATE.PAYMENT]: renderPaymentButtons,
    };

    return types[template]();
  };

  const renderButtonTemplate = () => {
    const isAgency = data?.event?.author ?? data?.author;

    return (
      <div className="card__user-entry">
        {/*<div className="card__user-data">*/}
        <Text className="card__user-name">
          <strong>{data?.name}</strong>
        </Text>
        <Text variant="md" className="card__user-text">
          {parseName(data?.description)?.substr(0, 250)}
        </Text>
        {isAgency && (
          <div className="card__user-agency">
            <Icon name="Flag" />
            <Text variant="md" className="card__user-agency__name">
              {isAgency}
            </Text>
          </div>
        )}
        {/*</div>*/}
      </div>
    );
  };

  const renderDefaultTemplate = () => {
    return (
      <div className="card__user-entry">
        <Text className="card__user-name">
          <strong>{data?.name}</strong>
        </Text>
        <Text variant="md" className="card__user-text">
          {parseName(data?.description)?.substr(0, 250)}
        </Text>
        <div className="card__user-agency">
          <Icon name="Flag" />
          <Text variant="md" className="card__user-agency__name">
            {data?.agency?.name}
          </Text>
        </div>
      </div>
    );
  };

  const renderContentTemplates = () => {
    const formationType = data.buttons ? 'withButtons' : 'default';

    const types = {
      withButtons: renderButtonTemplate,
      default: renderDefaultTemplate,
    };

    return types[formationType]();
  };

  const renderContent = () => {
    const isThumbnails = !(data.miniCoverFileId === undefined && !data?.startDate && !data?.endDate);

    return (
      <>
        <div className="card__user-data">
          {isThumbnails && (
            <div className="card__user-thumb">
              {renderThumb()}
              <LocationDate startDate={data?.startDate} endDate={data?.endDate} combineInfinities={false}>
                {({ getStartDate, getEndDate }) => (
                  <div className="card__user-date">
                    <Text variant="sm">
                      {getStartDate('dd.MM.yy') ?? <Icon name="Infinity" />} -{' '}
                      {getEndDate('dd.MM.yy') ?? <Icon name="Infinity" />}
                    </Text>
                  </div>
                )}
              </LocationDate>
            </div>
          )}
          {renderContentTemplates()}
        </div>
        {data.buttons && renderTemplateButtonsPanel()}
      </>
    );
  };

  const onClickFillCard = () => {
    onClick && onClick(data);
  };

  const classesCardUser = classNames('card__user', {
    'card__user--point': onClick,
  });

  return (
    <div className={classesCardUser} onClick={onClickFillCard}>
      {renderContent()}
    </div>
  );
};

export default CardUser;
