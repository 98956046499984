import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELArrowLineRight(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" fill="none" viewBox="0 0 16 12" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.528 1l5 5-5 5M1 6.008h11.048"
      />
    </svg>
  );
}
