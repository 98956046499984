import io from 'socket.io-client';
import { ISocketClient } from './index';

class SocketClient implements ISocketClient {
  host: any;

  options: {
    path?: string;
  };

  constructor({ host, options = {} }: ISocketClient) {
    this.options = options;
    const test = io as any;

    this.host = test.connect(host, options);
  }

  public createEvent(nameEvent, params = {}) {
    this.host.emit(nameEvent, params);
  }

  public openConnect(nameConnect: string, callback: Function) {
    this.host.on(nameConnect, callback);
  }

  public closeConnect(nameChannelEvent, Fn) {
    if (nameChannelEvent) {
      this.host.removeAllListeners(nameChannelEvent);
    } else {
      this.host.disconnect();
    }

    Fn && Fn();
  }
}

export default SocketClient;
