import React from 'react';

export default function QuestionRevert() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      fill="var(--el-fonts--icon-color)"
      viewBox="0 0 15 15"
    >
      <path
        fill="var(--el-fonts--icon-color)"
        fillRule="evenodd"
        d="M7.5 0a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm0 12.803a.938.938 0 110-1.875.938.938 0 010 1.875zm1.544-5.075c-.443.342-.688.53-.688 1.075v.379c0 .43-.38.781-.85.781-.471 0-.853-.351-.853-.781v-.379c0-1.272.78-1.873 1.294-2.27.445-.344.688-.531.688-1.075 0-.613-.507-1.114-1.13-1.114-.623 0-1.13.5-1.13 1.114 0 .432-.382.781-.851.781s-.852-.349-.852-.78c0-1.476 1.272-2.676 2.834-2.676 1.56 0 2.832 1.199 2.832 2.675 0 1.271-.778 1.871-1.294 2.27z"
        clipRule="evenodd"
      />
    </svg>
  );
}
