import React, { FC, Fragment, ReactElement, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { ILocationName } from '../app/Location/location.resources';
import { IReduxStore } from '../store/serviceReducer';
import { LOCAL } from './local';
import messages from './messages';

interface IProps {
  children: ReactNode;
  langName: ILocationName;
}

const ProviderINTL: FC<IProps> = ({ children, langName }): ReactElement => {
  const type = messages[LOCAL[langName]] as any;

  return (
    <IntlProvider locale={LOCAL[langName]} textComponent={Fragment} messages={type}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  langName: store.location.langName!,
});

export default connect(mapStateToProps)(ProviderINTL);
