import './index.scss';
import React, { FC, ReactNode } from 'react';
import { Text } from '../../../app.modules';

interface IProps {
  icon: ReactNode;
  title: string | ReactNode;
}

const CreatePreview: FC<IProps> = ({ icon, title }): any => {
  return (
    <div className="question-preview">
      {icon}
      <Text variant="sm">
        <strong>{title}</strong>
      </Text>
    </div>
  );
};

export default CreatePreview;
