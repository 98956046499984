import { IMembersAction, IMembersState } from './members.resources';
import types from './members.types';

const initialState: IMembersState = {
  events: {},
};

export default function membersReducer(state: IMembersState = initialState, action: IMembersAction): IMembersState {
  const actionType = {
    [types.EVENTS]: {
      ...state,
      events: action.events,
    },
  };

  return actionType[action.type] ?? state;
}
