export const typeRedirect = {
  ADMIN: 'Panel',
  USER: 'Dashboard',
  SUPER_ADMIN: 'Admin',
};

export const typeAuth = {
  IGNORE: 'Ignore',
  LOGIN: 'Login',
  SKIP: 'Skip',
  EXCEPTION: 'EXCEPTION',
};

export default {
  SESSION: 'SESSION',
  USER: 'USER',
  SCOPES: 'SCOPES',
  ROLE_IAM: 'ROLE_IAM',
  ROLES: 'ROLES',
  SOCKET_STREAM: 'SOCKET_STREAM',
  FORCE_UPDATE: 'FORCE_UPDATE',
};
