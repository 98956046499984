import React from 'react';

interface IProps {
  className?: string;
  onClick?(): void;
}

export default function ELMiniRows(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" fill="none" viewBox="0 0 22 20" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M7.586 1.278H20M7.586 9.666H20M7.586 18.054H20"
      />
      <circle cx="1.278" cy="18.054" r="1.278" fill="var(--el-fonts--icon-color)" />
      <circle cx="1.278" cy="9.666" r="1.278" fill="var(--el-fonts--icon-color)" />
      <circle cx="1.278" cy="1.278" r="1.278" fill="var(--el-fonts--icon-color)" />
    </svg>
  );
}
