import React from 'react';

interface IProps {
  className?: string;
}

export default function CoverEventSmall(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="33" fill="none" viewBox="0 0 48 33" {...props}>
      <path fill="var(--el-ui--image-event-1-color)" d="M3.287 1.244v29.06h43.9l.044-30.26L3.287 0v1.244z" />
      <path fill="var(--el-ui--image-event-2-color)" d="M0 3.822v29.06h43.9l.044-30.26L0 2.579v1.244z" />
      <path
        fill="var(--el-ui--image-event-1-color)"
        d="M2.622 5.598a.667.667 0 10-1.333 0 .667.667 0 001.333 0zm2.043 0a.667.667 0 10-1.333 0 .667.667 0 001.333 0zm2.045 0a.666.666 0 10-1.333 0 .666.666 0 001.333 0z"
      />
      <g fill="var(--el-general--layout-color)" opacity="0.5">
        <path d="M2.533 9.285h-.178v9.064h.178V9.285zm.801 0h-.178v9.064h.178V9.285zm.709 0h-.178v9.064h.178V9.285zm.801 0h-.178v9.064h.178V9.285zm.711 0h-.178v9.064h.178V9.285zm.799 0h-.178v9.064h.178V9.285zm.712 0H6.89v9.064h.177V9.285zm.711 0H7.6v9.064h.177V9.285zm.709 0H8.31v9.064h.177V9.285zm.801 0H9.11v9.064h.178V9.285zm.711 0H9.82v9.064h.178V9.285zm.801 0h-.178v9.064h.178V9.285zm.711 0h-.178v9.064h.178V9.285z" />
        <path d="M11.509 9.285H2.355v.178h9.154v-.178zm0 .889H2.355v.178h9.154v-.178zm0 .889H2.355v.177h9.154v-.178zm0 .888H2.355v.178h9.154v-.178zm0 .889H2.355v.178h9.154v-.178zm0 .889H2.355v.177h9.154v-.178zm0 .888H2.355v.178h9.154v-.178zm0 .889H2.355v.178h9.154v-.178zm0 .89H2.355v.178h9.154v-.178zm0 .887H2.355v.178h9.154v-.178zm0 .889H2.355v.178h9.154v-.178zm2.223 2.488h-.177v9.064h.177V20.66zm.799 0h-.178v9.064h.178V20.66zm.709 0h-.178v9.064h.178V20.66zm.801 0h-.178v9.064h.178V20.66zm.711 0h-.178v9.064h.178V20.66zm.798 0h-.177v9.064h.178V20.66zm.714 0h-.178v9.064h.178V20.66zm.711 0h-.178v9.064h.178V20.66zm.709 0h-.178v9.064h.178V20.66zm.8 0h-.177v9.064h.177V20.66zm.711 0h-.177v9.064h.177V20.66zm.801 0h-.178v9.064h.178V20.66zm.711 0h-.178v9.064h.178V20.66z" />
        <path d="M22.708 20.66h-9.153v.178h9.153v-.178zm-.002.889h-9.153v.178h9.153v-.178zm0 .889h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .889h-9.153v.178h9.153v-.178zm0 .889h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .888h-9.153v.179h9.153v-.178zm0 .89h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .889h-9.153v.178h9.153v-.178zm1.065-19.283h-.177v9.064h.177v-9.064zm.801 0h-.177v9.064h.177v-9.064zm.709 0h-.177v9.064h.177v-9.064zm.801 0h-.178v9.064h.178v-9.064zm.711 0h-.178v9.064h.178v-9.064zm.799 0h-.178v9.064h.178v-9.064zm.713 0h-.178v9.064h.178v-9.064zm.711 0h-.178v9.064h.178v-9.064zm.709 0h-.178v9.064h.178v-9.064zm.8 0h-.177v9.064h.177v-9.064zm.711 0h-.177v9.064h.177v-9.064zm.801 0h-.178v9.064h.178v-9.064zm.711 0h-.178v9.064h.178v-9.064z" />
        <path d="M32.747 10.264h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .889h-9.153v.178h9.153v-.178zm0 .889h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .889h-9.153v.178h9.153v-.178zm0 .889h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178zm0 .889h-9.153v.178h9.153v-.178zm0 .889h-9.153v.177h9.153v-.177zm0 .888h-9.153v.178h9.153v-.178z" />
      </g>
      <path fill="var(--el-ui--image-event-1-color)" d="M19.772 12.13H6.71v9.065h13.063v-9.064z" />
      <path fill="var(--el-ui--image-event-3-color)" d="M21.106 13.285H8.043v9.064h13.063v-9.064z" />
      <path
        fill="var(--el-general--layout-color)"
        d="M18.174 14.53h-7.465v.177h7.465v-.178zm-1.334.443h-6.13v.177h6.132v-.177zm1.956.357h-8.087v.178h8.087v-.178zm-4.532 1.154h-3.555v.178h3.555v-.178zm1.866.446h-5.421v.177h5.42v-.177zm-.356.355H10.71v.178h5.065v-.178zm-1.51 1.242h-3.555v.178h3.555v-.178zm1.866.446h-5.421v.177h5.42v-.177zm-.356.355H10.71v.178h5.065v-.178zm-6.177-3.733a.578.578 0 100-1.156.578.578 0 000 1.156zm0 1.867a.578.578 0 100-1.155.578.578 0 000 1.155zm.09 2.133a.578.578 0 100-1.155.578.578 0 000 1.155zm31.503-6.31h-6.487v.178h6.487v-.178zm0 .979h-6.487v.177h6.487v-.177zm0 .976h-6.487v.178h6.487v-.178zm0 .979h-6.487v.177h6.487v-.177zm0 .976h-6.487v.178h6.487v-.178zm0 .979h-6.487v.178h6.487v-.178zm0 .976h-6.487v.178h6.487v-.178zm0 .977h-6.487v.178h6.487v-.178zm0 .978h-6.487v.178h6.487v-.178zm.534-14.128h-40.7v.177h40.7v-.177zm-3.779 5.243a2 2 0 100-4 2 2 0 000 4z"
      />
    </svg>
  );
}
