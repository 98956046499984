import React from 'react';

interface IProps {
  className?: string;
}

export default function ELMark(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="31" fill="none" viewBox="0 0 48 31" {...props}>
      <path
        fill="var(--el-fonts--icon-color)"
        d="M0 0h44.446c2.155 0 3.607 2.204 2.756 4.184l-4.353 10.132a3 3 0 000 2.368l4.353 10.132c.85 1.98-.601 4.184-2.756 4.184H0V0z"
      />
    </svg>
  );
}
