import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELStatsNotYet(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="2" fill="none" viewBox="0 0 10 2" {...props}>
      <path stroke="var(--el-fonts--icon-color)" strokeWidth="2" d="M0 1h9.5" />
    </svg>
  );
}
