import get from 'lodash/get';
import React, { ReactText } from 'react';
import translate from '../../../i18n/translate';
import { uuid } from '../../../utils';
import { Icon } from '../../app.modules';
import { questionType } from '../../Learning/learning.types';
import CreatePreview from './CreatePreview';
import Choice from './CreateTypes/TypeChoose';
import Free from './CreateTypes/TypeFree';

interface IProps {
  types: object;
  typeAnswers: object;
  typeDataAnswers: object;
  typeFieldsDefault: object;
  modules: object;
  answerFiltered: object;
  preview: object;
  categories: Array<{
    title: ReactText;
    path: string;
  }>;
}

class Bridge implements IProps {
  categories: Array<{
    title: ReactText;
    path: string;
  }>;

  modules: Array<{
    title: string;
    id: string;
  }>;

  types: object;

  preview: object;

  typeAnswers: object;

  typeDataAnswers: object;

  typeFieldsDefault: object;

  answerFiltered: object;

  constructor() {
    this.types = {
      [questionType.CHOICE]: {
        component: [(data) => <Choice data={data} />],
        preview: <CreatePreview key={1} icon={<Icon name="TypeChoice" />} title={translate('qs.type.choose')} />,
        typeAnswer: 'choiceQuestionAnswers',
        typeDataAnswers: 'choiceQuestionAnswerDataType',
        answerFiltered: 'choiceQuestionAnswerFiltered',
        typeValidation: 'choiceQuestionAnswerValidation',
        typeFieldsDefault: {
          multipleAnswers: false,
          shuffleAnswers: false,
          countIncomplete: false,
          score: false,
        },
      },
      [questionType.FREE]: {
        component: [(data) => <Free data={data} />],
        preview: <CreatePreview key={1} icon={<Icon name="TypeFree" />} title={translate('qs.type.free')} />,
        typeAnswer: 'studentQuestionAnswers',
        typeDataAnswers: 'studentQuestionAnswerDataType',
        answerFiltered: 'studentQuestionAnswerFiltered',
        typeValidation: 'studentQuestionAnswerValidation',
        typeFieldsDefault: {},
      },
    };

    this.modules = [
      {
        title: 'Выбор ответа',
        id: questionType.CHOICE,
      },
      {
        title: 'Свободный ответ',
        id: questionType.FREE,
      },
    ];
  }

  public choiceQuestionAnswerDataType(data, index) {
    return {
      comment: data?.comment || '',
      correct: data?.correct || false,
      id: data?.id,
      questionId: data?.questionId,
      score: +data?.score || 0,
      text: data?.text || '',
      type: data?.type || 'DefiniteAnswer',
      position: data?.position || index,
    };
  }

  public studentQuestionAnswerDataType(data) {
    return {
      type: data?.type || 'TextAnswer',
      comment: data?.comment || '',
      id: data?.id || '',
      manualCheck: data?.manualCheck || false,
      score: +data?.score || 0,
      text: data?.text || '',
    };
  }

  public choiceQuestionAnswers(data) {
    const { name, type, typeFields, typeAnswer, body, required, position, hidden, id } = data;

    return {
      name,
      id,
      required,
      body,
      type,
      typeFields,
      position,
      hidden,
      video: {
        url: data?.video?.url,
        fileId: data?.video?.fileId,
        coverId: data?.video?.coverId,
        code: data?.video?.code,
        autoPlay: data?.video?.autoPlay,
      },
      [typeAnswer]: data[typeAnswer].data.map((el, i) => this.getTypeDataAnswer(type, el, i)),
    };
  }

  public studentQuestionAnswers(data) {
    const { name, type, typeFields, typeAnswer, body, required, position, hidden, id } = data;

    return {
      name,
      id,
      body,
      required,
      type,
      typeFields,
      position,
      hidden,
      [typeAnswer]: this.getTypeDataAnswer(type, data[typeAnswer], null),
    };
  }

  public getTypeDataAnswer(type, data, index) {
    const searchField = this.types[type].typeDataAnswers;

    return this[searchField](data, index);
  }

  private getTypeFields(data) {
    const searchDefaultData = this.types[data.type].typeFieldsDefault;

    let newTypes = {};

    for (const key in searchDefaultData) {
      const field = searchDefaultData[key];

      newTypes = {
        ...newTypes,
        [key]: data?.typeFields?.hasOwnProperty(key) ? data.typeFields[key] : field,
      };
    }

    return newTypes;
  } //TODO [BackEnd]: Use from backend

  static sorted(data) {
    return data.sort((a, b) => a.position - b.position);
  }

  public choiceQuestionAnswerFiltered(data, typeAnswer, type) {
    const filtered = (!get(data, typeAnswer, [])?.length ? [0, 1] : get(data, typeAnswer, [])).map((el, i) => ({
      ...this.getTypeDataAnswer(type, el, i),
      uuid: uuid(),
    }));

    return {
      uuid: uuid(),
      data: Bridge.sorted(filtered),
    };
  }

  public studentQuestionAnswerFiltered(data, typeAnswer) {
    return this.getTypeDataAnswer(data.type, data[typeAnswer], null);
  }

  public getTypeDataQuestion(data) {
    const { type } = data;

    const { typeAnswer } = this.types[type];

    const answerParser = this.types[type].answerFiltered;

    return {
      ...data,
      type,
      hidden: data.hidden,
      uuid: uuid(),
      name: data?.name || '',
      id: data?.id,
      testId: data?.testId,
      typeFields: this.getTypeFields(data),
      position: data?.position || 0,
      typeAnswer,
      [typeAnswer]: this[answerParser](data, typeAnswer, type),
    };
  }

  private choiceQuestionAnswerValidation(question, answers) {
    const isCorrectAll = question.typeFields.multipleAnswers;

    const isCountIncomplete = question?.typeFields?.multipleAnswers && !question?.typeFields?.countIncomplete;

    const { data } = answers;

    const filteredAnswer = (key) => data.filter((el) => !el[key]);

    const filteredAnswerCorrect = data.length - filteredAnswer('correct').length < 2;

    const isCorrect = data.filter((el) => el.correct && !el.score);

    const slots = {
      body: !question?.body,
      text: filteredAnswer('text').length !== 0,
      correct: isCorrectAll ? filteredAnswerCorrect : !data.some((el) => el.correct),
      ...(isCountIncomplete && {
        scoreComplete: +question?.typeFields?.score === 0,
      }),
      ...(!isCountIncomplete && {
        score: isCorrect.length !== 0,
      }),
    };

    const test = Object.values(slots).every((el) => !el);

    return {
      error: !test,
      slots,
    };
  }

  private studentQuestionAnswerValidation(question, answers) {
    const { text, score } = answers;

    const typesAnswerText = {
      TextAnswer: !text.length,
      NumberAnswer: isNaN(text),
    };

    const slots = {
      body: !question?.body,
      text: typesAnswerText[answers.type] || text.length === 0,
      score: !+score,
    };

    const test = Object.values(slots).every((el) => !el);

    return {
      error: !test,
      slots,
    };
  }

  private errorParser(data) {
    const isTypeAnswer = data?.typeAnswer;

    const { typeValidation } = this.types[data.type];

    if (data.hasOwnProperty(isTypeAnswer)) {
      const answers = data[isTypeAnswer];

      return this[typeValidation](data, answers);
    }
  }

  public onValidData(cloneBlock) {
    const isTypeAnswer = cloneBlock?.typeAnswer;

    const { typeValidation } = this.types[cloneBlock.type];

    if (cloneBlock.hasOwnProperty(isTypeAnswer)) {
      const answers = cloneBlock[isTypeAnswer];

      const isError = this[typeValidation](cloneBlock, answers)?.error;

      return { error: isError };
    }
  }

  public parseBodyError(data) {
    const { body } = this.errorParser(data)?.slots;

    return data?.error && body;
  }

  public parseFieldsError(data) {
    const { correct } = this.errorParser(data)?.slots;

    return data?.error && correct;
  }

  public parseAnswerTextError(data) {
    const { text } = this.errorParser(data)?.slots;

    return data?.error && text;
  }

  public parseScoresError(data) {
    const { score } = this.errorParser(data)?.slots;

    return data?.error && score;
  }

  public parseScoreCompleteError(data) {
    const { scoreComplete } = this.errorParser(data)?.slots;

    return data?.error && scoreComplete;
  }

  public onErrorChange(data) {
    return {
      ...('error' in data && this.onValidData(data)),
    };
  }

  public getParseTypeDataQuestion(data, testId) {
    const filtered = get(data, 'data', [])
      .map((el) => ({
        ...el,
        ...this.getTypeDataQuestion(el),
      }))
      .sort((a, b) => a.position - b.position);

    return [
      {
        uuid: uuid(),
        data: filtered,
        testId,
      },
    ];
  }

  public onRequestData(data) {
    const { typeAnswer } = this.types[data.type];

    return this[typeAnswer](data);
  }

  public getType(data) {
    const [component] = this.types[data.type].component;

    return component(data);
  }

  public getPreview(type) {
    return this.types[type].preview;
  }

  public getModule() {
    return this.modules;
  }
}

export default new Bridge();
