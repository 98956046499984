export default {
  EN: {
    'homework.usr.awr.name': 'My answer',
    'homework.usr.send.tlt': 'Check up if you completed everything before submitting the answer',
    'homework.usr.awr.lbl':
      'The answer is being examined by the teacher.After examination it will be sent back for improvement, rejected or accepted with a mark.',
    'homework.status.pending': 'Being examined',
    'homework.status.rework': 'For improvement',
    'homework.status.result': 'To results',
    'homework.status.accepted': 'To the task',
    'homework.status.success': 'Accepted',
    'homework.status.success.lbl':
      'Your answer has been accepted by the teacher. You can discuss this decision with the teacher at',
    'homework.status.success.lbl.2': 'to the task.',
    'homework.status.cancel.lbl':
      'Your answer has been rejected by the teacher. You can discuss this decision with the teacher at',
    'homework.status.cancel.lbl.2': 'to the task.',
    'homework.history.tlt': 'Examination history',
    'homework.send.accept': 'Sent to examination',
    'homework.send.repeat': 'Reanswered',
    'homework.send.rework': 'Sent for improvement',
    'homework.awr.usr.tlt': 'Student answer',
    'homework.change.scr': 'Change mark',
    'homework.adm.cancel.lbl': 'Leave a comment for the student,why the task has been rejected.',
    'homework.adm.rework.lbl': 'Leave a comment for the student, what is needed to be improved.',
    'homework.adm.cmt': 'Teacher comment',
    'homework.adm.cmt.pls': 'Enter a comment to decision made',
    'homework.tlt': 'Homework',
  },
};
