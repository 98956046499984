import React from 'react';

interface IProps {
  onClick?(): void;
  className?: string;
}

export default function ELEyeHide(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="17" fill="none" viewBox="0 0 19 17" {...props}>
      <g clipPath="url(#a)">
        <path
          fill="var(--el-fonts--icon-color)"
          fillRule="evenodd"
          d="M2.47 4.093c-.84.84-1.52 1.83-2.02 2.92-.34.74-.34 1.59 0 2.33 1.57 3.44 5.03 5.84 9.05 5.84 1.44 0 2.81-.31 4.04-.86l-1.64-1.51a7.948 7.948 0 01-9.63-4.29c-.1-.21-.1-.46 0-.67.41-.9.98-1.71 1.68-2.39l-1.48-1.37zm12.91 6.48c.55-.61 1.01-1.3 1.36-2.06.1-.21.1-.46 0-.67-1.27-2.76-4.04-4.66-7.24-4.66-.85 0-1.67.13-2.44.38-.33.11-.7.04-.95-.19L6 3.263c-.51-.47-.36-1.33.3-1.56 1-.34 2.08-.53 3.2-.53 4.02 0 7.48 2.39 9.05 5.83.34.74.34 1.6 0 2.34-.43.94-1 1.81-1.69 2.57-.38.41-1.02.45-1.43.07-.41-.38-.42-1-.05-1.41z"
          clipRule="evenodd"
        />
        <path
          fill="var(--el-fonts--icon-color)"
          fillRule="evenodd"
          d="M7.1 6.373c-.39.52-.6 1.15-.6 1.81 0 .8.32 1.56.88 2.12s1.32.88 2.12.88c.73 0 1.44-.27 1.99-.75L10 9.043c-.15.09-.32.14-.5.14-.27 0-.52-.11-.71-.29-.18-.19-.29-.45-.29-.71 0-.15.03-.29.1-.43l-1.5-1.38zm5.19.71c-.15-.38-.38-.73-.67-1.03-.35-.35-.78-.61-1.25-.75l1.92 1.78z"
          clipRule="evenodd"
        />
        <path stroke="var(--el-fonts--icon-color)" strokeLinecap="round" strokeWidth="2" d="M1 1.583l15.33 14.15" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h18.81v16.15H0z" transform="translate(0 .583)" />
        </clipPath>
      </defs>
    </svg>
  );
}
