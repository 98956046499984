import React, { FC, ReactNode, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IReduxStore } from '../store/serviceReducer';

interface IProps {
  children: ReactNode;
  is: string | Array<string> | null | undefined;
  allowedRoles?: Array<any> | undefined;
  roleIAm: any;
}

interface IState {
  isRole: any;
}

const Permission: FC<IProps> = ({ children, is, roleIAm, allowedRoles }): any => {
  const [state, setState] = useState<IState>({
    isRole: {}, //scopes
  });

  if ((!is || is === null) && !allowedRoles) return children; //TODO

  is &&
    useEffect(() => {
      if (roleIAm?.agencyRoles?.length === 0 || !roleIAm?.agencyRoles) return;
      const [isRole] = roleIAm.agencyRoles;

      setState({
        ...state,
        isRole,
      });
    }, [roleIAm]);

  const onEntity__EVENTS = (data, params: any = null) => () => {
    const isAllow = data?.some((role) => roleIAm.eventsRoles.some((el) => el.role === role));

    if (isAllow && params) {
      return roleIAm.eventsRoles.some((el) => el.eventId === +params.eventId && data.some((role) => role === el.role));
    }

    return isAllow;
  };

  const onTestAllowedRoles = () => {
    if (!allowedRoles || !roleIAm?.eventsRoles) return null;

    const [entity, dataEntity] = allowedRoles;

    const params = allowedRoles[2];

    const typesEntity = {
      events: onEntity__EVENTS(dataEntity, params),
    };

    if (!typesEntity[entity]) return null;

    const isHavePermissions = typesEntity[entity]();

    return isHavePermissions && children;
  };

  const testOnScopeRoles = () => {
    const isOwner = state.isRole.slug === 'owner';

    if (isOwner) return children;

    if (!state?.isRole?.scopes?.length && !isOwner) return onTestAllowedRoles();

    const scopes = [...state.isRole.scopes];

    const isHavePermissions = scopes.find(
      (el) => `${el.privilege} ${el.resource}` === (Array.isArray(is) ? is.join(' ') : is)
    );

    return isHavePermissions && children;
  };

  return <>{testOnScopeRoles()}</>;
};

const mapStateToProps = (store: IReduxStore) => ({
  roleIAm: store.middleware.roleIAm!,
});

export default connect(mapStateToProps)(Permission);
