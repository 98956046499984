import React from 'react';

export default function CheckPart() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="7" fill="none" viewBox="0 0 10 7">
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeWidth="2"
        d="M8.162 1.24L4.228 5.175a.5.5 0 01-.707 0L1.413 3.067"
      />
    </svg>
  );
}
