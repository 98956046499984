import React from 'react';

interface IProps {
  className?: string;
}

export default function ELLogout(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" fill="none" viewBox="0 0 18 20" {...props}>
      <g clipPath="url(#logout)">
        <path
          fill="var(--el-fonts--icon-color)"
          fillRule="evenodd"
          d="M3 2h6.38c.55 0 1 .45 1 1v.76c0 .55.45 1 1 1s1-.45 1-1V3c0-1.66-1.34-3-3-3H3C1.34 0 0 1.34 0 3v14c0 1.66 1.34 3 3 3h6.38c1.66 0 3-1.34 3-3v-.76c0-.55-.45-1-1-1s-1 .45-1 1V17c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V3c0-.55.45-1 1-1z"
          clipRule="evenodd"
        />
        <path
          stroke="var(--el-fonts--icon-color)"
          strokeLinecap="round"
          strokeWidth="2"
          d="M6.19 10h9.52m-1.42-2.86l2.5 2.5c.2.2.2.51 0 .71l-2.5 2.5"
        />
      </g>
      <defs>
        <clipPath id="logout">
          <path fill="#fff" d="M0 0h17.94v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
