import { CONFIG } from '../../env';
import Provider, { IRequestConstructor, IRequestDataParse } from '../../providers';
import { EDUCATIONS } from '../../routes/server';

class Api extends Provider {
  ROUTE: {
    [key: string]: any;
  };

  constructor(props: IRequestConstructor) {
    super(props);
    this.ROUTE = { ...EDUCATIONS };
  }

  protected testCreate(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.CREATE, data);
  }

  protected testCreateMark(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.post(ADMIN_PANEL.CREATE_MARK, data);
  }

  protected getTests(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.LIST, data);
  }

  protected testRead(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.get(ADMIN_PANEL.ROUTINE, data);
  }

  protected testDelete(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.delete(ADMIN_PANEL.ROUTINE, data);
  }

  protected testDeleteMark(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.delete(ADMIN_PANEL.DELETE_MARK, data);
  }

  protected testUpdate(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.patch(ADMIN_PANEL.ROUTINE, data);
  }

  protected testUpdateMark(data: IRequestDataParse): void {
    const { ADMIN_PANEL } = this.ROUTE;

    this.put(ADMIN_PANEL.UPDATE_MARK, data);
  }
}

export default new Api({
  host: CONFIG.REACT_APP_API_URL || '',
});
