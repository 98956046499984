import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELRocketMin(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="25" fill="none" viewBox="0 0 19 25" {...props}>
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.159"
        d="M5.148 15.35s1.094-4.137 2.872-6.953C9.18 6.56 10.6 4.82 12.299 3.666c.86-.584 2.062-1.609 3.226-1.083"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.159"
        d="M10.454 12.117a2.005 2.005 0 101.883-3.54 2.005 2.005 0 00-1.883 3.54z"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.159"
        d="M10.742 18.324s2.817-3.22 4.157-6.268c.875-1.989 1.654-4.215 1.66-6.267.004-1.043.063-2.654-1.036-3.207M5.146 15.35s-.564 1.576.307 2.03c.87.453 2.954 1.579 3.62 1.934.671.358 1.669-.989 1.669-.989"
      />
      <path
        stroke="var(--el-fonts--icon-color)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.159"
        d="M2.075 14.394c-.136.296-.843 2.281-.339 3.239.393.746 3.135-1.403 3.357-2.113.223-.71 1.469-4.258 1.469-4.258s-3.5.97-4.487 3.132zM13.338 14.961s1.152 3.444-.089 5.472c-.17.278-1.42 1.974-2.495 2.092-.838.091-.59-3.384-.126-3.965.464-.581 2.71-3.599 2.71-3.599zM6.379 19.682l-2.092 3.934M4.916 18.857l-1.145 2.154M7.832 20.447L6.68 22.614"
      />
    </svg>
  );
}
