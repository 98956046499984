import './index.scss';
import React, { FC, ReactElement } from 'react';
import MiddlewareGuard from '../../Middleware/MiddlewareGuard';

interface IProps {
  onDestroy?: any;
  data: any;
  ButtonClose: any;
}

const ModalSignIn: FC<IProps> = function ({ onDestroy, data, ButtonClose }): ReactElement {
  const onUpdate = () => {
    // new Promise((resolve)=>{
    data.params?.onChange(onDestroy);
    // })
  };

  return (
    <div className="modal-sign-in">
      {ButtonClose()}
      <MiddlewareGuard redirect={false} onUpdate={onUpdate} />;
    </div>
  );
};

export default ModalSignIn;
